import gql from 'graphql-tag';
import { UserFields, GroupFields } from './fragments';

const GET_GROUP_MEMBER = gql`
  query getGroupMember($userId: ID!, $groupId: ID!) {
    groupMember(where: { user_id: $userId, group_id: $groupId }) {
      id
      user {
        ...UserFields
      }
      group {
        ...GroupFields
      }
      role
    }
  }
  ${UserFields}
  ${GroupFields}
`;

const UPDATE_GROUP_MEMBERS = gql`
  mutation updateGroupMembers(
    $data: [GroupMembersInput!]!
    $group_id: ID!
  ) {
    updateGroupMembers(data: $data, group_id: $group_id) {
      status
      message
      memberId
      emaiL
      user_id
      currentGroup_id
    }
  }
`;

const ADD_GROUP_MEMBERS = gql `
  mutation addGroupMembers(
    $data: [GroupMembersInput!]!
    $group_id: ID!
  ) {
    addGroupMembers(data: $data, group_id: $group_id) {
      status
      message
      memberId
      emaiL
      user_id
      currentGroup_id
    }
  }
`

const DELETE_GROUP_MEMBER = gql `
  mutation deleteGroupMember($data: GroupMemberDeleteInput!) {
    deleteGroupMember(data: $data) {
      id
      user {
        ...UserFields
      }
      group {
        ...GroupFields
      }
      role
    }
  }
  ${UserFields}
  ${GroupFields}
`;

export { GET_GROUP_MEMBER, UPDATE_GROUP_MEMBERS, ADD_GROUP_MEMBERS, DELETE_GROUP_MEMBER };
