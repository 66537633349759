import { 
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import useSortQuickResourcesAsc from '../../../../hooks/useSortQuickResourcesAsc';
import KeyElementsContentLibrary from './KeyElementsContentLibrary';
import styles from './styles';

const KeyElementsContent = ({keyElements, keyElementID, questionResources}) => {
  const classes = styles();
  const [type, setType] = useState('EBOOK');
  const [quickResource, setQuickResource] = useState([]);
  const [quickResourcesByType, setQuickResourcesByType] = useState([]);
  const [elementData, setElementData] = useState({pages: [], cards: [], clip_cards: []});

  const { sortQuickresourceByNumberTitle } = useSortQuickResourcesAsc();

  const handleGetQuickResourceByID = (id) => { 
    const quickResourceFound = quickResourcesByType.find((item, index) => {
      return item.id === id;
    });
    setQuickResource(quickResourceFound);
  }

  //This function gets text from a html tag
  const handleGetContentHtmlString = (tag) => {
    let span = document.createElement('span');
    span.innerHTML = tag
    return span.textContent || span.innerText;
  }
  
  //This function gets Resources by type of resource available.
  const handleGetQuickResourcesByType = useCallback((type, elementData) => {
    switch (type) {
      case 'EBOOK':
        const pages = elementData.pages;
        setQuickResourcesByType(sortQuickresourceByNumberTitle(pages));
        setType(type);
        setQuickResource(sortQuickresourceByNumberTitle(pages)[0]);
        break;
      case 'QUICKDECK':
        const quickdecks = elementData.cards;
        setQuickResourcesByType(sortQuickresourceByNumberTitle(quickdecks));
        setType(type);
        setQuickResource(sortQuickresourceByNumberTitle(quickdecks)[0]);
        break;
      case 'QUICKCLIP':
        const quickclips = elementData.clip_cards;
        setQuickResourcesByType(sortQuickresourceByNumberTitle(quickclips));
        setType(type);
        setQuickResource(sortQuickresourceByNumberTitle(quickclips)[0]);
        break;
      default:
        const defaultPages = elementData.pages;
        setQuickResourcesByType(sortQuickresourceByNumberTitle(defaultPages));
        setType(type);
        setQuickResource(sortQuickresourceByNumberTitle(defaultPages)[0]);
        break
    }    
  }, [sortQuickresourceByNumberTitle]);

  useEffect(() => {

    if (questionResources) {
      setElementData(questionResources);
      handleGetQuickResourcesByType(type, questionResources);
    } else {
      const keyElementData = keyElements.find((keyElement) => {
        return keyElement[1].id === keyElementID;
      });

      if (keyElementData) {
        setElementData(keyElementData[1]);
        handleGetQuickResourcesByType(type, keyElementData[1]);
      }
    }
  }, [keyElementID, type, keyElements, questionResources, handleGetQuickResourcesByType]);
  
  useEffect(() => {
    if (elementData.pages.length >= 1) {
      setType('EBOOK')
    } else if (elementData.cards.length >= 1) {
      setType('QUICKDECK');
    } else if (elementData.clip_cards.length >= 1){
      setType('QUICKCLIP');
    }
  }, [keyElementID, elementData])

  //Gets the title of the current page in Key Elements
  const handleGetHtmlTagFromContentPage= (content) => {
    let temp = document.createElement('div');
    temp.innerHTML = content;
    const elements = temp.getElementsByTagName('*');
    const textContent = elements[0]?.textContent;
    return textContent;
  }

  return (
    <div className={classes.keyElementsContent}>
      <div className={classes.keyElementsContent_layout}>
        <div className={classes.keyElementsContent_titleListContainer}>
          <Typography variant='h5'>Media Library</Typography>
          <ul className={classes.mediaLibrary_navbar}>
            {
              elementData.pages.length <= 0 ? null
                : <li 
                  className={ type === 'EBOOK' ? classes.mediaLibrary_navbarItemActive : classes.mediaLibrary_navbarItem} 
                  onClick={() => {handleGetQuickResourcesByType('EBOOK', elementData)}}>EBOOK</li>
            }
            {
              elementData.cards.length <= 0 ? null
                : <li 
                  className={ type === 'QUICKDECK' ? classes.mediaLibrary_navbarItemActive : classes.mediaLibrary_navbarItem} 
                  onClick={() => {handleGetQuickResourcesByType('QUICKDECK', elementData)}}>QUICKDECK</li>
            }
            {
              elementData.clip_cards.length <= 0 ? null
                : <li 
                  className={ type === 'QUICKCLIP' ? classes.mediaLibrary_navbarItemActive : classes.mediaLibrary_navbarItem} 
                  onClick={() => {handleGetQuickResourcesByType('QUICKCLIP', elementData)}}>QUICKCLIP</li>
            }
          </ul>
          <ul className={classes.keyElementsContent_titleList}>
            {
              quickResourcesByType?.map((item, index) => {
                return (
                  <li 
                  className={ quickResource.id === item.id ? classes.titleItemActive : classes.titleItem} 
                  onClick={() => {handleGetQuickResourceByID(item.id)}} key={item.id}>
                    <span>•</span>
                    <p>
                      {
                        type === 'QUICKDECK' ? item.term ? handleGetContentHtmlString(item.term) : 'No title': null
                      }
                      {
                        type === 'QUICKCLIP' ? item.title ? item.title : 'No title' : null
                      }
                      {
                        type === 'EBOOK' ? item.content ? handleGetHtmlTagFromContentPage(item.content) : 'No title' : null
                      }
                    </p>
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className={classes.keyElementsContent_itemContainer}>
          <Typography variant='h5'>
            { type === 'EBOOK' ? 'eBook Content' : null}
            { type === 'QUICKDECK' ? 'QuickDeck Content' : null}
            { type === 'QUICKCLIP' ? 'QuickClip Content' : null}
          </Typography>
          <KeyElementsContentLibrary 
          setQuickResource={setQuickResource} 
          type={type} 
          quickResource={quickResource}
          quickResourcesByType={quickResourcesByType}
          />
        </div>
      </div>
    </div>
  )
}

export default KeyElementsContent;