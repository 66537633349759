import { useEffect, useState } from 'react';

const useMergeKeyElements = (keyElements, setKeyElementID) => {
  const [keyElementsSorted, setKeyElementsSorted] = useState([]); //KeyElements order by name resource.

  useEffect(() => {
    if (keyElements) {
      if (keyElements.length >= 1) {
        const getArrayWithKeyElementsSorted = (reapetFilter) => {
          const keyElements = Object.entries(reapetFilter);
          setKeyElementID(keyElements[0][1].id);
          return keyElements;
        }

        const keyElementsSortedByOrderKey = keyElements.sort(function(a, b){return a.order_key - b.order_key});
        
        /*Make an object list with the keyElementsName with its props and just add a new keyElement if there's
        no one in the list, if there's one, merge the current props with the new props from the keyElement*/
        const reapetFilter = keyElementsSortedByOrderKey.reduce((acc, el) => {
          let keyElementName = el.name.toLowerCase();
        
          if (acc[keyElementName]) {
            acc[keyElementName] = {
              ...acc[keyElementName],
              cards: acc[keyElementName].cards.concat(el.cards !== null ? el.cards : []),
              clip_cards: acc[keyElementName].clip_cards.concat(el.clip_cards !== null ? el.clip_cards : []),
              pages: acc[keyElementName].pages.concat(el.pages !== null ? el.pages : [])
            }
          } else {
            acc[keyElementName] = {
              name: el.name,
              cards: el.cards !== null ? el.cards : [],
              clip_cards: el.clip_cards !== null ? el.clip_cards : [],
              pages: el.pages !== null ? el.pages : [],
              id: el.id
            }
          }
      
          return acc;
        }, {});
  
        setKeyElementsSorted(getArrayWithKeyElementsSorted(reapetFilter));
      } else {
        setKeyElementsSorted([]);
      }
    }
  }, [keyElements, setKeyElementID])

  return {
    keyElementsSorted
  }
}

export default useMergeKeyElements;