import React, { useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Typography,
} from '@material-ui/core';

import { Heading } from '../../common';
import useStyles from './styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { readableInstitutionRole } from '../../../lib';

const cardTitleWidth = 80;

const MobileMemberList = ({
  loading,
  members,
  onDelete,
  onEdit,
  disableButton,
  currentMember,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = (email) => {
    if (open === email) {
      setOpen(false);
    } else {
      setOpen(email);
    }
  };

  if (loading) {
    return <Typography>Loading members...</Typography>;
  }

  if (members && !members.length) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <Typography>No Matching Results</Typography>
      </Box>
    );
  }

  return members.map((member) => (
    <div key={member.email}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        onClick={() => handleOpen(member.email)}
        className={classes.mobileCollapseBar}
      >
        <Heading variant="h4" component="h4" style={{ width: cardTitleWidth }}>
          Name
        </Heading>
        <Typography style={{ flex: 1 }}>{member.name}</Typography>
        {member.email === open ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </Box>
      <Collapse in={member.email === open}>
        <Box display="flex" alignItems="center" px={2} pb={2}>
          <Heading
            variant="h4"
            component="h4"
            style={{ width: cardTitleWidth }}
          >
            Email
          </Heading>
          <Typography style={{ flex: 1 }}>{member.email}</Typography>
        </Box>
        <Box display="flex" alignItems="center" px={2} pb={2}>
          <Heading
            variant="h4"
            component="h4"
            style={{ width: cardTitleWidth }}
          >
            Role
          </Heading>
          <Typography style={{ flex: 1 }}>
            {readableInstitutionRole(member.role)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" px={2} pb={2}>
          <Heading
            variant="h4"
            component="h4"
            style={{ width: cardTitleWidth }}
          >
            Status
          </Heading>
          <Typography style={{ flex: 1 }}>
            {member.isInvitation ? 'Invited' : 'Accepted'}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" px={2} pb={2}>
          <Heading
            variant="h4"
            component="h4"
            style={{ width: cardTitleWidth }}
          >
            Action
          </Heading>
          {member.member_id && member.user_id === currentMember?.user_id ? (
            <Chip label="Me" color="primary" />
          ) : (
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Button
                  variant="outlined"
                  onClick={() => onEdit(member)}
                  size="small"
                  disabled={disableButton(member)}
                >
                  Edit
                </Button>
              </Box>
              <Button
                variant="outlined"
                onClick={() => onDelete(member)}
                size="small"
                disabled={disableButton(member)}
              >
                Delete
              </Button>
            </Box>
          )}
        </Box>
      </Collapse>
      <Divider />
    </div>
  ));
};

export default MobileMemberList;
