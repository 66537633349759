import React from 'react';
import {
  Toolbar,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import useStyles from './styles';
import { HasAccess } from '../../common';
import { useViewer } from '../../../providers/ViewerProvider';
import { MENU_ITEMS } from './config';
import { useInstitution } from '../../../providers/InstitutionProvider';
import clsx from 'clsx';

const MainNav = ({ location, history, onClose, isDrawer, className }) => {
  const classes = useStyles();
  const { currentMember } = useInstitution();
  const viewer = useViewer();

  const handleClick = (routePath) => {
    if(onClose) {
      onClose();
    }
    history.push(routePath);
  };

  const memberRole = currentMember ? currentMember.role : null;

  const currentRouteProps = (route) => {
    if(location.pathname.includes(route) && route !== '/') {
      return {color: 'primary', variant: "h4"};
    } else if (location.pathname === "/" && route === '/') {
      return {color: 'primary', variant: "h4"};
    } else {
      return {};
    }
  }

  if(!isDrawer) {
    return (
      <div className={classes.headerNavContainer}>
        {
          MENU_ITEMS.map((item) => (
            <HasAccess
            key={item.id}
            role={item.id === 2 ? memberRole : viewer.role}
            allowedRoles={item.allowedRoles}
            >
            <ListItem
            className={clsx(classes.listItem, classes.inlineListItem)}
            onClick={() => handleClick(item.route)}
            >
            <ListItemText primary={item.text} primaryTypographyProps={currentRouteProps(item.route)}/>
            </ListItem>
            </HasAccess>
        ))
        }
      </div>
    )
  }

  return (
    <>
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List className={clsx(className, {[classes.inline]: !isDrawer})}>
        {MENU_ITEMS.map((item) => (
          <HasAccess
          key={item.id}
          role={item.id === 2 ? memberRole : viewer.role}
          allowedRoles={item.allowedRoles}
          >
          <ListItem
          className={classes.listItem}
          onClick={() => handleClick(item.route)}
          >
          <ListItemText primary={item.text} primaryTypographyProps={currentRouteProps(item.route)}/>
          </ListItem>
          </HasAccess>
          ))}
          </List>
        </div>
    </>
  );
};

export default MainNav;
