import React from 'react';
import {
  CMS_ACCESS,
  SALE_ACCESS,
  EVERYONE,
  MANAGE_JASPERACTIVE_ACCESS,
} from '../../../lib';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import DeviceHubOutlinedIcon from '@material-ui/icons/DeviceHubOutlined';

export const MENU_ITEMS = [
  {
    id: 1,
    text: 'Home',
    route: '/',
    icon: <LayersOutlinedIcon />,
    allowedRoles: EVERYONE,
  },
  {
    id: 3,
    text: 'Content Management',
    route: '/cms',
    icon: <BallotOutlinedIcon />,
    allowedRoles: CMS_ACCESS,
  },
  {
    id: 4,
    text: 'Sales Management',
    route: '/sales-management',
    icon: <MonetizationOnOutlinedIcon />,
    allowedRoles: SALE_ACCESS,
  },
  // {
  //   id: 5,
  //   text: 'Customer Success',
  //   route: '/customer-success',
  //   icon: <FavoriteBorderOutlinedIcon />,
  //   allowedRoles: CUSTOMER_SUCCESS_ACCESS,
  // },
  // {
  //   id: 6,
  //   text: 'Technical Support',
  //   route: '/tech-support',
  //   icon: <EmojiObjectsOutlinedIcon />,
  //   allowedRoles: TECH_SUPPORT_ACCESS,
  // },
  {
    id: 7,
    text: 'Admin Dashboard',
    route: '/manage-jasperactive',
    icon: <DeviceHubOutlinedIcon />,
    allowedRoles: MANAGE_JASPERACTIVE_ACCESS,
  },
  // {
  //   id: 8,
  //   text: 'System Administration',
  //   route: '/sys-admin',
  //   icon: <BubbleChartOutlinedIcon />,
  //   allowedRoles: SYS_ADMIN_ACCESS,
  // },
];
