import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  groupHeader: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  groupKey: {
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
  copyIcon: {
    marginLeft: 3,
    fontSize: 'inherit',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  groupInfo: {
    marginRight: theme.spacing(14),

    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(7),
    },
  },
  navlink: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    ...theme.typography.subtitle1,
    fontWeight: 'normal',
  },
  homeLink: {},
  active: {
    fontWeight: 'bold',
    cursor: 'default',
  },
}));
