import React, { useContext } from 'react';
import { HasAccess } from '../../../components';
import { ViewerContext } from '../../../providers/ViewerProvider';

const allowedRoles = ['CONTENT_MANAGER', 'PRODUCT_MANAGER', 'DEVELOPER'];

const IsManager = ({ children }) => {
  const { viewer } = useContext(ViewerContext);
  return (
    <HasAccess role={viewer.role} allowedRoles={allowedRoles}>
      {children}
    </HasAccess>
  );
};

export const useIsManager = () => {
  const { viewer } = useContext(ViewerContext);

  return allowedRoles.includes(viewer.role);
};

export default IsManager;
