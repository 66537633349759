import omitDeep from 'omit-deep';
import { auditDataFields } from './auditDataFields';

const QUESTION_DATA = [
  'question',
  'incorrect_msg',
  'incorrect_msg_two',
  'correct_msg',
  'type',
  'answers',
  'belongsTo',
];

export const getQuestionData = data => {
  return Object.keys(data).reduce((acc, key) => {
    if (!QUESTION_DATA.includes(key)) return acc;
    if (key === 'answers') {
      acc[key] = data[key].reduce((acc, ans) => {
        acc.push(omitDeep(ans, ['__typename', ...auditDataFields]));
        return acc;
      }, []);
    } else {
      acc[key] = data[key];
    }
    return acc;
  }, {});
};
