import React, { Fragment } from "react";
import styles from './styles';

const CircularProgressBar = ({percentNumber, totalQuestions, correctAnswers, showFeedback}) => {
  const classes = styles();

  const computeYPosition = (deg) => {
    const r = 140;
    let y;
    y = - r * Math.sin(deg * Math.PI / 180);
    return y;
  }

  const computeXPosition = (deg) => {
    const r = 140;
    let x;
    x = r * Math.cos(deg * Math.PI / 180);
    return x;
  }

  const computStrokeDashoffset = (percentNumber) => {
    const dashOffset = 660 - (660 * percentNumber) / 100;
    return dashOffset;
  }

  return (
    <div className={classes.circularProgressBar_container}>
      <div className={classes.circularProgressBar}>
        <svg>
          <circle cy={177} cx={177} r={140}></circle>
          <circle cy={177} cx={177} r={140} style={{strokeDashoffset: computStrokeDashoffset(percentNumber), stroke: percentNumber >= 75 ? '#219653' : '#F2C94C'}}></circle>
        </svg>
        <div style={{transform: `translate(${computeXPosition( 225 - ((percentNumber * 270) / 100))}px, ${computeYPosition( 225 - ((percentNumber * 270) / 100))}px)`}} className={classes.positionEmoji}>
          {
            percentNumber >= 75 ? 
              <svg style={{backgroundColor: '#219653'}} width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 0C24.5604 0 31.5 6.93959 31.5 15.5C31.5 24.0604 24.5604 31 16 31C7.43959 31 0.5 24.0604 0.5 15.5C0.5 6.93959 7.43959 0 16 0ZM16 1.9375C8.50964 1.9375 2.4375 8.00964 2.4375 15.5C2.4375 22.9904 8.50964 29.0625 16 29.0625C23.4904 29.0625 29.5625 22.9904 29.5625 15.5C29.5625 8.00964 23.4904 1.9375 16 1.9375ZM10.4872 20.2029C13.4118 23.146 18.1075 23.2587 21.1675 20.53L21.5128 20.2029C21.89 19.8234 22.5033 19.8214 22.8828 20.1985C23.2202 20.5338 23.2592 21.0557 22.9988 21.434L22.8872 21.5686L22.8438 21.6119C19.0401 25.3916 12.8926 25.3722 9.11283 21.5686C8.73571 21.189 8.73764 20.5757 9.11716 20.1985C9.49667 19.8214 10.11 19.8234 10.4872 20.2029ZM20.8438 10.6562C21.9138 10.6562 22.7812 11.5237 22.7812 12.5938C22.7812 13.6638 21.9138 14.5312 20.8438 14.5312C19.7737 14.5312 18.9062 13.6638 18.9062 12.5938C18.9062 11.5237 19.7737 10.6562 20.8438 10.6562ZM11.1562 10.6562C12.2263 10.6562 13.0938 11.5237 13.0938 12.5938C13.0938 13.6638 12.2263 14.5312 11.1562 14.5312C10.0862 14.5312 9.21875 13.6638 9.21875 12.5938C9.21875 11.5237 10.0862 10.6562 11.1562 10.6562Z" fill="white"/>
              </svg>
              :
              <svg style={{backgroundColor: '#E1BA44'}} width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.1562 14.5312C12.2263 14.5312 13.0938 13.6638 13.0938 12.5938C13.0938 11.5237 12.2263 10.6562 11.1562 10.6562C10.0862 10.6562 9.21875 11.5237 9.21875 12.5938C9.21875 13.6638 10.0862 14.5312 11.1562 14.5312ZM19.3906 20.3438C19.3906 22.2163 17.8726 23.7344 16 23.7344C14.1274 23.7344 12.6094 22.2163 12.6094 20.3438C12.6094 18.4712 14.1274 16.9531 16 16.9531C17.8726 16.9531 19.3906 18.4712 19.3906 20.3438ZM20.8438 14.5312C21.9138 14.5312 22.7812 13.6638 22.7812 12.5938C22.7812 11.5237 21.9138 10.6562 20.8438 10.6562C19.7737 10.6562 18.9062 11.5237 18.9062 12.5938C18.9062 13.6638 19.7737 14.5312 20.8438 14.5312ZM16 0C7.43959 0 0.5 6.93959 0.5 15.5C0.5 24.0604 7.43959 31 16 31C24.5604 31 31.5 24.0604 31.5 15.5C31.5 6.93959 24.5604 0 16 0ZM2.4375 15.5C2.4375 8.00964 8.50964 1.9375 16 1.9375C23.4904 1.9375 29.5625 8.00964 29.5625 15.5C29.5625 22.9904 23.4904 29.0625 16 29.0625C8.50964 29.0625 2.4375 22.9904 2.4375 15.5Z" fill="#4F4F4F"/>
            </svg>
          }
        </div>
        <div className={classes.percentNumber}>
          <p style={percentNumber >= 75 ? {color: '#219653'} : {color: '#F2C94C'}}>{percentNumber}<span>%</span></p>
          <span>Score: {correctAnswers}/{totalQuestions}</span>
        </div>
      </div>
      <div className={classes.feedback_description}>
        {
          percentNumber >= 75 && showFeedback && 
            <Fragment>
              <p>Congratulations!</p>
              <p>You achieved the required score.</p>
            </Fragment>
        }
        {
          percentNumber < 75 && showFeedback && 
            <Fragment>
              <p>Don’t worry!</p>
              <p>You didn’t get the required score, but you can try again.</p>
            </Fragment>
        }
      </div>
    </div>
  )
}

export default CircularProgressBar;