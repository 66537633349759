import React from 'react';
import { Button, Box, CircularProgress, Typography } from '@material-ui/core';
import styles from './styles';
import InstitutionList from '../InstitutionList';

const InstitutionSearchResults = ({
  data,
  loading,
  error,
  fetchMore,
  history,
  match,
}) => {
  const classes = styles();

  if (loading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.institutionSearchResultsContainer}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (error || !data) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.institutionSearchResultsContainer}
      >
        <Typography variant="h6" color="textSecondary">
          Could not find any results.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.institutionSearchResultsContainer}>
      <InstitutionList
        onSelect={(institution) =>
          history.push(`${match.path}/institution/${institution.id}`)
        }
        institutions={
          data.paginatedInstitutions
            ? data.paginatedInstitutions.institutions
            : []
        }
      />
      {data.paginatedInstitutions && data.paginatedInstitutions.hasMore && (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.fetchMoreButton}
            onClick={() =>
              fetchMore({
                variables: {
                  after: data.paginatedInstitutions.cursor,
                },
                updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    ...fetchMoreResult,
                    paginatedInstitutions: {
                      ...fetchMoreResult.paginatedInstitutions,
                      institutions: [
                        ...prev.paginatedInstitutions.institutions,
                        ...fetchMoreResult.paginatedInstitutions.institutions,
                      ],
                    },
                  };
                },
              })
            }
          >
            More Results
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default InstitutionSearchResults;
