import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_QUESTION_RESOURCES,
  CREATE_QUESTION_RESOURCE,
  DELETE_QUESTION_RESOURCE,
} from '../../../../../../apollo/queries';
import { withSnackbar } from 'notistack';

export const QuestionResourceContext = React.createContext();

const QuestionResourceProvider = ({ question, enqueueSnackbar, children }) => {
  const [showResource, setShowResource] = useState(null);
  const [resourceType, setResourceType] = useState(null);

  const handleAddResource = (type) => {
    setResourceType(null); //Reset always the previous resourcetype selected.
    setShowResource(type);
  };


  const { data, loading, error } = useQuery(GET_QUESTION_RESOURCES, {
    variables: { questionId: question.id },
  });
  const [createResource] = useMutation(CREATE_QUESTION_RESOURCE);
  const [deleteResource] = useMutation(DELETE_QUESTION_RESOURCE);
  const [selectedResourceIds, setSelectedResourceIds] = useState([]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const questionResources = data.questionResources.reduce(
    (acc, resource) => {
      if (resource.type === 'hint') acc.hints.push(resource);
      if (resource.type === 'reveal') acc.reveals.push(resource);
      return acc;
    },
    { hints: [], reveals: [] },
  );

  const setResourceIds = (ids) => {
    setSelectedResourceIds(ids);
  };

  const addSelectedResources = async () => {
    if (selectedResourceIds.length === 0 || !showResource || !resourceType) {
      return;
    }
    try {
      await createResource({
        variables: {
          data: {
            type: showResource,
            question_id: question.id,
            resource_type: resourceType,
            resource_ids: selectedResourceIds,
          },
        },
        refetchQueries: ['getQuestionResources', 'getCMSStudyProject'],
      });
      setSelectedResourceIds([]);
      setShowResource(null);
      enqueueSnackbar('Resource has been added.', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {}
  };

  const deleteQuestionResource = async (id) => {
    try {
      await deleteResource({
        variables: { id },
        refetchQueries: ['getQuestionResources', 'getCMSStudyProject'],
      });
      enqueueSnackbar('Resource has been removed.', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {}
  };

  const state = {
    currentHints: questionResources.hints,
    currentReveals: questionResources.reveals,
    currentHintResourceIds: questionResources.hints.reduce((acc, resource) => {
      if (resource.resource_type === 'quickdeck') {
        acc.push(resource.card.id);
      }
      if (resource.resource_type === 'quickclip') {
        acc.push(resource.clip_card.id);
      }
      if (resource.resource_type === 'page') {
        acc.push(resource.page.id);
      }

      return acc;
    }, []),
    currentRevealResourceIds: questionResources.reveals.reduce(
      (acc, resource) => {
        if (resource.resource_type === 'quickdeck') {
          acc.push(resource.card.id);
        }
        if (resource.resource_type === 'quickclip') {
          acc.push(resource.clip_card.id);
        }
        if (resource.resource_type === 'page') {
          acc.push(resource.page.id);
        }
        return acc;
      },
      [],
    ),
    selectedResourceIds,
    setResourceIds,
    addSelectedResources,
    deleteQuestionResource,
    handleAddResource,
    setShowResource,
    showResource,
    setResourceType,
    resourceType,
  };

  return (
    <QuestionResourceContext.Provider value={state}>
      {children}
    </QuestionResourceContext.Provider>
  );
};

export const QuestionResourceConsumer = QuestionResourceContext.Consumer;

export default withSnackbar(QuestionResourceProvider);
