import React from 'react';
import { Card, CardContent, Typography, Chip, Box } from '@material-ui/core';
import { DeleteButton } from '../../common';

const CMSLessonCard = ({ lesson, onDelete }) => {
  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          pb={2}
        >
          <div>
            <Typography variant="h6">{lesson.name}</Typography>
            <Typography variant="caption">{lesson.course.name}</Typography>
          </div>
          <DeleteButton onClick={onDelete} />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Chip
            color="primary"
            label={`${lesson.studyProjects.length} Study Project${
              lesson.studyProjects.length === 1 ? '' : 's'
            }`}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CMSLessonCard;
