export const validateLicenseForm = (
  values,
  isQuote,
  isEdit,
  isActivation,
  isRenewal,
) => {
  let errors = {};

  if (isQuote || isEdit) {
    if (!values?.name) errors.name = 'required';
    if (!values?.max_users) errors.max_users = 'required';
    if (!values?.products.length) errors.products = 'must select one';
  }
  if (isActivation || isEdit) {
    if (!isRenewal) {
      if (!values?.activation_date) errors.activation_date = 'required';
    }
    if (!isEdit) {
      if (!values?.expiry_date) errors.expiry_date = 'required';
    }
  }

  return errors;
};
