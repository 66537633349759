import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textInput: {
    marginBottom: theme.spacing(2),
  },
  memberListContainer: {
    flexGrow: 1,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[300]}`,
    overflow: 'auto',
  },
  mobileCollapseBar: {
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.grey[100],
    },
  },
  // InstitutionMemberEditForm
  formContainer: {
    width: 400,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
