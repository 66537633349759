import gql from 'graphql-tag';

const UserFields = gql`
  fragment UserFields on User {
    id
    first_name
    last_name
    email
    avatar
    role
  }
`;

const AnswerFields = gql`
  fragment AnswerFields on Answer {
    id
    answer
    answer_image
    sequence
    match
    is_correct
    createdBy {
      ...UserFields
    }
    created_on
    deletedBy {
      ...UserFields
    }
    deleted_on
  }
  ${UserFields}
`;

const ProductFields = gql`
  fragment ProductFields on Product {
    id
    name
    logo
    color
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    published
    publishedBy {
      ...UserFields
    }
    published_on
    licensedeleted_by
  }
  ${UserFields}
`;

const CourseFields = gql`
  fragment CourseFields on Course {
    id
    name
    levels
    logo
    product {
      ...ProductFields
    }
    description
    createdBy {
      ...UserFields
    }
    modifiedBy {
      ...UserFields
    }
    deletedBy {
      ...UserFields
    }
    created_on
    modified_on
    deleted_on
    published
    publishedBy {
      ...UserFields
    }
    published_on
    lessons {
      id
    }
    display_order
  }
  ${ProductFields}
  ${UserFields}
`;

const AssessmentCustomSettingsFields = gql`
  fragment AssessmentCustomSettingsFields on AssessmentCustomSettings {
    id
    assessment_id
    time
    questions_per_lesson
    course_id
    group_id
  }
`;

const AllProductFields = gql`
  fragment AllProductFields on Product {
    ...ProductFields
    courses {
      ...CourseFields
    }
  }
  ${ProductFields}
  ${CourseFields}
`;

const LicenseFields = gql`
  fragment LicenseFields on License {
    id
    key
    name
    purchase_order
    expiry_date
    max_users
    is_gmetrix
    totalRenewals
    slots_used
    last_renewed
    created_on
    created_by
    deleted_by
    deleted_on
    modified_by
    modified_on
    is_quoted
    days_to_expire
    activation_date
    institution {
      id
      name
    }
    products {
      ...AllProductFields
    }
  }
  ${AllProductFields}
`;

const InstitutionFields = gql`
  fragment InstitutionFields on Institution {
    id
    name
    country
    country_code
    state
    state_code
    address
    city
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    logo
    sales_manager {
      ...UserFields
    }
  }
  ${UserFields}
`;

const InstitutionMemberFields = gql`
  fragment InstitutionMemberFields on InstitutionMember {
    id
    user {
      ...UserFields
      groups {
        id
        name
        owner {
          id
          first_name
          last_name
        }
      }
    }
    role
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
  }
  ${UserFields}
`;

const AllInstitutionMemberFields = gql`
  fragment AllInstitutionMemberFields on InstitutionMember {
    ...InstitutionMemberFields
    institution {
      ...InstitutionFields
    }
  }
  ${InstitutionMemberFields}
  ${InstitutionFields}
`;

const AllInstitutionFields = gql`
  fragment AllInstitutionFields on Institution {
    ...InstitutionFields
    licenses {
      ...LicenseFields
    }
    members {
      ...InstitutionMemberFields
    }
  }
  ${InstitutionFields}
  ${LicenseFields}
  ${InstitutionMemberFields}
`;

const GroupMemberFields = gql`
  fragment GroupMemberFields on GroupMember {
    id
    user {
      ...UserFields
    }
    role
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
  }
  ${UserFields}
`;

const GroupFields = gql`
  fragment GroupFields on Group {
    id
    name
    key
    status
    start_date
    end_date
    number_of_members
    group_size
    external_id
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    institution {
      ...InstitutionFields
    }
    owner {
      ...UserFields
    }
    members {
      ...GroupMemberFields
    }
  }
  ${InstitutionFields}
  ${UserFields}
  ${GroupMemberFields}
`;

const AllGroupMemberFields = gql`
  fragment AllGroupMemberFields on GroupMember {
    ...GroupMemberFields
    group {
      ...GroupFields
    }
  }
  ${GroupMemberFields}
  ${GroupFields}
`;

const BookPageFields = gql`
  fragment BookPageFields on BookPage {
    id
    title
    content
    page_number
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
  }
  ${UserFields}
`;

const BookFields = gql`
  fragment BookFields on Book {
    id
    name
    product {
      ...ProductFields
    }
    pages {
      ...BookPageFields
    }
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
  }
  ${ProductFields}
  ${BookPageFields}
  ${UserFields}
`;

const CMSBookFields = gql`
  fragment CMSBookFields on Book {
    id
    name
    product {
      id
      name
      color
    }
  }
`;

const QuickDeckCardFields = gql`
  fragment QuickDeckCardFields on QuickDeckCard {
    id
    title
    term
    definition
    image
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    published
    publishedBy {
      ...UserFields
    }
    published_on
  }
  ${UserFields}
`;

const QuickClipCardFields = gql`
  fragment QuickClipCardFields on QuickClipCard {
    id
    title
    video
    video_with_subtitles
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    published
    publishedBy {
      ...UserFields
    }
    published_on
  }
  ${UserFields}
`;

const QuestionFields = gql`
  fragment QuestionFields on Question {
    id
    question
    type
    incorrect_msg
    incorrect_msg_two
    correct_msg
    hint {
      type
      cards {
        ...QuickDeckCardFields
      }
      clip_cards {
        ...QuickClipCardFields
      }
      pages {
        ...BookPageFields
      }
    }
    reveal {
      type
      cards {
        ...QuickDeckCardFields
      }
      clip_cards {
        ...QuickClipCardFields
      }
      pages {
        ...BookPageFields
      }
    }
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    answers {
      ...AnswerFields
    }
  }
  ${UserFields}
  ${AnswerFields}
  ${BookPageFields}
  ${QuickDeckCardFields}
  ${QuickClipCardFields}
`;

const LessonFields = gql`
  fragment LessonFields on Lesson {
    id
    name
    logo
    display_order
    description
    course {
      ...CourseFields
    }
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    published
    publishedBy {
      ...UserFields
    }
    published_on
  }
  ${CourseFields}
  ${UserFields}
`;

const BenchmarkQuestionFields = gql`
  fragment BenchmarkQuestionFields on BenchmarkQuestion {
    id
    question
    type
    incorrect_msg
    incorrect_msg_two
    correct_msg
    lesson {
      ...LessonFields
    }
    hint {
      type
      cards {
        ...QuickDeckCardFields
      }
      clip_cards {
        ...QuickClipCardFields
      }
      pages {
        ...BookPageFields
      }
    }
    reveal {
      type
      cards {
        ...QuickDeckCardFields
      }
      clip_cards {
        ...QuickClipCardFields
      }
      pages {
        ...BookPageFields
      }
    }
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    answers {
      ...AnswerFields
    }
  }
  ${UserFields}
  ${AnswerFields}
  ${BookPageFields}
  ${LessonFields}
  ${QuickDeckCardFields}
  ${QuickClipCardFields}
`;

const BenchmarkFields = gql` 
  fragment BenchmarkFields on Benchmark {
    id
    level
    description
    time
    course {
      ...CourseFields
    }
    questions {
      ...BenchmarkQuestionFields
    }
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    published
    publishedBy {
      ...UserFields
    }
    published_on
    questions_per_lesson
  }
  ${CourseFields}
  ${BenchmarkQuestionFields}
  ${UserFields}
`;

const SummaryFields = gql`
  fragment SummaryFields on Summary {
    id
    level
    description
    time
    course {
      ...CourseFields
    }
    questions {
      ...BenchmarkQuestionFields
    }
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    published
    publishedBy {
      ...UserFields
    }
    published_on
    questions_per_lesson
  }
  ${CourseFields}
  ${BenchmarkQuestionFields}
  ${UserFields}
`;

const CaseStudyFields = gql`
  fragment CaseStudyFields on CaseStudy {
    id
    case {
      ...QuestionFields
    }
    reinforcedQuestions {
      ...QuestionFields
    }
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    published
    publishedBy {
      ...UserFields
    }
    published_on
  }
  ${QuestionFields}
  ${UserFields}
`;

const KeyElementFields = gql`
  fragment KeyElementFields on KeyElement {
    id
    name
    type
    order_key
    cards {
      ...QuickDeckCardFields
    }
    clip_cards {
      ...QuickClipCardFields
    }
    pages {
      ...BookPageFields
    }
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    published
    publishedBy {
      ...UserFields
    }
    published_on
  }
  ${QuickDeckCardFields}
  ${QuickClipCardFields}
  ${BookPageFields}
  ${UserFields}
`;

const StudyProjectFields = gql`
  fragment StudyProjectFields on StudyProject {
    id
    introduction
    level
    lesson {
      id
      name
      logo
      display_order
      description
      course_id
    }
    caseStudies {
      ...CaseStudyFields
    }
    keyElements {
      ...KeyElementFields
    }
    createdBy {
      ...UserFields
    }
    created_on
    modifiedBy {
      ...UserFields
    }
    modified_on
    deletedBy {
      ...UserFields
    }
    deleted_on
    published
    publishedBy {
      ...UserFields
    }
    published_on
    study_project_number
  }
  ${CaseStudyFields}
  ${UserFields}
  ${KeyElementFields}
`;

const AllLessonFields = gql`
  fragment AllLessonFields on Lesson {
    ...LessonFields
    pages {
      ...BookPageFields
    }
    cards {
      ...QuickDeckCardFields
    }
    clip_cards {
      ...QuickClipCardFields
    }
    studyProjects {
      ...StudyProjectFields
    }
  }
  ${LessonFields}
  ${BookPageFields}
  ${QuickDeckCardFields}
  ${QuickClipCardFields}
  ${StudyProjectFields}
`;

const AllCourseFields = gql`
  fragment AllCourseFields on Course {
    ...CourseFields
    benchmarks {
      ...BenchmarkFields
    }
    lessons {
      ...LessonFields
    }
    summaries {
      ...SummaryFields
    }
  }
  ${CourseFields}
  ${BenchmarkFields}
  ${LessonFields}
  ${SummaryFields}
`;

const AllUserFields = gql`
  fragment AllUserFields on User {
    ...UserFields
    created_by
    created_on
    modified_by
    modified_on
    deleted_by
    deleted_on
    groups {
      ...GroupFields
    }
    institutions {
      ...InstitutionFields
    }
    # add the remaining fields as needed
  }
  ${UserFields}
  ${GroupFields}
  ${InstitutionFields}
`;

const AllGroupFields = gql`
  fragment AllGroupFields on Group {
    ...GroupFields
    courses {
      ...CourseFields
      summaries {
        id
        level
        description
        time
        questions_per_lesson
        published
      }
      benchmarks {
        id
        level
        description
        time
        questions_per_lesson
        published
      }
    }
    license {
      ...LicenseFields
    }
    members {
      ...GroupMemberFields
    }
    owner {
      ...UserFields
    }
    assessmentCustomSettings {
      ...AssessmentCustomSettingsFields
    }
  }
  ${GroupFields}
  ${CourseFields}
  ${LicenseFields}
  ${GroupMemberFields}
  ${UserFields}
  ${AssessmentCustomSettingsFields}
`;

export {
  AnswerFields,
  BenchmarkFields,
  BenchmarkQuestionFields,
  BookFields,
  BookPageFields,
  CMSBookFields,
  UserFields,
  AllUserFields,
  QuestionFields,
  LicenseFields,
  InstitutionFields,
  AllInstitutionFields,
  InstitutionMemberFields,
  ProductFields,
  AllProductFields,
  CourseFields,
  AllCourseFields,
  AllInstitutionMemberFields,
  GroupFields,
  AllGroupFields,
  GroupMemberFields,
  AllGroupMemberFields,
  LessonFields,
  AllLessonFields,
  QuickDeckCardFields,
  QuickClipCardFields,
  StudyProjectFields,
  CaseStudyFields,
  KeyElementFields,
  SummaryFields,
};
