import gql from 'graphql-tag';
import { QuestionFields } from './fragments';

const CREATE_QUESTION = gql`
  mutation createQuestion($data: QuestionCreateInput!) {
    createQuestion(data: $data) {
      ...QuestionFields
    }
  }
  ${QuestionFields}
`;

const UPDATE_QUESTION = gql`
  mutation updateQuestion(
    $data: QuestionUpdateInput!
    $where: QuestionWhereUniqueInput!
  ) {
    updateQuestion(data: $data, where: $where) {
      ...QuestionFields
    }
  }
  ${QuestionFields}
`;

const GET_QUESTIONS = gql`
  query getQuestions($where: QuestionWhereInput) {
    questions(where: $where) {
      ...QuestionFields
    }
  }
  ${QuestionFields}
`;

const GET_ALL_QUESTIONS = gql`
  query allQuestions {
    questions {
      ...QuestionFields
    }
  }
  ${QuestionFields}
`;

const GET_QUESTION_TYPES = gql`
  query allQuestionTypes {
    __type(name: "QuestionType") {
      name
      enumValues {
        name
      }
    }
  }
`;

const GET_QUESTION_BY_ID = gql`
  query questionById($where: QuestionWhereUniqueInput!) {
    question(where: $where) {
      ...QuestionFields
    }
  }
  ${QuestionFields}
`;

const DELETE_QUESTION = gql`
  mutation deleteQuestion($id: ID!) {
    deleteQuestion(id: $id)
  }
`;

export {
  GET_QUESTION_BY_ID,
  CREATE_QUESTION,
  GET_QUESTIONS,
  GET_ALL_QUESTIONS,
  UPDATE_QUESTION,
  GET_QUESTION_TYPES,
  DELETE_QUESTION,
};
