const READABLE_ROLES = [
  { role: 'USER', newRole: 'User' },
  { role: 'SYSTEM_ADMIN', newRole: 'System Administrator' },
  { role: 'TECH_SUPPORT', newRole: 'Technical Support' },
  { role: 'CONTENT_MANAGER', newRole: 'Content Manager' },
  { role: 'CONTENT_DEVELOPER', newRole: 'Content Developer' },
  { role: 'SALES_MANAGER', newRole: 'Sales Manager' },
  { role: 'CUSTOMER_SUCCESS', newRole: 'Customer Success' },
  { role: 'DISTRIBUTOR', newRole: 'Distributor' },
  { role: 'RESELLER', newRole: 'Reseller' },
  { role: 'DEVELOPER', newRole: 'Developer' },
  { role: 'PRODUCT_MANAGER', newRole: 'Product Manager' },
  { role: 'CENTER_ADMIN', newRole: 'Center Administrator' },
  { role: 'STUDENT', newRole: 'Student' },
  { role: 'INSTRUCTOR', newRole: 'Instructor' },
  { role: 'MAIN_INSTRUCTOR', newRole: 'Main Instructor' },
];

export const getReadableRole = (role) => {
  const readableRole = READABLE_ROLES.find((r) => r.role === role);

  return readableRole ? readableRole.newRole : null;
};
