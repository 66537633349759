import { useQuery } from '@apollo/react-hooks';
import { USER_INVITATIONS } from '../queries';

export function useUserInvitations(email) {
  const { data, loading, error, refetch } = useQuery(USER_INVITATIONS, {
    variables: {
      email: email,
    },
    fetchPolicy: 'network-only',
    skip: !email,
  });

  return { data, loading, error, refetch };
}
