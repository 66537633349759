import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',

    '& .MuiFormControl-root': {
      marginRight: theme.spacing(2),
    },
  },
  searchInput: {
    width: 300,
  },
  viewInput: {
    width: 100,
  },
  filterInput: {
    minWidth: 220,
  },
  searchButton: {
    marginRight: theme.spacing(2),
  },
}));

export default styles;
