import gql from 'graphql-tag';
import { QuickDeckCardFields } from './fragments';

const CREATE_QUICKDECK_CARD = gql`
  mutation createQuickdeckCard($data: QuickDeckCardCreateInput!) {
    createQuickDeckCard(data: $data) {
      ...QuickDeckCardFields
    }
  }
  ${QuickDeckCardFields}
`;

const UPDATE_QUICKDECK_CARD = gql`
  mutation updateQuickdeckCard(
    $data: QuickDeckCardUpdateInput!
    $where: QuickDeckWhereUniqueInput!
  ) {
    updateQuickDeckCard(data: $data, where: $where) {
      ...QuickDeckCardFields
    }
  }
  ${QuickDeckCardFields}
`;

const PUBLISH_QUICKDECK_CARD = gql`
  mutation publishQuickDeckCard($id: ID!) {
    publishQuickDeckCard(id: $id)
  }
`;

const UNPUBLISH_QUICKDECK_CARD = gql`
  mutation unpublishQuickDeckCard($id: ID!) {
    unpublishQuickDeckCard(id: $id)
  }
`;

const DELETE_QUICKDECK_CARD = gql`
  mutation deleteQuickDeckCard($id: ID!) {
    deleteQuickDeckCard(id: $id)
  }
`;

const GET_LESSON_CARDS = gql`
  query getLessonCards($lessonId: ID!) {
    quickDeckCards(where: { lesson_id: $lessonId }) {
      ...QuickDeckCardFields
    }
  }
  ${QuickDeckCardFields}
`;

export {
  CREATE_QUICKDECK_CARD,
  UPDATE_QUICKDECK_CARD,
  PUBLISH_QUICKDECK_CARD,
  UNPUBLISH_QUICKDECK_CARD,
  DELETE_QUICKDECK_CARD,
  GET_LESSON_CARDS,
};
