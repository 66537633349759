import React from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import styles from './styles';

const QuickCardControls = ({
  nextCard,
  prevCard,
  currentCardNumber,
  cards,
  disableNext,
  disablePrev,
  selectCard,
  maxCardsNumberLimit,
  minCardsNumberLimit,
  ...restProps
}) => {
  const classes = styles();
  const pageWidth = 920;

  const renderCardsNumbers = () => {
    const cardsNumbers = cards.map((item, index) => {
      if (index < maxCardsNumberLimit && (index + 1) > minCardsNumberLimit) {
        return (
          <IconButton key={index} onClick={() => {selectCard(index)}} size='small' className={currentCardNumber !== index ? classes.navigationButton :classes.navigationButtonSelected}>{index + 1}</IconButton>
        )
      } else {
        return null;
      }
    })

    return cardsNumbers;
  }

  return (
    <Box
      {...restProps}
      maxWidth={pageWidth}
      display="flex"
      alignItems="center"
      width='100%'
      justifyContent="space-between"
      padding='0 1em'
    >
      <Button onClick={prevCard} disabled={disablePrev} color="primary">
        Prev
      </Button>
      <div className={classes.buttonGroupNav}>
        {renderCardsNumbers()}
      </div>
      <Button onClick={nextCard} disabled={disableNext} color="primary">
        Next
      </Button>
    </Box>
  );
};


export default QuickCardControls;