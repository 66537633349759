import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  boxesMarggins: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
  cardContentFrm:{
    paddingTop: theme.spacing(1),
    marginLeft:3
  },
  logoInstitutionFrm:{
    align: theme.spacing(2),
  },
  logoLabel:{
    marginBottom: theme.spacing(3),
  },
  TextFielCostumeStyle:{
    textTransform: 'capitalize',
    flex: 1,
    marginLeft: 8,
    marginRight: 8,
  },
  ButtomIconEdit:
  {
    
    marginTop: 'auto',
    //marginLeft: 0,
   // marginBottom: 30,
  },
  root: {
    flexGrow: 1,
    height: '100%',
    background: theme.palette.background.light,
  },
}));

export default styles;
