import React from 'react';
import { Form, Field } from 'react-final-form';
import { FormControl, TextField, Box, Button } from '@material-ui/core';
import { CloudinaryImageWidget } from '../../common';

const LessonForm = ({ lesson = {}, onSubmit, onCancel = null, courseId }) => {
  return (
    <Form
      onSubmit={onSubmit}
      validate={() => { }}
      initialValues={{
        ...lesson,
        course_id: courseId
      }}
      render={({ handleSubmit, pristine, valid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Field name="name">
              {({ input, meta }) => (
                <TextField
                  id="name"
                  type="text"
                  label="Lesson Name"
                  value={input.value}
                  onChange={input.onChange}
                  variant="filled"
                  required
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Field name="display_order">
              {({ input, meta }) => (
                <TextField
                  id="display_order"
                  type="number"
                  label="Display Order"
                  value={input.value}
                  onChange={input.onChange}
                  variant="filled"
                  required
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Field name="logo">
              {({ input, meta }) => (
                <CloudinaryImageWidget
                  multiple={false}
                  maxFiles={1}
                  acceptedFormat={'svg'}
                  files={input.value}
                  buttonLabel="Lesson Logo"
                  onInsert={(files) => {
                    input.onChange(files);
                  }}
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Field name="description">
              {({ input, meta }) => (
                <TextField
                  id="description"
                  type="text"
                  multiline
                  rows="3"
                  label="Lesson Description"
                  value={input.value}
                  onChange={input.onChange}
                  variant="filled"
                />
              )}
            </Field>
          </FormControl>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {onCancel && (
              <Button
                type="button"
                onClick={onCancel}
                variant="text"
                color="primary"
                disabled={submitting}
              >
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              variant="text"
              color="primary"
              disabled={submitting || pristine || !valid}
            >
              {lesson.id ? 'Save' : 'Create'}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default LessonForm;
