import React from 'react';
import useStyles from './styles';

export default function DashboardShell({ header, main }) {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <header className={classes.heading}>{header}</header>
      <div className={classes.main}>{main}</div>
    </section>
  );
}
