import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  radioButton: {
    marginBottom: theme.spacing(2),
  },
}));
