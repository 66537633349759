import React, { Fragment } from 'react';
import {
  SlideUpDialog,
  EditorText,
  DeleteButton,
  ResourceCard,
} from '../../../../../../components';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  List,
  ListItem,
  Card,
  CardActions,
  CardContent,
  ListItemIcon,
  Checkbox,
  Grid,
} from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import { CMS_GET_LESSON } from '../../../../../../apollo/queries';
import styles from './styles';
import ScrollDialog from './ScrollDialog';
import QuestionResourceProvider, {
  QuestionResourceConsumer,
} from './QuestionResourceProvider';

const QuestionResources = ({ lessons, open, onClose, question, lessonId }) => {
  const classes = styles();

  return (
    <SlideUpDialog open={open} onClose={onClose} title="Question Resources">
      <QuestionResourceProvider question={question}>
        <QuestionResourceConsumer>
          {({
            selectedResourceIds,
            addSelectedResources,
            currentHintResourceIds,
            currentRevealResourceIds,
            setResourceIds,
            currentHints,
            currentReveals,
            deleteQuestionResource,
            handleAddResource,
            setShowResource,
            showResource,
            resourceType,
            setResourceType,
          }) => (
            <Fragment>
              <ScrollDialog
                open={Boolean(showResource)}
                onClose={() => setShowResource(false)}
                title="Select Resources to Add"
                onAccept={addSelectedResources}
                acceptText="Save"
                disableAccept={selectedResourceIds.length === 0}
                maxWidth={'md'}
              >
                <Fragment>
                  {resourceType === null && (
                    <Box display="flex" justifyContent="center">
                      <Button
                        style={{ marginRight: 16 }}
                        variant="outlined"
                        color="primary"
                        onClick={() => setResourceType('quickdeck')}
                        disabled={resourceType === 'page'}
                      >
                        Quickdeck
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setResourceType('page')}
                        disabled={resourceType === 'quickdeck'}
                      >
                        EBook Page
                      </Button>
                      <Button
                        style={{ marginLeft: 16 }}
                        variant="outlined"
                        color="primary"
                        onClick={() => setResourceType('quickclip')}
                        disabled={resourceType === 'quickclip'}
                      >
                        Quickclip
                      </Button>
                    </Box>
                  )}
                  {resourceType !== null && (
                    <ResourceSelectList
                      lessons={lessons}
                      resourceType={resourceType}
                      lessonId={lessonId}
                      question={question}
                      type={showResource}
                      selectedResourceIds={selectedResourceIds}
                      setResourceIds={setResourceIds}
                      currentHintResourceIds={currentHintResourceIds}
                      currentRevealResourceIds={currentRevealResourceIds}
                    />
                  )}
                </Fragment>
              </ScrollDialog>
              <Box p={2} className={classes.slideDialogContent}>
                <Box mb={2}>
                  <Button
                    style={{ marginRight: 16 }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddResource('hint')}
                  >
                    Add Hint Resource
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddResource('reveal')}
                  >
                    Add Reveal Resource
                  </Button>
                </Box>
                <Typography variant="h5" gutterBottom>
                  Question
                </Typography>
                <EditorText text={question.question} />
                <Typography variant="h5" gutterBottom>
                  Question Hint Resources
                </Typography>
                <QuestionResourceList
                  resources={currentHints}
                  type="hint"
                  deleteResource={deleteQuestionResource}
                />
                <Typography variant="h5" gutterBottom>
                  Question Reveal Resources
                </Typography>
                <QuestionResourceList
                  resources={currentReveals}
                  type="reveal"
                  deleteResource={deleteQuestionResource}
                />
              </Box>
            </Fragment>
          )}
        </QuestionResourceConsumer>
      </QuestionResourceProvider>
    </SlideUpDialog>
  );
};

export default QuestionResources;

const ResourceSelectList = ({
  lessons,
  resourceType,
  lessonId,
  type,
  currentHintResourceIds,
  currentRevealResourceIds,
  selectedResourceIds,
  setResourceIds,
}) => {
  const classes = styles();
  const [selectedLessonId, setSelectedLessonId] = React.useState(lessonId);
  const [loadLesson, { loading, data, error }] = useLazyQuery(CMS_GET_LESSON, {
    variables: { lessonId: selectedLessonId },
  });

  React.useEffect(() => {
    loadLesson();
  }, [loadLesson, selectedLessonId]);

  const onLessonChange = (lesson_id) => {
    setSelectedLessonId(lesson_id);
  };

  if (loading || !data) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const handleResourceClick = (id) => {
    if (selectedResourceIds.includes(id)) {
      setResourceIds(
        selectedResourceIds.filter((resourceId) => resourceId !== id),
      );
    } else {
      setResourceIds([...selectedResourceIds, id]);
    }
  };

  if (resourceType === 'quickdeck') {
    const cards = data.lesson.cards.filter((card) =>
      type === 'hint'
        ? !currentHintResourceIds.includes(card.id)
        : !currentRevealResourceIds.includes(card.id),
    );

    if (cards.length === 0) {
      return <NoResources lessons={lessons} lessonId={selectedLessonId} onLessonChange={onLessonChange} containerClassName={classes.allResourcesTaken} />;
    }
    return (
      <Box>
        <LessonsSelect lessons={lessons} lessonId={selectedLessonId} onLessonChange={onLessonChange} />
        <List>
          {cards.map((card) => (
            <ListItem key={card.id} onClick={() => handleResourceClick(card.id)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedResourceIds.includes(card.id)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <Card style={{ minWidth: 350 }}>
                <CardContent>
                  <ResourceCard text1_label="Card Term" text1={card.term} text2_label="Card Definition" text2={card.definition} image={card.image} />
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }

  if (resourceType === 'quickclip') {
    const cards = data.lesson.clip_cards.filter((clipcard) =>
      type === 'hint'
        ? !currentHintResourceIds.includes(clipcard.id)
        : !currentRevealResourceIds.includes(clipcard.id),
    );

    if (cards.length === 0) {
      return <NoResources lessons={lessons} lessonId={selectedLessonId} onLessonChange={onLessonChange} containerClassName={classes.allResourcesTaken} />;
    }
    return (
      <Box>
        <LessonsSelect lessons={lessons} lessonId={selectedLessonId} onLessonChange={onLessonChange} />
        <List>
          {cards.map((clip) => (
            <ListItem key={clip.id} onClick={() => handleResourceClick(clip.id)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedResourceIds.includes(clip.id)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <Card style={{ minWidth: 350 }}>
                <CardContent>
                  <ResourceCard title={clip.title} text1_label="Clip" text1={clip.video} text2_label="Clip with subtitles" text2={clip.video_with_subtitles} />
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }

  if (resourceType === 'page') {
    const pages = data.lesson.pages.filter((page) =>
      type === 'hint'
        ? !currentHintResourceIds.includes(page.id)
        : !currentRevealResourceIds.includes(page.id),
    );
    if (pages.length === 0) {
      return <NoResources lessons={lessons} lessonId={selectedLessonId} onLessonChange={onLessonChange} containerClassName={classes.allResourcesTaken} />;
    }
    return (
      <Box>
        <LessonsSelect lessons={lessons} lessonId={selectedLessonId} onLessonChange={onLessonChange} />
        <List>
          {pages.map((page) => (
            <ListItem key={page.id} onClick={() => handleResourceClick(page.id)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedResourceIds.includes(page.id)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <Card>
                <CardContent>
                  <ResourceCard title={page.title} text1_label="eBook Page" text1={page.content} />
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }
};

const LessonsSelect = ({ lessons, lessonId, onLessonChange }) => {
  const handleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onLessonChange(e.target.value);
  };
  return (
    <Box mb={2}>
      <FormControl fullWidth>
        <InputLabel id="lesson-select-label">Lesson</InputLabel>
        <Select
          labelId="lesson-select-label"
          id="lesson-select"
          value={lessonId}
          label="Lesson"
          onChange={handleChange}
        >
          {lessons.map(lesson => <MenuItem key={lesson.id} value={lesson.id}>{lesson.name}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
};

const NoResources = ({ lessons, lessonId, onLessonChange, containerClassName }) => {
  return (
    <Box>
      <LessonsSelect lessons={lessons} lessonId={lessonId} onLessonChange={onLessonChange} />
      <Typography component="div" className={containerClassName}>
        No Resources. If you haven't selected any resources check to see that
        you have quickdeck cards or quickclips cards or e-book pages added to
        the selected lesson
      </Typography>
    </Box>
  );
};

const QuestionResourceList = ({ resources, type, deleteResource }) => {
  const classes = styles();
  if (resources.length === 0) {
    return (
      <Typography component="div" className={classes.allResourcesTaken}>
        No {type === 'hint' ? 'Hint' : 'Reveal'} Resources. Click "Add{' '}
        {type === 'hint' ? 'Hint' : 'Reveal'} Resource" to get started.
      </Typography>
    );
  }

  return (
    <QuickResourceList resources={resources} deleteResource={deleteResource} classes={classes} />
  )
};

const QuickResourceList = ({ resources, deleteResource, classes }) => (
  <Grid container spacing={2}>
    {resources.map((resource) => (
      <Grid key={resource.id} item xs={12} sm={6} md={4}>
        <Card style={{ margin: 8 }}>
          <CardActions className={classes.quickResourceHeader}>
            <Typography variant='h4'>
              {
                resource.resource_type === 'quickdeck' && 'Quickdeck Resource'
              }
              {
                resource.resource_type === 'quickclip' && 'Quickclip Resource'
              }
              {
                resource.resource_type === 'page' && 'Ebook Page Resource'
              }
            </Typography>
            <DeleteButton onClick={() => deleteResource(resource.id)} />
          </CardActions>
          {
            resource.resource_type === 'quickdeck' && (
              <CardContent className={classes.cardContent}>
                <ResourceCard text1_label="Card Term" text1={resource.card.term} text2_label="Card Definition" text2={resource.card.definition} image={resource.card.image} />
              </CardContent>
            )
          }
          {
            resource.resource_type === 'quickclip' && (
              <CardContent className={classes.cardContent}>
                <ResourceCard title={resource.clip_card.title} text1_label="Clip" text1={resource.clip_card.video} text2_label="Clip with subtitles" text2={resource.clip_card.video_with_subtitles} />
              </CardContent>
            )
          }
          {
            resource.resource_type === 'page' && (
              <CardContent>
                <ResourceCard title={resource.page.title} text1_label="eBook Page" text1={resource.page.content} />
              </CardContent>
            )
          }
        </Card>
      </Grid>
    ))}
  </Grid>
);
