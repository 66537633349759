import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Field, Form } from 'react-final-form';
import { SALE_ACCESS, studentToLearner, validateEmail } from '../../../lib';
import { ViewerContext } from '../../../providers/ViewerProvider';
import { PrimaryButton } from '../../common';
import useStyles from './styles';
import { useInstitutionRoles } from './useInstitutionRoles';

const validate = async (values) => {
  let errors = {};

  if (!values.first_name) errors.first_name = 'A first name is required';
  if (!values.last_name) errors.last_name = 'A last name is required';
  if (!values.role) errors.role = 'A role is required';

  return errors;
};

const isValidEmail = async (value, members) => {
  const existingMember = members.find((member) => member.email === value);

  if (existingMember)
    return 'A user with this email already belongs to this Intstitution';
  if (!value) return 'An email is required';

  const isEmailValid = await validateEmail(value);
  if (!isEmailValid) return 'Please enter a valid email address';
};

const InstitutionInviteForm = ({ onSubmit, onCancel, members }) => {
  const classes = useStyles();
  const { viewer } = useContext(ViewerContext);
  const selectableRoles = useInstitutionRoles();

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, valid, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Field name="first_name">
            {({ input, meta }) => (
              <TextField
                className={classes.textInput}
                fullWidth
                required
                variant="filled"
                id="first_name"
                name="first_name"
                label="First Name"
                {...input}
                helperText={
                  meta.error &&
                  meta.touched && (
                    <Typography variant="caption" color="primary">
                      {meta.error}
                    </Typography>
                  )
                }
              />
            )}
          </Field>
          <Field name="last_name">
            {({ input, meta }) => (
              <TextField
                className={classes.textInput}
                fullWidth
                required
                variant="filled"
                id="last_name"
                name="last_name"
                label="Last Name"
                {...input}
                helperText={
                  meta.error &&
                  meta.touched && (
                    <Typography variant="caption" color="primary">
                      {meta.error}
                    </Typography>
                  )
                }
              />
            )}
          </Field>
          <Field
            name="email"
            validate={(value) => isValidEmail(value, members)}
          >
            {({ input, meta }) => (
              <TextField
                className={classes.textInput}
                fullWidth
                required
                variant="filled"
                id="email"
                name="email"
                label="Email"
                {...input}
                helperText={
                  meta.error &&
                  meta.touched && (
                    <Typography variant="caption" color="primary">
                      {meta.error}
                    </Typography>
                  )
                }
              />
            )}
          </Field>
          <Field name="role">
            {({ input, meta }) => (
              <TextField
                className={classes.textInput}
                select
                fullWidth
                required
                variant="filled"
                id="role"
                name="role"
                label="Role"
                {...input}
                helperText={
                  meta.error &&
                  meta.touched && (
                    <Typography variant="caption" color="primary">
                      {meta.error}
                    </Typography>
                  )
                }
              >
                {selectableRoles.filter((role) => SALE_ACCESS.includes(viewer.role) || role.value !== "CENTER_ADMIN").map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {studentToLearner(role.name)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Field>
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              type="button"
              onClick={() => onCancel()}
              variant="outlined"
              style={{ marginRight: 8 }}
              disabled={submitting}
            >
              Cancel
            </Button>
            <PrimaryButton
              type="submit"
              disabled={submitting || !valid || pristine}
            >
              Send Invite
            </PrimaryButton>
          </Box>
        </form>
      )}
    />
  );
};

export default InstitutionInviteForm;
