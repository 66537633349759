import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  submitButton: {
    flex: 1,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    background: theme.palette.white.main,
  },
  table: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));
