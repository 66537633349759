import React from 'react';
import styles from './styles';

const DrawerShell = ({ drawer, content, ...restProps }) => {
  const classes = styles();
  return (
    <section {...restProps} className={classes.courseCurriculumSection}>
      <aside className={classes.curriculumDrawer}>{drawer}</aside>
      <main className={classes.curriculumContent}>{content}</main>
    </section>
  );
};

export default DrawerShell;
