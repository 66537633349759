import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  addInstructor: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxHeight: '80%',
    height: 'auto',
    maxWidth: 960,
    padding: '1em 2em',
  },
  addInstructor_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  addInstructor_main: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
    height: 600,
    overflow: 'hidden',
    padding: '0.5em'
  },
  addInstructor_containerList: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
  },
  addInstructor_instructorList: {
    display: 'flex',
    padding: 0,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    overflowY: 'auto',
    flex: 1,
    width: '100%',
    margin: '0.5em 0',
    borderRadius: '0.5em',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  addInstructor_footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  successLog: {
    '& > span': {
      color: '#3fae2a'
    }
  },
  errorLog: {
    '& > span': {
      color: '#bf1212'
    }
  },
}))