import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  courseLibrarySection: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabWrapper: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  cardMiddle: { 
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: '#00000089'
  },
}));
