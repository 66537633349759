import React from "react";
import {  
  Tab
} from "@material-ui/core";
import styles from './styles';

const KeyElementsCard = ({title, id, setKeyElementID, keyElementID, setValue, index}) => {
  const classes = styles();

  const handleOnClick = () => {
    setKeyElementID(id);
    setValue(index);
  }

  return (
    <Tab label={title}
      className={ id === keyElementID ? classes.keyElementsCardActive : classes.keyElementsCard}
      onClick={handleOnClick}>
    </Tab>
  )
}

export default KeyElementsCard;