import {
  Button,
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useUpdateGroupMembers } from "../../../../apollo/api/groupMember";
import { CloseButton } from "../../../../components";
import styles from "./styles";
import { useSnackbar } from "notistack";

const GroupMembersFinder = ({ newGroup, groups, setShowStudentsFinder, institutionMembers }) => {
  const classes = styles();
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [valueTab, setValueTab] = useState(0);
  const { updateGroupMembers } = useUpdateGroupMembers();
  //const { addGroupMembers } = useAddGroupMembers();

  //Mutation to move stundents to specific group
  const handleMoveStudentMembers = async () => {
    try {
      const res = await updateGroupMembers({
        variables: {
          data: selectedStudents,
          group_id: newGroup.id
        }
      });

      if (res.data) {
        const studentsWithLogs = students.map((student) => {
          const studentWithLogs = res.data.updateGroupMembers.find((item) => item.currentGroup_id === student.currentGroup_id && item.user_id === student.user.id);
          if (studentWithLogs) {
            return {
              ...student,
              logs: studentWithLogs
            }
          } else {
            return {
              ...student,
              logs: {

              }
            }
          }
        });

        setStudents(studentsWithLogs);
      }
    } catch (error) {
      enqueueSnackbar(error.message,
        { variant: 'error' },
      );
    }
  }

  /*const handleAddStudentsMembers = async () => {
    try {
      const res = await addGroupMembers({
        variables: {
          data: selectedStudents,
          group_id: newGroup.id
        },
      });

      if (res.data) {
        const studentsWithLogs = students.map((student) => {
          const studentWithLogs = res.data.addGroupMembers.find((item) => item.currentGroup_id === student.currentGroup_id && item.user_id === student.user.id);
          if (studentWithLogs) {
            return {
              ...student,
              logs: studentWithLogs
            }
          } else {
            return {
              ...student,
              logs: {

              }
            }
          }
        });
        setStudents(studentsWithLogs);
      }
    } catch (error) {
      enqueueSnackbar(error.message,
        { variant: 'error' },
      );
    }
  }*/

  const handleOnChangeTab = (event, valueTab) => {
    setValueTab(valueTab);
  }

  useEffect(() => {
    setSelectedStudents([]);
  }, [valueTab])

  return (
    <Card className={classes.groupMembersFinder}>
      <div className={classes.groupMembersFinder_header}>
        <div style={{ marginBottom: '1em' }}>
          <Typography variant='h2' style={{ fontWeight: 'bold' }}>{`Institution: ${newGroup.institution.name}`}</Typography>
          <Typography variant='h3'>{`Add Learners to the ${newGroup.name} group`}</Typography>
        </div>
        <CloseButton onClick={() => setShowStudentsFinder(false)} style={{ color: '#bf1212' }} iconProps={{ fontSize: "large" }} />
      </div>
      <Tabs
        className={classes.navbar_tabs}
        value={valueTab}
        variant='scrollable'
        scrollButtons='auto'
        onChange={handleOnChangeTab}>
        <Tab className={valueTab === 0 && classes.navbar_tabActive} label='Institution groups' />
        <Tab className={valueTab === 1 && classes.navbar_tabActive} label='Institution learners' />
      </Tabs>
      <div className={classes.groupMembersFinder_main}>
        {
          valueTab === 0 &&
          <StudentsByGroups
            groups={groups}
            students={students}
            setStudents={setStudents}
            setSelectedStudents={setSelectedStudents}
            selectedStudents={selectedStudents}
          />
        }
        {
          valueTab === 1 &&
          <StudentsByInstitution
            students={students}
            institutionMembers={institutionMembers}
            setStudents={setStudents}
            setSelectedStudents={setSelectedStudents}
            selectedStudents={selectedStudents} />
        }
      </div>
      <div className={classes.groupMembersFinder_footer}>
        {/*<Button 
        disabled={selectedStudents.length <= 0 ? true : false} onClick={handleAddStudentsMembers} 
      className={ selectedStudents.length <= 0 ? classes.groupMembersFinder_footer_addButtonDis : classes.groupMembersFinder_footer_addButton}>add to the current group</Button>*/}
        <Button 
        disabled={selectedStudents.length <= 0 ? true : false} onClick={handleMoveStudentMembers} 
        className={ selectedStudents.length <= 0 ? classes.groupMembersFinder_footer_addButtonDis : classes.groupMembersFinder_footer_addButton}>move to the current group</Button>
      </div>
    </Card>
  )
}

const StudentsByGroups = ({ groups, students, setStudents, setSelectedStudents, selectedStudents }) => {
  const classes = styles();
  const [currentGroup, setCurrentGroup] = useState(groups[0]);

  //If the member already was selected
  const isStudentSelected = (member) => {
    return selectedStudents.find((student) => student.id === member.id);
  }

  //adds or removes a student from selectedStudents
  const handleOnChangeChecked = (member) => {
    const alreadyStudentWasSelected = isStudentSelected(member);

    if (alreadyStudentWasSelected) {
      setSelectedStudents(selectedStudents.filter((student) => student.id !== member.id));
    } else {
      setSelectedStudents([...selectedStudents, {
        id: member.id,
        user_id: member.user.id,
        role: member.role,
        email: member.user.email,
        currentGroup_id: currentGroup.id
      }]);
    }
  }

  useEffect(() => {
    if (currentGroup) {
      const membersWithStudentRole = currentGroup.members.filter((member) => member.role === 'STUDENT');
      setStudents(membersWithStudentRole.map((member) => {
        return {
          ...member,
          currentGroup_id: currentGroup.id
        }
      }));
    }
  }, [currentGroup, setStudents])

  return (
    <Fragment>
      <div className={classes.groupMembersFinder_groups}>
        <Typography style={{ fontWeight: 'bold', fontSize: '15px' }} variant='subtitle1'>Groups</Typography>
        <List className={classes.groupMembersFinder_groupList}>
          {
            groups.length <= 0 ? <span style={{ textAlign: 'center', margin: '1em 0' }}>There're no groups</span> :
              groups.map((group) => {
                return (
                  <ListItem className={currentGroup.id === group.id ? classes.groupMembersFinder_groupItemActive : classes.groupMembersFinder_groupItem} onClick={() => setCurrentGroup(group)} button key={group.id}>
                    <ListItemText primary={group.name} />
                  </ListItem>
                )
              })
          }
        </List>
      </div>
      {
        groups.length <= 0 ? null
          : <div className={classes.groupMembersFinder_students}>
            <Typography style={{ fontWeight: 'bold', fontSize: '15px' }} variant='subtitle1'>{`Students in ${currentGroup.name} group`}</Typography>
            <StudentsList students={students} handleOnChangeChecked={handleOnChangeChecked} isStudentSelected={isStudentSelected} />
          </div>
      }
    </Fragment>
  )
}

const StudentsByInstitution = ({ students, institutionMembers, setStudents, setSelectedStudents, selectedStudents }) => {
  const classes = styles();
  //If the member already was selected
  const isStudentSelected = (member) => {
    return selectedStudents.find((student) => student.id === member.id && student.currentGroup_id === member.currentGroup_id);
  }

  //adds or removes a student from selectedStudents
  const handleOnChangeChecked = (member) => {
    const alreadyStudentWasSelected = isStudentSelected(member);

    if (alreadyStudentWasSelected) {
      setSelectedStudents(selectedStudents.filter((student) => student.user_id !== member.user.id || student.currentGroup_id !== member.currentGroup_id));
    } else {
      setSelectedStudents([...selectedStudents, {
        id: member.id,
        user_id: member.user.id,
        role: member.role,
        email: member.user.email,
        currentGroup_id: member.currentGroup_id
      }]);
    }
  }

  useEffect(() => {
    const handleGetMembersWithStudentRole = () => {
      let studentsWithGroup = [];

      const membersWithStudentRole = institutionMembers.filter((member) => member.role === 'STUDENT');

      for (const member of membersWithStudentRole) {
        if (member.user.groups.length >= 1) {
          const memberInGroups = member.user.groups.map((group) => {
            return {
              id: member.id,
              role: member.role,
              user: {
                first_name: member.user.first_name,
                last_name: member.user.last_name,
                id: member.user.id,
                email: member.user.email
              },
              currentGroup_id: group ? group.id : '',
              currentGroup_name: group ? group.name : '',
              currentGroup_owner: group ? group.owner : ''
            }
          });

          studentsWithGroup = [...studentsWithGroup, ...memberInGroups]
        } else {
          const memberWithoutGroup = {
            id: member.id,
            role: member.role,
            user: {
              first_name: member.user.first_name,
              last_name: member.user.last_name,
              id: member.user.id,
              email: member.user.email
            },
            currentGroup_id: '',
            currentGroup_name: '',
            currentGroup_owner: ''
          }

          studentsWithGroup = [...studentsWithGroup, memberWithoutGroup];
        }
      }

      return studentsWithGroup;
    }

    setStudents(handleGetMembersWithStudentRole());
    // eslint-disable-next-line
  }, [institutionMembers.length, setStudents]);

  return (
    <div className={classes.groupMembersFinder_students} style={{ flex: 1 }}>
      <StudentsList students={students} isStudentSelected={isStudentSelected} handleOnChangeChecked={handleOnChangeChecked} />
    </div>
  )
}

const StudentsList = ({ students, isStudentSelected, handleOnChangeChecked }) => {
  const classes = styles();

  return (
    <List className={classes.groupMembersFinder_studentsList}>
      <ListItem divider>
        <ListItemText style={{ flex: 0.4 }} primary="Student Name" secondary="Email" primaryTypographyProps={{ variant: "subtitle1" }} secondaryTypographyProps={{ variant: "subtitle2" }} />
        <ListItemText style={{ flex: 0.4 }} primary="Current Group" secondary="Instructor" primaryTypographyProps={{ variant: "subtitle1" }} secondaryTypographyProps={{ variant: "subtitle2" }} />
        <ListItemText style={{ flex: 0.2 }} primary="" />
      </ListItem>
      {
        students.length <= 0 ? 'There´re no students to add' :
          students.map((student, index) => {
            return (
              <ListItem onClick={() => handleOnChangeChecked(student)} style={{ color: '#bf1212' }} button key={index}>
                <ListItemText style={{ flex: 0.4 }} primary={student.user.first_name + ' ' + student.user.last_name} secondary={student.user.email} />
                <ListItemText style={{ flex: 0.4 }} primary={student.currentGroup_name} secondary={student.currentGroup_owner ? `${student.currentGroup_owner.first_name} ${student.currentGroup_owner.last_name}` : null} />
                <ListItemText style={{ flex: 0.2 }} className={student.logs?.status ? classes.successLog : classes.errorLog} primary={student.logs?.message} />
                <ListItemSecondaryAction>
                  <Checkbox
                    style={{ color: '#bf1212' }}
                    edge='end'
                    onChange={() => handleOnChangeChecked(student)}
                    checked={isStudentSelected(student) !== undefined ? true : false} />
                </ListItemSecondaryAction>
              </ListItem>
            )
          })
      }
    </List>
  )
}

export default GroupMembersFinder;