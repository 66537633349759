import React, { useEffect, useState } from 'react';
import { 
  Button,
  Card, Checkbox, List, ListItem, ListItemSecondaryAction, ListItemText, Typography 
} from "@material-ui/core";
import styles from './styles';
import { CloseButton } from '../../../../components';
import { useAddGroupMembers } from '../../../../apollo/api/groupMember';
import { useSnackbar } from 'notistack';

const AddInstructor = ({newGroup, setShowInstructorFinder, institutionMembers}) => {
  const classes = styles();
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const { addGroupMembers } = useAddGroupMembers();
  const { enqueueSnackbar } = useSnackbar();

  //If the member already was selected
  const isInstructorSelected = (member) => {
    return selectedInstructors.find((instructor) => instructor.id === member.id && instructor.currentGroup_id === member.currentGroup_id);
  };
  
  //adds or removes a student from selectedStudents
  const handleOnChangeChecked = (member) => {
    const alreadyInstructorWasSelected = isInstructorSelected(member);

    if (alreadyInstructorWasSelected) {
      setSelectedInstructors(selectedInstructors.filter((instructor) => instructor.user_id !== member.user.id || instructor.currentGroup_id !== member.currentGroup_id));
    } else {
      setSelectedInstructors([...selectedInstructors, {
        id: member.id,
        user_id: member.user.id,
        role: member.role,
        email: member.user.email,
      }]);
    }
  };

  const handleAddInstructorsMembers = async () => {
    try {
      const res = await addGroupMembers({
        variables: {
          data: selectedInstructors,
          group_id: newGroup.id
        },
      });

      if (res.data) {
        const instructorsWithLogs = instructors.map((instructor) => {
          const instructorWithLogs = res.data.addGroupMembers.find((item) =>  item.user_id === instructor.user.id);
          if (instructorWithLogs) {
            return {
              ...instructor,
              logs: instructorWithLogs
            }
          } else {
            return {
              ...instructor,
              logs: {
  
              }
            }
          }
        });
        setInstructors(instructorsWithLogs);
      }
    } catch (error) {
      enqueueSnackbar(error.message,
        { variant: 'error' },
      );
    }
  }

  useEffect(() => {  
    const membersWithInstructorRole = institutionMembers.filter((member) => member.role === 'INSTRUCTOR');

    const membersWithProps = membersWithInstructorRole.map((member) => {
      return {
        id: member.id,
        role: member.role,
        user: {
          first_name: member.user.first_name,
          last_name: member.user.last_name,
          id: member.user.id,
          email: member.user.email
        }
      }
    });

    setInstructors(membersWithProps);
    // eslint-disable-next-line
  }, [institutionMembers.length]);

  return (
    <Card className={classes.addInstructor}>
      <div className={classes.addInstructor_header}>
        <div style={{marginBottom: '1em'}}>
          <Typography variant='h2' style={{fontWeight: 'bold'}}>{ `Institution: ${newGroup.institution.name}`}</Typography>
          <Typography variant='h3'>{`Add Instructor to the ${newGroup.name} group`}</Typography>
        </div>
        <CloseButton onClick={() => setShowInstructorFinder(false)} style={{color: '#bf1212'}} iconProps={{fontSize: "large"}}/>
      </div>
      <div className={classes.addInstructor_main}>
        <div className={classes.addInstructor_containerList}>
          <List className={classes.addInstructor_instructorList}>
            {
              instructors.length <= 0 ? 'There´re no instructors to add' :
              instructors.map((instructor, index) => {
                  return (
                    <ListItem onClick={() => handleOnChangeChecked(instructor)} style={{color: '#bf1212'}} button key={index}>
                      <ListItemText style={{flex: 0.4}} primary={instructor.user.first_name + ' ' + instructor.user.last_name} secondary={instructor.user.email}/>
                      <ListItemText style={{flex: 0.4}} primary={instructor.currentGroup_name} secondary={instructor.currentGroup_owner ? `Instructor: ${instructor.currentGroup_owner.first_name} ${instructor.currentGroup_owner.last_name}` : null}/>
                      <ListItemText style={{flex: 0.2}} className={ instructor.logs?.status ? classes.successLog : classes.errorLog} primary={instructor.logs?.message}/>
                      <ListItemSecondaryAction>
                        <Checkbox
                        style={{color: '#bf1212'}}
                        edge='end'
                        onChange={() => handleOnChangeChecked(instructor)}
                        checked={isInstructorSelected(instructor) !== undefined ? true : false}/>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })
            }
          </List>
        </div>
      </div>
      <div className={classes.addInstructor_footer}>
        <Button 
        disabled={selectedInstructors.length <= 0 ? true : false} onClick={handleAddInstructorsMembers} color='primary' variant='contained' >add to the current group</Button>
      </div>
    </Card>
  )
}

export default AddInstructor;