import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  buttonGroup: {
    margin: 'auto',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
  },
  contentWrapper: {
    height: 400,
    overflowY: 'scroll',
  },
}));

export default styles;
