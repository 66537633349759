import React, { memo } from 'react';
import styles from './styles';
import { Button, Box } from '@material-ui/core';
import { KeyElements } from '../../components';
import { KeyElementsDashboard } from '../../components/KeyElements';
import SelectionImmersiveReader from '../../../../components/immersiveReader/SelectionImmersiveReader';
import ImmersiveReaderButton from '../../../../components/immersiveReader/ImmersiveReaderButton';

const Intro = ({ project, onClick, courseId }) => {
  const classes = styles();
  const gs6CourseID = ['0b256b0e-0571-4f5c-99a6-948422b59bf5', '18038e80-837d-44f8-9c72-9dbda87dd368', '747f5c1c-47f1-4cad-b1a8-e60999ebdc3c']
  return (
    <Box className={classes.introContainer}>
      <ImmersiveReaderButton text={project.introduction}/>
      <SelectionImmersiveReader htmlText={project.introduction}/>
      {
        gs6CourseID.includes(courseId ? courseId : project.lesson.course_id) ? <KeyElementsDashboard dashboardTitle={'Key Elements'} keyElements={project.keyElements.filter((ke) => ke.published)} /> :
          <KeyElements keyElements={project.keyElements.filter((ke) => ke.published)} />
      }
      <Box className={classes.buttonContainer}>
        <Button
          onClick={onClick}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default memo(Intro);
