import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(1),
        minWidth: '350px'
    },
    boxesMarggins: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        paddingTop: theme.spacing(0),
    },
    chip: {
        marginRight: '5px',
        marginTop: '5px'
    }
}));