import React, { forwardRef } from "react";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const Subtitle = forwardRef(({ children, variant = "subtitle1", ...restProps }, ref) => {
  return (
    <Typography ref={ref} variant={variant} {...restProps}>
      {children}
    </Typography>
  );
});

Subtitle.propTypes = {
  ...Typography.propTypes,
  variant: PropTypes.oneOf(["subtitle1", "subtitle2"])
};

export default Subtitle;
