import React from 'react';
import { Container, Paper, Typography, Button } from '@material-ui/core';
import { InstitutionForm } from '../../../../components';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_INSTITUTION } from '../../../../apollo/queries';
import { withSnackbar } from 'notistack';

const AddInstitution = ({ history, enqueueSnackbar }) => {
  const [createInstitution] = useMutation(CREATE_INSTITUTION);

  return (
    <Container>
      <Button
        style={{ marginTop: 16 }}
        onClick={() => history.goBack()}
        variant="outlined"
        color="primary"
      >
        Back
      </Button>
      <Paper style={{ padding: 16, maxWidth: 600, margin: 'auto' }}>
        <Typography variant="h5" style={{ paddingBottom: 16 }}>
          Create New Institution
        </Typography>
        <InstitutionForm
          onSubmit={async (values) => {
            const result = await createInstitution({
              variables: { data: values },
            });
            enqueueSnackbar(`Successfully created ${values.name}`, {
              variant: 'success',
              autoHideDuration: 1500,
            });
            history.push(
              `/sales-management/institution/${result.data.createInstitution.id}`,
            );
          }}
        />
      </Paper>
    </Container>
  );
};

export default withSnackbar(AddInstitution);
