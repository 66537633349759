import { makeStyles } from '@material-ui/core/styles';
import { containerPadding, mobileContainerPadding } from '../../../../theme';

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    ...containerPadding(theme),
    overflow: "auto",

    [theme.breakpoints.down("xs")]: {
      ...mobileContainerPadding(theme)
    }
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginBottom: theme.spacing(2)
  },
  noGroupCard: {
    boxShadow: theme.shadows[3]
  },
  card: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default useStyles;
