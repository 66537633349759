import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_SUMMARIES } from '../../../apollo/queries';
import { LoadingScreen } from '../../../components';
import { getAssessmentQuestions, getTotalAssessmentTime } from '../../Benchmark/helpers';
import { useGroup } from '../../GroupDashboard/providers/GroupProvider';

export const SummaryContext = React.createContext();

const SummaryProvider = ({ courseId, summaryLevel, children }) => {
  const { data, loading, error } = useQuery(GET_SUMMARIES, {
    variables: { courseId, level: summaryLevel },
  });

  const group = useGroup();

  if (loading) return <LoadingScreen>Loading Assessment...</LoadingScreen>;
  if (error) return <p>Error...</p>;

  const { summaries } = data;

  const publishedSummaries = summaries.filter((summary) => summary.published !== false);
  
  const state = {
    summaries,
    questions: getAssessmentQuestions(publishedSummaries, group?.assessmentCustomSettings),
    time: getTotalAssessmentTime(publishedSummaries, group?.assessmentCustomSettings),
  };

  return (
    <SummaryContext.Provider value={state}>
      {children}
    </SummaryContext.Provider>
  );
};

export default SummaryProvider;