import React, { useCallback, useEffect, useRef, useState } from "react";
import useImmersiveReaderLauncher from "./useImmersiveReaderLauncher";
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { Tooltip } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@material-ui/core/styles';

const SelectionImmersiveReader = ({ htmlText, style }) => {
  const [showReadButton, setShowReadButton] = useState(false);
  const [readButtonStyleTop, setReadButtonStyleTop] = useState(0);
  const [readButtonStyleLeft, setReadButtonStyleLeft] = useState(0);
  const selection_contentID = `selection_content_${uuidv4()}`;
  const theme = useTheme();
  const readerButton = useRef(null);

  //Close ReadButton if user clicks outside of div 
  const handleOnClickOutSideSelectionContent = useCallback((e) => {
    if (readerButton.current && !readerButton.current.contains(e.target)) {
      setShowReadButton(false);
    }
  },[readerButton]);


  useEffect(() => {
      document.addEventListener('mousedown', handleOnClickOutSideSelectionContent);

      return () => {
          document.removeEventListener('mousedown', handleOnClickOutSideSelectionContent);
      }
  },[handleOnClickOutSideSelectionContent]);

  const { handleOnLaunch } = useImmersiveReaderLauncher();

  const read = async () => {
    if (isTextSelected()) {
      await handleOnLaunch('', getSelectedText());
    }

    setShowReadButton(false);
  }

  const mouseDownHandler = (e) => {
    e.persist();
    switchReadButtonVisibility(false, e);
  }

  const mouseUpHandler = (e) => {
    e.persist();
    setTimeout(() => { switchReadButtonVisibility(isTextSelected(), e) }, 1);
  }

  const switchReadButtonVisibility = (isVisible, e) => {
    isVisible && updateButtonPosition(e);
    setShowReadButton(isVisible);
  }

  const updateButtonPosition = (e) => {
    setReadButtonStyleTop(e.pageY - 50 > 0 ? e.pageY - 50 : e.pageY);
    setReadButtonStyleLeft(e.pageX - 17.5 > 0 ? e.pageX - 17.5 : e.pageX);
  }

  const getSelectedText = () => {
    if (window.getSelection)
      return window.getSelection().toString().trim();
    else if (document.selection)
      return document.selection.createRange().text.trim();
    return '';
  }

  const isTextSelected = () => {
    return getSelectedText() !== '';
  }

  return (
    <React.Fragment>
      {showReadButton &&
      <Tooltip ref={readerButton} title="Immersive Reader">
        <VolumeUpIcon
          fontSize="48"
          style={{ 
            color: '#ffffff', 
            cursor: "pointer", 
            padding: 1, 
            backgroundColor: '#3fae2a', 
            borderRadius: 2,
            position: 'fixed',
            top: readButtonStyleTop,
            left: readButtonStyleLeft,
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            zIndex: 3000
           }}
          data-button-style="iconAndText"
          onClick={read}
        />
      </Tooltip>
      }
      <style dangerouslySetInnerHTML={{
        __html: `
        #${selection_contentID} { font-family: ${theme.typography.fontFamily} !important; word-wrap: break-word !important;  }
        #${selection_contentID} h1 { font-size: 25.62px !important; }
        #${selection_contentID} h2 { font-size: 21px !important; }
        #${selection_contentID} h3 { font-size: 19.38px !important; }
        #${selection_contentID} h4 { font-size: 14px !important; }
        #${selection_contentID} h5 { font-size: 11.62px !important; }
        #${selection_contentID} h6 { font-size: 9.38px !important; }
        #${selection_contentID} img { max-width: 100% !important; height: auto !important; } 
        #${selection_contentID} video { max-width: 100% !important; height: 100% !important; width: 100% !important; }`
      }} />
      <div id={selection_contentID} onMouseDown={mouseDownHandler} style={style} onMouseUp={mouseUpHandler} dangerouslySetInnerHTML={{ __html: htmlText }} />
    </React.Fragment>
  );
}

export default SelectionImmersiveReader;