import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { CourseCard, Heading } from '../../../../components';
import styles from './styles';
import { useGroup } from '../../providers/GroupProvider';

const GroupCourses = ({ match, history }) => {
  const group = useGroup();
  const classes = styles();

  const courses = group.courses.filter((course) => course.published).map((course) => {
    return {
      ...course,
      assessmentCustomSettings: group.assessmentCustomSettings.filter((assessment) => assessment.course_id === course.id)
    }
  }).sort((a, b) => { return a.display_order - b.display_order});

  return (
    <div className={classes.groupCourseContainer}>
      <Box mb={3}>
        <Heading variant="h2" component="h2">
          Courses
        </Heading>
      </Box>
      <Grid container spacing={8}>
        {!courses.length ? (
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Courses are currently under maintenance. Be sure to check back
              later to see the updates!
            </Typography>
          </Grid>
        ) : (
          courses.map((course) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={course.id}>
              <CourseCard
                course={course}
                groupId={group.id}
                onClick={() => history.push(`${match.url}/course/${course.id}`)}
              />
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
};

export default GroupCourses;
