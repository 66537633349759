import React from 'react';
import InstitutionCard from '../InstitutionCard';

const InstitutionList = ({institutions, currentInstitution, onClick, isSelected, currentMember}) => {
  if(!institutions.length || !currentInstitution) return null;

  const getRoleMember = (institution, currentMember) => {
    const institutionMember = institution.members.find((member) => member.user.id === currentMember.user.id);
    if (institutionMember) {
      return institutionMember.role;
    } else {
      return '';
    }
  };
  
  return institutions.map(institution => {
    return (
      <InstitutionCard 
      key={institution.id} 
      institution={institution} 
      roleMember={getRoleMember(institution, currentMember)} 
      onClick={onClick} 
      isSelected={isSelected(institution.id)}
      />
    )
  });
}

export default InstitutionList;
