import gql from 'graphql-tag';
import { BenchmarkFields, BenchmarkQuestionFields } from './fragments';

const GET_BENCHMARK = gql`
  query getBenchmark($where: BenchmarkWhereUniqueInput!) {
    benchmark(where: $where) {
      ...BenchmarkFields
    }
  }
  ${BenchmarkFields}
`;

const GET_BENCHMARKS = gql`
  query getBenchmarks($courseId: ID!, $level: String!) {
    benchmarks(courseId: $courseId, level: $level) {
      ...BenchmarkFields
    }
  }
  ${BenchmarkFields}
`;

const USER_COMPLETED_BENCHMARK_IDS = gql`
  query getStudentProgress($userId: ID!, $groupId: ID!) {
    completedBenchmarks(userId: $userId, groupId: $groupId)
  }
`;

const UPDATE_BENCHMARK = gql`
  mutation updateBenchmark(
    $data: BenchmarkUpdateInput!
    $where: BenchmarkWhereUniqueInput!
  ) {
    updateBenchmark(data: $data, where: $where) {
      ...BenchmarkFields
    }
  }
  ${BenchmarkFields}
`;

const PUBLISH_BENCHMARK = gql`
  mutation publishBenchmark($id: ID!) {
    publishBenchmark(id: $id)
  }
`;

const UNPUBLISH_BENCHMARK = gql`
  mutation unpublishBenchmark($id: ID!) {
    unpublishBenchmark(id: $id)
  }
`;

const GET_BENCHMARK_DEVELOPMENT = gql`
  query getBenchmarkDevelopoment($where: BenchmarkWhereUniqueInput!) {
    benchmark(where: $where) {
      id
      level
      published
      description
      time
      questions_per_lesson
      questions {
        id
        lesson {
          id
        }
      }
    }
  }
`;

const SET_QUESTION_LESSON_REFERENCE = gql`
  mutation setQuestionLessonReference($lessonId: ID, $questionId: ID!) {
    setQuestionLessonReference(questionId: $questionId, lessonId: $lessonId) {
      ...BenchmarkQuestionFields
    }
  }
  ${BenchmarkQuestionFields}
`;

const GET_TOTAL_QUESTIONS_NUMBER_BENCHMARK = gql`
  query getTotalQuestionsNumber($benchmarkId: ID!, $groupId: ID!) {
    totalQuestionsBenchmark(benchmarkId: $benchmarkId, groupId: $groupId)
  }
`;

export {
  GET_BENCHMARK,
  GET_BENCHMARKS,
  USER_COMPLETED_BENCHMARK_IDS,
  UPDATE_BENCHMARK,
  PUBLISH_BENCHMARK,
  UNPUBLISH_BENCHMARK,
  GET_BENCHMARK_DEVELOPMENT,
  SET_QUESTION_LESSON_REFERENCE,
  GET_TOTAL_QUESTIONS_NUMBER_BENCHMARK,
};
