import React, { Component } from "react";
import { List as MaterialList } from "@material-ui/core";

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount = () => {
    this.setState({ data: this.props.data });
  };

  render() {
    const { data } = this.state;
    const { renderItem, listEmptyComponent, ...restProps } = this.props;
    if (!data.length) {
      return this.props.listEmptyComponent
        ? this.props.listEmptyComponent()
        : null;
    }
    return (
      <MaterialList component='nav' aria-label='question list' {...restProps}>
        {this.state.data.map(item => this.props.renderItem(item))}
      </MaterialList>
    );
  }
}

export default List;
