import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';
import { EditorText } from '../../../common';
import styled from 'styled-components';
import clsx from 'clsx';
import SelectionImmersiveReader from '../../../immersiveReader/SelectionImmersiveReader';


const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const Matching = ({ answers, userAnswer, onChange, showAnswer, setMatchingOptions }) => {
  const [concepts, setConcepts] = React.useState({});
  const [descriptions, setDescriptions] = React.useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateUserAnswers = React.useCallback((tempConcepts, tempDescriptions) => {
    onChange({ concepts: tempConcepts, descriptions: tempDescriptions });
  }, [onChange]);

  useEffect(() => {
    function handleResize() {
      // Set window width to state
      setWindowWidth(window.innerWidth);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    const isQuestionAnswered = userAnswer.concepts && userAnswer.descriptions;
    let tempConcepts = isQuestionAnswered ? { ...userAnswer.concepts } : buildMatchingState(answers, "answer", true);
    let tempDescriptions = isQuestionAnswered ? { ...userAnswer.descriptions } : buildMatchingState(answers, "match", false);

    setConcepts(tempConcepts);
    setDescriptions(tempDescriptions);

    if (!isQuestionAnswered && !showAnswer) {
      updateUserAnswers(tempConcepts, tempDescriptions);
    }

  }, [answers, userAnswer, showAnswer, setConcepts, setDescriptions, updateUserAnswers, setMatchingOptions]);

  useEffect(() => {
    const isQuestionAnswered = userAnswer.concepts && userAnswer.descriptions;
    let tempConcepts = isQuestionAnswered ? { ...userAnswer.concepts } : buildMatchingState(answers, "answer", true);
    let tempDescriptions = isQuestionAnswered ? { ...userAnswer.descriptions } : buildMatchingState(answers, "match", false);

    setMatchingOptions({
      concepts: tempConcepts,
      descriptions: tempDescriptions,
    });
  }, [userAnswer, answers, setMatchingOptions]);

  const onDragEnd = result => {
    if (result.destination == null || result.source.droppableId === result.destination.droppableId)
      return;

    const source = result.source.droppableId.split("_");
    const destination = result.destination.droppableId.split("_");

    const sourceName = source[0];
    const sourceId = source[1];
    const destinationName = destination[0];
    const destinationId = destination[1];
    let tempConcepts = { ...concepts };
    let tempDescriptions = { ...descriptions }

    if (sourceName === "concept" && destinationName === "description") {
      tempConcepts[sourceId].letter = null;

      if (tempDescriptions[destinationId].letter) {
        const conceptId = Object.keys(tempConcepts).find(conceptId => tempConcepts[conceptId].systemLetter === tempDescriptions[destinationId].letter);
        tempConcepts[conceptId].letter = tempDescriptions[destinationId].letter;
      }

      tempDescriptions[destinationId].letter = result.draggableId;

      setConcepts(tempConcepts);
      setDescriptions(tempDescriptions);
      updateUserAnswers(tempConcepts, tempDescriptions);
    }

    if (sourceName === "description" && destinationName === "description") {
      if (tempDescriptions[destinationId].letter) {
        const conceptId = Object.keys(tempConcepts).find(conceptId => tempConcepts[conceptId].systemLetter === tempDescriptions[destinationId].letter);
        tempConcepts[conceptId].letter = tempDescriptions[destinationId].letter;
        setConcepts(tempConcepts);
      }

      tempDescriptions[sourceId].letter = null;
      tempDescriptions[destinationId].letter = result.draggableId;

      setDescriptions(tempDescriptions);
      updateUserAnswers(tempConcepts, tempDescriptions);
    }

    if (sourceName === "description" && destinationName === "concept" && !tempConcepts[destinationId].letter && tempConcepts[destinationId].systemLetter === result.draggableId) {
      tempConcepts[destinationId].letter = result.draggableId;
      tempDescriptions[sourceId].letter = null;

      setConcepts(tempConcepts);
      setDescriptions(tempDescriptions);
      updateUserAnswers(tempConcepts, tempDescriptions);
    }
  };

  const classes = styles();
  return (
    <DragDropContext onDragEnd={onDragEnd} >
      {
        windowWidth <= 960 ?
          <Box className={classes.container_movil}>
            <Box className={classes.firstItemsContainer} >
              <Box
              display='flex'
              flexDirection='column'
              gridGap='0.5em'
              >
                <Typography variant='subtitle1' >Concepts</Typography>
                {
                  Object.keys(concepts).map((conceptId, index) => (
                    <Box key={index} className={classes.secondItemContainer}>
                      <Paper key={conceptId} className={classes.itemPaper} elevation={3}>
                        <Box className={classes.conceptItemContent}>
                          <Box className={classes.conceptBox}>
                            <Box fontWeight="bold" mr={1}>{concepts[conceptId].systemLetter}.</Box>
                            <EditorText text={concepts[conceptId].text} />
                          </Box>
                          <Box>
                            <Droppable droppableId={`concept_${conceptId}`} isDropDisabled={showAnswer}>
                              {(provided, snapshot) => (
                                <DroppingContainer ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver} >
                                  {!showAnswer && concepts[conceptId].letter && <DraggableItem id={concepts[conceptId].letter} text={concepts[conceptId].letter} classes={classes} isDragDisabled={true} />}
                                  {provided.placeholder}
                                </DroppingContainer>
                              )}
                            </Droppable>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>))
                }
              </Box>
              <Box
              display='flex'
              flexDirection='column'
              gridGap='0.5em'
              >
                <Typography variant='subtitle1' >Descriptions</Typography>
                {
                  Object.keys(descriptions).map((descriptionId, descriptionIndex) => {
                    return (
                      <Paper key={descriptionId} className={classes.itemPaper} elevation={3}>
                        <Droppable droppableId={`description_${descriptionId}`} isDropDisabled={showAnswer}>
                          {(provided, snapshot) => (
                            <Box className={classes.descItemContent}>
                              <Box width={40}>
                                <DroppingContainer ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver} >
                                  {(showAnswer || descriptions[descriptionId].letter) && <DraggableItem id={showAnswer ? concepts[descriptionId].systemLetter : descriptions[descriptionId].letter} text={showAnswer ? concepts[descriptionId].systemLetter : descriptions[descriptionId].letter} classes={classes} isDragDisabled={showAnswer} />}
                                  {provided.placeholder}
                                </DroppingContainer>
                              </Box>
                              <SelectionImmersiveReader htmlText={descriptions[descriptionId].text} />
                            </Box>
                          )}
                        </Droppable>
                      </Paper>
                    )
                  })
                }
              </Box>
            </Box>
          </Box> :
          <Box className={classes.container}>
            <Box className={clsx(classes.itemContainer, classes.firstItemContainer)}>
              {Object.keys(concepts).map((conceptId, index) => (
                <Box key={index} className={classes.secondItemContainer}>
                  <Paper key={conceptId} className={classes.itemPaper} elevation={3}>
                    <Box className={classes.conceptItemContent}>
                      <Box className={classes.conceptBox}>
                        <Box fontWeight="bold" mr={1}>{concepts[conceptId].systemLetter}.</Box>
                        <EditorText text={concepts[conceptId].text} />
                      </Box>
                      <Box>
                        <Droppable droppableId={`concept_${conceptId}`} isDropDisabled={showAnswer}>
                          {(provided, snapshot) => (
                            <DroppingContainer ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver} >
                              {!showAnswer && concepts[conceptId].letter && <DraggableItem id={concepts[conceptId].letter} text={concepts[conceptId].letter} classes={classes} isDragDisabled={true} />}
                              {provided.placeholder}
                            </DroppingContainer>
                          )}
                        </Droppable>
                      </Box>
                    </Box>
                  </Paper>
                  {Object.keys(descriptions).map((descriptionId, descriptionIndex) => {
                    if (descriptionIndex === index) {
                      return (
                        <Paper key={descriptionId} className={classes.itemPaper} elevation={3}>
                          <Droppable droppableId={`description_${descriptionId}`} isDropDisabled={showAnswer}>
                            {(provided, snapshot) => (
                              <Box className={classes.descItemContent}>
                                <Box width={40}>
                                  <DroppingContainer ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver} >
                                    {(showAnswer || descriptions[descriptionId].letter) && <DraggableItem id={showAnswer ? concepts[descriptionId].systemLetter : descriptions[descriptionId].letter} text={showAnswer ? concepts[descriptionId].systemLetter : descriptions[descriptionId].letter} classes={classes} isDragDisabled={showAnswer} />}
                                    {provided.placeholder}
                                  </DroppingContainer>
                                </Box>
                                <SelectionImmersiveReader htmlText={descriptions[descriptionId].text} />
                              </Box>
                            )}
                          </Droppable>
                        </Paper>
                      )
                    } else {
                      return null
                    }
                  })}
                </Box>
              ))}
            </Box>
          </Box>
      }
    </DragDropContext >
  );
};

const buildMatchingState = (answers, textPropName, isConcept) => {
  let alphabetIndex = 0;

  answers.sort(() => .5 - Math.random());

  const items = answers.reduce((acc, item) => {
    acc[item.id] = {
      id: item.id,
      letter: isConcept ? alphabet[alphabetIndex] : null,
      text: item[textPropName]
    };

    if (isConcept) {
      acc[item.id].systemLetter = acc[item.id].letter;
    }

    alphabetIndex++;

    return acc;
  }, {});

  return items;
};

const DraggableItem = ({ id, text, classes }) => {
  return (
    <Draggable key={id} draggableId={id} index={0}>
      {(provided, snapshot) => (
        <DraggingControl {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} isDragging={snapshot.isDragging}>
          <Paper className={clsx(classes.circle, classes.draggingControl)} elevation={3}>
            {text}
          </Paper>
        </DraggingControl>
      )}
    </Draggable>
  );
};

const DraggingControl = styled.div`
`;

const DroppingContainer = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -moz-box-shadow:    inset 0 0 5px #000000;
  -webkit-box-shadow: inset 0 0 5px #000000;
  box-shadow:         inset 0 0 5px #000000;
  margin-right: 10px;  
}`;

const styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: "row",
    },
    border: "1px solid #e5e5e5",
    padding: "25px",
    borderRadius: "5px"
  },
  container_movil: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 0,
      display: 'flex',
    }
  },
  itemContainer: {
    flex: 1,
  },
  itemPaper: {
    padding: "15px 20px",
    marginBottom: theme.spacing(1),
    "& p": {
      margin: "0px !important",
      padding: "0px !important"
    },
    width: "100%",
    display: 'flex',
    alignItems: 'center',
  },
  firstItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '1em',
    width: '100%',
  },
  secondItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 0
    }
  },
  conceptItemContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  conceptBox: {
    display: "flex",
    flexDirection: "row"
  },
  descItemContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "row-reverse",
      justifyContent: 'space-between',
      gap: '1em',
      width: '100%'
    }
  },
  circle: {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  grayCircle: {
    backgroundColor: "lightgray",
    color: "white"
  },
  draggingControl: {
    backgroundColor: "#C03030",
    border: "1px solid #C03030",
    color: "white",
  },

}));

export default Matching;