import { makeStyles } from '@material-ui/core/styles';
import BackgroundImage from '../../../assets/background/study-group-dashboard-bg.png';

const styles = makeStyles((theme) => ({
  appShell: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    overflow: 'hidden',
    backgroundColor: "white",
    backgroundImage: `url(${BackgroundImage})`,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
    overflow: "hidden"
  },
}));

export default styles;
