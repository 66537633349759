import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import {
  GET_GROUP_BY_ID,
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  GET_GROUP_MEMBER,
  JOIN_GROUP,
  GET_USER_INSTITUTION_GROUPS,
} from '../apollo/queries';
import { adopt } from 'react-adopt';

const groupData = ({ render, groupId }) => {
  return (
    <Query
      query={GET_GROUP_BY_ID}
      variables={{ groupId }}
      // fetchPolicy="cache-and-network"
    >
      {({ data: { group } = {}, loading, error }) =>
        render({ group, loading, error })
      }
    </Query>
  );
};

const addGroup = ({ render }) => (
  <Mutation mutation={CREATE_GROUP}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const updateGroup = ({ render }) => (
  <Mutation mutation={UPDATE_GROUP}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const deleteGroup = ({ render }) => (
  <Mutation mutation={DELETE_GROUP}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const joinGroup = ({ render }) => (
  <Mutation mutation={JOIN_GROUP}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const getMember = ({ render, groupId, userId }) => {
  return (
    <Query
      query={GET_GROUP_MEMBER}
      variables={{ groupId, userId }}
      skip={!groupId || !userId}
    >
      {({ data: { groupMember } = {}, loading, error }) =>
        render({ groupMember, loading, error })
      }
    </Query>
  );
};

const userInstitutionGroups = ({ render, userId, institutionId }) => {
  return (
    <Query
      query={GET_USER_INSTITUTION_GROUPS}
      variables={{ userId, institutionId }}
      // fetchPolicy="cache-and-network"
      skip={!institutionId}
      notifyOnNetworkStatusChange
    >
      {({ data: { groups } = {}, loading, error, networkStatus }) =>
        render({ groups, loading, error, networkStatus })
      }
    </Query>
  );
};

const GroupContainer = adopt({
  groupData,
  addGroup,
  updateGroup,
  deleteGroup,
  getMember,
  joinGroup,
  userInstitutionGroups,
});

export default GroupContainer;
