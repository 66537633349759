import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SelectionImmersiveReader from '../../../immersiveReader/SelectionImmersiveReader';

const TrueOrFalse = ({ answers, userAnswer, onChange, questionType, showAnswer }) => {
    const [state, setState] = React.useState({});
    const classes = useStyles();
    const answerOptions = getAnswerOptions(questionType);

    React.useEffect(() => {
        const isQuestionAnswered = !Array.isArray(userAnswer) && typeof userAnswer === 'object';
        const tempState = isQuestionAnswered ? { ...userAnswer } : buildItems(answers);

        setState(tempState);

        if (!isQuestionAnswered && !showAnswer) {
            onChange(tempState);
        }
    }, [answers, userAnswer, showAnswer, setState, onChange]);

    const onChangeAnswer = (userAnswer) => {
        const answer = answers.find(item => item.id === userAnswer.id);
        const tempState = { ...state, [userAnswer.id]: { text: answer.answer, userAnswer: userAnswer.value, is_correct: answer.is_correct === userAnswer.value, systemAnswer: answer.is_correct } };
        setState(tempState);
        onChange(tempState);
    };

    const getAnswer = (answerId) => {
        if (state[answerId]) {
            if (showAnswer) {
                return state[answerId].systemAnswer;
            }
            else {
                return state[answerId].userAnswer;
            }
        }
        else
            return null;
    };

    return (
        <Box className={classes.container}>
            {Object.keys(state).map((answerId, index) =>
                <Item
                    key={answerId}
                    answerId={answerId}
                    onChange={onChangeAnswer}
                    text={state[answerId].text}
                    trueText={answerOptions.trueText}
                    falseText={answerOptions.falseText}
                    userAnswer={getAnswer(answerId)}
                    showBorder={index + 1 < answers.length}
                    isAnswerDisabled={showAnswer}
                />
            )}
        </Box>
    );
};

const buildItems = (answers) => {
    let items = answers.reduce((acc, item) => {
        acc[item.id] = { text: item.answer, userAnswer: null, is_correct: false, systemAnswer: item.is_correct };
        return acc;
    }, {});

    return items;
};

const Item = ({ answerId, userAnswer, text, trueText, falseText, showBorder, onChange, isAnswerDisabled }) => {
    const classes = useItemStyles({ showBorder });

    const onChangeTrue = (selected) => {
        if (!isAnswerDisabled && !userAnswer) {
            onChange({ id: answerId, value: selected });
        }
    };

    const onChangeFalse = (selected) => {
        if (!isAnswerDisabled && (userAnswer === true || userAnswer === null)) {
            onChange({ id: answerId, value: !selected });
        }
    };

    return (
        <Box className={classes.item}>
            <SelectionImmersiveReader htmlText={text}/>
            <Box className={classes.buttonsContainer}>
                <RoundButton text={trueText} selected={userAnswer} onChange={onChangeTrue} isAnswerDisabled={isAnswerDisabled} />
                <RoundButton text={falseText} selected={userAnswer === null ? null : !userAnswer} onChange={onChangeFalse} isAnswerDisabled={isAnswerDisabled} />
            </Box>
        </Box>
    );
};


const RoundButton = ({ text, selected, onChange, isDisabled }) => {
    const classes = useRoundButtonStyles({ selected });

    const onClick = () => {
        onChange(!selected);
    };

    return (
        <Box className={classes.container} boxShadow={3} onClick={onClick}>
            <Typography variant="subtitle2" className={classes.text}>
                {text}
            </Typography>
        </Box>
    );
};

const getAnswerOptions = questionType => {
    if (questionType === "CORRECT_OR_INCORRECT") {
        return { trueText: "CORRECT", falseText: "INCORRECT" };
    }

    return { trueText: "YES", falseText: "NO" };
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #e5e5e5",
        padding: "5px 20px",
        borderRadius: "5px"
    }
}));

const useItemStyles = makeStyles(theme => ({
    item: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: props => props.showBorder ? "1px solid #E5E5E5" : "none"
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "row",
    }
}));

const useRoundButtonStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        padding: "7px",
        color: "white",
        cursor: "pointer",
        marginLeft: theme.spacing(1),
        backgroundColor: props => props.selected === null || props.selected === false ? "#D7D5D5" : "#BE2026",
    },
    text: {
        color: "white"
    }
}));



export default TrueOrFalse;