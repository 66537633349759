import React, { useContext, useState, useEffect } from 'react';
import { useViewer } from './ViewerProvider';
import { LoadingScreen } from '../components';
import { useUserInstitutions } from '../apollo/api';

const CURRENT_INSTITUTION_ID = 'CURRENT_INSTITUTION_ID';

const InstitutionContext = React.createContext();

const InstitutionProvider = ({ children }) => {
  const [_currentInstitution, _setCurrentInstitution] = useState(null);
  const viewer = useViewer();
  
  const currentInstitutionId =
    localStorage.getItem(CURRENT_INSTITUTION_ID) || null;

  const { data, loading, error, refetch } = useUserInstitutions(viewer ? viewer.id : null);

  useEffect(() => {
    if (data && data.institutions && data.institutions.length) {
      if (currentInstitutionId) {
        const userSelectedInstitution = data.institutions.find(
          (institution) => institution.id === currentInstitutionId);
        
          if(userSelectedInstitution) {
            _setCurrentInstitution(userSelectedInstitution);
          } else {
            _setCurrentInstitution(data.institutions[0])
          }
      } else {
        _setCurrentInstitution(data.institutions[0]);
      }
    }
  }, [data, currentInstitutionId]);

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error...</p>;

  const setCurrentInstitution = (institution) => {
    localStorage.setItem(CURRENT_INSTITUTION_ID, institution.id);

    _setCurrentInstitution(institution);
  };

  const updateUserInstitutions = async (institution) => {
    localStorage.setItem(CURRENT_INSTITUTION_ID, institution.id);
    await refetch();
  };

  const state = {
    institutions: data ? data.institutions : [],
    currentInstitution: _currentInstitution,
    currentMember: _currentInstitution ? _currentInstitution.members.find((member) => member.user.id === viewer.id) : null,
    setCurrentInstitution,
    updateUserInstitutions,
  };

  return (
    <InstitutionContext.Provider value={state}>
      {children}
    </InstitutionContext.Provider>
  );
};

export const useInstitution = () => {
  const institutionState = useContext(InstitutionContext);

  return institutionState;
};

export default InstitutionProvider;
