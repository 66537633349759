import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  benchmarkSection: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.white.main,
    height: '100vh',
    width: '99.9vw',
    zIndex: 1202,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowX: 'auto',
  },
  // benchmarkContainer: {},
  // questionWrapper: {
  //   paddingBottom: theme.spacing(7),
  // },
  // questionHeader: {
  //   background: theme.palette.white.main,
  //   boxShadow: 'none',
  // },
  // questionToolbar: {
  //   padding: theme.spacing(2),
  //   paddingBottom: theme.spacing(1),
  //   paddingTop: theme.spacing(1),
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  // },
  // filler: {
  //   height: 84,
  //   [theme.breakpoints.up('md')]: {
  //     height: 107,
  //   },
  // },
  // questionTimer: {
  //   fontSize: 25,
  //   [theme.breakpoints.up('md')]: {
  //     fontSize: 40,
  //   },
  // },

  // button: {
  //   width: '100%',
  //   [theme.breakpoints.up('sm')]: {
  //     width: 'auto',
  //   },
  // },
}));
