import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  FormControl,
  TextField,
  MenuItem,
  Box,
  Button,
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { GET_FORM_PRODUCTS } from '../../../apollo/queries';

const EBookCreateForm = ({ onSubmit, onCancel }) => {
  const { data, loading, error } = useQuery(GET_FORM_PRODUCTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        let errors = {};
        if (!values.name) errors.name = 'a title is required';
        if (!values.product_id) errors.product_id = 'please select a product';

        return errors;
      }}
      render={({ handleSubmit, pristine, submitting, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth style={{ marginBottom: 8 }}>
            <Field name="name">
              {({ input, meta }) => (
                <TextField
                  id="name"
                  label="Book Title"
                  value={input.value}
                  onChange={value => input.onChange(value)}
                  variant="filled"
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 8 }}>
            <Field name="product_id">
              {({ input, meta }) => (
                <TextField
                  id="product_id"
                  select
                  label="Product"
                  value={input.value}
                  onChange={value => input.onChange(value)}
                  variant="filled"
                >
                  {data.products.map(product => (
                    <MenuItem value={product.id} key={product.id}>
                      {product.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
          </FormControl>
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="button"
              onClick={onCancel}
              variant="text"
              color="primary"
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="text"
              color="primary"
              disabled={pristine || submitting || !valid}
            >
              Create E-Book
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default EBookCreateForm;
