import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    currentInstitution: ID
  }
  extend type Mutation {
    updateCurrentInstitution(id: ID!): ID
  }
`;

export const resolvers = {
  Mutation: {
    updateCurrentInstitution: (_, { id }, { cache }) => {
      cache.writeData({ data: { currentInstitution: id } });
    },
  },
};
