import React from 'react';
import { Box, Card, CardHeader, Typography } from '@material-ui/core';
import useStyles from './styles';
import ReceiptIcon from '@material-ui/icons/Receipt';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';

const LicenseStatus = ({ daysToExpire, className, status }) => {
  const classes = useStyles();
  let _status = status;
  const indicatorStyles = {
    [classes.statusGreen]: daysToExpire > 90,
    [classes.statusYellow]: daysToExpire <= 90 && daysToExpire > 0,
    [classes.statusRed]: daysToExpire <= 0,
    [classes.statusQuote]: daysToExpire <= -18619,
  };

  if (daysToExpire <= -18619) {
    _status = 'Quoted';
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={className}
    >
      <div className={clsx(classes.statusIndicator, indicatorStyles)} />
      <Typography variant="caption">{_status}</Typography>
    </Box>
  );
};

function useLicenseStatus(daysToExpire) {
  const status = daysToExpire > 0 ? 'Active' : 'Expired';

  return { status, LicenseStatus };
}

const LicenseCard = ({ license }) => {
  const classes = useStyles();
  const { status, LicenseStatus } = useLicenseStatus(
    license ? license.days_to_expire : '',
  );

  const getLicenseExpiryText = (date) => {
    if (date) {
      return `Expiration Date: ${moment(license.expiry_date).format(
        'MMM Do YYYY',
      )}`;
    }
    return `Expiration Date: N/A`;
  };

  return (
    <Card
      key={license ? license.id : Math.random()}
      variant="outlined"
      className={classes.licenseCard}
    >
      <CardHeader
        avatar={
          license ? (
            <ReceiptIcon fontSize="large" color="primary" />
          ) : (
            <Skeleton
              animation="wave"
              variant="circle"
              width={40}
              height={40}
            />
          )
        }
        title={
          license ? (
            license.name
          ) : (
            <Skeleton
              animation="wave"
              height={10}
              width="60%"
              style={{ marginBottom: 6 }}
            />
          )
        }
        subheader={
          license ? (
            getLicenseExpiryText(license.expiry_date)
          ) : (
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          )
        }
        subheaderTypographyProps={{ variant: 'subtitle2' }}
        titleTypographyProps={{ variant: 'h4' }}
      />
      {license ? (
        <LicenseStatus
          className={classes.status}
          status={status}
          daysToExpire={license.days_to_expire}
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          style={{
            position: 'absolute',
            marginBottom: 6,
            top: 0,
            right: 0,
            margin: 4,
          }}
        >
          <Skeleton
            animation="wave"
            variant="circle"
            width={9}
            height={9}
            style={{ marginRight: 4 }}
          />
          <Skeleton animation="wave" height={10} width={50} />
        </Box>
      )}
    </Card>
  );
};

export default LicenseCard;
