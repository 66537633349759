import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
} from '@material-ui/core';
import styles from './styles';

const UserList = ({ onSelect, users }) => {
  const classes = styles();
  if (!users.length) {
    return (
      <Box p={2}>
        <Typography variant="h5">No Users</Typography>
      </Box>
    );
  }
  return (
    <List
      component="nav"
      aria-label="institution list"
      className={classes.userList}
    >
      {users.map((item) => (
        <ListItem button key={item.id} divider={true}>
          <ListItemText
            onClick={() => onSelect(item)}
            primary={`${item.first_name} ${item.last_name}`}
            secondary={item.email}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default UserList;
