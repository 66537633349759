import React, { useState } from 'react';
import {
  Typography
} from '@material-ui/core';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import styles from './styles';
import KeyElementsList from './KeyElementsList';
import KeyElementsContent from './KeyElementsContent';

//Hooks
import useMergeKeyElements from '../../../../hooks/useMergeKeyElements';

const KeyElementsDashboard = ({ keyElements, questionResources, dashboardTitle }) => {
  const classes = styles();
  const [keyElementID, setKeyElementID] = useState(null);

  const {keyElementsSorted} = useMergeKeyElements(keyElements, setKeyElementID);

  if (keyElements) {
    if (keyElements.length === 0) {
      return null
    }
  }

  return (
    <div className={classes.keyElementsDashboard}>
      <div className={classes.keyElementsDashboard_header}>
        <EmojiObjectsOutlinedIcon fontSize="large" style={{ marginRight: 4, color: '#FFFFFF' }} />{' '}
        <Typography variant='h6'  >{dashboardTitle}</Typography>
      </div>
      <div className={classes.keyElementsDashboard_main}>
        {
          !questionResources ? 
            <KeyElementsList 
            keyElements={keyElementsSorted} 
            setKeyElementID={setKeyElementID}
            keyElementID={keyElementID}/> : null
        }
        <KeyElementsContent 
        questionResources={questionResources}
        keyElements={keyElementsSorted}
        keyElementID={keyElementID}/>
      </div>
    </div>
  );
};

export default KeyElementsDashboard;
