import gql from 'graphql-tag';
import { AnswerFields } from './fragments';

const DELETE_ANSWER = gql`
  mutation deleteAnswer($id: ID!) {
    deleteAnswer(id: $id)
  }
`;

const RESTORE_ANSWER = gql`
  mutation restoreAnswer($id: ID!) {
    restoreAnswer(id: $id) {
      ...AnswerFields
    }
  }
  ${AnswerFields}
`;

export { DELETE_ANSWER, RESTORE_ANSWER };
