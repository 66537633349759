import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonGroup: {
    marginRight: 'auto',
  },
  viewInput: {
    flex: `30% 0 0`,
  },
  checkboxContainer: {
    padding: 3,
  },
  licenseSearchResultsContainer: {
    marginTop: theme.spacing(1),
    minHeight: 300,
    maxHeight: 300,
    overflowY: 'scroll',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  dayButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
  },
}));

export default styles;
