import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  assessmentSettings_footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '1em 0',
    gap: '1em',
  },
  titleSection: {
    fontSize: 16, 
    marginBottom: '1em',
    color: '#bf1212',
  }
}));