import React from 'react';
import { Container, Grid, Typography, Toolbar } from '@material-ui/core';
import {
  InstitutionSearch,
  LicenseSearch,
  UsersWithNoInstitution,
} from '../../../../components';

const SalesDashboard = ({ history, match }) => {
  return (
    <Container>
      <Typography variant="h5" style={{ padding: 8 }}>
        Sales Dashboard
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <InstitutionSearch history={history} match={match} />
        </Grid>
        <Grid item xs={12} md={6}>
          <LicenseSearch history={history} match={match} />
        </Grid>
        <Grid item xs={12} md={6}>
          <UsersWithNoInstitution history={history} match={match} />
        </Grid>
      </Grid>
      <Toolbar />
    </Container>
  );
};

export default SalesDashboard;
