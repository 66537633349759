import React from 'react';
import { KeyElementsDashboard } from './KeyElements';


const QuestionResourcesDashboard = ({response, question, caseStudyNumber}) => {
  if (response.case === 'hint' && question.hint !== null) {
    return (
      <KeyElementsDashboard dashboardTitle={`Case Study ${caseStudyNumber}`} questionResources={question.hint}/>
    )
  } else if (response.case === 'incorrect' && question.reveal !== null) {
    return (
      <KeyElementsDashboard dashboardTitle={`Case Study ${caseStudyNumber}`} questionResources={question.reveal}/>
    )
  }
}

export default QuestionResourcesDashboard;