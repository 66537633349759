import React, { useState, useContext } from 'react';
import { BenchmarkContext } from './BenchmarkProvider';
import { checkAnswer, removeTextFromUserAnswer } from '../../../lib';
import { ViewerContext } from '../../../providers/ViewerProvider';
import { withSnackbar } from 'notistack';
import { CREATE_BENCHMARK_RESULT } from '../../../apollo/queries';
import { useMutation } from '@apollo/react-hooks';

export const BMStudentAnswerContext = React.createContext();

const BenchmarkUserAnswerProvider = ({ groupId, enqueueSnackbar, children }) => {
  const { viewer } = useContext(ViewerContext);
  const { questions, benchmarks } = useContext(BenchmarkContext);
  const [_userAnswer, _setUserAnswer] = useState({});
  const [userResults, setUserResults] = useState([]);
  const [timeSpentUserAnswers, setTimeSpentUserAnswers] = useState({});

  const [createResults] = useMutation(CREATE_BENCHMARK_RESULT, {
    refetchQueries: ['getStudentProgress', 'getStudentCourseProgress'],
  });

  const setUserAnswer = (answers) => {
    _setUserAnswer(answers);
  };

  const getBenchmark = (benchmarks, question) => {
    const benchmark = benchmarks.reduce((acc, benchmark) => {
      const isQuestionInBenchmark = benchmark.questions.find((questionBm) => questionBm.id === question.id);
      if (isQuestionInBenchmark) {
        acc.push(benchmark);
      }

      return acc;
    }, []);
    return benchmark[0];
  };

  const getStudentResults = (question) => {
    const isCorrect = checkAnswer(question, _userAnswer[question.id]);
    const benchmark = getBenchmark(benchmarks, question);
    const answer = _userAnswer[question.id] ? _userAnswer[question.id] : [];
    const studentAnswer = JSON.stringify(removeTextFromUserAnswer(question.type, answer));

    return {
      result: isCorrect,
      question_id: question.id,
      benchmark_id: benchmark.id,
      group_id: groupId,
      user_id: viewer?.id,
      answer: studentAnswer,
      seconds_spent: timeSpentUserAnswers[question.id]
    };
  };

  const submitBenchmark = async () => {
    try {
      const results = questions.map(getStudentResults);   
      if (groupId) {
        await createResults({
          variables: { data: results },
          refetchQueries: ['getStudentCourseProgress'],
        });
      }
      setUserResults(results);
      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Oops! Something went wrong!', { variant: 'error' });
      return false;
    }
  };

  return (
    <BMStudentAnswerContext.Provider
      value={{
        userAnswer: _userAnswer,
        setUserAnswer,
        submitBenchmark,
        userResults,
        setTimeSpentUserAnswers,
        timeSpentUserAnswers,
      }}
    >
      {children}
    </BMStudentAnswerContext.Provider>
  );
};

export default withSnackbar(BenchmarkUserAnswerProvider);
