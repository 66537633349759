import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  courseDashboard: {
    padding: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  expansionPanel: {
    background: 'transparent',
    boxShadow: 'none',
  },
  benchmarkSummaryRoot: {
    background: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
  },
  // CourseBenchmarks.js
  flexitem: {
    flex: '0 1 20%',

    [theme.breakpoints.down('sm')]: {
      flex: '0 1 100%',
    },
    
  },
  // Cards.js
  card: {
    position: 'relative',
    minWidth: 250,

    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
    },
  },
  icon: {
    color: theme.palette.success.main,
  },
  lockIcon: {
    color: theme.palette.grey[400],
  },
  completedContainer: {
    color: theme.palette.success.main,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: -2,
    right: 20,
  },
  draftContainer: {
    color: theme.palette.warning.main,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: -2,
    right: 20,
  },
  courseLesson_header: {
    '& .MuiCardHeader-title': {
      fontWeight: 'bold'
    },
    '& .MuiCardHeader-subheader': {
      fontWeight: 'normal'
    }
  }
}));
