import gql from 'graphql-tag';

const CREATE_STUDY_PROJECT_RESULT = gql`
  mutation createStudyProjectResults($data: [StudyProjectResultCreateInput!]!) {
    createStudyProjectResult(data: $data) {
      id
      result
      answer
      question {
        id
        question
      }
      studyProject {
        id
        level
      }
    }
  }
`;

const STUDENT_STUDY_PROJECT_RESULTS = gql`
  query getStudentProjectResults($userId: ID!, $courseId: ID!) {
    studyProjectResults(where: { user_id: $userId, course_id: $courseId }) {
      id
      result
      answer
      question {
        id
        question
      }
      studyProject {
        id
        level
      }
    }
  }
`;

export { CREATE_STUDY_PROJECT_RESULT, STUDENT_STUDY_PROJECT_RESULTS };
