import React, { Fragment, useState } from 'react';
import { EditButton } from '../../../../../../../components';
import { Paper, Modal, Typography } from '@material-ui/core';
import { KeyElementContainer } from '../../../../../../../containers';
import styles from './styles';
import KeyElementForm from './KeyElementForm';

const EditKeyElement = ({ keyElement, enqueueSnackbar }) => {
  const classes = styles();
  const [showForm, setShowForm] = useState(false);

  const handleSubmit = async (values, updateKeyElement) => {
    await updateKeyElement.mutation({
      variables: {
        data: { 
          ...values,
          order_key: parseInt(values.order_key)
         },
        where: { id: keyElement.id },
      },
      refetchQueries: ['getKeyElements'],
    });
    enqueueSnackbar(`Successfully updated ${values.name}`, {
      variant: 'success',
      autoHideDuration: 1500,
    });
    setShowForm(false);
  };

  return (
    <Fragment>
      <EditButton
        onClick={(event) => {
          event.stopPropagation();
          setShowForm(true);
        }}
        className={classes.toolbarButton}
      />
      <Modal
        onClick={(event) => event.stopPropagation()}
        open={showForm}
        onClose={(event) => {
          event.stopPropagation();
          setShowForm(false);
        }}
      >
        <Paper className={classes.formWrapper} elevation={0}>
          <Typography variant="h6" gutterBottom>
            Edit {keyElement.name}
          </Typography>
          <KeyElementContainer>
            {({ updateKeyElement }) => (
              <KeyElementForm
                onSubmit={(values) => handleSubmit(values, updateKeyElement)}
                keyElement={keyElement}
              />
            )}
          </KeyElementContainer>
        </Paper>
      </Modal>
    </Fragment>
  );
};

export default EditKeyElement;
