import React from 'react';
import { Card, CardContent, Typography, Chip, Box } from '@material-ui/core';
import { DeleteButton } from '../../common';

const CMSCourseCard = ({ course, onDelete }) => {
  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          pb={2}
        >
          <div>
            <Typography variant="h6">{course.name}</Typography>
            <Typography variant="caption">{course.description}</Typography>
          </div>
          <DeleteButton onClick={onDelete} />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Chip color="primary" label={`${course.levels} Benchmarks`} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CMSCourseCard;
