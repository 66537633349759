import React, { Fragment, useState } from 'react';
import {
  Typography,
  Modal,
  Paper,
  Box,
  Card,
  Chip,
  CardActionArea,
  FormControl,
  TextField,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import { CourseForm, EditButton, DeleteButton } from '../../../../components';
import styles from './styles';
import { withSnackbar } from 'notistack';
import { useIsManager } from '../IsManager';
import { CourseContainer } from '../../../../containers';
import clsx from 'clsx';

const ProductCourseList = ({ product, match, history, enqueueSnackbar }) => {
  const classes = styles();
  const [editCourse, setEditCourse] = useState(false);
  const [showDeleteCourse, setShowDeleteCourse] = useState(null);
  const [courseToDelete, setCourseToDelete] = useState('');

  const isManager = useIsManager();

  const handleDeleteCourse = async (courseId, deleteCourse) => {
    try {
      await deleteCourse.mutation({
        variables: { id: courseId },
        refetchQueries: ['allCMSProducts'],
      });
      enqueueSnackbar('Successfully deleted Course', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar('Failed to deleted Course. Please contact Tech Support', {
        variant: 'error',
      });
    }
    setCourseToDelete('');
    setShowDeleteCourse(false);
  };

  const handleCourseUpdate = async (values, updateCourse) => {
    try {
      const data = {};
      data.name = values.name;
      data.levels = values.levels;
      data.logo = values.logo;
      data.display_order = parseInt(values.display_order);

      if (values.description) {
        data.description = values.description;
      }
      await updateCourse.mutation({
        variables: {
          data,
          where: { id: values.id },
        },
      });
      enqueueSnackbar('Course updated', {
        variant: 'success',
        autoHideDuration: 1500,
      });
      setEditCourse(false);
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong please try again', {
        variant: 'error',
      });
    }
  };

  const onDeleteInputChange = (event) => {
    setCourseToDelete(event.target.value);
  };

  const courses = product.courses;

  if (!courses.length) {
    return (
      <Card className={classes.courseCard}>
        <Box pl={3} pr={3} pt={2} pb={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="textSecondary">
              No Courses click Add Course to get started.
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <CourseContainer>
      {({ updateCourse, deleteCourse }) => (
        <Fragment>
          <Modal
            open={Boolean(showDeleteCourse)}
            onClose={(event) => {
              event.stopPropagation();
              setShowDeleteCourse(null);
            }}
          >
            <Paper className={clsx(classes.modal, classes.deleteProductModal)}>
              {Boolean(showDeleteCourse) && (
                <Fragment>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Danger Zone
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: 16 }}>
                    Are you sure you want to delete {showDeleteCourse.name}.
                    Institutions using this this course will lose all progress
                    and access.
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControl style={{ width: '70%', marginBottom: 16 }}>
                      <TextField
                        autoFocus
                        value={courseToDelete}
                        onChange={onDeleteInputChange}
                        label="Course Name"
                        helperText="type the course name to continue"
                      />
                    </FormControl>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={courseToDelete !== showDeleteCourse.name}
                      onClick={() =>
                        handleDeleteCourse(showDeleteCourse.id, deleteCourse)
                      }
                    >
                      Delete {showDeleteCourse.name}
                    </Button>
                  </Box>
                </Fragment>
              )}
            </Paper>
          </Modal>
          <Modal
            open={Boolean(editCourse)}
            onClose={() => setEditCourse(false)}
            disableEnforceFocus={true}
          >
            <Paper className={classes.modal}>
              <Typography variant="h5" gutterBottom>
                Edit Course
              </Typography>
              <CourseForm
                course={editCourse}
                onSubmit={(values) => handleCourseUpdate(values, updateCourse)}
              />
            </Paper>
          </Modal>
          {courses.map((course) => {
            return (
              <Card className={classes.courseCard} key={course.id}>
                <Box pl={3} pr={3} pt={2} pb={2}>
                  <Box display="flex" alignItems="center">
                    <img
                      alt={course.name}
                      src={course.logo}
                      style={{ width: '100%', height: 'auto', maxWidth: 60 }}
                    />
                    <Typography variant="h5" style={{ marginLeft: 8 }}>
                      {course.name}
                    </Typography>
                    <EditButton
                      style={{ marginLeft: 'auto' }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setEditCourse(course);
                      }}
                    />
                    {isManager && (
                      <DeleteButton
                        style={{ marginRight: -12 }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowDeleteCourse(course);
                        }}
                      />
                    )}
                  </Box>
                  <CardActionArea
                    onClick={() =>
                      history.push(`${match.url}/course/${course.id}`)
                    }
                  >
                    <Box width="75%" pt={2} pb={2}>
                      {course.description && (
                        <Typography variant="caption">
                          {course.description}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="caption" color="textSecondary">
                        Last Updated:{' '}
                        {moment(course.modified_on).format('MMM DD YYYY')} |{' '}
                        {course.modifiedBy.first_name}{' '}
                        {course.modifiedBy.last_name}
                      </Typography>

                      <Chip
                        label={product.name}
                        style={{ background: product.color, color: '#FFF' }}
                      />
                    </Box>
                  </CardActionArea>
                </Box>
              </Card>
            );
          })}
        </Fragment>
      )}
    </CourseContainer>
  );
};

export default withSnackbar(ProductCourseList);
