import React, { useContext, Fragment } from 'react';
import { Container, Box, Typography, Paper, Button } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  CMS_GET_LESSON,
  UPDATE_LESSON,
  PUBLISH_LESSON,
  UNPUBLISH_LESSON,
  DELETE_LESSON,
} from '../../../../../../apollo/queries';
import { LessonForm } from '../../../../../../components';
import styles from './styles';

import { withSnackbar } from 'notistack';
import { getLessonData } from '../../../../../../lib';
import { UserConfirmationContext } from '../../../../../../providers/UserConfirmationProvider';
import { useIsManager } from '../../../IsManager';

const LessonEdit = ({
  course,
  enqueueSnackbar,
  lessonId,
  setShowCurriculum,
}) => {
  const classes = styles();
  const { showConfirmation } = useContext(UserConfirmationContext);
  const isManager = useIsManager();

  const refetchQueries = ['getCMSLesson'];

  const { data, loading, error } = useQuery(CMS_GET_LESSON, {
    variables: { lessonId },
  });

  const [updateLesson] = useMutation(UPDATE_LESSON, { refetchQueries });
  const [publishLesson] = useMutation(PUBLISH_LESSON, { refetchQueries });
  const [unpublishLesson] = useMutation(UNPUBLISH_LESSON, { refetchQueries });
  const [deleteLesson] = useMutation(DELETE_LESSON, {
    refetchQueries: ['getCourseById'],
  });

  const handleSubmit = async (values) => {
    try {
      const data = getLessonData(values);
      await updateLesson({
        variables: {
          data: { description: null, ...data },
          where: { id: values.id },
        },
      });
      enqueueSnackbar('Successfully Updated Lesson!', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar('Failed! Contact Tech Support if problem persists.', {
        variant: 'error',
      });
    }
  };

  const handleDelete = async (lessonId) => {
    try {
      await deleteLesson({ variables: { id: lessonId } });
    } catch (error) {
      enqueueSnackbar('Failed! Contact Tech Support if problem persists.', {
        variant: 'error',
      });
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const lesson = data.lesson;

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={3}
        mb={3}
      >
        <Typography variant="h4" style={{ marginRight: 'auto' }}>
          Lesson
        </Typography>
        {isManager && (
          <Fragment>
            <Button
              style={{ marginRight: 16 }}
              onClick={async () => {
                const variables = { id: lesson.id };
                if (lesson.published) {
                  await unpublishLesson({ variables });
                } else {
                  await publishLesson({ variables });
                }
                enqueueSnackbar(
                  `${lesson.name} has been ${
                    lesson.published ? 'un' : ''
                  }published!`,
                  { variant: 'success', autoHideDuration: 1500 },
                );
              }}
              variant="contained"
              color="primary"
            >
              {lesson.published ? 'Unpublish' : 'Publish'}
            </Button>
            <Button
              onClick={() => {
                showConfirmation({
                  title: `Delete ${lesson.name}`,
                  message: 'Are you sure you want to delete this Lesson?',
                  confirmed: () => handleDelete(lesson.id),
                });
              }}
              variant="contained"
              color="primary"
            >
              Delete Lesson
            </Button>
          </Fragment>
        )}
      </Box>
      <Paper className={classes.paper}>
        <LessonForm lesson={lesson} onSubmit={handleSubmit} />
      </Paper>
    </Container>
  );
};

export default withSnackbar(LessonEdit);
