import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';
import styles from './styles';
import moment from 'moment';
import clsx from 'clsx';

const LicenceRenewalList = ({ onSelect, licenses, selectedLicence }) => {
  const classes = styles();
  if (!licenses.length) {
    return (
      <Box p={2}>
        <Typography variant="h5">No Licences</Typography>
      </Box>
    );
  }
  return (
    <List
      component="nav"
      aria-label="institution list"
      className={classes.root}
    >
      {licenses.map((item) => (
        <ListItem button key={item.id} divider={true}>
          <ListItemText
            disableTypography
            onClick={() => onSelect(item)}
            primary={
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="subtitle1">
                    {item.institution.name}
                  </Typography>
                </Grid>
              </Grid>
            }
            secondary={
              <div>
                <Typography variant="body2" color="textSecondary">
                  License Name: {item.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={clsx({
                    [classes.days90]: item.days_to_expire <= 90,
                    [classes.days60]: item.days_to_expire <= 60,
                    [classes.days30]: item.days_to_expire <= 30,
                  })}
                >
                  Expires on: {moment(item.expiry_date).format('MMM DD, YYYY')}
                </Typography>
              </div>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default LicenceRenewalList;
