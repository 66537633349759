import React from 'react';
import { Typography, Drawer, Toolbar } from '@material-ui/core';
import styles from './styles';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined';
import { NavLink } from 'react-router-dom';

const SideLessonNav = ({ match }) => {
  const classes = styles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <NavLink
          exact
          to={match.url}
          className={classes.navItem}
          activeClassName={classes.active}
        >
          <LocalLibraryOutlinedIcon className={classes.navIcon} />
          <Typography variant="caption">Lesson</Typography>
        </NavLink>
        <NavLink
          to={`${match.url}/library`}
          className={classes.navItem}
          activeClassName={classes.active}
        >
          <LibraryBooksOutlinedIcon className={classes.navIcon} />
          <Typography variant="caption">eBook</Typography>
        </NavLink>
        <NavLink
          to={`${match.url}/quickdeck`}
          className={classes.navItem}
          activeClassName={classes.active}
        >
          <FlashOnIcon className={classes.navIcon} />
          <Typography variant="caption">Quickdeck</Typography>
        </NavLink>
      </div>
    </Drawer>
  );
};

export default SideLessonNav;
