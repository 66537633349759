import React from 'react';
import AssessmentComplete from '../../../../assets/logos/il_completed.svg';
import { Typography, Box, Button ,useMediaQuery, useTheme } from '@material-ui/core';
import styles from './styles';

const BenchmarkComplete = ({ onClick, freeVersion, level, title }) => {
  const classes = styles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className={classes.completeContainer} p={2} mt={isMobile ? 0 : 16} >
      <img src={AssessmentComplete} alt="Assessment Complete"/>
      <Typography component="p" className={classes.benchmarkCompleteTitle} >
        {level === 'all' ? 'All Levels' : `Level ${level} ${title} Complete`}
      </Typography>
      <Typography component="p" className={classes.planCreateTitle} >
        {
          title === 'Benchmark Assessment' && "Lesson plan created! Let's get started"
        }
        {
          title === 'Summary Assessment' && "Great! You're the best"
        }
      </Typography>

      <Button onClick={onClick} variant="outlined" className={classes.goToButton} >
        {freeVersion ? 'Back to Home' : 'Go To My Lesson Plan'}
      </Button>
    </Box>
  );
};

export default BenchmarkComplete;
