import React from 'react';
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { PrimaryButton } from '../../common';
import useStyles from './styles';
import { useInstitutionRoles } from './useInstitutionRoles';

const LockedValue = ({ name, value }) => (
  <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
    <Typography style={{ fontWeight: 'bold', marginRight: 8 }}>
      {name}:
    </Typography>
    <Typography style={{ flex: 1 }}>{value}</Typography>
  </Box>
);

const InstitutionMemberEditForm = ({
  member,
  onCancel = () => {},
  onSubmit,
}) => {
  const classes = useStyles();
  const selectableRoles = useInstitutionRoles();

  return (
    <Form
      initialValues={{ role: member ? member.role : 'STUDENT' }}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.formContainer}>
            <LockedValue name="Name" value={member.name} />
            <LockedValue name="Email" value={member.email} />
            <LockedValue
              name="Status"
              value={member.isInviation ? 'Invited' : 'Accepted'}
            />
            <Field name="role">
              {({ input, meta }) => (
                <TextField
                  select
                  fullWidth
                  required
                  variant="filled"
                  id="role"
                  name="role"
                  label="Role"
                  {...input}
                  helperText={
                    meta.error &&
                    meta.touched && (
                      <Typography variant="caption" color="primary">
                        {meta.error}
                      </Typography>
                    )
                  }
                >
                  {selectableRoles.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
          </div>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button
                variant="outlined"
                type="button"
                onClick={onCancel}
                disabled={submitting}
              >
                Cancel
              </Button>
            </Box>
            <PrimaryButton type="submit" disabled={pristine || submitting}>
              {submitting ? 'Saving...' : 'Save'}
            </PrimaryButton>
          </Box>
        </form>
      )}
    />
  );
};

export default InstitutionMemberEditForm;
