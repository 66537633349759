export const checkAnswer = (question, answer) => {
  if (!answer) return false;


  if (question.type === 'MATCHING') {
    const status = getMatchingUserAnswer(answer.concepts, answer.descriptions);
    return getStatusMatching(status).is_correct;
  } else if (question.type === 'TRUE_OR_FALSE' || question.type === 'CORRECT_OR_INCORRECT') {
    return getStatusTrueOrFalse(answer).is_correct;
  }

  const correctAns = getCorrectAnswer(question);
  return checkUserAnswer(question.type, correctAns, answer);
};

export const getCorrectAnswer = (question) => {
  if (question.type === 'SEQUENCE') {
    return question.answers.sort((a, b) => a.sequence - b.sequence).map((ans) => ans.id);
  } else {
    return question.answers.filter((ans) => ans.is_correct).map((ans) => ans.id);
  }
};

export const checkUserAnswer = (type, correctAns, answer) => {
  if (correctAns.length !== answer.length) return false;
  if (type === 'SEQUENCE') {
    return !correctAns.map((ans, index) => ans === answer[index]).includes(false);
  } else {
    return !correctAns.map((ans) => answer.includes(ans)).includes(false);
  }
};

export const isQuestionAnswered = (type, userAnswer) => {
  switch (type) {
    case 'MULTIPLE_CHOICE':
    case 'SEQUENCE':
      return userAnswer?.length >= 1;
    case 'TRUE_OR_FALSE':
    case 'CORRECT_OR_INCORRECT':
      return getStatusTrueOrFalse(userAnswer).is_answered;
    case 'MATCHING':
      if (userAnswer && userAnswer.concepts && userAnswer.descriptions) {
        const status = getMatchingUserAnswer(userAnswer.concepts, userAnswer.descriptions);
        return getStatusMatching(status).is_answered;
      } else {
        return false;
      }
    default: return false;
  }
};

export const getStatusMatching = (status) => {
  const statusKeys = Object.keys(status);
  const is_answered = statusKeys.length > 0 && statusKeys.length === statusKeys.filter(answerId => status[answerId].is_answered).length;
  const is_correct = statusKeys.length > 0 && statusKeys.length === statusKeys.filter(answerId => status[answerId].is_correct).length;
  return { is_answered, is_correct, result: is_correct };
};

export const getMatchingUserAnswer = (concepts, descriptions) => {
  return Object.keys(concepts).reduce((acc, conceptId) => {
    const concept = concepts[conceptId];
    const description = descriptions[Object.keys(descriptions).find((descriptionId) => descriptionId === conceptId)];

    acc[concept.id] = {
      is_answered: Boolean(description.letter),
      is_correct: concept.systemLetter === description.letter && Boolean(description.letter),
    };

    return acc;
  }, {});
};

export const getStatusTrueOrFalse = (status) => {
  const statusKeys = Object.keys(status || {});
  const is_answered = statusKeys.length > 0 && statusKeys.length === statusKeys.filter(answerId => status[answerId].userAnswer === true || status[answerId].userAnswer === false).length;
  const is_correct = statusKeys.length > 0 && statusKeys.length === statusKeys.filter(answerId => status[answerId].is_correct === true).length;
  return { is_answered, is_correct, result: is_correct };
};

export const removeTextFromUserAnswer = (qustionType, userAnswer) => {
  const answer = { ...userAnswer };
  if (qustionType === 'TRUE_OR_FALSE' || qustionType === 'CORRECT_OR_INCORRECT') {
    Object.keys(answer).forEach(id => delete answer[id].text);
  } else if (qustionType === 'MATCHING') {
    Object.keys(answer.concepts).forEach(id => { delete answer.concepts[id].id; delete answer.concepts[id].text; });
    Object.keys(answer.descriptions).forEach(id => { delete answer.descriptions[id].id; delete answer.descriptions[id].text; });
  }

  return answer;
};