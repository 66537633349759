import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_INSTITUTION_ACTIVITES } from '../../../apollo/queries';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

const InstitutionActivityList = ({ institutionId }) => {
  const { data, loading, error, fetchMore } = useQuery(
    GET_INSTITUTION_ACTIVITES,
    {
      variables: { institution_id: institutionId, pageSize: 5 },
    },
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  return (
    <List>
      <InstitutionActivity
        data={data.institutionActivities}
        fetchMore={fetchMore}
      />
    </List>
  );
};

export default InstitutionActivityList;

const InstitutionActivity = ({ data, fetchMore }) => {
  if (data.activities.length === 0) {
    return (
      <Typography variant="h5" color="textSecondary" align="center">
        No Activity Recorded
      </Typography>
    );
  }
  return (
    <Fragment>
      {data.activities.map((item) => (
        <ListItem key={item.id}>
          <ListItemAvatar>
            <Avatar
              alt={`${item.user.first_name} ${item.user.last_name}`}
              src={item.user.avatar}
            />
          </ListItemAvatar>
          <ListItemText
            primary={`${item.user.first_name} ${item.user.last_name}`}
            secondary={`${item.activity} on ${moment(item.created_on).format(
              'MMM DD YYYY',
            )}`}
          />
        </ListItem>
      ))}
      {data.hasMore && (
        <ListItem>
          <Button
            style={{ margin: 'auto' }}
            onClick={() =>
              fetchMore({
                variables: {
                  after: data.cursor,
                },
                updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    ...fetchMoreResult,
                    institutionActivities: {
                      ...fetchMoreResult.institutionActivities,
                      activities: [
                        ...prev.institutionActivities.activities,
                        ...fetchMoreResult.institutionActivities.activities,
                      ],
                    },
                  };
                },
              })
            }
          >
            Load More
          </Button>
        </ListItem>
      )}
    </Fragment>
  );
};
