import React from 'react';
import { Box } from '@material-ui/core';
import styles from './styles';
import { CourseDashboardSummaryCard } from './Cards';

const CourseSummaries = ({
  course,
  studentCourseProgress,
  isPreview,
  history,
  match,
  assessmentCustomSettings,
}) => {
  const classes = styles();

  const handleSummaryClick = (summary) => {
    if (!summary) {
      history.push(`${match.url}/summary/all`);
    } else {
      history.push(`${match.url}/summary/${summary.level}`);
    }
  };

  const summaryProgress = isPreview
    ? {}
    : studentCourseProgress.summary.reduce((acc, summary) => {
        acc[summary.summary_id] = summary.complete;
        return acc;
      }, {});

      summaryProgress.allComplete = isPreview
    ? {}
    : studentCourseProgress.summary.filter((summary) => summary.complete)
        .length === studentCourseProgress.summary.length;

  const summaries = isPreview ? course.summaries : course.summaries.filter((b) => b.published);
  
  return (
    <Box display="flex" flexWrap="wrap">
      {summaries.map((summary) => (
        <Box key={summary.id} className={classes.flexitem} p={1}>
          <CourseDashboardSummaryCard
            borderColor={course.product.color}
            level={summary.level}
            complete={isPreview ? false : summaryProgress[summary.id]}
            onClick={() =>
              handleSummaryClick(
                summary,
                isPreview ? false : summaryProgress[summary.id],
              )
            }
          />
        </Box>
      ))}

      {/*<Box className={classes.flexitem} p={1}>
        <CourseDashboardSummaryCard
          level="all"
          borderColor={course.product.color}
          onClick={() =>
            handleSummaryClick(
              null,
              isPreview ? false : summaryProgress.allComplete,
            )
          }
          complete={isPreview ? false : summaryProgress.allComplete}
        />
        </Box>*/}
    </Box>
  );
};

export default CourseSummaries;