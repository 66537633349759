import { Box } from '@material-ui/core';
import React from 'react';
import { StudentQuestion } from '../../../../components';
import styles from './styles';

const Assessment = ({
  onQuestionChange,
  userAnswers,
  question,
  showAnswer,
  children
}) => {
  const classes = styles();

  return (
    <Box className={classes.assessmentContainer}>
      <StudentQuestion
        className={classes.questionContainer}
        onChange={onQuestionChange}
        userAnswers={userAnswers}
        question={question}
        showAnswer={showAnswer}
      >
        {children}
      </StudentQuestion>
    </Box>
  );
};

export default Assessment;
