import React from 'react';
import {
  Button,
  TextField,
  MenuItem,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import styles from './styles';
import clsx from 'clsx';

const PAGE_SIZE = 20;

const LicenseSearchBar = ({
  daysToRenewal,
  setDaysToRenewal,
  pageSize,
  setPageSize,
  assignedToMe,
  setAssignedToMe,
}) => {
  const _pageSize = pageSize;
  const _daysToRenewal = daysToRenewal;
  const _assignedToMe = assignedToMe;

  const classes = styles();

  const handleClick = (days) => {
    setDaysToRenewal(days);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleCheckbox = () => {
    setAssignedToMe(!assignedToMe);
  };

  return (
    <div>
      <div className={classes.root}>
        <ButtonGroup size="small" className={classes.buttonGroup}>
          <Button
            onClick={() => handleClick(30)}
            className={[
              classes.dayButton,
              clsx({ [classes.selected]: _daysToRenewal === 30 }),
            ]}
          >
            30 Days
          </Button>
          <Button
            onClick={() => handleClick(60)}
            className={[
              classes.dayButton,
              clsx({ [classes.selected]: _daysToRenewal === 60 }),
            ]}
          >
            60 Days
          </Button>
          <Button
            onClick={() => handleClick(90)}
            className={[
              classes.dayButton,
              clsx({ [classes.selected]: _daysToRenewal === 90 }),
            ]}
          >
            90 Days
          </Button>
        </ButtonGroup>

        <TextField
          className={classes.viewInput}
          size="small"
          variant="outlined"
          label="View"
          name="pageSize"
          id="pageSize"
          onChange={handlePageSizeChange}
          value={_pageSize}
          select
        >
          {new Array(5).fill(PAGE_SIZE).map((size, index) => (
            <MenuItem key={index} value={size + index * 10}>
              {size + index * 10}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <FormControlLabel
        className={classes.checkboxContainer}
        value="end"
        control={
          <Checkbox
            color="primary"
            value={_assignedToMe}
            onClick={handleCheckbox}
          />
        }
        label="Assigned to Me"
        labelPlacement="end"
      />
    </div>
  );
};

export default LicenseSearchBar;
