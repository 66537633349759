import { Route, Redirect } from "react-router-dom";
import React, { useContext } from "react";

import { ViewerContext } from "../../providers/ViewerProvider";

const PrivateRoute = ({ component: Component, location, ...restProps }) => {
  const { viewer } = useContext(ViewerContext);
  return (
    <Route
      render={props => {
        if (viewer) return <Component {...props} />;
        return (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }}
      {...restProps}
    />
  );
};

export default PrivateRoute;
