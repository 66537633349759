import React, { Fragment, useState, useContext, useRef, useEffect } from 'react';
import { StudentQuestion } from '../../../../components';
import styles from './styles';
import { Button, Container, Box } from '@material-ui/core';
import { clone } from 'ramda';
import BenchmarkTestOverview from '../BenchmarkTestOverview';
import { useHistory } from 'react-router-dom';
import BenchmarkTestHeader from '../BenchmarkTestHeader';
import { BenchmarkSettingsContext } from '../../providers/BenchmarkSettingsProvider';
import { UserConfirmationContext } from '../../../../providers/UserConfirmationProvider';

const BenchmarkTest = ({
  showResultsPage,
  onTimerComplete,
  enqueueSnackbar,
  courseLogo,
  courseName,
  level,
  title,
  questions,
  time,
  userAnswer,
  setUserAnswer,
  submitEntity,
  setTimeSpentUserAnswers,
  timeSpentUserAnswers,
}) => {
  const { freeVersion } = useContext(BenchmarkSettingsContext);

  const classes = styles();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [lastQuestion, setLastQuestion] = useState(false);
  const [showOverview, setShowOverview] = useState(false);
  const [pauseTimer, setPauseTimer] = useState(false);

  const timerRef = useRef(null);

  useEffect(() => {
    if (timerRef.current) {
      timerRef.current.resetTimeSpent();
    }
  }, [questionIndex, timerRef]);

  const onNextQuestion = () => {
    const question = questions[questionIndex];
    setTimeSpentUserAnswers({ 
      ...timeSpentUserAnswers, 
      [question.id]: timerRef.current.getTimeSpentByQuestion()
    });
    const nextIndex = questionIndex + 1;
    if (nextIndex + 1 >= questions.length) setLastQuestion(true);
    setQuestionIndex(nextIndex);
  };

  const onQuestionChange = (answer) => {
    const answers = clone(userAnswer);
    const question = questions[questionIndex];
    answers[question.id] = answer;
    setUserAnswer(answers);
  };

  const handleReview = () => {
    const question = questions[questionIndex];
    setTimeSpentUserAnswers({ ...timeSpentUserAnswers, [question.id]: timeSpentUserAnswers[question.id] ? timeSpentUserAnswers[question.id] + timerRef.current?.getTimeSpentByQuestion() : timerRef.current?.getTimeSpentByQuestion() })
    timerRef.current.resetTimeSpent();
    setShowOverview(true);
  };

  return (
    <Container style={{ padding: '1em' }} maxWidth="md">
      <BenchmarkTestHeader
        courseLogo={courseLogo}
        courseName={courseName}
        showOverview={showOverview}
        questions={questions}
        questionIndex={questionIndex}
        hideTimer={freeVersion}
        time={time}
        pauseTimer={pauseTimer}
        onTimerComplete={onTimerComplete}
        level={level}
        title={title}
        timerRef={timerRef}
      />
      {!showOverview && (
        <Fragment>
          <StudentQuestion
            className={classes.questionContainer}
            onChange={onQuestionChange}
            userAnswers={userAnswer}
            question={questions[questionIndex]}
          />
          <AnswerBar
            onNextQuestion={() => onNextQuestion()}
            lastQuestion={questions.length === 1 ? true : lastQuestion}
            onSubmit={() => handleReview()}
            title={title}
          />
        </Fragment>
      )}
      {showOverview && (
        <BenchmarkTestOverview
          enqueueSnackbar={enqueueSnackbar}
          showResultsPage={showResultsPage}
          questions={questions}
          userAnswer={userAnswer}
          submitEntity={submitEntity}
          onQuestionClick={(index) => {
            setShowOverview(false);
            setQuestionIndex(index);
            timerRef.current.resetTimeSpent();
          }}
          pauseTimer={() => setPauseTimer(true)}
        />
      )}
    </Container>
  );
};

export default BenchmarkTest;

const AnswerBar = ({ onNextQuestion, lastQuestion, onSubmit, title }) => {
  const classes = styles();
  const history = useHistory();

  const { showConfirmation } = useContext(UserConfirmationContext);

  const handleClose = () => {
    showConfirmation({
      title: 'Exit Benchmark?',
      message:
        'If you exit now, your progress will not be saved. Are you sure you want to exit?',
      confirmed: () => history.goBack(),
      confirmText: 'Exit',
    });
  };

  return (
    <Box display="flex" justifyContent="space-between" gridGap='1em' >
      <Button
        variant="outlined"
        style={{ borderStyle: 'none', color: '#BD161C' }}
        onClick={() => {
          handleClose();
        }}
      >
        {
          title === 'Summary Assessment' && 'Quit Summary'
        }
        {
          title === 'Benchmark Assessment' && 'Quit Benchmark'
        }
      </Button>
      <Button
        className={classes.button}
        onClick={lastQuestion ? onSubmit : onNextQuestion}
        variant="contained"
        color="primary"
      >
        {lastQuestion ? 'Continue to Review' : 'Next Question'}
      </Button>
    </Box>
  );
};
