import React from 'react';
import NoGroupCard from '../NoGroupCard';
import GroupCard from '../GroupCard';
import { Grid } from '@material-ui/core';
import { useInstitutionGroups } from '../../../../apollo/api';

const UserInstitutionGroups = ({ institution, member, viewer, history }) => {
  const { data, loading, error, networkStatus } = useInstitutionGroups(
    viewer ? viewer.id : null,
    institution ? institution.id : null,
  );

  if (error) return <p>Error...</p>;

  const groups = data ? data.groups : [];

  if (groups && !groups.length && !loading) {
    return (
      <NoGroupCard
        title={`You have no associated groups. Click above to join${
          member && member.role !== 'STUDENT' ? '/create' : ''
        } a group`}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      {(loading || networkStatus === 4 ? Array.from(new Array(3)) : groups).map(
        (group, index) => (
          <Grid key={index} item xs={12} sm={12} md={6} lg={4} xl={3}>
            <GroupCard
              group={group}
              history={history}
              institutionMemberRole={member ? member.role : 'STUDENT'}
              onClick={() => history.push(`/group/${group ? group.id : ''}`)}
            />
          </Grid>
        ),
      )}
    </Grid>
  );
};

export default UserInstitutionGroups;
