import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  buttonFormControl: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  courseContainer: {
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[100]
  },
  saveButton: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      marginLeft: theme.spacing(1),
      order: 1,
    },
  },
  dangerZone: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    background: '#FEEBEE',
    color: '#B71C1C',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  deleteButton: {
    display: 'flex',
    color: '#B71C1C',
    borderColor: '#B71C1C',
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
  productExpansionPanel: {
    border: '1px solid #ccc',
  },
  productSummaryPanel: { height: 20, borderBottom: '1px solid #ccc' },
  productPanelDetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    '&:not(:last-child)': {
      borderBottom: '1px solid #ccc',
    },
  },
}));
