import React, { Fragment } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';

const EditorText = ({ text, style }) => {
  const editorId = `editor_${uuidv4()}`;
  const theme = useTheme();
  return (
    <Fragment>
      <style dangerouslySetInnerHTML={{
        __html: `
        #${editorId} { font-family: ${theme.typography.fontFamily} !important; word-wrap: break-word !important;  }
        #${editorId} h1 { font-size: 25.62px !important; }
        #${editorId} h2 { font-size: 21px !important; }
        #${editorId} h3 { font-size: 19.38px !important; }
        #${editorId} h4 { font-size: 14px !important; }
        #${editorId} h5 { font-size: 11.62px !important; }
        #${editorId} h6 { font-size: 9.38px !important; }
        #${editorId} img { max-width: 100% !important; height: auto !important; } 
        #${editorId} video { max-width: 100% !important; height: 100% !important; width: 100% !important; }`
      }} />
      <div id={editorId} dangerouslySetInnerHTML={{ __html: text }} style={style} />
    </Fragment>
  );
};

export default EditorText;
