import React, { Fragment, useState } from 'react';
import { AddButton, ResourceCard } from '../../../../../../../components';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  List,
  ListItemIcon,
  ListItem,
  Checkbox,
  Card,
  CardContent,
} from '@material-ui/core';
import { CMS_GET_LESSON } from '../../../../../../../apollo/queries';
import { useLazyQuery } from '@apollo/react-hooks';
import ScrollDialog from '../ScrollDialog';
import { KeyElementContainer } from '../../../../../../../containers';
import styles from './styles';

const AddKeyElementResource = ({ lessons, keyElement, lesson, enqueueSnackbar }) => {
  const classes = styles();
  const [showList, setShowList] = useState(false);
  const [selectedResourceIds, setSelectedResourceIds] = useState([]);

  let existingResourceIds;

  if (keyElement.type === 'quickdeck') {
    existingResourceIds = keyElement.cards.map((card) => card.id);
  } else if (keyElement.type === 'quickclip') {
    existingResourceIds = keyElement.clip_cards.map((card) => card.id);
  } else {
    existingResourceIds = keyElement.pages.map((page) => page.id);
  }

  const handleSubmit = async (event, addKeyElementResource) => {
    event.stopPropagation();
    await addKeyElementResource.mutation({
      variables: {
        data: {
          resource_ids: selectedResourceIds,
          key_element_id: keyElement.id,
        },
      },
      refetchQueries: ['getKeyElements'],
    });
    enqueueSnackbar(`Successfully added selected resources`, {
      variant: 'success',
      autoHideDuration: 1500,
    });
    setSelectedResourceIds([]);
    setShowList(false);
  };

  return (
    <Fragment>
      <AddButton
        className={classes.toolbarButton}
        title="Resource"
        onClick={(event) => {
          event.stopPropagation();
          setShowList(true);
        }}
      />
      <KeyElementContainer>
        {({ addKeyElementResource }) => (
          <ScrollDialog
            open={showList}
            onClose={(event) => {
              event.stopPropagation();
              setShowList(false);
            }}
            title="Select Resources to Add"
            onAccept={(event) => handleSubmit(event, addKeyElementResource)}
            acceptText="Add Resources"
            disableAccept={selectedResourceIds.length === 0}
            maxWidth={'md'}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}>
            <ResourceSelectList
              type={keyElement.type}
              lessons={lessons}
              lesson={lesson}
              existingResourceIds={existingResourceIds}
              selectedResourceIds={selectedResourceIds}
              setSelectedResourceIds={setSelectedResourceIds}
            />
          </ScrollDialog>
        )}
      </KeyElementContainer>
    </Fragment>
  );
};

export default AddKeyElementResource;

const ResourceSelectList = ({
  type,
  lessons,
  lesson,
  existingResourceIds,
  selectedResourceIds,
  setSelectedResourceIds,
}) => {
  const [lessonId, setLessonId] = useState(lesson.id);
  const [loadLesson, { loading, data, error }] = useLazyQuery(CMS_GET_LESSON, {
    variables: { lessonId: lessonId },
  });

  React.useEffect(() => {
    loadLesson();
  }, [loadLesson, lessonId]);

  const onLessonChange = (lesson_id) => {
    setLessonId(lesson_id);
  };

  const handleResourceClick = (event, id) => {
    event.stopPropagation();
    if (selectedResourceIds.includes(id)) {
      setSelectedResourceIds(
        selectedResourceIds.filter((resourceId) => resourceId !== id),
      );
    } else {
      setSelectedResourceIds([...selectedResourceIds, id]);
    }
  };

  if (loading || !data) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  if (type === 'quickdeck') {
    const cards = data.lesson.cards.filter(
      (card) => !existingResourceIds.includes(card.id),
    );

    if (cards.length === 0) {
      return <NoResources lessons={lessons} lessonId={lessonId} onLessonChange={onLessonChange} />;
    }
    return (
      <Box>
        <LessonsSelect lessons={lessons} lessonId={lessonId} onLessonChange={onLessonChange} />
        <List>
          {cards.map((card) => (
            <ListItem
              key={card.id}
              onClick={(event) => handleResourceClick(event, card.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedResourceIds.includes(card.id)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <Card style={{ minWidth: 350 }}>
                <CardContent>
                  <ResourceCard text1_label="Card Term" text1={card.term} text2_label="Card Definition" text2={card.definition} image={card.image} />
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }

  if (type === 'quickclip') {
    const cards = data.lesson.clip_cards.filter(
      (card) => !existingResourceIds.includes(card.id),
    );

    if (cards.length === 0) {
      return <NoResources lessons={lessons} lessonId={lessonId} onLessonChange={onLessonChange} />;
    }
    return (
      <Box>
        <LessonsSelect lessons={lessons} lessonId={lessonId} onLessonChange={onLessonChange} />
        <List>
          {cards.map((card) => (
            <ListItem
              key={card.id}
              onClick={(event) => handleResourceClick(event, card.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedResourceIds.includes(card.id)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <Card style={{ minWidth: 350 }}>
                <CardContent>
                  <ResourceCard title={card.title} text1_label="Clip" text1={card.video} text2_label="Clip with subtitles" text2={card.video_with_subtitles} />
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }

  if (type === 'ebook') {
    const pages = data.lesson.pages.filter(
      (page) => !existingResourceIds.includes(page.id),
    );
    if (pages.length === 0) {
      return <NoResources lessons={lessons} lessonId={lessonId} onLessonChange={onLessonChange} />;
    }
    return (
      <Box>
        <LessonsSelect lessons={lessons} lessonId={lessonId} onLessonChange={onLessonChange} />
        <List>
          {pages.map((page) => (
            <ListItem
              key={page.id} ß
              onClick={(event) => handleResourceClick(event, page.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedResourceIds.includes(page.id)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <Card>
                <CardContent>
                  <ResourceCard title={page.title} text1_label="eBook Page" text1={page.content} />
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Box>

    );
  }
};

const LessonsSelect = ({ lessons, lessonId, onLessonChange }) => {
  const handleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onLessonChange(e.target.value);
  };
  return (
    <Box mb={2}>
      <FormControl fullWidth>
        <InputLabel id="lesson-select-label">Lesson</InputLabel>
        <Select
          labelId="lesson-select-label"
          id="lesson-select"
          value={lessonId}
          label="Lesson"
          onChange={handleChange}
        >
          {lessons.map(lesson => <MenuItem key={lesson.id} value={lesson.id}>{lesson.name}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
};

const NoResources = ({ lessons, lessonId, onLessonChange }) => {
  return (
    <Box>
      <LessonsSelect lessons={lessons} lessonId={lessonId} onLessonChange={onLessonChange} />
      <Typography component="div">
        No Resources. If you haven't selected any resources check to see that
        you have quickdeck cards or quickclips cards or e-book pages added to
        the selected lesson
      </Typography>
    </Box>
  );
};