import React, { useState, useContext, Fragment } from 'react';
import { LessonContainer } from '../../../../../containers';
import { CMSLessonCard as LessonCard } from '../../../../../components';
import {
  Typography,
  Button,
  Modal,
  Paper,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Toolbar,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import styles from './styles';
import { withSnackbar } from 'notistack';
import clsx from 'clsx';
import { UserConfirmationContext } from '../../../../../providers/UserConfirmationProvider';

const FreeLesson = ({ enqueueSnackbar }) => {
  const classes = styles();
  const { showConfirmation } = useContext(UserConfirmationContext);
  const [submitting, setSubmitting] = useState(false);
  const [showLessons, setShowLessons] = useState(false);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const refetchQueries = ['getFreeLessons'];

  const handleSubmit = async (addFreeLesson) => {
    try {
      setSubmitting(true);
      await addFreeLesson.mutation({
        variables: { ids: selectedLessons },
        refetchQueries,
      });

      enqueueSnackbar('Free Lessons successfully added!', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar(
        'An error occured please try again later. if problem persists contact tech support!',
        { variant: 'error' },
      );
    }
    setSubmitting(false);
    setSelectedLessons([]);
    setShowLessons(false);
  };

  const _deleteFreeLesson = async (lesson, deleteFreeLesson) => {
    try {
      await deleteFreeLesson.mutation({
        variables: { id: lesson.id },
        refetchQueries,
      });
      enqueueSnackbar(`${lesson.name} removed from free lessons`, {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar(
        `failed to remove ${lesson.name} from free lessons. Please try again later. if problem persist contact tech support`,
        { variant: 'error' },
      );
    }
  };

  const handleDelete = (lesson, deleteFreeLesson) => {
    showConfirmation({
      title: `Remove Free Lesson`,
      message: `Are you sure you want to remove ${lesson.name} from the free lessons section?`,
      confirmed: () => _deleteFreeLesson(lesson, deleteFreeLesson),
    });
  };

  const handleSelectedLesson = (lessonId) => {
    const selected = [...selectedLessons];
    const index = selected.findIndex((id) => id === lessonId);
    if (index !== -1) {
      selected.splice(index, 1);
    } else {
      selected.push(lessonId);
    }
    setSelectedLessons(selected);
  };

  const isChecked = (lessonId) => selectedLessons.includes(lessonId);

  return (
    <LessonContainer>
      {({
        freeLessons: { freeLessons, loading, error },
        allLessons: { lessons, loading: loadingOne, error: errorOne },
        addFreeLesson,
        deleteFreeLesson,
      }) => {
        if (loading || loadingOne) return <p>Loading...</p>;
        if (error || errorOne) return <p>Error...</p>;

        const freeLessonIds = freeLessons.map((lesson) => lesson.id);

        return (
          <section className={classes.freeLessonSection}>
            <Modal
              open={showLessons}
              onClose={() => setShowLessons(false)}
              aria-labelledby="lesson list"
              aria-describedby="list of lessons to select"
            >
              <Paper className={classes.modal}>
                <Typography variant="h5" gutterBottom>
                  Select Free Lessons
                </Typography>
                <List>
                  {lessons.map((lesson) => (
                    <ListItem
                      className={clsx(classes.listItem, {
                        [classes.alreadySelected]: freeLessonIds.includes(
                          lesson.id,
                        ),
                      })}
                      key={lesson.id}
                      onClick={
                        freeLessonIds.includes(lesson.id)
                          ? null
                          : () => handleSelectedLesson(lesson.id)
                      }
                    >
                      <ListItemText
                        primary={lesson.name}
                        secondary={lesson.course.name}
                      />
                      <Checkbox
                        color="primary"
                        checked={
                          freeLessonIds.includes(lesson.id)
                            ? true
                            : isChecked(lesson.id)
                        }
                      />
                    </ListItem>
                  ))}
                </List>
                <Toolbar className={classes.actions}>
                  <Button
                    style={{ marginRight: 8 }}
                    disabled={submitting}
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowLessons(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={submitting || selectedLessons.length === 0}
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(addFreeLesson)}
                  >
                    Add Lessons
                  </Button>
                </Toolbar>
              </Paper>
            </Modal>
            {!freeLessons.length && (
              <div className={classes.noLessonsContainer}>
                <Typography align="center" variant="h4" color="textSecondary">
                  No Free Lessons
                </Typography>
                <Typography align="center" variant="body1">
                  click the button below to get started
                </Typography>
                <Button
                  className={classes.addFreeLessonButton}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setShowLessons(true)}
                >
                  Add Free Lesson
                </Button>
              </div>
            )}
            {freeLessons.length >= 1 && (
              <Fragment>
                <Button
                  style={{ marginLeft: 8 }}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setShowLessons(true)}
                >
                  Add Free Lesson
                </Button>
                <Grid container>
                  {freeLessons.map((lesson) => (
                    <Grid
                      key={lesson.id}
                      style={{ padding: 8 }}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <LessonCard
                        lesson={lesson}
                        onDelete={() => handleDelete(lesson, deleteFreeLesson)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Fragment>
            )}
          </section>
        );
      }}
    </LessonContainer>
  );
};

export default withSnackbar(FreeLesson);
