import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  resultsCount: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fetchMoreButton: {
    minWidth: 200,
  },
}));

export default styles;
