import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '2em'
  },
  button: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  introContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 960,
    width: '100%',
    padding: 16,
  }
}));
