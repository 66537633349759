import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import {
  GET_KEY_ELEMENTS,
  CREATE_KEY_ELEMENT,
  UPDATE_KEY_ELEMENT,
  DELETE_KEY_ELEMENT,
  PUBLISH_KEY_ELEMENT,
  UNPUBLISH_KEY_ELEMENT,
  ADD_KEY_ELEMENT_RESOURCE,
  REMOVE_KEY_ELEMENT_RESOURCE,
} from '../apollo/queries';
import { adopt } from 'react-adopt';

const studyProjectKeyElements = ({ render, studyProjectId }) => (
  <Query
    query={GET_KEY_ELEMENTS}
    skip={!studyProjectId}
    variables={{ where: { study_project_id: studyProjectId } }}
  >
    {({ data: { keyElements } = {}, loading, error }) =>
      render({ keyElements, loading, error })
    }
  </Query>
);

const addKeyElement = ({ render }) => (
  <Mutation mutation={CREATE_KEY_ELEMENT}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const updateKeyElement = ({ render }) => (
  <Mutation mutation={UPDATE_KEY_ELEMENT}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const deleteKeyElement = ({ render }) => (
  <Mutation mutation={DELETE_KEY_ELEMENT}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const publishKeyElement = ({ render }) => (
  <Mutation mutation={PUBLISH_KEY_ELEMENT}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const unpublishKeyElement = ({ render }) => (
  <Mutation mutation={UNPUBLISH_KEY_ELEMENT}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const addKeyElementResource = ({ render }) => (
  <Mutation mutation={ADD_KEY_ELEMENT_RESOURCE}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const removeKeyElementResource = ({ render }) => (
  <Mutation mutation={REMOVE_KEY_ELEMENT_RESOURCE}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const KeyElementContainer = adopt({
  studyProjectKeyElements,
  addKeyElement,
  updateKeyElement,
  deleteKeyElement,
  publishKeyElement,
  unpublishKeyElement,
  addKeyElementResource,
  removeKeyElementResource,
});

export default KeyElementContainer;
