import React from 'react';
import { Container, Box, Typography, Paper } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_COURSE } from '../../../../../../apollo/queries';
import { CourseForm } from '../../../../../../components';
import styles from './styles';
import omitDeep from 'omit-deep';
import { clone } from 'ramda';

import { withSnackbar } from 'notistack';
import { auditDataFields } from '../../../../../../lib';

const CourseSetting = ({ course, enqueueSnackbar }) => {
  const classes = styles();

  const [updateCourse] = useMutation(UPDATE_COURSE, {
    refetchQueries: ['allCMSProducts', 'getCourseById'],
  });

  const handleCourseSubmit = async values => {
    const data = omitDeep(clone(values), [
      'id',
      '__typename',
      'lessons',
      'benchmarks',
      'product',
      ...auditDataFields,
    ]);
    try {
      await updateCourse({
        variables: {
          data: {
            description: null,
            ...data,
          },
          where: { id: course.id },
        },
      });
      enqueueSnackbar(`Successfully Updated ${course.name}!`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(
        `Failed to update ${course.name}. Contact Tech Support if problem persists.`,
        { variant: 'error' },
      );
    }
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex" alignItems="center" mt={3} mb={3}>
        <Typography variant="h4">{course.name} Settings</Typography>
      </Box>
      <Paper className={classes.paper}>
        <CourseForm
          course={course}
          onSubmit={handleCourseSubmit}
          productId={course.product.id}
        />
      </Paper>
    </Container>
  );
};

export default withSnackbar(CourseSetting);
