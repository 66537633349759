import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    border: 0,
    margin: 0,
    display: "inline-flex",
    padding: 0,
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top",
    borderRadius: theme.shape.borderRadius,
  },
  fieldset: {
    border: "1px solid rgba(0,0,0,.23)",
    top: "-5px",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "0",
    padding: "0",
    position: "absolute",
    borderStyle: "solid",
    borderWidth: "1px",
    paddingLeft: "8px",
    borderRadius: "inherit",
    pointerEvents: "none",
  },
  legend: {
    height: "11px",
    padding: "0",
    fontSize: "0.75rem",
    maxWidth: 1000,
    textAlign: "left",
    visibility: "hidden",
    "& span": {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  label: {
    display: "block",
    transformOrigin: "top left",
    transform: "translate(14px, -6px) scale(0.75)",
    color: "rgba(0,0,0,0.54)",
    zIndex: 1,
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    left: 0,
    padding: 0,
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em",
  },
  color: {
    flex: 1,
    height: 19,
    borderRadius: theme.shape.borderRadius,
  },
  swatch: {
    padding: "18.5px 14px",
  },
  popover: {
    position: "absolute",
    zIndex: "2",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
}));
