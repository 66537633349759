import React from 'react';
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Divider,
} from '@material-ui/core';
import { CircularProgressBar, EditorText } from '../../../../components';
import styles from './styles';

import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { isQuestionAnswered } from '../../../../lib';

const BenchmarkResults = ({ onClick, timeRanOut = false, title, questions, userResults, courseLogo, courseName }) => {
  const classes = styles();

  const results = userResults.reduce((acc, result) => {
    acc[result.question_id] = {
      correct: result.result,
      answer: JSON.parse(result.answer),
    };

    return acc;
  }, {});

  const lessonSortedResults = questions
    .reduce((acc, question) => {
      const lesson = question.lesson;
      const lessonIndex = acc.findIndex((l) => l.id === lesson.id);
      const result = results[question.id];

      if (lessonIndex !== -1) {
        acc[lessonIndex].questions.push({ ...question, result });
      } else {
        acc.push({ ...lesson, questions: [{ ...question, result }] });
      }

      return acc;
    }, [])
    .map((lesson) => ({
      ...lesson,
      score: `${lesson.questions.filter((question) => question.result.correct).length
        }/${lesson.questions.length}`,
    }))
    .sort((a, b) => a.display_order - b.display_order);
  
  
  const computeScore = (results) => {
    let totalQuestions = Object.keys(results).length;
    let correctAnswers = 0;

    // eslint-disable-next-line
    Object.keys(results).some((key) => {
      if (results[key].correct === true) {
        correctAnswers++;
      }
    });

    return {
      averageScore: (Math.round((correctAnswers / totalQuestions) * 100)),
      totalQuestions,
      correctAnswers
    }
  }

  return (
    <Box
      display='flex'
      width='100%'
      justifyContent='center'
      alignItems='flex-start'
    >
      <Box maxWidth={960} width='100%' >
        <Box className={classes.benchmarkResults_header} >
          <Box display="flex" flexDirection="column" >
            <Typography className={classes.assessmentCompleteTitle} >
              {
                title === 'Summary Assessment' && "SUMMARY ASSESSMENT COMPLETED"
              }
              {
                title === 'Benchmark Assessment' && "BENCHMARK ASSESSMENT COMPLETED"
              }
            </Typography>
            <Typography className={classes.checkResultTitle} >Check Your Results.</Typography>
            <Typography variant="overline" color="textSecondary" style={{fontSize: 16}} >
              {
                title === 'Summary Assessment' && "Let's Review Your Summary Assessment Results"
              }
              {
                title === 'Benchmark Assessment' && "Let's Review Your Benchmark Assessment Results"
              }
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <img src={courseLogo} alt="courseLogo" width={30} height={30} />
            <Typography style={{ fontWeight: 'bold', marginLeft: 8 }}>
              {courseName}
            </Typography>
          </Box>
        </Box>
        <Divider></Divider>
        <Box className={classes.progressBarContainer}>
          {
            title === 'Benchmark Assessment' && <CircularProgressBar correctAnswers={computeScore(results).correctAnswers} showFeedback={false} totalQuestions={computeScore(results).totalQuestions} percentNumber={computeScore(results).averageScore}/>
          }
          {
            title === 'Summary Assessment' && <CircularProgressBar correctAnswers={computeScore(results).correctAnswers} showFeedback={true} totalQuestions={computeScore(results).totalQuestions} percentNumber={computeScore(results).averageScore}/>
          }
          {
            title === 'Summary Assessment' && computeScore(results).averageScore < 75 && <Button onClick={() => window.location.reload()} className={classes.buttonTryAgain}>Review needed</Button>
          }
        </Box>
        <Box mb={4} pl={2} pr={2}>
          {lessonSortedResults.map((lesson) => (
            <div key={lesson.id}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={3}
                mb={2}
              >
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Lesson {lesson.display_order} {lesson.name}
                </Typography>
                <Typography
                  align="right"
                  variant="body1"
                  color="textSecondary"
                >
                  Score: {lesson.score}
                </Typography>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="100%">
                      <Typography className={classes.questionsAndResultTitle} >Questions</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.questionsAndResultTitle}  >Status</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lesson.questions.map((question, index) => (
                    <TableRow key={question.id}>
                      <TableCell style={{ display: "flex", alignItems: "center" }} >
                        <Typography variant="overline" style={{ marginRight: 8 }} >
                          Q-{index + 1}
                        </Typography>
                        <EditorText text={question.question} />
                      </TableCell>
                      <TableCell>
                        <IsCorrect
                          type={question.type}
                          result={question.result}
                          showUnanswered={timeRanOut}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Toolbar />
            </div>
          ))}
        </Box>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.answerBar}>
            <Button
              className={classes.button}
              onClick={onClick}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
    </Box>
  );
};

const IsCorrect = ({ type, result, showUnanswered }) => {
  const isAnswered = isQuestionAnswered(type, result);

  if (showUnanswered && !isAnswered) {
    return (
      <Box display="flex" alignItems="center">
        <ErrorOutlineIcon style={{ color: '#F57C00' }} />

        <Typography
          style={{ marginLeft: 8 }}
          variant="subtitle2"
          color="textSecondary"
        >
          Unanswered
        </Typography>
      </Box>
    );
  }
  return (
    <Box display="flex" alignItems="center">
      {result.correct ? (
        <DoneIcon style={{ color: '#10AC84' }} />
      ) : (
        <CancelOutlinedIcon color="primary" />
      )}
      <Typography
        style={{ marginLeft: 8 }}
        variant="subtitle2"
        color="textSecondary"
      >
        {result.correct ? 'Correct' : 'Incorrect'}
      </Typography>
    </Box>
  );
};

export default BenchmarkResults;

// FEEDBACK ONCE ADDED BACK -- DO NOT DELETE

// {!question.result.correct && (
//   <Fragment>
//     <Typography
//       variant="caption"
//       style={{ fontWeight: 'bold', margin: `5px 0px` }}
//     >
//       Feedback
//     </Typography>
//     <Typography variant="body2">
//       We encourage you to learn more about this in{' '}
//       <b>
//         Lesson {lesson.display_order} {lesson.name}
//       </b>
//     </Typography>
//   </Fragment>
// )}
