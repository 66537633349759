import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_STUDENT_GRADEBOOK_OVERVIEW } from '../../../../apollo/queries';
import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Avatar,
} from '@material-ui/core';
import styles from './styles';
import clsx from 'clsx';
import {
  getGradebookBenchmarks,
  getGradebookLessons,
  getLessonLevelProgress,
  getAssessmentLevelProgress,
  getGradebookSummaries,
} from './helpers';

import GradebookOverviewCard from './GradeOverviewCard';
import GradeOverviewCard from './GradeOverviewCard';
import GradeLockedCard from './GradeLockedCard';

const StudentGradebook = ({ viewer, course, group }) => {
  const classes = styles();

  const { data, loading, error } = useQuery(GET_STUDENT_GRADEBOOK_OVERVIEW, {
    variables: {
      courseId: course.id,
      groupId: group.id,
      studentId: viewer.id,
    },
    fetchPolicy: 'network-only',
  });

  if (error) return <p>Error...</p>;

  const benchmarks = loading
    ? []
    : getGradebookBenchmarks(course.benchmarks, data.studentGradebookOverview);

  const summaries = loading
  ? []
  : getGradebookSummaries(course.summaries, data.studentGradebookOverview);

  const lessons = loading
    ? []
    : getGradebookLessons(course.lessons, data.studentGradebookOverview);

  const handleGetH1HtmlTagFromIntroductionField = (introduction) => {
    let temp = document.createElement('div');
    temp.innerHTML = introduction;
    const h2Elements = temp.getElementsByTagName('h2');
    const textContent = h2Elements[0]?.textContent;
    return textContent;
  }

  return (
    <section className={classes.gradebookSection}>
      <Typography
        variant="h2"
        className={clsx(classes.overviewText, classes.title)}
      >
        Gradebook
      </Typography>

      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Box mb={6}>
          <Card variant="outlined">
            <CardHeader
              titleTypographyProps={{ variant: 'h3' }}
              title="Benchmark"
            />
            <CardContent style={{ display: 'flex', flexWrap: 'wrap' }}>
              {benchmarks.map((benchmark) => {
                return benchmark.complete ? (
                  <GradebookOverviewCard
                    key={benchmark.id}
                    bordercolor={course.product.color}
                    level={benchmark.level}
                    progress={getAssessmentLevelProgress(benchmark)}
                    totalquestion={benchmark.totalQuestions}
                    correctanswers={benchmark.correctAnswers}
                    cardType="benchmark"
                  />
                ) : (
                  <GradeLockedCard
                    key={benchmark.id}
                    cardType="benchmark"
                    level={benchmark.level}
                    borderColor={course.product.color}
                  />
                );
              })}
              {/*benchmarks.length === 0 ? (
                <GradeLockedCard
                  cardType="all"
                  borderColor={course.product.color}
                />
              ) : (
                <GradebookOverviewCard
                  bordercolor={course.product.color}
                  level="all"
                  progress={getAssessmentLevelAllProgress(benchmarks)}
                  totalquestion={getTotalQuestions(benchmarks)}
                  correctanswers={getTotalCorrectAnswers(benchmarks)}
                  cardType="all_benchmarks"
                />
              )*/}
            </CardContent>
          </Card>
        </Box>
      )}

      {loading ? "" : (
        lessons.map((lesson) => {
          return (
            <Box mb={6} key={lesson.id}>
              <Card variant="outlined">
                <CardHeader
                  avatar={<Avatar src={lesson.logo} />}
                  titleTypographyProps={{ variant: 'h3' }}
                  title={`Lesson ${lesson.display_order}`}
                  subheader={lesson.name}
                />
                <CardContent style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {lesson.studyProjects.map((studyproject, index) => {
                    return studyproject.complete ? (
                      <GradeOverviewCard
                        key={studyproject ? studyproject.id : index}
                        cardType="studyproject"
                        bordercolor={course.product.color}
                        level={studyproject.level}
                        logo={lesson.logo}
                        typeOfStudyProject={handleGetH1HtmlTagFromIntroductionField(studyproject.introduction)}
                        totalquestion={studyproject.totalQuestions}
                        correctanswers={studyproject.correctAnswers}
                        progress={getLessonLevelProgress(studyproject)}
                        studyProjectNumber={studyproject.study_project_number}
                      />
                    ) : (
                      <GradeLockedCard
                        typeOfStudyProject={handleGetH1HtmlTagFromIntroductionField(studyproject.introduction)}
                        cardType="studyproject"
                        key={studyproject ? studyproject.id : index}
                        logo={lesson.logo}
                        draft={studyproject.draft}
                        level={studyproject.level}
                        borderColor={course.product.color}
                        studyProjectNumber={studyproject.study_project_number}
                      />
                    );
                  })}
                </CardContent>
              </Card>
            </Box>
          );
        })
      )}

      {
        loading ? "" :
        <Box mb={6}>
          <Card variant="outlined">
            <CardHeader
              titleTypographyProps={{ variant: 'h3' }}
              title="Summary"
            />
            <CardContent style={{ display: 'flex', flexWrap: 'wrap' }}>
              {summaries.map((summary) => {
                return summary.complete ? (
                  <GradebookOverviewCard
                    groupId={group.id}
                    key={summary.id}
                    summaryAssessmentId={summary.id}
                    bordercolor={course.product.color}
                    level={summary.level}
                    progress={getAssessmentLevelProgress(summary)}
                    totalquestion={summary.totalQuestions}
                    correctanswers={summary.correctAnswers}
                    cardType="summary"
                  />
                ) : (
                  <GradeLockedCard
                    key={summary.id}
                    cardType="summary"
                    level={summary.level}
                    borderColor={course.product.color}
                  />
                );
              })}
              {/*summaries.length === 0 ? (
                <GradeLockedCard
                  cardType="all"
                  borderColor={course.product.color}
                />
              ) : (
                <GradebookOverviewCard
                  bordercolor={course.product.color}
                  level="all"
                  progress={getAssessmentLevelAllProgress(summaries)}
                  totalquestion={getTotalQuestions(summaries)}
                  correctanswers={getTotalCorrectAnswers(summaries)}
                  cardType="all_summaries"
                />
              )*/}
            </CardContent>
          </Card>
        </Box>
      }

      {/* {loading ? (
        <GradebookOverviewCard style={{ marginBottom: 16 }}>
          <Skeleton variant="circle" width={40} height={40} />
          <Skeleton
            height={10}
            width="20%"
            style={{ marginLeft: 16, marginRight: 'auto' }}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Skeleton height={10} width={30} />
            <Skeleton height={10} width={60} />
          </Box>
        </GradebookOverviewCard>
      ) : (
        <Box mb={6}>
          <Card variant="outlined">
            <CardHeader
              titleTypographyProps={{ variant: 'h3' }}
              title="Benchmark"
            />
            <CardContent>
              {benchmarks.map((benchmark) => {
                return (
                  <GradebookOverviewCard
                    key={benchmark.id}
                    borderColor={course.product.color}
                    style={{ maxWidth: '20%' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        padding: 16,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        variant="h4"
                        style={{ textAlign: 'center', marginBottom: 16 }}
                      >
                        {benchmark.level === 'all'
                          ? 'All Levels Benchmark Assessment'
                          : `Level ${benchmark.level} Assessment`}
                      </Typography>
                      <div style={{ display:"flex", justifyContent:"space-between", flex:"1" }} >
                        <div style={{ flex:"0.2" }} >
                          <Typography
                            variant="subtitle2"
                            style={{ marginBottom: 8 }}
                          >
                            Progress
                          </Typography>
                          <CircularProgressWithLabel progress={100}  />
                        </div>
                        <GradePerformanceIndicator flex="0.7" />
                      </div>
                     
                    </div>
                  </GradebookOverviewCard>
                );
              })}
            </CardContent>
          </Card>
        </Box>
      )} */}

      {/* {loading ? (
        <GradebookOverviewCard style={{ marginBottom: 16 }}>
          <Skeleton variant="circle" width={40} height={40} />
          <Skeleton
            height={10}
            width="20%"
            style={{ marginLeft: 16, marginRight: 'auto' }}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Skeleton height={10} width={30} />
            <Skeleton height={10} width={60} />
          </Box>
        </GradebookOverviewCard>
      ) : (
        benchmarks.map((benchmark) => (
          <GradebookOverviewCard
            style={{ marginBottom: 16 }}
            borderColor={course.product.color}
            key={benchmark.id}
          >
            <img
              src={BenchmarkLogo}
              alt="benchmark-logo"
              width={40}
              height={40}
              style={{ marginRight: 8 }}
            />
            <Typography variant="h4" style={{ fontWeight: 'bold', flex: 1 }}>
              Benchmark Assessment
            </Typography>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                {benchmark.score}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {benchmark.complete ? 'Correct' : ''} Answers
              </Typography>
            </Box>
          </GradebookOverviewCard>
        ))
      )} */}

      {/* {loading
        ? new Array(4).fill(1).map((_, index) => (
            <GradebookOverviewExpansionCard
              style={{ marginBottom: 16 }}
              key={index}
            >
              <Box height={65} display="flex" alignItems="center" width="100%">
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton
                  height={10}
                  width="20%"
                  style={{ marginLeft: 16, marginRight: 'auto' }}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Skeleton
                    variant="circle"
                    width={40}
                    height={40}
                    style={{ marginBottom: 4 }}
                  />
                  <Skeleton height={10} width={60} />
                </Box>
              </Box>
            </GradebookOverviewExpansionCard>
          ))
        : lessons.map((lesson) => (
            <GradebookOverviewExpansionCard
              style={{ marginBottom: 16 }}
              key={lesson.id}
              borderColor={course.product.color}
              details={
                <GradebookStudyProjectOveriew
                  studyProjects={lesson.studyProjects}
                />
              }
            >
              <img
                src={lesson.logo}
                alt={`lesson-${lesson.lessonNumber}`}
                width={40}
                height={40}
                style={{ marginRight: 8 }}
              />
              <Typography variant="h4" style={{ fontWeight: 'bold', flex: 1 }}>
                Lesson {lesson.lessonNumber}
              </Typography>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <CircularProgressWithLabel
                  progress={getLessonLevelProgress(
                    lesson.studyProjects,
                    currentLevel + 1,
                  )}
                />
                <Typography variant="caption" color="textSecondary">
                  Your Progress
                </Typography>
              </Box>
            </GradebookOverviewExpansionCard>
          ))}  */}
    </section>
  );
};

export default StudentGradebook;
