import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Avatar } from '@material-ui/core';
import { Heading } from '../../common';
import SelectionImmersiveReader from '../../immersiveReader/SelectionImmersiveReader';
import ImmersiveReaderButton from '../../immersiveReader/ImmersiveReaderButton';

const pageWidth = 927;

const LibraryBook = ({ pages, lesson }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };
  const handlePrevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  useEffect(() => {
    setPageNumber(0);
  }, [pages]);

  if (!pages[pageNumber]) {
    // sets out of range page numbers to zero when pages have already loaded once
    setPageNumber(0);
    return <p>No Pages</p>;
  }

  return (
    <Box 
    style={{
      maxWidth: 960,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    }}
    >
      {
        lesson && 
          <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5em'}}>
            <Avatar src={lesson.logo} />
            <Heading variant="h2" component="h2" align="center">
              {lesson.name}
            </Heading>
          </Box>
      }
      <EBookControls
        nextPage={handleNextPage}
        prevPage={handlePrevPage}
        currentPage={pageNumber}
        totalPages={pages.length}
        disableNext={pageNumber >= pages.length - 1}
        disablePrev={pageNumber === 0}
        mt={1}
        mb={1}
      />
      <Box 
        display='flex'
        overflow='auto'
        flexDirection='column'
        margin='1em 0 1em 0'
        padding='2em'
      >
        <ImmersiveReaderButton text={pages[pageNumber].content} />
        <SelectionImmersiveReader htmlText={pages[pageNumber].content} />
      </Box>
    </Box>
  );
};

export default LibraryBook;

const EBookControls = (props) => {
  const {
    nextPage,
    prevPage,
    currentPage,
    totalPages,
    noPageCount,
    disableNext,
    disablePrev,
    ...restProps
  } = props;

  return (
    <Box
      {...restProps}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      maxWidth={pageWidth}
      m="1em"
    >
      <Button onClick={prevPage} disabled={disablePrev} color="primary">
        Prev
      </Button>
      {!noPageCount && (
        <Typography>
          {currentPage + 1} / {totalPages}
        </Typography>
      )}
      <Button onClick={nextPage} disabled={disableNext} color="primary">
        Next
      </Button>
    </Box>
  );
};
