import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Dashboard } from './components';
import CourseDashboard from '../CourseDashboard';
import Benchmark from '../Benchmark';
import StudyProject from '../StudyProject';
import MediaLibrary from '../StudyProject/components/MediaLibrary';
import GroupProvider from './providers/GroupProvider';
import GroupMemberProvider from './providers/GroupMemberProvider';
import { useViewer } from '../../providers/ViewerProvider';
import Summary from '../Summary';
import GroupsProvider from './providers/GroupsProvider';
import InstitutionProvider, { useInstitution } from '../../providers/InstitutionProvider';

const GroupDashboard = ({ match }) => {
  const groupId = match.params.groupId;
  const viewer = useViewer();
  const institution = useInstitution();

  const { currentInstitution } = institution; //Current institution
  const gmetrixInstructorID = 'c749bf06-677f-4fb8-bd75-e312fd285573'; //If enrollment endpoint changes, this need to be updated.

  return (
  <InstitutionProvider>
    <GroupProvider groupId={groupId}>
      <GroupMemberProvider userId={viewer.id} groupId={groupId}>
        <GroupsProvider institutionId={currentInstitution?.id} userId={gmetrixInstructorID}>
          <Switch>
            <Route
              path={`${match.path}/course/:courseId/study-project/:projectId/media-library/:questionId/:type/:caseStudyNumber`}
              component={MediaLibrary}
            />
            <Route
              path={`${match.path}/course/:courseId/study-project/:projectId`}
              component={StudyProject}
            />
            <Route
              path={`${match.path}/course/:courseId/benchmark/:level`}
              component={Benchmark}
            />
            <Route
              path={`${match.path}/course/:courseId/summary/:level`}
              component={Summary}
            />
            <Route
              path={`${match.path}/course/:courseId`}
              component={CourseDashboard}
            />
            <Route path={match.path} component={Dashboard} />
          </Switch>
        </GroupsProvider>
      </GroupMemberProvider>
    </GroupProvider>
  </InstitutionProvider>
  );
};

export default GroupDashboard;
