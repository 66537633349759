import React, { Fragment, useState, useEffect } from 'react';
import styles from './styles';
import { Typography, Box, Button, Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_BOOK_BY_ID,
  CREATE_BOOK_PAGE,
  UPDATE_BOOK_PAGE,
} from '../../../../apollo/queries';
import { EBookPageForm, AddButton } from '../../../../components';
import { withSnackbar } from 'notistack';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const EBookDevelopment = ({ match, history, enqueueSnackbar }) => {
  const classes = styles();
  const [page, setPage] = useState(1);
  const [addPage, setAddPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const { data, loading, error } = useQuery(GET_BOOK_BY_ID, {
    variables: { id: match.params.ebookId },
  });
  const [createPage] = useMutation(CREATE_BOOK_PAGE, {
    refetchQueries: ['getBook'],
  });
  const [updatePage] = useMutation(UPDATE_BOOK_PAGE, {
    refetchQueries: ['getBook'],
  });

  useEffect(() => {
    if (data) {
      const selectedPage = data.book.pages.filter(
        (p) => p.page_number === page,
      );
      if (selectedPage.length) setSelectedPage(selectedPage[0]);
      else setSelectedPage(null);
    }
  }, [page, data]);

  const handlePageCreation = async (values) => {
    const result = await createPage({
      variables: {
        data: {
          ebook_id: match.params.ebookId,
          title: values.title,
          content: values.content,
          page_number: +values.page_number,
        },
      },
    });
    setAddPage(false);
    setPage(result.data.createPage.page_number);
    enqueueSnackbar('Successfully Created Page', {
      variant: 'success',
      autoHideDuration: 1500,
    });
  };

  const handlePageUpdate = async (values) => {
    const result = await updatePage({
      variables: {
        data: {
          title: values.title,
          content: values.content,
        },
        where: {
          id: selectedPage.id,
        },
      },
    });
    if (result) {
      enqueueSnackbar('Successfully Updated Page', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const pages = data.book.pages;

  return (
    <Container className={classes.eBookSection} maxWidth="md">
      <Button
        variant="text"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <Box p={2} display="flex" justifyContent="space-between">
        <Typography variant="h4">{data.book.name}</Typography>
        {!addPage ? (
          <AddButton title="Add Page" onClick={() => setAddPage(true)} />
        ) : (
          <Button
            color="primary"
            variant="text"
            onClick={() => setAddPage(false)}
          >
            Cancel
          </Button>
        )}
      </Box>

      <Box display="flex" flexDirection="column" p={2}>
        {pages.length === 0 && !addPage && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height={500}
          >
            <Typography variant="h5" color="textSecondary">
              Book Currently Empty
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ marginBottom: 32 }}
            >
              Click the Add button below to get Started
            </Typography>
            <AddButton title="Add Page" onClick={() => setAddPage(true)} />
          </Box>
        )}
        {pages.length !== 0 && !addPage && (
          <Fragment>
            <Pagination
              style={{ alignSelf: 'center', marginBottom: 16 }}
              count={pages.length}
              page={page}
              onChange={(event, value) => setPage(value)}
            />
            {selectedPage && (
              <EBookPageForm page={selectedPage} onSubmit={handlePageUpdate} />
            )}
          </Fragment>
        )}
        {addPage && (
          <Box>
            <EBookPageForm
              nextPage={
                pages.length >= 1 ? pages[pages.length - 1].page_number + 1 : 1
              }
              bookId={match.params.ebookId}
              onSubmit={handlePageCreation}
            />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default withSnackbar(EBookDevelopment);
