import { makeStyles } from '@material-ui/core/styles';
import { containerPadding, mobileContainerPadding } from '../../theme';

const drawerWidth = 384;

const styles = makeStyles((theme) => ({
  studyGroupSection: {
    height: '100%',
    width: '100%',
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      overflow: 'auto',
    },
  },
  tabBar: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    color: theme.palette.common.black,
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  drawer: {
    minWidth: drawerWidth,
    ...containerPadding(theme),
    background: theme.palette.common.white,
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'auto',

    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      minWidth: '100%',
      boxShadow: theme.shadows[2],
      overflow: 'initial',
      ...mobileContainerPadding(theme),
    },
  },
  heading: {
    marginBottom: theme.spacing(3),
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    ...containerPadding(theme),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      overflow: 'initial',
      ...mobileContainerPadding(theme),
    },
  },
  scrollWrapper: {
    flexGrow: 1,
    overflow: 'auto',
    overflowX: 'hidden',

    [theme.breakpoints.down('sm')]: {
      overflow: 'initial',
    },
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export default styles;
