import React from 'react';
import { Form, Field } from 'react-final-form';
import { FormControl, TextField, Button } from '@material-ui/core';
import { CREATE_PRODUCT, UPDATE_PRODUCT } from '../../../apollo/queries';
import { useMutation } from '@apollo/react-hooks';
import { ColorPicker } from '../../common';
import styles from './styles';
import { CloudinaryImageWidget } from '../../common';

const ProductForm = ({ product, onCancel }) => {
  const classes = styles();

  const [createProduct] = useMutation(CREATE_PRODUCT, {
    refetchQueries: ['allCMSProducts'],
  });
  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: ['allCMSProducts'],
    awaitRefetchQueries: true,
  });

  return (
    <Form
      initialValues={product ? product : { color: '#BF1212' }}
      onSubmit={async (values) => {
        if (values.id) {
          await updateProduct({
            variables: {
              data: {
                name: values.name,
                color: values.color,
                logo: values.logo,
              },
              where: { id: values.id },
            },
          });
        } else {
          await createProduct({ variables: { data: values } });
        }
        if (onCancel) {
          onCancel();
        }
      }}
      validate={(values) => {
        let errors = {};

        if (!values.name) errors.name = 'A Product name is required';
        if (!values.color) errors.color = 'A Product color is required';
        if (!values.logo) errors.logo = 'A Product logo is required';

        return errors;
      }}
      render={({ handleSubmit, values, pristine, valid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth style={{ paddingBottom: 16 }}>
            <Field name="name">
              {({ input, meta }) => (
                <TextField
                  error={!pristine && Boolean(meta.error)}
                  id="name"
                  label="Product Name"
                  value={input.value}
                  onChange={(value) => input.onChange(value)}
                  variant="filled"
                  helperText={!pristine && meta.error ? meta.error : ''}
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth style={{ paddingBottom: 16 }}>
            <Field name="logo">
              {({ input, meta }) => (
                <CloudinaryImageWidget
                  multiple={false}
                  maxFiles={1}
                  acceptedFormat={'svg'}
                  files={input.value}
                  buttonLabel="Product Logo"
                  onInsert={(files) => {
                    input.onChange(files);
                  }}
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth style={{ paddingBottom: 16 }}>
            <Field name="color">
              {({ input, meta }) => (
                <ColorPicker
                  label="Product Color"
                  value={input.value}
                  onChange={(value) => input.onChange(value)}
                />
              )}
            </Field>
          </FormControl>
          <div className={classes.buttonFormControl}>
            {onCancel && (
              <Button
                type="button"
                variant="text"
                onClick={() => onCancel()}
                color="primary"
              >
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              variant="text"
              color="primary"
              disabled={
                pristine || submitting || !valid
              }
            >
              {product ? 'Update' : 'Create'}
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default ProductForm;
