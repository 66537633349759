import React, { useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { LevelTabs } from '../../../../components';
import { GET_ALL_BENCHMARK_RESULTS } from '../../../../apollo/queries';
import { useQuery } from '@apollo/react-hooks';
import styles from './styles';
import GradeOverviewCard from './GradeOverviewCard';
import EntityGradeOverview from './EntityGradebook';

const BenchmarkGradebook = ({ selectedCourse, selectedStudent, selectedLevel, group }) => {
  const classes = styles();
  const [currentLevel, setCurrentLevel] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentLevel(newValue);
  };

  const { data, loading, error } = useQuery(GET_ALL_BENCHMARK_RESULTS, {
    variables: {
      courseId: selectedCourse ? selectedCourse.id : null,
      groupId: group.id,
    },
    skip: !selectedCourse || !group,
    fetchPolicy: 'no-cache',
  });

  const getBenchmarkData = (data, selectedLevel, currentLevel) => {
    return data
      ? data.instructorBenchmarkGradebookOverview.find(
        (bm) => bm.level === (selectedLevel && selectedLevel !== 'all' ? selectedLevel : currentLevel + 1),
      )
      : null;
  }

  const benchmark = useMemo(() => getBenchmarkData(data, selectedLevel, currentLevel), [selectedLevel, currentLevel, data]);

  if (error) return <p>Error...</p>;

  const student =
    selectedStudent === 'all'
      ? null
      : Boolean(benchmark)
        ? benchmark.students.find((student) => student.id === selectedStudent)
        : null;

  return (
    <GradeOverviewCard
      title="Benchmark"
      noCourseSelected={!selectedCourse}
      className={classes.entityOverviewCard}
    >
      {selectedCourse && (
        <LevelTabs
          course={selectedCourse}
          currentLevel={currentLevel}
          handleTabChange={handleTabChange}
          selectedLevel={selectedLevel}
        />
      )}
      {loading && (
        <Box p={6} textAlign="center">
          <CircularProgress size={60} />
        </Box>
      )}
      {selectedCourse && !loading && benchmark ?
        <EntityGradeOverview entity={benchmark} student={student} /> : <Typography variant='subtitle1' style={{ padding: '1em' }}>The Benchmark has not been published yet</Typography>
      }
    </GradeOverviewCard>
  );
};

export default BenchmarkGradebook;