export const containerPadding = (theme) => ({
  paddingTop: theme.spacing(9),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
})

export const mobileContainerPadding = (theme) => ({   
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
})