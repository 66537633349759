import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  cardImage: {
    'max-width': '100% !important;',
    height: 'auto !important',
  },
}));

const CardImage = ({ src, alt }) => {
  const classes = styles();

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <img className={classes.cardImage} src={src} alt={alt}></img>
    </Box>
  );
};

export default CardImage;
