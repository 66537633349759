import gql from 'graphql-tag';
import { KeyElementFields } from './fragments';

const GET_KEY_ELEMENTS = gql`
  query getKeyElements($where: KeyElementWhereInput) {
    keyElements(where: $where) {
      ...KeyElementFields
    }
  }
  ${KeyElementFields}
`;

const CREATE_KEY_ELEMENT = gql`
  mutation addKeyElement($data: KeyElementCreateInput!) {
    createKeyElement(data: $data) {
      ...KeyElementFields
    }
  }
  ${KeyElementFields}
`;

const ADD_KEY_ELEMENT_RESOURCE = gql`
  mutation addKeyElementResources($data: KeyElementResourceCreateInput!) {
    addKeyElementResource(data: $data)
  }
`;

const REMOVE_KEY_ELEMENT_RESOURCE = gql`
  mutation removeKeyElementResource($keyElementId: ID!, $resourceId: ID!) {
    removeKeyElementResource(
      keyElementId: $keyElementId
      resourceId: $resourceId
    )
  }
`;

const UPDATE_KEY_ELEMENT = gql`
  mutation updateKeyElement(
    $data: KeyElementUpdateInput!
    $where: KeyElementWhereUniqueInput!
  ) {
    updateKeyElement(data: $data, where: $where) {
      ...KeyElementFields
    }
  }
  ${KeyElementFields}
`;

const DELETE_KEY_ELEMENT = gql`
  mutation deleteKeyElement($id: ID!) {
    deleteKeyElement(id: $id)
  }
`;

const PUBLISH_KEY_ELEMENT = gql`
  mutation publishKeyElement($id: ID!) {
    publishKeyElement(id: $id)
  }
`;

const UNPUBLISH_KEY_ELEMENT = gql`
  mutation unpublishKeyElement($id: ID!) {
    unpublishKeyElement(id: $id)
  }
`;

export {
  GET_KEY_ELEMENTS,
  CREATE_KEY_ELEMENT,
  ADD_KEY_ELEMENT_RESOURCE,
  REMOVE_KEY_ELEMENT_RESOURCE,
  UPDATE_KEY_ELEMENT,
  DELETE_KEY_ELEMENT,
  PUBLISH_KEY_ELEMENT,
  UNPUBLISH_KEY_ELEMENT,
};
