import React from "react";
import styles from "./styles";

const AppShell = ({ children, header }) => {
  const classes = styles();

  return (
    <section className={classes.appShell}>
      {header}
      <main className={classes.main}>{children}</main>
    </section>
  );
};

export default AppShell;
