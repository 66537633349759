import React from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import JasperactiveLogo from '../../../../assets/logos/brand/jasperactive-logo-red.svg';
import styles from './styles';
const BenchmarkContainer = ({ children, logo, name, showpage, level }) => {
  const classes = styles();
  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
        className={classes.container}
      >
        <Box>
          <img
            src={JasperactiveLogo}
            alt="jasperactvelogo"
            width="200px"
            height="50px"
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            {logo ? (
              <img src={logo} alt="courselogo" width="40px" height="40px" />
            ) : (
              ''
            )}
            <Typography className={classes.courseTitle}>{name}</Typography>
          </Box>
          {showpage === 'assessment' || showpage === 'results' ? (
            <Typography className={classes.benchmarkLevelTitle}>
              {level === 'all' ? 'All Levels' : `Level ${level}`} Benchmark
              Assessment
            </Typography>
          ) : (
            ''
          )}
        </Box>
      </Box>
      {children}
    </Container>
  );
};

export default BenchmarkContainer;
