import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  startContainer: {
    '& img': {
      height: 200,
      width: 200,
    },
    '& p': {
      marginBottom: theme.spacing(2),
    },
  },
  levelTitle: {
    marginTop: 8,
    marginBottom: 8,
    fontSize: 20,
    color: '#C03030',
  },
  buttonContainer: {
    display:"flex",
    justifyContent:"space-between",
    [theme.breakpoints.down('sm')]:{
      flexDirection:"column"
    }
  },
  button: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]:{
      marginTop:8,
      marginBottom:8
    }
  },
  startButton: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      order: 1,
      marginBottom: 0,
    },
  },
  cancelButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  bechmarkTitle: {
    color: '#000000',
    fontSize:24
  },
  benchmarkDescription: {
    color: '#000000',
    fontSize: 18,
  },
  assessmentIcon: {
    width: 100,
    height: 100,
    color: theme.palette.success.main,
  },
  carousel: {
    marginTop: theme.spacing(4),
    height: '350px',
    display:"flex",
    alignItems:"center",

  },
  customDot:{
    borderRadius:"50%",
    width:20,
    height:20,
    background:"#fff",
    margin:5,
    boxShadow:"inset 1.5px 1.4px 2px rgba(0, 0, 0, 0.25);",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"

  },
  activeCustomDot:{
    color:theme.palette.primary.main,
    width:"50%",
    height:"50%",
    borderRadius:"50%",
    backgroundColor:theme.palette.primary.main
  },
}));
