import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  projectSection: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.white.main,
    zIndex: theme.zIndex.drawer + 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: '100vh',
    overflowX: 'auto',
  },
  projectContainer: {
    height: '100%',
    width: '100%',
  },
  levelText: {
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  projectTitle: {
    ...theme.typography.h4,
    color: theme.palette.white.main
  },
  questionTitle: {
    ...theme.typography.h4,
    color: theme.palette.white.main,
  },
  questionSubtitle: {
    ...theme.typography.h5,
  },
  questionHeader: {
    background: theme.palette.white.main,
    boxShadow: 'none',
  },
  header: {
    backgroundColor: '#3FAE2A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toolbar: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 960,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
    width: '100%'
  },
  header_title: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1em',
    '& > p': {
      color: '#FFFFFF'
    }
  },
  header_close: {
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  questionTimer: {
    ...theme.typography.h6,
  },
  answerResponseContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    width: '100%',
    maxWidth: 960
  },
  answerResponseSnackbarContainer: {
    width: '100%',
    zIndex: theme.zIndex.appBar + 1,
    paddingBottom: '1em',
  },
  answerResponse: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
  },
  answerResponseModal: {
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    left: '50%',
    width: '100%',
    padding: theme.spacing(2),
    maxWidth: theme.breakpoints.width('md'),
    transform: 'translate(-50%, -50%)',
  },
  correctResponse: {
    background: '#C8E6C9',
  },
  successIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  hintResponse: {
    background: '#FFE0B2',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1),
  },
  incorrectResponse: {
    background: '#FECDD2',
  },
  errorIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  answerBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '2em',
    padding: '0 16px',
    gap: '1em',
  },
  button: {
    width: 'auto',
  },
  projectButton: {
    marginRight: 'auto',
  },
  warningButton: {
    background: '#FF8F22',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#E57F1C'
    }
  },
  errorButton: {
    background: '#BD161C',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#A11419'
    }
  },
  quickCardContainer: {
    width: '100%',
    height: 582,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    borderRadius: '0.2em',
    margin: '0.5em',
    '@media (max-width: 375px)': {
      height: 375
    }
  },
  mainContainer: {
    position: 'relative',
    padding: '1em 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column'
  },
  modalSuccessSaveProgress: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  cardSuccessSaveProgress: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 640,
    width: '100%',
    padding: '1em',
    gap: '3em'
  },
  cardSuccessSaveProgressButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '1em',
  },
  mediaLibrary_container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2000,
    top: 0,
    left: 0,
    backgroundColor: '#FFFFFF',
    bottom: 0,
    right: 0,
    height: '100vh',
    overflowX: 'auto',
  },
  mediaLibrary_header: {
    padding: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
  },
  mediaLibrary_headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 960,
    flex: 1,
    padding: '0 1.5em'
  }
}));
