import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    background: theme.palette.grey[100],
  },
  licenseInfoContainer: {
    display: "flex",

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap"
    }
  },
  licenseInfo: {
    marginRight: theme.spacing(4),

    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    }
  },
}));
