import React, { memo } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import CancelIcon from '@material-ui/icons/Cancel';
import { EditorText } from '../../../components';
import styles from '../styles';
import QuickClipIcon from '../../../components/customMaterialIcons/quickclipIcon';

const AnswerResponse = ({ response, question, caseStudyNumber, courseId, match, lesson }) => {
  const classes = styles();
  if (!response) return null;

  const showButton = () => {
    if (
      (response.case === 'hint' && question.hint !== null) ||
      (response.case === 'incorrect' && question.reveal !== null)
    ) {
      return true;
    }
    return false;
  };

  const getButtonText = () => {
    if (response.case === 'hint' && question.hint !== null) {
      return question.hint.type === 'page' ? 'eBook' : question.hint.type === 'quickdeck' ? 'view quickdeck' : 'play quickclips';
    }
    if (response.case === 'incorrect' && question.reveal !== null) {
      return question.reveal.type === 'page' ? 'eBook' : question.reveal.type === 'quickdeck' ? 'view quickdeck' : 'play quickclips';
    }
    return '';
  };

  //This function gets the button icon to show.
  const getButtonIcon = () => {
    if (response.case === 'hint' && question.hint !== null) {
      return question.hint.type === 'quickdeck' ? null : <QuickClipIcon viewBox="-5 -5 24 24"/>;
    }
    if (response.case === 'incorrect' && question.reveal !== null) {
      return question.reveal.type === 'quickdeck' ? null : <QuickClipIcon viewBox="-5 -5 24 24"/>;
    }
    return null;
  }

  let className;
  let buttonColor; //Styles for hint button or incorrect button in answer response card.

  switch (response.case) {
    case 'correct':
      className = classes.correctResponse;
      break;
    case 'hint':
      className = classes.hintResponse;
      buttonColor = classes.warningButton;
      break;
    case 'incorrect':
      className = classes.incorrectResponse;
      buttonColor = classes.errorButton
      break;
    default:
      className = '';
  }
  
  //course Ids where to show ResourcesDashboard component.
  const gs6CourseID = ['0b256b0e-0571-4f5c-99a6-948422b59bf5', '18038e80-837d-44f8-9c72-9dbda87dd368', '747f5c1c-47f1-4cad-b1a8-e60999ebdc3c']

  const handleOnClickMediaLibrary = () => {
    const url = `${match.url}/media-library/${question.id}/${response.case}/${caseStudyNumber}/${lesson.course_id}`
    localStorage.setItem('redirectMediaLibrary', url);
    const newtab = window.open(url, '_blank');
    newtab.focus();
  };

  return (
    <div className={classes.answerResponseSnackbarContainer}>
      <Box className={`${classes.answerResponse} ${className}`}>
        <Box display="flex" alignItems="center">
          {response.case === 'correct' && (
            <>
              <CheckCircleIcon className={classes.successIcon} />
              <Typography variant="subtitle2">Success!</Typography>
            </>
          )}
          {response.case === 'hint' && (
            <>
              <WarningIcon className={classes.warningIcon} />
              <Typography variant="subtitle2">
                You're almost there, here is a hint.
              </Typography>
            </>
          )}
          {response.case === 'incorrect' && (
            <>
              <CancelIcon className={classes.errorIcon} />
              <Typography variant="subtitle2">
                Nice try
              </Typography>
            </>
          )}
        </Box>
        <Box pt={1}>
          <EditorText text={response.message} />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          { showButton() ? 
              !gs6CourseID.includes(courseId) ? 
                <Button startIcon={getButtonIcon()} className={buttonColor} onClick={handleOnClickMediaLibrary}>
                  {getButtonText()}
                </Button>
              :
                <Button className={buttonColor} onClick={handleOnClickMediaLibrary}>
                  go to media library
                </Button>
            : null
          }
        </Box>
      </Box>
    </div>
  );
};

export default memo(AnswerResponse);
