import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  gradebookSection: {
    display:"flex",
    flexDirection:"column",
    padding: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  title: {
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(3)}px 0px`,
    fontWeight:500
  },
  flexitem: {
    flex: '0 1 20%',

    [theme.breakpoints.down('md')]:{
      flex: '0 1 25%'
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 100%',
    },
  },
  lockIcon:{
    color:theme.palette.grey[400]
  },
  studyLeveltitle:{
    color:theme.palette.grey[500],
    marginLeft:theme.spacing(1)
  },
  benchmarkLogo:{
    color:theme.palette.common.white,
    backgroundColor:theme.palette.success.main
  },
  assessmentDetails_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '1.5em'
  },
  assessmentDetails_container: {
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center',
    height: '100%',
  },
  assessmentDetails_overflowHidden: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 720,
    backgroundColor: '#ffffff',
    maxWidth: 1250,
    width: '100%',
    maxHeight: '80%',
    overflow: 'hidden',
    padding: '1.5em 0 1.5em 1.5em',
    margin: '1em',
  },
  assessmentDetails_questions: {
    display: 'flex',
    gap: '1em',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflowY: 'auto',
    padding: '0 1.5em 0 0',
    marginTop: '1em'
  },
  assessmentDetails_contentQuestion: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  assessmentDetails_contentQuestion_header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#3fae2a',
    color: '#ffffff',
    padding: '0 1em',
    borderRadius: 4,
    '& > p': {
      fontWeight: 'bold',
      fontSize: 16
    }
  },
  assessmentDetails_question: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em',
  },
  assessmentDetails_questionDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 1em',
    gap: '1.5em',
    '@media(max-width: 540px)': {
      flexDirection: 'column'
    } 
  },
  assessmentDetails_answerDetails: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  multipleChoice_answerOptions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    justifyContent: 'flex-start',
    padding: 0,
  },
  multipleChoice_answerIncorrect: {
    display: 'flex',
    padding: '0.5em 1em',
    borderRadius: '0.5em',
    backgroundColor: '#fecdd2',
  },
  multipleChoice_answerCorrect: {
    display: 'flex',
    padding: '0.5em 1em',
    borderRadius: '0.5em',
    backgroundColor: '#c8e6c9',
  },
  multipleChoice_answer: {
    display: 'flex',
    padding: '0.5em 1em',
    borderRadius: '0.5em',
    backgroundColor: '#fafafa'
  },
  sequence_answerOptionsCorrect: {
    display: 'flex',
    padding: '1em',
    borderRadius: '0.5em',
    flexDirection: 'column',
    backgroundColor: '#c8e6c9',
    gap: '0.5em'
  },
  sequence_answerOptions: {
    display: 'flex',
    padding: '0em',
    flexDirection: 'column',
    borderRadius: '0.5em',
    gap: '0.5em'
  },
  sequence_answer: {
    display: 'flex',
    padding: '1em',
    borderRadius: '0.5em',
    backgroundColor: '#fafafa'
  },
  sequence_answerOptionsIncorrect: {
    display: 'flex',
    padding: '1em',
    borderRadius: '0.5em',
    backgroundColor: '#fecdd2',
    gap: '0.5em',
    flexDirection: 'column',
  },
  true_or_false_answersOptions: {
    display: 'flex',
    padding: '0',
    flexDirection: 'column',
    borderRadius: '0.5em',
    gap: '0.5em'
  },
  true_or_false_answerOptionCorrect: {
    display: 'flex',
    padding: '0.5em 1em',
    borderRadius: '0.5em',
    backgroundColor: '#c8e6c9',
    gap: '2em',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  true_or_false_answerOptionIncorrect: {
    display: 'flex',
    padding: '0.5em 1em',
    gap: '2em',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.5em',
    backgroundColor: '#fecdd2',
  },
  true_or_false_answerOption: {
    display: 'flex',
    padding: '0.5em 1em',
    gap: '2em',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.5em',
    backgroundColor: '#fafafa'
  },
  matching_answersOptions: {
    display: 'flex',
    padding: '0',
    flexDirection: 'column',
    borderRadius: '0.5em',
    gap: '0.5em'
  },
  matching_answerOptionCorrect: {
    display: 'flex',
    padding: '0.5em 1em',
    borderRadius: '0.5em',
    backgroundColor: '#c8e6c9',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  matching_answerOptionIncorrect: {
    display: 'flex',
    padding: '0.5em 1em',
    borderRadius: '0.5em',
    backgroundColor: '#fecdd2',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  matching_answerOption: {
    display: 'flex',
    padding: '0.5em 1em',
    borderRadius: '0.5em',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#fafafa'
  }
}));

export default styles;
