import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  addKeyElementButton: {
    marginBottom: theme.spacing(2),
  },
  toolbarButton: {
    marginRight: theme.spacing(1),
  },
  resourceCountChip: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  expansionPanel: {
    background: 'transparent',
    boxShadow: 'none',
  },
  formWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
    height: 'auto',
    maxHeight: 600,
    padding: theme.spacing(3),
  },
}));

export default styles;
