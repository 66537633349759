import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import GroupIcon from '@material-ui/icons/Group';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import { GROUP_STATUSES } from '../../../../lib';
import { Heading, Subtitle } from '../../../../components';

const GroupStatus = ({ status, className }) => {
  const classes = useStyles();

  const indicatorStyles = {
    [classes.statusGreen]: status === 'Active',
    [classes.statusYellow]: status === 'Inactive',
    [classes.statusRed]: status !== 'Inactive' && !['Active'].includes(status),
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={className}
    >
      <div className={clsx(classes.statusIndicator, indicatorStyles)} />
      <Typography variant="caption">{status}</Typography>
    </Box>
  );
};

function useGroupStatus(groupStatus) {
  const locked = groupStatus !== 'STARTED';
  const status = GROUP_STATUSES.filter(
    (status) => status.value === groupStatus,
  )[0];

  return { locked, status: status ? status.name : '...', GroupStatus };
}

const GroupCard = ({ group, onClick, institutionMemberRole }) => {
  const classes = useStyles();
  const { locked, status, GroupStatus } = useGroupStatus(
    group ? group.status : '',
  );

  const handleClick = () => {
    if (
      locked &&
      !['CENTER_ADMIN', 'INSTRUCTOR'].includes(institutionMemberRole)
    ) {
      return;
    }
    onClick();
  };

  return (
    <Card
      key={group ? group.id : Math.random()}
      variant="outlined"
      className={classes.groupCard}
    >
      <CardActionArea onClick={handleClick}>
        <CardHeader
          disableTypography
          avatar={
            group ? (
              <GroupIcon fontSize="large" color="primary" />
            ) : (
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
            )
          }
          title={
            group ? (
              <Heading variant="h4" component="h4">
                {group.name}
              </Heading>
            ) : (
              <Skeleton
                animation="wave"
                height={10}
                width="60%"
                style={{ marginBottom: 6 }}
              />
            )
          }
          subheader={
            group ? (
              <Box>
                <Subtitle
                  variant="subtitle2"
                  color="textSecondary"
                >{`${group.members.filter((member) => member.deletedBy === null).length} Members`}</Subtitle>
                <Subtitle
                  variant="subtitle2"
                  color="textSecondary"
                >{`Instructor: ${group.owner.first_name} ${group.owner.last_name}`}</Subtitle>
              </Box>
            ) : (
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            )
          }
          subheaderTypographyProps={{ variant: 'subtitle2' }}
          titleTypographyProps={{ variant: 'h4' }}
        />
        {group ? (
          <GroupStatus className={classes.status} status={status} />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            style={{
              position: 'absolute',
              marginBottom: 6,
              top: 0,
              right: 0,
              margin: 4,
            }}
          >
            <Skeleton
              animation="wave"
              variant="circle"
              width={9}
              height={9}
              style={{ marginRight: 4 }}
            />
            <Skeleton animation="wave" height={10} width={50} />
          </Box>
        )}
      </CardActionArea>
    </Card>
  );
};

export default GroupCard;
