import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Heading } from '../components';

const styles = (theme) => ({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleContainer} {...other}>
      <Heading variant="h3" className={classes.title}>{children}</Heading>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon fontSize="small"/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function Dialog({open, onClose, children, ...restProps}) {

  return (
      <MuiDialog onClose={onClose} open={open} {...restProps}>
        {children}
      </MuiDialog>
  );
}


export default function useDialog() {
  const [open, setOpen] = useState(false);

  const toggleDialog = () => setOpen(open => !open);

  return { isOpen: open, toggleDialog, Dialog, DialogTitle, DialogContent, DialogActions };
}