import React, { Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import {
  FormControl,
  TextField,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Paper,
} from '@material-ui/core';
import moment from 'moment';
import styles from './styles';
import { GROUP_STATUSES } from '../../../lib';
import { LicenseDetails } from '../../license';
import { updateGroupValidate, getGroupUpdateValues } from '../helpers';
import DeleteIcon from '@material-ui/icons/Delete';
import CollapsingList from '../CreateGroupForm/CollapsingList';
import { JasperDateInput } from '../../common';

const UpdateGroupForm = ({ group, onSubmit, onDelete }) => {
  const classes = styles();

  const handleProductClick = (form, product) => {
    const { value, change } = form.getFieldState('courses');
    let newValue = [];
    isAllProductCoursesSelected(product, value)
      ? (newValue = value.filter(
          (v) =>
            !product.courses
              .filter((c) => c.published)
              .map((c) => c.id)
              .includes(v),
        ))
      : (newValue = [...value, ...product.courses.map((c) => c.id)]);

    change(newValue.filter((a, b) => newValue.indexOf(a) === b));
  };

  const isAllProductCoursesSelected = (product, selectedCourses) => {
    const productCourseIds = product.courses
      .filter((c) => c.published)
      .map((c) => c.id);

    let allChosen = true;
    productCourseIds.forEach((id) => {
      if (!allChosen) return false;
      allChosen = selectedCourses.includes(id);
    });

    return allChosen;
  };

  const handleCourseClick = (course, value, input) => {
    input.onChange(value);
  };

  const groupStatusList = GROUP_STATUSES;
  const initialValues = getGroupUpdateValues(group);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={(values) => updateGroupValidate(values, group.license)}
      render={({ handleSubmit, values, form, submitting, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth className={classes.formControl}>
            <Field name="name">
              {({ input, meta }) => (
                <TextField
                  required
                  error={!pristine && meta.invalid}
                  id="name"
                  name="name"
                  variant="filled"
                  label="Group Name"
                  type="text"
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  helperText={!pristine && meta.invalid ? meta.error : ''}
                  value={input.value}
                  onChange={(value) => input.onChange(value)}
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <Typography variant="subtitle2">License Information</Typography>
          </FormControl>

          <Box mb={1}>
            <LicenseDetails
              license={group.license}
              groupSize={values.group_size}
            />
          </Box>

          <FormControl fullWidth className={classes.formControl}>
            <Field name="group_size">
              {({ input, meta }) => {
                return (
                  <TextField
                    required
                    error={meta.invalid}
                    id="group_size"
                    name="group_size"
                    variant="filled"
                    label="Number of Group Members"
                    type="number"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    helperText={meta.invalid ? meta.error : ''}
                    value={input.value}
                    onChange={(value) => input.onChange(value)}
                  />
                );
              }}
            </Field>
          </FormControl>

          <Paper className={classes.courseContainer}>
            <Typography variant="caption" component="p" gutterBottom>
              Available Courses<span style={{ color: 'red' }}>*</span>
            </Typography>
            <Typography component="p" gutterBottom>
              Select the Course(s) that you want to include in this Group.
            </Typography>

            {group.license.products.map((product) => (
              <Box key={product.id} mb={1}>
                <CollapsingList
                  heading={
                    <FormControlLabel
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={isAllProductCoursesSelected(
                            product,
                            values.courses,
                          )}
                          onChange={() => handleProductClick(form, product)}
                        />
                      }
                      label={product.name}
                      labelPlacement="end"
                    />
                  }
                >
                  <Box ml={6} display="flex" flexDirection="column">
                    {product.courses
                      .filter((c) => c.published)
                      .map((course) => (
                        <Field
                          key={course.id}
                          name="courses"
                          type="checkbox"
                          value={course.id}
                        >
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  color="primary"
                                  checked={input.checked}
                                  onChange={(value) =>
                                    handleCourseClick(product, value, input)
                                  }
                                />
                              }
                              label={course.name}
                              labelPlacement="end"
                              classes={{ label: classes.checkboxLabel }}
                            />
                          )}
                        </Field>
                      ))}
                  </Box>
                </CollapsingList>
              </Box>
            ))}
          </Paper>

          <FormControl fullWidth className={classes.formControl}>
            <Field name="start_date" type="date">
              {({ input, meta }) => (
                <Fragment>
                  <JasperDateInput
                    required
                    error={meta.invalid}
                    minDate={moment(group.created_on)}
                    maxDate={moment(group.license.expiry_date)}
                    helperText={meta.invalid ? meta.error : ''}
                    variant="inline"
                    inputVariant="filled"
                    label="Group Start Date"
                    value={input.value}
                    onChange={(date) => input.onChange(date)}
                  />
                </Fragment>
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <Field name="end_date" type="date">
              {({ input, meta }) => (
                <JasperDateInput
                  required
                  minDate={moment()}
                  maxDate={moment(group.license.expiry_date)}
                  variant="inline"
                  inputVariant="filled"
                  label="Group End Date"
                  value={input.value}
                  onChange={(date) => input.onChange(date)}
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <Field name="status">
              {({ input }) => (
                <TextField
                  required
                  id="status"
                  name="status"
                  select
                  variant="filled"
                  label="Select a status"
                  type="text"
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  value={input.value}
                  onChange={(value) => input.onChange(value)}
                >
                  {groupStatusList.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
          </FormControl>

          <div>
            <Typography variant="subtitle2" gutterBottom>
              Danger Zone
            </Typography>
            <div className={classes.dangerZone}>
              <Typography component="p" gutterBottom>
                Delete Group
              </Typography>
              <Typography component="p" gutterBottom>
                If you delete this group, you will no longer be able to access
                it.
              </Typography>
              <Button
                className={classes.deleteButton}
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={onDelete}
              >
                Delete Group
              </Button>
            </div>
          </div>

          <div className={classes.buttonFormControl}>
            <Button
              className={classes.saveButton}
              type="submit"
              disabled={submitting || pristine || !valid}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button
              type="button"
              onClick={() => form.reset(initialValues)}
              disabled={submitting}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default UpdateGroupForm;
