import React, { useState, useContext } from 'react';
import {
  Typography,
  Button,
  Box,
  Paper,
  MenuItem,
  TextField,
  withStyles,
  Tooltip,
} from '@material-ui/core';
import {
  BenchmarkForm,
  QuestionForm,
  DeleteButton,
  ExpansionPanel,
  AddButton,
} from '../../../../../../components';
import styles from './styles';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_BENCHMARK_DEVELOPMENT,
  UPDATE_BENCHMARK,
  CREATE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  PUBLISH_BENCHMARK,
  UNPUBLISH_BENCHMARK,
  SET_QUESTION_LESSON_REFERENCE,
} from '../../../../../../apollo/queries';
import { withSnackbar } from 'notistack';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { getQuestionData } from '../../../../../../lib';
import { UserConfirmationContext } from '../../../../../../providers/UserConfirmationProvider';
import { useIsManager } from '../../../IsManager';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const BenchmarkDevelopment = ({ course, benchmarkId, enqueueSnackbar }) => {
  const classes = styles();
  const isManager = useIsManager();
  const { showConfirmation } = useContext(UserConfirmationContext);
  const [addQuestion, setAddQuestion] = useState(false);
  const { data, loading, error } = useQuery(GET_BENCHMARK_DEVELOPMENT, {
    variables: { where: { id: benchmarkId } },
  });
  const refetchQueries = ['getBenchmarkDevelopoment'];
  const [updateBenchmark] = useMutation(UPDATE_BENCHMARK);
  const [createQuestion] = useMutation(CREATE_QUESTION, {
    refetchQueries,
  });
  const [updateQuestion] = useMutation(UPDATE_QUESTION, {
    refetchQueries,
  });
  const [deleteQuestion] = useMutation(DELETE_QUESTION, {
    refetchQueries,
  });
  const [publishBenchmark] = useMutation(PUBLISH_BENCHMARK, { refetchQueries });
  const [unpublishBenchmark] = useMutation(UNPUBLISH_BENCHMARK, {
    refetchQueries,
  });
  const [setLessonReference] = useMutation(SET_QUESTION_LESSON_REFERENCE);

  const [expanded, setExpanded] = React.useState(false);

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const deleteBenchmarkQuestion = async (questionId) => {
    try {
      await deleteQuestion({ variables: { id: questionId } });
      enqueueSnackbar('Successfully deleted question', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Contact Tech Support', {
        variant: 'error',
      });
    }
  };

  const handleQuestionSubmit = async (values) => {

    if (values.type === "TRUE_OR_FALSE" || values.type === 'CORRECT_OR_INCORRECT') {
      values.answers.map((answer) => {
        return answer.is_correct = JSON.parse(answer.is_correct)
      })
    }

    if (values.type === "MATCHING") {
      values.answers.map((answer) => {
        return delete answer.is_correct;
      })
    }
    try {
      if (values.id) {
        await updateQuestion({
          variables: {
            data: {
              ...getQuestionData(values),
            },
            where: {
              id: values.id,
            },
          },
        });
      } else {
        await createQuestion({
          variables: {
            data: {
              ...getQuestionData(values),
              belongsTo: { benchmark_id: benchmarkId },
            },
          },
        });
        setAddQuestion(false);
      }
      enqueueSnackbar(
        `Successfully ${values.id ? 'updated' : 'created'} question!`,
        {
          variant: 'success',
          autoHideDuration: 1500,
        },
      );
    } catch (error) {
      enqueueSnackbar(`Failed to ${values.id ? 'update' : 'create'} question`, {
        variant: 'error',
      });
    }
  };

  const handleQuestionReferenceChange = async (event, questionId) => {
    event.stopPropagation();
    const lessonId = event.target.value === 'none' ? null : event.target.value;

    await setLessonReference({
      variables: { lessonId, questionId },
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const benchmark = data.benchmark;
  const questions = benchmark.questions;

  return (
    <section className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">
          <AssessmentOutlinedIcon /> Benchmark Level {benchmark.level}
        </Typography>
        {isManager && (
          <Button
            onClick={() => {
              if (benchmark.published) {
                unpublishBenchmark({ variables: { id: benchmark.id } });
              } else {
                publishBenchmark({ variables: { id: benchmark.id } });
              }
              enqueueSnackbar(
                `Benchmark has been ${benchmark.published ? 'un' : ''
                }published!`,
                { variant: 'success', autoHideDuration: 1500 },
              );
            }}
            variant="contained"
            color="primary"
          >
            {benchmark.published ? 'Unpublish' : 'Publish'}
          </Button>
        )}
      </Box>
      <Paper className={classes.benchmarkFormWrapper}>
        <BenchmarkForm
          onSubmit={async (values) => {
            try {
              await updateBenchmark({
                variables: { data: {
                  ...values,
                  questions_per_lesson: parseInt(values.questions_per_lesson),
                }, where: { id: benchmarkId } },
              });
              enqueueSnackbar('Benchmark Updated!', {
                variant: 'success',
                autoHideDuration: 1500,
              });
            } catch (error) {
              enqueueSnackbar('Oops! something went wrong. Please try again', {
                variant: 'error',
              });
            }
          }}
          benchmark={benchmark}
        />
      </Paper>
      {questions.map((question, index) => (
        <ExpansionPanel
          expanded={expanded === `panel${index}`}
          onChange={handlePanelChange(`panel${index}`)}
          showExpandIcon
          defaultExpanded={!Boolean(question.id)}
          key={question.id ? question.id : index}
          TransitionProps={{ unmountOnExit: true }}
          summary={
            <Box display="flex" alignItems="center" width="100%">
              <QuestionAnswerOutlinedIcon />

              <Typography variant="h5" style={{ marginLeft: 8, flex: 1 }}>
                Question #{index + 1}
              </Typography>

              {!question.lesson && (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Warning</Typography>
                      <b>Students</b> and <b>Instructors</b> will <b>NOT</b> be
                      able to see this question. Select a lesson to allow this
                      question to be viewed.
                    </React.Fragment>
                  }
                >
                  <ReportProblemOutlinedIcon className={classes.warningIcon} />
                </HtmlTooltip>
              )}
              <Typography color="inherit" style={{ marginRight: 8 }}>
                Lesson Reference:
              </Typography>
              <TextField
                value={question.lesson ? question.lesson.id : 'none'}
                onChange={(event) =>
                  handleQuestionReferenceChange(event, question.id)
                }
                select
              >
                <MenuItem value="none">None</MenuItem>
                {course.lessons.map((lesson) => (
                  <MenuItem value={lesson.id} key={lesson.id}>
                    {lesson.name}
                  </MenuItem>
                ))}
              </TextField>
              <DeleteButton
                onClick={(event) => {
                  event.stopPropagation();
                  showConfirmation({
                    title: 'Delete Question',
                    message: 'Are you sure you want to delete this question?',
                    confirmed: () => deleteBenchmarkQuestion(question.id),
                  });
                }}
              />
            </Box>
          }
          details={
            <Box pl={3} pr={3}>
              <QuestionForm
                questionId={question.id}
                variant="benchmark"
                onSubmit={handleQuestionSubmit}
              />
            </Box>
          }
        />
      ))}
      {addQuestion && (
        <ExpansionPanel
          showExpandIcon
          defaultExpanded={true}
          summary={
            <Box display="flex" alignItems="center" width="100%">
              <QuestionAnswerOutlinedIcon />

              <Typography variant="h5" style={{ marginLeft: 8 }}>
                Question #{questions.length + 1}
              </Typography>
              <DeleteButton
                style={{ marginLeft: 'auto' }}
                onClick={(event) => {
                  event.stopPropagation();
                  setAddQuestion(false);
                }}
              />
            </Box>
          }
          details={
            <Box pl={3} pr={3}>
              <QuestionForm
                questionId={null}
                variant="benchmark"
                onSubmit={handleQuestionSubmit}
              />
            </Box>
          }
        />
      )}
      <Box mt={2} mb={2}>
        <AddButton
          title="Add Question"
          disabled={addQuestion}
          onClick={() => setAddQuestion(true)}
        />
      </Box>
    </section>
  );
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default withSnackbar(BenchmarkDevelopment);
