import React, { useState } from 'react';
import { Button, TextField, MenuItem, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { ALL_USER_ROLES } from '../../../../lib';
import styles from './styles';
import config from '../../manage_jasperactive_config.json';

const UserSearchBar = ({ search, pageSize }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const [value, setValue] = useState(search || '');
  const [_pageSize, setPageSize] = useState(pageSize);
  const [_filter, setFilter] = useState('ALL');
  const classes = styles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const clearSearch = () => {
    setValue('');
    setPageSize(config.PAGE_SIZE);
    setFilter('ALL');
    history.push(match.path);
  };

  return (
    <div className={classes.root}>
      <TextField
        className={classes.searchInput}
        size="small"
        variant="outlined"
        label="Search"
        name="search"
        id="search"
        onChange={handleChange}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        className={classes.viewInput}
        size="small"
        variant="outlined"
        label="View"
        name="pageSize"
        id="pageSize"
        onChange={handlePageSizeChange}
        value={_pageSize}
        select
      >
        {new Array(5).fill(config.PAGE_SIZE).map((size, index) => (
          <MenuItem key={index} value={size + index * 10}>
            {size + index * 10}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        className={classes.filterInput}
        size="small"
        variant="outlined"
        label="Role"
        name="role"
        id="role"
        onChange={handleFilterChange}
        value={_filter}
        select
      >
        <MenuItem value={'ALL'}>All</MenuItem>
        {ALL_USER_ROLES.map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </TextField>
      <Button
        className={classes.searchButton}
        variant="outlined"
        color="primary"
        onClick={() =>
          history.push(
            `${match.path}/?search=${value}&pageSize=${_pageSize}&filter=${_filter}`,
          )
        }
      >
        Search
      </Button>
      {(value !== '' ||
        _pageSize !== config.PAGE_SIZE ||
        _filter !== 'ALL') && (
        <Button onClick={() => clearSearch()}>Clear</Button>
      )}
    </div>
  );
};

export default UserSearchBar;
