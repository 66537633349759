import React, { Fragment } from 'react';
import {
  Avatar,
  Typography,
  Box,
  Paper,
  makeStyles,
  Divider,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { getReadableRole } from '../../../lib';

const styles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
}));

const UserInstitutions = ({
  institutions = [],
  currentInstitution,
  onClick,
}) => {
  const classes = styles();

  return (
    <Paper className={classes.container}>
      {institutions.map((institution) => {
        if (currentInstitution && institution.id === currentInstitution.id)
          return null;
        return (
          <Fragment key={institution.id}>
            <Box
              p={2}
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => onClick(institution)}
            >
              <Avatar style={{ background: '#BF1212', marginRight: 8 }}>
                <StarIcon />
              </Avatar>

              <div style={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">{institution.name}</Typography>
                <Typography
                  variant="body2"
                  style={{ color: 'rgba(0,0,0,0.54)' }}
                >
                  Role: {getReadableRole(institution.members[0].role)}
                </Typography>
              </div>
            </Box>
            <Divider />
          </Fragment>
        );
      })}
    </Paper>
  );
};

export default UserInstitutions;
