import React, { useState, useContext, Fragment } from 'react';
import { Box, Typography, Button, Paper, Chip } from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import {
  StudyProjectForm,
  DeleteButton,
  CheckUserAction,
  AddButton,
  ExpansionPanel,
  QuestionForm,
  DarkChip,
} from '../../../../../../components';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ListIcon from '@material-ui/icons/List';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  CMS_GET_STUDY_PROJECT,
  UPDATE_STUDY_PROJECT,
  PUBLISH_STUDY_PROJECT,
  UNPUBLISH_STUDY_PROJECT,
  DELETE_STUDY_PROJECT,
  ADD_CASE_STUDY,
  CREATE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  PUBLISH_CASE_STUDY,
  UNPUBLISH_CASE_STUDY,
  DELETE_CASE_STUDY,
} from '../../../../../../apollo/queries';
import styles from './styles';
import { withSnackbar } from 'notistack';
import { getQuestionData } from '../../../../../../lib';
import { UserConfirmationContext } from '../../../../../../providers/UserConfirmationProvider';
import { useIsManager } from '../../../IsManager';
import QuestionResources from './QuestionResources';
import ProjectKeyElements from './ProjectKeyElements';

const CaseStudyChip = ({ caseStudy }) => {
  if (!caseStudy.case && caseStudy.reinforcedQuestions.length >= 1) {
    return <Chip size="small" label="No Case Scenario" />;
  } else if (caseStudy.case && caseStudy.reinforcedQuestions.length === 0) {
    return <Chip size="small" label="No Reinforcement Questions" />;
  } else if (!caseStudy.case && caseStudy.reinforcedQuestions.length === 0) {
    return <DarkChip size="small" label="No Content" />;
  } else {
    return null;
  }
};

const StudyProjectDevelopment = ({
  course,
  studyProjectId,
  enqueueSnackbar,
  setShowCurriculum,
}) => {
  const classes = styles();
  const isManager = useIsManager();
  const { showConfirmation } = useContext(UserConfirmationContext);
  const [showQuestionResources, setShowQuestionResources] = useState(null);
  const [addReinforcedQuestion, setAddReinforcedQuestion] = useState(false);
  const [showKeyElements, setShowKeyElements] = useState(false);

  const { data, loading, error } = useQuery(CMS_GET_STUDY_PROJECT, {
    variables: { where: { id: studyProjectId } },
  });
  const refetchQueries = ['getCMSStudyProject'];
  const [updateStudyProject] = useMutation(UPDATE_STUDY_PROJECT, {
    refetchQueries,
  });
  const [publishStudyProject] = useMutation(PUBLISH_STUDY_PROJECT, {
    refetchQueries,
  });
  const [unpublishStudyProject] = useMutation(UNPUBLISH_STUDY_PROJECT, {
    refetchQueries,
  });
  const [deleteStudyProject] = useMutation(DELETE_STUDY_PROJECT, {
    refetchQueries: [...refetchQueries, 'getLessonStudyProjects'],
  });
  const [publishCaseStudy] = useMutation(PUBLISH_CASE_STUDY, {
    refetchQueries,
  });
  const [unpublishCaseStudy] = useMutation(UNPUBLISH_CASE_STUDY, {
    refetchQueries,
  });
  const [deleteCaseStudy] = useMutation(DELETE_CASE_STUDY, {
    refetchQueries: [...refetchQueries, 'getLessonStudyProjects'],
  });
  const [addCaseStudy] = useMutation(ADD_CASE_STUDY, { refetchQueries });
  const [createQuestion] = useMutation(CREATE_QUESTION, { refetchQueries });
  const [updateQuestion] = useMutation(UPDATE_QUESTION, { refetchQueries });
  const [deleteQuestion] = useMutation(DELETE_QUESTION, { refetchQueries });
  const [deleteProject, setDeleteProject] = useState(null);
  const [expanded, setExpanded] = React.useState(false);

  const handleCaseStudyPanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleStudyProjectSave = async (values) => {
    const variables = {
      data: {
        level: values.level,
        introduction: values.introduction,
        study_project_number: parseInt(values.study_project_number),
      },
      where: {
        id: values.id,
      },
    };

    try {
      await updateStudyProject({ variables });
      enqueueSnackbar('Successfully updated Study Project!', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar(
        'Oops! Something went wrong updating. Please refresh your browser and try again. If problem persist contact Tech Support',
      );
    }
  };

  const handleOnDelete = async () => {
    await deleteStudyProject({ variables: { id: studyProjectId } });
    enqueueSnackbar('Study Project has be removed', { autoHideDuration: 1500 });
    setShowCurriculum(null);
  };

  const handleCaseStudyCreation = async (projectId) => {
    try {
      await addCaseStudy({ variables: { studyProjectId: projectId } });
      enqueueSnackbar('Added Case Study', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar(
        'Oops! Something went wrong. refresh the page and try again. if problem persist contact Tech Support.',
        { variant: 'error' },
      );
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      await deleteQuestion({ variables: { id: questionId } });
      enqueueSnackbar('Question has been deleted!', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar(
        'Unable to delete Question. If problem persists contact Tech Support',
      );
    }
  };

  const handleDeleteCaseStudy = async (caseStudyId) => {
    try {
      await deleteCaseStudy({ variables: { id: caseStudyId } });
      enqueueSnackbar('Successfully Deleted Case Study!', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar(
        'Failed! Please contact Tech Support if problem persists.',
        { variant: 'error' },
      );
    }
  };

  const handleCaseQuestionSave = async (
    values,
    case_study_id,
    is_reinforced,
  ) => {
    try {
      if (values.id) {
        await updateQuestion({
          variables: {
            data: {
              ...getQuestionData(values),
            },
            where: {
              id: values.id,
            },
          },
        });
        enqueueSnackbar('Successfully updated Case Study!', {
          variant: 'success',
          autoHideDuration: 1500,
        });
      } else {
        await createQuestion({
          variables: {
            data: {
              ...getQuestionData(values),
              belongsTo: { case_study_id, is_reinforced },
            },
          },
        });
        enqueueSnackbar('Successfully created Case Scenario!', {
          variant: 'success',
          autoHideDuration: 1500,
        });
      }
    } catch (error) {
      enqueueSnackbar(
        `Failed to ${values.id ? 'update' : 'create'} Case Study`,
        {
          variant: 'error',
        },
      );
    }
    if (addReinforcedQuestion) {
      setAddReinforcedQuestion(false);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const studyProject = data.studyProject;
  return (
    <section className={classes.container}>
      <CheckUserAction
        open={Boolean(deleteProject)}
        onClose={() => setDeleteProject(null)}
        title="Delete Study Project"
        content="Are you sure you want to delete this Study Project?"
        onClick={() => handleOnDelete()}
      />
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <Typography variant="h4" style={{ marginRight: 'auto' }}>
          <AssignmentOutlinedIcon /> Study Project Level {studyProject.level}
        </Typography>
        {isManager && (
          <Fragment>
            <Button
              style={{ marginRight: 16 }}
              onClick={async () => {
                const variables = { id: studyProjectId };
                if (studyProject.published) {
                  await unpublishStudyProject({ variables });
                } else {
                  await publishStudyProject({ variables });
                }
                enqueueSnackbar(
                  `Study Project has been ${studyProject.published ? 'un' : ''
                  }published!`,
                  { variant: 'success', autoHideDuration: 1500 },
                );
              }}
              variant="contained"
              color="primary"
            >
              {studyProject.published ? 'Unpublish' : 'Publish'}
            </Button>
            <Button
              onClick={() => setDeleteProject(true)}
              variant="contained"
              color="primary"
            >
              Delete Study Project
            </Button>
          </Fragment>
        )}
      </Box>
      <Paper className={classes.studyProjectFormWrapper}>
        <StudyProjectForm
          lessonId={studyProject.lesson.id}
          studyProject={studyProject}
          maxLevel={course.levels}
          onSubmit={(values) => handleStudyProjectSave(values)}
          introductionVariant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowKeyElements(true)}
        >
          Manage Key Elements
        </Button>
        <ProjectKeyElements
          lessons={course.lessons}
          open={showKeyElements}
          onClose={() => setShowKeyElements(false)}
          project={studyProject}
        />
      </Paper>
      {studyProject.caseStudies.map((caseStudy, index) => (
        <ExpansionPanel
          expanded={expanded === `panel${index}`}
          onChange={handleCaseStudyPanelChange(`panel${index}`)}
          key={caseStudy.id}
          showExpandIcon
          summary={
            <Box display="flex" alignItems="center" width="100%">
              <DragIndicatorIcon style={{ marginRight: 8 }} />
              <Typography variant="h6" style={{ marginRight: 8 }}>
                Case Study #{index + 1}
              </Typography>
              <CaseStudyChip caseStudy={caseStudy} />
              {isManager && (
                <Fragment>
                  <Button
                    style={{ marginLeft: 'auto', marginRight: 16 }}
                    onClick={async (event) => {
                      event.stopPropagation();
                      const variables = { id: caseStudy.id };
                      if (caseStudy.published) {
                        await unpublishCaseStudy({ variables });
                      } else {
                        await publishCaseStudy({ variables });
                      }
                      enqueueSnackbar(
                        `Case Study has been ${caseStudy.published ? 'un' : ''
                        }published!`,
                        { variant: 'success', autoHideDuration: 1500 },
                      );
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {caseStudy.published ? 'Unpublish' : 'Publish'}
                  </Button>
                  <DeleteButton
                    onClick={(event) => {
                      event.stopPropagation();
                      showConfirmation({
                        title: 'Delete Case Study',
                        message:
                          'Are you sure you want to delete this Case Study?',
                        confirmed: () => handleDeleteCaseStudy(caseStudy.id),
                      });
                    }}
                  />
                </Fragment>
              )}
            </Box>
          }
          details={
            <Box pr={2} pl={2} pb={2}>
              <ExpansionPanel
                showExpandIcon
                className={classes.caseStudyPanel}
                summaryProps={{
                  className: classes.caseSummaryPanel,
                }}
                detailProps={{
                  className: classes.caseDetailsPanel,
                }}
                TransitionProps={{ unmountOnExit: true }}
                summary={
                  <Box display="flex" alignItems="center" width="100%">
                    <ListIcon style={{ marginRight: 8 }} />
                    <Typography variant="subtitle1" fontWeight="bold">
                      Case Scenario
                    </Typography>
                  </Box>
                }
                details={
                  <Box>
                    <QuestionForm
                      variant="case study"
                      questionId={caseStudy.case ? caseStudy.case.id : null}
                      onSubmit={(values) =>
                        handleCaseQuestionSave(values, caseStudy.id, false)
                      }
                    />
                    <Button
                      disabled={caseStudy.case ? false : true}
                      onClick={() =>
                        setShowQuestionResources(caseStudy.case.id)
                      }
                    >
                      Link Resources
                    </Button>
                    {caseStudy.case && (
                      <QuestionResources
                        lessons={course.lessons}
                        open={showQuestionResources === caseStudy.case.id}
                        onClose={() => setShowQuestionResources(null)}
                        question={caseStudy.case}
                        lessonId={studyProject.lesson.id}
                      />
                    )}
                  </Box>
                }
              />
              <ExpansionPanel
                showExpandIcon
                className={classes.caseStudyPanel}
                summaryProps={{
                  className: classes.caseSummaryPanel,
                }}
                detailProps={{
                  className: classes.caseDetailsPanel,
                }}
                TransitionProps={{ unmountOnExit: true }}
                summary={
                  <Box display="flex" alignItems="center" width="100%">
                    <ListIcon style={{ marginRight: 8 }} />
                    <Typography variant="subtitle1" fontWeight="bold">
                      Reinforcement Questions
                    </Typography>
                  </Box>
                }
                details={
                  <Box>
                    {caseStudy.reinforcedQuestions.length === 0 &&
                      !addReinforcedQuestion && (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="div"
                          style={{ marginBottom: 8 }}
                        >
                          No Reinforcement Questions. Click add question to get
                          started.
                        </Typography>
                      )}
                    {caseStudy.reinforcedQuestions.map((question, index) => (
                      <ExpansionPanel
                        key={question.id}
                        showExpandIcon
                        className={classes.caseStudyPanel}
                        summaryProps={{
                          className: classes.caseSummaryPanel,
                        }}
                        detailProps={{
                          className: classes.caseDetailsPanel,
                        }}
                        TransitionProps={{ unmountOnExit: true }}
                        summary={
                          <Box display="flex" alignItems="center" width="100%">
                            <DragIndicatorIcon />

                            <Typography variant="h5" style={{ marginLeft: 8 }}>
                              Question #{index + 1}
                            </Typography>
                            <DeleteButton
                              style={{ marginLeft: 'auto' }}
                              onClick={(event) => {
                                event.stopPropagation();
                                showConfirmation({
                                  title: 'Delete Reinforced Question',
                                  message:
                                    'Are you sure you want to delete this Question?',
                                  confirmed: () => {
                                    handleDeleteQuestion(question.id);
                                  },
                                });
                              }}
                            />
                          </Box>
                        }
                        details={
                          <Box>
                            <QuestionForm
                              variant="reinforced"
                              questionId={question.id}
                              onSubmit={(values) =>
                                handleCaseQuestionSave(
                                  values,
                                  caseStudy.id,
                                  true,
                                )
                              }
                            />
                            <Button
                              onClick={() =>
                                setShowQuestionResources(question.id)
                              }
                            >
                              Link Resources
                            </Button>
                            <QuestionResources
                              lessons={course.lessons}
                              open={showQuestionResources === question.id}
                              onClose={() => setShowQuestionResources(null)}
                              question={question}
                              lessonId={studyProject.lesson.id}
                            />
                          </Box>
                        }
                      />
                    ))}
                    {addReinforcedQuestion && (
                      <ExpansionPanel
                        defaultExpanded
                        showExpandIcon
                        className={classes.caseStudyPanel}
                        summaryProps={{
                          className: classes.caseSummaryPanel,
                        }}
                        detailProps={{
                          className: classes.caseDetailsPanel,
                        }}
                        TransitionProps={{ unmountOnExit: true }}
                        summary={
                          <Box display="flex" alignItems="center" width="100%">
                            <DragIndicatorIcon />

                            <Typography variant="h5" style={{ marginLeft: 8 }}>
                              Question #
                              {caseStudy.reinforcedQuestions.length + 1}
                            </Typography>
                            <DeleteButton
                              style={{ marginLeft: 'auto' }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setAddReinforcedQuestion(false);
                              }}
                            />
                          </Box>
                        }
                        details={
                          <QuestionForm
                            variant="reinforced"
                            onSubmit={(values) => {
                              handleCaseQuestionSave(
                                values,
                                caseStudy.id,
                                true,
                              );
                            }}
                            onCancel={() => setAddReinforcedQuestion(false)}
                          />
                        }
                      />
                    )}
                    <AddButton
                      title="Add Question"
                      disabled={addReinforcedQuestion}
                      onClick={() => setAddReinforcedQuestion(true)}
                    />
                  </Box>
                }
              />
            </Box>
          }
        />
      ))}
      <AddButton
        title="Add Case Study"
        onClick={() => handleCaseStudyCreation(studyProject.id)}
      />
    </section>
  );
};

export default withSnackbar(StudyProjectDevelopment);
