import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_BENCHMARKS } from '../../../apollo/queries';
import { LoadingScreen } from '../../../components';
import { getAssessmentQuestions, getTotalAssessmentTime } from '../helpers';
import { useContext } from 'react';
import { BenchmarkProgressContext } from './BenchmarkProgressProvider';
import { useGroup } from '../../GroupDashboard/providers/GroupProvider';

export const BenchmarkContext = React.createContext();

const BenchmarkProvider = ({ courseId, benchmarkLevel, children }) => {
  const { complete_benchmark_ids } = useContext(BenchmarkProgressContext);
  let benchmarks = [];

  const { data, loading, error } = useQuery(GET_BENCHMARKS, {
    variables: { courseId, level: benchmarkLevel },
  });

  const group = useGroup();

  if (loading) return <LoadingScreen>Loading Assessment...</LoadingScreen>;
  if (error) return <p>Error...</p>;

  benchmarks = data.benchmarks.filter(
    (benchmark) => benchmark.published !== false && !complete_benchmark_ids.includes(benchmark.id),
  );

  const state = {
    benchmarks,
    questions: getAssessmentQuestions(benchmarks, group?.assessmentCustomSettings),
    time: getTotalAssessmentTime(benchmarks, group?.assessmentCustomSettings),
  };

  return (
    <BenchmarkContext.Provider value={state}>
      {children}
    </BenchmarkContext.Provider>
  );
};

export default BenchmarkProvider;
