import React, { Fragment, useState } from 'react';
import { AddButton } from '../../../../../../../components';
import { Paper, Modal, Typography } from '@material-ui/core';
import { KeyElementContainer } from '../../../../../../../containers';
import styles from './styles';
import KeyElementForm from './KeyElementForm';

const AddKeyElement = ({ studyProjectId, enqueueSnackbar }) => {
  const classes = styles();
  const [showForm, setShowForm] = useState(false);

  const handleSubmit = async (values, addKeyElement) => {
    await addKeyElement.mutation({
      variables: {
        data: { ...values, 
          order_key: parseInt(values.order_key),
          study_project_id: studyProjectId },
      },
      refetchQueries: ['getKeyElements'],
    });
    enqueueSnackbar(`Successfully added ${values.name}`, {
      variant: 'success',
      autoHideDuration: 1500,
    });
    setShowForm(false);
  };

  return (
    <Fragment>
      <AddButton
        title="Key Element"
        onClick={() => setShowForm(true)}
        className={classes.addKeyElementButton}
      />
      <Modal open={showForm} onClose={() => setShowForm(false)}>
        <Paper className={classes.formWrapper} elevation={0}>
          <Typography variant="h6" gutterBottom>
            New Key Element
          </Typography>
          <KeyElementContainer>
            {({ addKeyElement }) => (
              <KeyElementForm
                onSubmit={(values) => handleSubmit(values, addKeyElement)}
              />
            )}
          </KeyElementContainer>
        </Paper>
      </Modal>
    </Fragment>
  );
};

export default AddKeyElement;
