import React, { useContext } from 'react';
import { Box, Paper } from '@material-ui/core';
import { Heading, UpdateGroupForm } from '../../../../components';
import { withSnackbar } from 'notistack';
import styles from './styles';
import { UserConfirmationContext } from '../../../../providers/UserConfirmationProvider';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_GROUP, DELETE_GROUP } from '../../../../apollo/queries';
import { useGroup } from '../../providers/GroupProvider';
import { isInstructor } from '../../../../lib';
import { Redirect } from 'react-router-dom';
import { useGroupMember } from '../../providers/GroupMemberProvider';

const GroupSettings = ({ match, history, location, enqueueSnackbar }) => {
  const group = useGroup();
  const member = useGroupMember();
  const classes = styles();
  const { showConfirmation } = useContext(UserConfirmationContext);
  const [updateGroup] = useMutation(UPDATE_GROUP, {
    refetchQueries: ['getGroupById'],
  });
  const [deleteGroup] = useMutation(DELETE_GROUP, {
    refetchQueries: ['getUserInstitutionGroups'],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (values) => {
    await updateGroup({
      variables: {
        data: { ...values, group_size: +values.group_size },
        where: { id: group.id },
      },
    });
    enqueueSnackbar(`Updated ${group.name}`, {
      variant: 'success',
      autoHideDuration: 1500,
    });
  };

  const handleDelete = () => {
    showConfirmation({
      title: `Delete ${group.name}`,
      message:
        'Any Progress made to this Group will no longer be accessible. All data will remain in Jasperactive',
      confirmed: async () => {
        await deleteGroup({
          variables: { id: group.id },
        });
        enqueueSnackbar(`${group.name} has been removed`, {
          autoHideDuration: 1500,
        });
        history.push('/');
      },
    });
  };

  if (!isInstructor(member.role)) {
    return (
      <Redirect
        to={{
          pathname: `/group/${match.params.groupId}`,
          from: location.state,
        }}
      />
    );
  }

  return (
    <div className={classes.settingsContainer}>
      <Paper className={classes.settingsPaper}>
        <Box pb={2}>
          <Heading variant="h2" component="h2">
            Group Settings
          </Heading>
        </Box>

        <UpdateGroupForm
          group={group}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </Paper>
    </div>
  );
};

export default withSnackbar(GroupSettings);
