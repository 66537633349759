import React, { useState } from 'react';
import {
  Typography,
  Link,
  Paper,
  Modal,
  Container,
  Toolbar,
  AppBar,
  Box,
} from '@material-ui/core';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import styles from './styles';
import { LibraryCards, LibraryBook, CloseButton, LibraryCardsClip } from '../../../../components';

const ButtonLink = ({ onClick, children, ...restProps }) => {
  return (
    <Link component="button" variant="body2" onClick={onClick} {...restProps}>
      <EmojiObjectsOutlinedIcon fontSize="small" style={{ marginRight: 4 }} />{' '}
      {children}
    </Link>
  );
};

const KeyElements = ({ keyElements }) => {
  const [showKeyElement, setShowKeyElement] = useState(null);
  const classes = styles();
  if (keyElements.length === 0) return null;

  return (
    <div>
      <Typography variant="h6" className={classes.keyElementTitle}>
        Key Elements
      </Typography>
      {keyElements.map((keyElement) => (
        <ButtonLink
          key={keyElement.id}
          onClick={() => setShowKeyElement(keyElement.id)}
          className={classes.buttonLink}
        >
          {keyElement.name}
        </ButtonLink>
      ))}
      <Modal
        open={Boolean(showKeyElement)}
        onClose={() => setShowKeyElement(null)}
        aria-labelledby="key-element"
        aria-describedby="showing-key-element"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <KeyElementBody
          keyElement={keyElements.find(
            (keyElement) => keyElement.id === showKeyElement,
          )}
          onClose={() => setShowKeyElement(null)}
        />
      </Modal>
    </div>
  );
};

export default KeyElements;

const KeyElementBody = ({ keyElement, onClose }) => {
  const classes = styles();
  if (!keyElement) return null;

  return (
    <Paper className={classes.keyElementBody}>
      <Container className={classes.keyElementBody_container} maxWidth="md">
        <AppBar position='relative' className={classes.appBar} color="default">
          <Container maxWidth="md">
            <Toolbar className={classes.appBarToolbar}>
              <Box>
                <Typography style={{fontSize: '24px', marginBottom: '0.25em'}} variant="h6">{keyElement.name}</Typography>
                <Typography variant='subtitle1'>
                  {keyElement.type === 'quickdeck' && (
                    keyElement.cards.length + ' Quickdeck(s)'
                  )}
                  {keyElement.type === 'ebook' && (
                    keyElement.pages.length + ' Ebook page(s)'
                  )}
                  {keyElement.type === 'quickclip' && (
                      keyElement.clip_cards.length + ' Quickclip(s)'
                  )}
                </Typography>
              </Box>
              <CloseButton
                color="primary"
                iconProps={{ fontSize: 'large' }}
                onClick={onClose}
              />
            </Toolbar>
          </Container>
        </AppBar>
        {keyElement.type === 'quickdeck' && (
          <LibraryCards cards={keyElement.cards} />
        )}
        {keyElement.type === 'ebook' && (
          <LibraryBook pages={keyElement.pages} />
        )}
        {keyElement.type === 'quickclip' && (
          <LibraryCardsClip cards={keyElement.clip_cards} />
        )}
      </Container>
    </Paper>
  );
};
