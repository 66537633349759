import React from 'react';
import { Container, Grid, Button, Toolbar } from '@material-ui/core';
import { LoadingScreen, InstitutionMembers } from '../../../../components';
import { useQuery } from '@apollo/react-hooks';
import { GET_INSTITUTION_BY_ID } from '../../../../apollo/queries';
import InstitutionInformation from './InstitutionInformation';
import InstitutionLicenses from './InstitutionLicenses';
import InstitutionActivity from './InstitutionActivity';

const Institution = ({ match, history }) => {
  const institutionId = match.params.institutionId;

  const { data, loading, error } = useQuery(GET_INSTITUTION_BY_ID, {
    variables: { where: { id: institutionId } },
  });

  if (error) return <p>error...</p>;

  if (loading) return <LoadingScreen />;

  return (
    <Container>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{ margin: `16px 0px` }}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <InstitutionInformation institution={data.institution} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InstitutionLicenses institution={data.institution} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InstitutionMembers institutionId={data.institution.id} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InstitutionActivity institution={data.institution} />
        </Grid>
      </Grid>
      <Toolbar />
    </Container>
  );
};

export default Institution;
