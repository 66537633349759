import React, { useState } from 'react';
import {
  Typography,
  Button,
  Container,
  Box,
  Modal,
  Tabs,
  Tab,
  Grid,
  Paper,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ProductForm } from '../../../../components';
import styles from './styles';
import { withSnackbar } from 'notistack';
import { useIsManager } from '../IsManager';
import ProductList from './ProductList';
import FreeOffers from '../FreeOffers';

const TabPanel = ({ value, index, children }) => {
  if (value !== index) return null;
  return <Box flexGrow={1}>{children}</Box>;
};

const Dashboard = ({ match, history, enqueueSnackbar }) => {
  const classes = styles();
  const [showForm, setShowForm] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const isManager = useIsManager();

  return (
    <section className={classes.root}>
      <Modal
        open={showForm}
        onClose={() => setShowForm(false)}
        disableEnforceFocus={true}
      >
        <Paper className={classes.modal}>
          <ProductForm onCancel={() => setShowForm(false)} />
        </Paper>
      </Modal>
      <Container maxWidth="md" className={classes.container}>
        <Box display="flex" alignItems="center" mb={3}>
          <Typography className={classes.title} variant="h4">
            Content Management System
          </Typography>
          {isManager && currentTab === 0 && (
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              onClick={() => setShowForm(true)}
            >
              Create Product
            </Button>
          )}
        </Box>
        <Tabs
          value={currentTab}
          onChange={(event, value) => setCurrentTab(value)}
          indicatorColor="primary"
          textColor="inherit"
          className={classes.tabs}
        >
          <Tab label="Products" />
          <Tab label="Library" />
          {isManager && <Tab label="Free Offers" />}
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          <ProductList match={match} history={history} />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`${match.url}/ebook`)}
              >
                View E-Books
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                disabled
                onClick={() => history.push(`${match.url}/video`)}
              >
                View Videos
              </Button>
            </Grid>
          </Grid>
        </TabPanel>
        {isManager && (
          <TabPanel value={currentTab} index={2}>
            <FreeOffers />
          </TabPanel>
        )}
      </Container>
    </section>
  );
};

export default withSnackbar(Dashboard);
