import { useQuery } from '@apollo/react-hooks';
import { 
  Box,
  Card, 
  CircularProgress,
  Typography 
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import SelectionImmersiveReader from '../../../../components/immersiveReader/SelectionImmersiveReader';
import styles from './styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { CloseButton } from '../../../../components';

const AssessmentDetails = ({groupId, query, viewer, course_id, handleOnClickViewDetails, summaryAssessmentId}) => {
  const classes = styles();

  const {data, loading, error} = useQuery(query, {
    variables: {
      where: {
        user_id: viewer,
        course_id: course_id,
        group_id: groupId
      }
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return (
      <Card className={classes.assessmentDetails_overflowHidden}>
        <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        width='100%'
        height='100%'
        >
          <CircularProgress color='primary' />
        </Box>
      </Card>
    )
  };

  if (error) {
    return (
      <Card className={classes.assessmentDetails_overflowHidden}>
        <Typography variant='h3' >Your Assessment Details</Typography>
      </Card>
    )
  }

  const getAnswerIds = (answer) => {
    const userAnswer = JSON.parse(answer);
    const answerIds = Object.keys(userAnswer).map((answer) => {
      return userAnswer[`${answer}`];
    });

    return answerIds;
  }

  const normalizeTrueOrFalseAnswers = (answer) => {
    const userAnswer = JSON.parse(answer);

    const answers = Object.keys(userAnswer).map((answerId) => {
      return {
        ...userAnswer[answerId],
        answerId
      }
    });

    return answers;
  }

  return (
    <Card className={classes.assessmentDetails_overflowHidden}>
      <Box
      className={classes.assessmentDetails_header}
      >
        <Typography variant='h2' >Your Assessment Details</Typography>
        <CloseButton onClick={handleOnClickViewDetails}/>
      </Box>
      <Box className={classes.assessmentDetails_questions}>
        <Box className={classes.assessmentDetails_contentQuestion}>
          {
            data.summaryResults.filter((result) => result.summary.id === summaryAssessmentId).map((summaryResult) => {
              return (
                <Box key={summaryResult.id}>
                  <Box className={classes.assessmentDetails_contentQuestion_header}>
                      <p>Question</p>
                      <p>Status</p>
                  </Box>
                  <Box className={classes.assessmentDetails_question}>
                    <SelectionImmersiveReader htmlText={summaryResult.question.question}/>
                    {
                      summaryResult.result ? <CheckCircleOutlineIcon style={{color: '#209251'}}/> : <HighlightOffIcon color='primary' />
                    }
                  </Box>
                  <Box className={classes.assessmentDetails_questionDetails}>
                    <Box
                    className={classes.assessmentDetails_answerDetails}
                    >
                      <Typography variant='subtitle1' >Your Answers</Typography>
                      {
                        summaryResult.question.type === 'MULTIPLE_CHOICE' && 
                          <MultipleChoice 
                          isCorrectAnswer={false} 
                          question={summaryResult.question} 
                          userAnswer={getAnswerIds(summaryResult.answer)} 
                          />
                      }
                      {
                        summaryResult.question.type === 'SEQUENCE' && 
                          <Sequence 
                          isCorrectAnswer={false} 
                          question={summaryResult.question}
                          userAnswer={getAnswerIds(summaryResult.answer)}
                          questionResult={summaryResult.result}
                          />
                      }
                      {
                        summaryResult.question.type === 'TRUE_OR_FALSE' &&
                          <TrueOrFalse
                            isCorrectAnswer={false}
                            userAnswer={normalizeTrueOrFalseAnswers(summaryResult.answer)}
                            question={summaryResult.question}
                            type={'TRUE_OR_FALSE'}
                          />
                      }
                      {
                        summaryResult.question.type === 'CORRECT_OR_INCORRECT' &&
                          <TrueOrFalse
                            isCorrectAnswer={false}
                            userAnswer={normalizeTrueOrFalseAnswers(summaryResult.answer)}
                            question={summaryResult.question}
                            type={'CORRECT_OR_INCORRECT'}
                          />
                      }
                      {
                        summaryResult.question.type === 'MATCHING' &&
                          <Matching
                          isCorrectAnswer={false}
                          userAnswer={JSON.parse(summaryResult.answer)}
                          question={summaryResult.question}
                          />
                      }
                    </Box>
                    <Box 
                      className={classes.assessmentDetails_answerDetails}
                    >
                      <Typography variant='subtitle1' >Correct Answers</Typography>
                      {
                        summaryResult.question.type === 'MULTIPLE_CHOICE' && 
                          <MultipleChoice 
                          isCorrectAnswer={true} 
                          question={summaryResult.question} 
                          userAnswer={getAnswerIds(summaryResult.answer)} 
                          />
                      }
                      {
                        summaryResult.question.type === 'SEQUENCE' && 
                          <Sequence 
                          isCorrectAnswer={true} 
                          question={summaryResult.question}
                          userAnswer={getAnswerIds(summaryResult.answer)}
                          questionResult={summaryResult.result}
                          />
                      }
                      {
                        summaryResult.question.type === 'TRUE_OR_FALSE' &&
                          <TrueOrFalse
                            isCorrectAnswer={true}
                            question={summaryResult.question}
                            type={'TRUE_OR_FALSE'}
                          />
                      }
                      {
                        summaryResult.question.type === 'CORRECT_OR_INCORRECT' &&
                          <TrueOrFalse
                            isCorrectAnswer={true}
                            question={summaryResult.question}
                            type={'CORRECT_OR_INCORRECT'}
                          />
                      }
                      {
                        summaryResult.question.type === 'MATCHING' &&
                        <Matching
                        isCorrectAnswer={true}
                        userAnswer={JSON.parse(summaryResult.answer)}
                        question={summaryResult.question}
                        />
                      }
                    </Box>
                  </Box>
                </Box>
              )
            }) 
          }
        </Box>
      </Box>
    </Card>
  )
};


const MultipleChoice = ({question, userAnswer, isCorrectAnswer}) => {
  const classes = styles();

  if (isCorrectAnswer) {
    return (
      <ul className={classes.multipleChoice_answerOptions}>
        {
          question.answers.map((answerItem, index) => {
            if (answerItem.is_correct) {
              return (
                <li key={index} className={classes.multipleChoice_answer}>
                  <SelectionImmersiveReader htmlText={answerItem.answer}/>
                </li>
              )
            } else {
              return null;
            }
          })
        }
      </ul>
    )
  } else {
    return (
      <ul className={classes.multipleChoice_answerOptions}>
        {
          question.answers.map((answerItem, index) => {
            if (userAnswer.includes(answerItem.id) && !answerItem.is_correct) {
              return (
                <li key={index} className={classes.multipleChoice_answerIncorrect}>
                  <SelectionImmersiveReader htmlText={answerItem.answer}/>
                </li>
              )
            } else if (userAnswer.includes(answerItem.id) && answerItem.is_correct) {
              return (
                <li key={index} className={classes.multipleChoice_answerCorrect}>
                  <SelectionImmersiveReader htmlText={answerItem.answer}/>
                </li>
              )
            } else {
              return null;
            }
          })
        }
        {
          userAnswer.length === 0 &&
            <li className={classes.multipleChoice_answer}>
              There're no answers
            </li>
        }
      </ul>
    )
  }
};

const Sequence = ({questionResult, question, userAnswer, isCorrectAnswer}) => {
  const classes = styles();

  if (isCorrectAnswer) {
    return (
      <ul className={classes.sequence_answerOptions}>
        {
          question.answers.map((answerItem, index) => {
            return (
              <li key={index} className={classes.sequence_answer}>
                <SelectionImmersiveReader htmlText={answerItem.answer}/>
              </li>
            )
          })
        }
      </ul>
    )
  } else {
    return (
      <Fragment>
        {
          !questionResult && userAnswer.length !== 0 &&
            <ul className={classes.sequence_answerOptionsIncorrect} >
              {
                userAnswer.map((answerId, index) => {
                  const answer = question.answers.find((answer) => answer.id === answerId);
                  return (
                    <li key={index} className={classes.sequence_answer}>
                      <SelectionImmersiveReader htmlText={answer.answer}/>
                    </li>
                  )
                })
              }
            </ul>
        }
        {
          questionResult && userAnswer.length >= 1 &&
            <ul className={classes.sequence_answerOptionsCorrect} >
              {
                userAnswer.map((answerId, index) => {
                  const answer = question.answers.find((answer) => answer.id === answerId);
                  return (
                    <li key={index} className={classes.sequence_answer}>
                      <SelectionImmersiveReader htmlText={answer.answer}/>
                    </li>
                  )
                })
              }
            </ul>
        }
        {
          userAnswer.length === 0 &&
            <li className={classes.multipleChoice_answer}>
              There're no answers
            </li>
        }
      </Fragment>
    )
  }
};


const TrueOrFalse = ({isCorrectAnswer, userAnswer, question, type}) => {
  const classes = styles();

  if (isCorrectAnswer) {
    return (
      <ul className={classes.true_or_false_answersOptions}>
        {
          question.answers.map((answer, index) => {
            return(
              <li key={index} className={classes.true_or_false_answerOption}>
                <SelectionImmersiveReader htmlText={answer.answer}/>
                <span>
                  {
                    type === 'CORRECT_OR_INCORRECT' && `${answer.is_correct ? 'CORRECT' : 'INCORRECT'}`
                      
                  }
                  {
                    type === 'TRUE_OR_FALSE' && `${answer.is_correct ? 'TRUE' : 'FALSE'}`
                  }
                </span>
              </li>
            )
          })
        }
      </ul>
    )
  } else {
    const answersChecked = userAnswer.filter((answer) => answer.userAnswer !== null);

    return (
      <ul className={classes.true_or_false_answersOptions}>
        {
          answersChecked.length === 0 &&
            <li className={classes.multipleChoice_answer}>
              There're no answers
            </li>
        }
        {
          answersChecked.length >= 1 && 
            answersChecked.map((answerChecked, index) => {
                const answerOption = question.answers.find((answer) => answer.id === answerChecked.answerId);

                if (answerOption && answerOption.is_correct === answerChecked.userAnswer) {
                  return(
                    <li key={index} className={classes.true_or_false_answerOptionCorrect}>
                      <SelectionImmersiveReader htmlText={answerOption.answer}/>
                      <span>
                        {
                          type === 'CORRECT_OR_INCORRECT' && `${answerChecked.userAnswer ? 'CORRECT' : 'INCORRECT'}`
                        }
                        {
                          type === 'TRUE_OR_FALSE' && `${answerChecked.userAnswer ? 'TRUE' : 'FALSE'}`
                        }
                      </span>
                    </li>
                  )
                } else if (answerOption && answerOption.is_correct !== answerChecked.userAnswer) {
                  return(
                    <li key={index} className={classes.true_or_false_answerOptionIncorrect}>
                      <SelectionImmersiveReader htmlText={answerOption.answer}/>
                      <span>
                        {
                          type === 'CORRECT_OR_INCORRECT' && `${answerChecked.userAnswer ? 'CORRECT' : 'INCORRECT'}`
                        }
                        {
                          type === 'TRUE_OR_FALSE' && `${answerChecked.userAnswer ? 'TRUE' : 'FALSE'}`
                        }
                      </span>
                    </li>
                  )
                } else {
                  return null;
                }
              })
        }
      </ul>
    )
  }
};

const Matching = ({isCorrectAnswer, userAnswer, question}) => {
  const classes = styles();
  const [normalizedUserAnswer, setNormalizedUserAnswer] = useState([]);

  useEffect(() => {
    const getMatchingUserAnswer = (concepts, descriptions) => {
      return Object.keys(concepts).reduce((acc, conceptId) => {
        const concept = concepts[conceptId];
        const description = descriptions[Object.keys(descriptions).find((descriptionId) => descriptionId === conceptId)];
        const matchedDescriptionId = Object.keys(descriptions).reduce((acc, descriptionId) => {
          if (descriptions[descriptionId].letter === concept.systemLetter) {
            acc = descriptionId;
          }
          return acc;
        });

        const answer = {
          is_answered: Boolean(description.letter),
          is_correct: concept.systemLetter === description.letter && Boolean(description.letter),
          conceptId: conceptId,
          descriptionId: matchedDescriptionId
        };

        acc.push(answer) 
        return acc;
      }, []);
    };

    setNormalizedUserAnswer(
      getMatchingUserAnswer(userAnswer.concepts, userAnswer.descriptions).filter((item) => item.is_answered !== false)
    );
  }, [userAnswer])

  if (isCorrectAnswer) {
    return (
      <ul className={classes.matching_answersOptions}>
        {
          question.answers.map((answer, index) => {
            return(
              <li key={index} className={classes.matching_answerOption}>
                <SelectionImmersiveReader htmlText={answer.answer}/>
                <SelectionImmersiveReader htmlText={answer.match}/>
              </li>
            )
          })
        }
      </ul>
    )
  } else {
    return (
      <ul className={classes.matching_answersOptions}>
        {
          normalizedUserAnswer.length === 0 &&
            <li className={classes.multipleChoice_answer}>
              There're no answers
            </li>
        }
        {
          normalizedUserAnswer.length >= 1 &&
            normalizedUserAnswer.map((answer, index) => {
              const conceptOption = question.answers.find((answerOption) => answerOption.id === answer.conceptId);
              const description = question.answers.find((answerOption) => answerOption.id === answer.descriptionId);
              if (answer.is_correct) {
                return (
                  <li key={index} className={classes.matching_answerOptionCorrect}>
                    <SelectionImmersiveReader htmlText={description.answer}/>
                    <SelectionImmersiveReader htmlText={conceptOption.match}/>
                  </li>
                )
              } else {
                return (
                  <li key={index} className={classes.matching_answerOptionIncorrect}>
                    <SelectionImmersiveReader htmlText={description.answer}/>
                    <SelectionImmersiveReader htmlText={conceptOption.match}/>
                  </li>
                )
              }
            })
        }
      </ul>
    )
  }
}

export default AssessmentDetails;