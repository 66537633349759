import React, { useState } from 'react';
import { Tabs, Tab, AppBar, Box, Button, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useQuery } from '@apollo/react-hooks';
import { GET_COURSE_BY_ID } from '../../../../apollo/queries';
import { useIsManager } from '../IsManager';

import styles from './styles';
import {
  CourseCurriculum,
  Publish,
  CourseSetting,
  CourseLibrary,
} from './components';

const TabPanel = ({ value, index, children }) => {
  if (value !== index) return null;
  return <Box flexGrow={1}>{children}</Box>;
};

const Course = ({ match, history }) => {
  const classes = styles();
  const isManager = useIsManager();
  const { data, loading, error } = useQuery(GET_COURSE_BY_ID, {
    variables: { where: { id: match.params.courseId } },
    fetchPolicy: 'cache-and-network',
  });
  const [currentTab, setCurrentTab] = useState(
    +localStorage.getItem('currentTab') || 0,
  );

  const handleTabChange = (event, newValue) => {
    localStorage.setItem('currentTab', newValue);
    setCurrentTab(newValue);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  let CourseTabs = [CourseCurriculum, CourseLibrary, Publish, CourseSetting];

  if (!isManager) {
    CourseTabs.splice(2, 1);
  }

  return (
    <section className={classes.courseSection}>
      <AppBar
        position="static"
        style={{ background: 'transparent' }}
        elevation={0}
      >
        <div className={classes.toolbar}>
          <div className={classes.toolbarButtonWrapper}>
            <Button
              onClick={() => history.push('/cms')}
              startIcon={<ChevronLeftIcon />}
              className={classes.backButton}
              variant="text"
              color="primary"
            >
              {' '}
              Back
            </Button>
          </div>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="inherit"
            className={classes.tabs}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Curriculum" />
            <Tab label="Learning Library" />
            {isManager && <Tab label="Publish" />}
            <Tab label="Settings" />
          </Tabs>
        </div>
      </AppBar>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        background="pink"
      >
        <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.courseHeader}
        >
          <Typography variant="h5">{data.course.name}</Typography>
          <Button
            onClick={() =>
              history.push(`/cms/preview/course/${data.course.id}`)
            }
            variant="contained"
            color="primary"
          >
            Preview Course
          </Button>
        </Box>
        {CourseTabs.map((Component, index) => (
          <TabPanel value={currentTab} index={index} key={index}>
            <Component course={data.course} />
          </TabPanel>
        ))}
      </Box>
    </section>
  );
};

export default Course;
