import gql from 'graphql-tag';

const GET_STUDENT_GRADEBOOK_OVERVIEW = gql`
  query getStudentGradebookOverview(
    $studentId: ID!
    $groupId: ID!
    $courseId: ID!
  ) {
    studentGradebookOverview(
      studentId: $studentId
      groupId: $groupId
      courseId: $courseId
    ) {
      benchmarks {
        id
        level
        complete
        correctAnswers
        totalQuestions
        score
      }
      lessons {
        id
        lessonNumber
        complete
        progress
        studyProjects {
          id
          level
          complete
          draft
          correctAnswers
          totalQuestions
          score
          percent
        }
      }
      summaries {
        id
        level
        complete
        correctAnswers
        totalQuestions
        score
      }
    }
  }
`;

export { GET_STUDENT_GRADEBOOK_OVERVIEW };
