import gql from 'graphql-tag';

const CREATE_STUDY_PROJECT_PROGRESS_DRAFT = gql`
  mutation createStudyProjectProgressDraft($data: StudyProjectProgressDraftInput!) {
    createStudyProjectProgressDraft(data: $data) {
      user_id
      question_index
      study_project_id
      group_id
      progress
    }
  }
`;

const GET_STUDY_PROJECT_PROGRESS_DRAFT = gql`
  query getStudyProjectProgressDraft($where: StudyProjectProgressDraftWhereInput!) {
    studyProjectProgressDraft(where: $where) {
      user_id
      question_index
      study_project_id
      group_id
      progress
      questions
    }
  }
`;

export { CREATE_STUDY_PROJECT_PROGRESS_DRAFT, GET_STUDY_PROJECT_PROGRESS_DRAFT };