import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

const withDialog = WrappedComponent => props => {
  return (
    <Dialog open={props.open} onClose={props.onCancel}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <WrappedComponent {...props} />
      </DialogContent>
    </Dialog>
  );
};

export default withDialog;
