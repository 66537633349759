import React, { Fragment } from 'react';
import {
  SlideUpDialog,
  ExpansionPanel,
  ResourceCard,
} from '../../../../../../../components';
import {
  Typography,
  Card,
  CardActions,
  CardContent,
  Grid,
  Toolbar,
  Box,
} from '@material-ui/core';
import { KeyElementContainer } from '../../../../../../../containers';
import AddKeyElement from './AddKeyElement';
import EditKeyElement from './EditKeyElement';
import DeleteKeyElement from './DeleteKeyElement';
import PublishKeyElement from './PublishKeyElement';
import AddKeyElementResource from './AddKeyElementResource';
import DeleteKeyElementResource from './DeleteKeyElementResource';
import ResourceCountChip from './ResourceCountChip';
import styles from './styles';
import { withSnackbar } from 'notistack';

const ProjectKeyElements = ({ lessons, open, onClose, project, enqueueSnackbar, match }) => {
  const classes = styles();

  return (
    <SlideUpDialog
      open={open}
      onClose={onClose}
      title="Study Project Key Elements"
    >
      <Box p={2}>
        <AddKeyElement
          studyProjectId={project.id}
          enqueueSnackbar={enqueueSnackbar}
        />
        <KeyElementContainer studyProjectId={project.id}>
          {({ studyProjectKeyElements: { keyElements, loading, error } }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <p>Error...</p>;

            if (keyElements.length === 0) {
              return (
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height={400}
                >
                  <Typography variant="h6" color="textSecondary">
                    This study project currently has no key elements
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Click the button below to get started.
                  </Typography>

                  <AddKeyElement
                    studyProjectId={project.id}
                    enqueueSnackbar={enqueueSnackbar}
                  />
                </Box>
              );
            }

            return keyElements.map((keyElement) => (
              <ExpansionPanel
                key={keyElement.id}
                className={classes.expansionPanel}
                showExpandIcon
                summaryProps={{
                  className: classes.summaryRoot,
                }}
                summary={
                  <Toolbar style={{ width: '100%' }}>
                    <ResourceCountChip keyElement={keyElement} />
                    <Typography variant="h6" style={{ marginRight: "15px" }}>{keyElement.name}</Typography>
                    <div style={{ marginLeft: 'auto' }}>
                      <PublishKeyElement
                        keyElement={keyElement}
                        enqueueSnackbar={enqueueSnackbar}
                      />
                      <AddKeyElementResource
                        lessons={lessons}
                        keyElement={keyElement}
                        lesson={project.lesson}
                        enqueueSnackbar={enqueueSnackbar}
                      />
                      <EditKeyElement
                        keyElement={keyElement}
                        enqueueSnackbar={enqueueSnackbar}
                      />
                      <DeleteKeyElement
                        keyElement={keyElement}
                        enqueueSnackbar={enqueueSnackbar}
                      />
                    </div>
                  </Toolbar>
                }
                details={
                  <Fragment>
                    {keyElement.type === 'quickdeck' && (
                      <CardList
                        cards={keyElement.cards}
                        keyElementId={keyElement.id}
                        enqueueSnackbar={enqueueSnackbar}
                      />
                    )}
                    {keyElement.type === 'ebook' && (
                      <PageList
                        pages={keyElement.pages}
                        keyElementId={keyElement.id}
                        enqueueSnackbar={enqueueSnackbar}
                      />
                    )}
                    {keyElement.type === 'quickclip' && (
                      <ClipCardList
                        cards={keyElement.clip_cards}
                        keyElementId={keyElement.id}
                        enqueueSnackbar={enqueueSnackbar}
                      />
                    )}
                  </Fragment>
                }
              />
            ));
          }}
        </KeyElementContainer>
      </Box>
    </SlideUpDialog>
  );
};

export default withSnackbar(ProjectKeyElements);

const ClipCardList = ({ cards, keyElementId, enqueueSnackbar }) => {
  return (
    <Grid container spacing={2}>
      {cards.map((card) => (
        <Grid key={card.id} item xs={12} sm={6} md={4}>
          <Card style={{ margin: 8 }}>
            <CardActions>
              <DeleteKeyElementResource
                resourceId={card.id}
                keyElementId={keyElementId}
                enqueueSnackbar={enqueueSnackbar}
              />
            </CardActions>
            <CardContent>
              <ResourceCard title={card.title} text1_label="Clips" text1={card.video} text2_label="Clip with subtitles" text2={card.video_with_subtitles} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const CardList = ({ cards, keyElementId, enqueueSnackbar }) => {
  if (!cards || (cards && cards.length === 0)) {
    return (
      <Typography>No Resources. Click add resource to get started.</Typography>
    );
  }
  return (
    <Grid container spacing={2}>
      {cards.map((card) => (
        <Grid key={card.id} item xs={12} sm={6} md={4}>
          <Card style={{ margin: 8 }}>
            <CardActions>
              <DeleteKeyElementResource
                resourceId={card.id}
                keyElementId={keyElementId}
                enqueueSnackbar={enqueueSnackbar}
              />
            </CardActions>
            <CardContent>
              <ResourceCard text1_label="Card Term" text1={card.term} text2_label="Card Definition" text2={card.definition} image={card.image} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const PageList = ({ pages, keyElementId, enqueueSnackbar }) => {
  if (!pages || (pages && pages.length === 0)) {
    return (
      <Typography>No Resources. Click add resource to get started.</Typography>
    );
  }
  return (
    <Grid container spacing={2}>
      {pages.map((page) => (
        <Grid key={page.id} item xs={12} sm={6}>
          <Card style={{ margin: 8 }}>
            <CardActions>
              <DeleteKeyElementResource
                resourceId={page.id}
                keyElementId={keyElementId}
                enqueueSnackbar={enqueueSnackbar}
              />
            </CardActions>
            <CardContent>
              <ResourceCard title={page.title} text1_label="eBook Page" text1={page.content} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
