import React, { Component } from 'react';
import tinymce from 'tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/table';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/media';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/preview';
import CloudinaryMediaWidget from '../Cloudinary/MediaWidget';
import { v4 as uuidv4 } from 'uuid';
import { CloudinaryContext } from '../../../providers/CloudinaryProvider';

class TinyEditor extends Component {
  static contextType = CloudinaryContext;

  constructor(props) {
    super(props);

    this.state = {
      editor: null,
      value: props.value,
      placeholder: props.placeholder,
      id: uuidv4(),
    };

    if (this.props.showTinyMCEMediaOptions)
      this.tinyMCEMediaOptions = ' | image media';

    this.ImagesUploadHandler = this.ImagesUploadHandler.bind(this);
    this.InsertMedia = this.InsertMedia.bind(this);
    this.fixedEncodeURIComponent = this.fixedEncodeURIComponent.bind(this);
  }

  componentDidMount() {
    tinymce.init({
      menubar: false,
      selector: `#${this.state.id}`,
      skin_url: `${window.location.origin}/tiny_editor_skins/ui/oxide`,
      plugins:
        'advlist autolink lists link image charmap searchreplace media table paste help autoresize preview',
      branding: false,
      toolbar: `undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | fontsizeselect | removeformat${this.props.showTinyMCEMediaOptions ? ' | image media' : ''} | help`,
      images_upload_handler: this.ImagesUploadHandler,
      setup: (editor) => {
        this.setState({ editor });

        if (this.props.updateEditor)
          this.props.updateEditor(editor);

        editor.on('keyup change', () => {
          this.props.onChange(editor.getContent());
        });
      },
    });
  }

  componentWillUnmount() {
    // tinymce.remove(this.state.editor);
  }

  UNSAFE_componentWillReceiveProps(next_props) {
    if (
      this.props.allowRefresh === true &&
      this.state.editor &&
      this.state.editor.getContent() !== next_props.value
    ) {
      this.state.editor.setContent(next_props.value);
    }
  }

  render() {
    return (
      <div>
        {this.props.showMediaLibraryButton && (
          <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            <CloudinaryMediaWidget
              onInsert={(data) => {
                this.InsertMedia(this.state.editor, data);
              }}
            />
          </div>
        )}
        <textarea
          id={this.state.id}
          value={this.props.value}
          onChange={(e) => { }}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }

  ImagesUploadHandler(blobInfo, success, failure) {
    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
    formData.append('api_key', this.context.cloudinaryInfo.api_key);
    formData.append('timestamp', this.context.cloudinaryInfo.unixtime);
    formData.append('upload_preset', 'image_preset');
    formData.append('signature', this.context.cloudinaryInfo.uwsignature);

    const request = new XMLHttpRequest();
    request.open('POST', 'https://api.cloudinary.com/v1_1/jasperactive/image/upload');

    request.onload = function () {
      if (request.status !== 200) {
        failure('HTTP Error: ' + request.status);
        return;
      }

      const response = JSON.parse(request.responseText);

      if (!response || typeof response.secure_url != 'string') {
        failure('Invalid JSON: ' + request.responseText);
        return;
      }

      localStorage.setItem(response.public_id, response.width);

      success(response.secure_url);
    };

    request.send(formData);
  }

  InsertMedia(editor, assets) {
    if (assets && assets.length > 0) {
      assets.forEach(asset => {
        switch (asset.resource_type) {
          case "image":
            editor.insertContent(`<img width="${asset.width}" height="${asset.height}" src="${asset.secure_url}"/>`);
            break;
          case "video":
            const source = `<source src="${asset.secure_url}" type="video/${asset.format}" />`;
            const img = `<img class="mce-object mce-object-video" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-mce-p-crossorigin="anonymous" width="300" height="150" data-mce-p-controls="controls" data-mce-p-controlsList="nodownload" data-mce-p-disablePictureInPicture="" data-mce-html="${this.fixedEncodeURIComponent(source)}" data-mce-object="video" data-mce-selected="1">`;
            editor.insertContent(img);
            break;
          case "raw":
            const sourceTrack = `<track src="${asset.secure_url}" label="English" kind="subtitles" srclang="en"/>`;
            const imgTrack = `<img width="24" height="24" src="https://res.cloudinary.com/jasperactive/image/upload/v1642012327/insert_drive_file_black_24dp.svg" data-mce-html="${this.fixedEncodeURIComponent(sourceTrack)}" data-mce-object="img" data-mce-selected="1">`;
            editor.insertContent(imgTrack);
            break;
          default:
            console.log('Unrecognized asset: ', asset.resource_type);
            break;
        }
      });
      return true;
    }
    else {
      return false;
    }
  }

  fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()]/g, escape).replace(/\*/g, "%2A");
  }
}

export default TinyEditor;
