import React, { useState } from 'react';
import { Box, Collapse, makeStyles } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const ArrowIcon = ({ open, onClick }) => {
  const classes = useStyles();

  return open ? (
    <KeyboardArrowUpIcon
      className={classes.icon}
      fontSize="small"
      onClick={onClick}
      color="primary"
    />
  ) : (
    <KeyboardArrowDownIcon
      className={classes.icon}
      fontSize="small"
      onClick={onClick}
    />
  );
};

const CollapsingList = ({ heading, children, ...restProps }) => {
  const [open, setOpen] = useState(true);

  return (
    <div {...restProps}>
      <Box display="flex" alignItems="center">
        <ArrowIcon open={open} onClick={() => setOpen((open) => !open)} />
        {heading}
      </Box>
      <Collapse in={open}>{children}</Collapse>
    </div>
  );
};

export default CollapsingList;
