import React from 'react';

import { 
  Box, 
  Button, 
  Paper, 
  Typography 
} from "@material-ui/core";
import { SummaryForm } from "../../../../../../components";
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import styles from './styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { 
  GET_SUMMARY_DEVELOPMENT, 
  PUBLISH_SUMMARY, 
  UNPUBLISH_SUMMARY, 
  UPDATE_SUMMARY 
} from '../../../../../../apollo/queries';
import { useSnackbar } from 'notistack';
import { useIsManager } from '../../../IsManager';


const SummaryDevelopment = ({course, summaryId}) => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const isManager = useIsManager();

  const { data, loading, error } = useQuery(GET_SUMMARY_DEVELOPMENT, {
    variables: { where: { id: summaryId } },
  });

  const refetchQueries = ['getSummaryDevelopoment'];

  const [updateSummary] = useMutation(UPDATE_SUMMARY);
  const [publishSummary] = useMutation(PUBLISH_SUMMARY, { refetchQueries });
  const [unpublishSummary] = useMutation(UNPUBLISH_SUMMARY, { refetchQueries });


  const handleOnSubmit = async (values) => {
    try {
      await updateSummary({
        variables: { data: {
          ...values,
          questions_per_lesson: parseInt(values.questions_per_lesson),
        }, where: { id: summaryId } },
      });
      enqueueSnackbar('Summary Updated!', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar('Oops! something went wrong. Please try again', {
        variant: 'error',
      });
    }
  }
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const {summary} = data;


  const handleOnPublish = () => {
    if (summary.published) {
      unpublishSummary({ variables: { id: summary.id } });
    } else {
      publishSummary({ variables: { id: summary.id } });
    }
    enqueueSnackbar(
      `Summary has been ${summary.published ? 'un' : ''
      }published!`,
      { variant: 'success', autoHideDuration: 1500 },
    );
}

  return (
    <section className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">
          <AssessmentOutlinedIcon /> Summary Level {summary.level}
        </Typography>
        {isManager && (
          <Button
            onClick={handleOnPublish}
            variant="contained"
            color="primary"
          >
            {summary.published ? 'Unpublish' : 'Publish'}
          </Button>
        )}
      </Box>
      <Paper className={classes.summaryFormWrapper}>
        <SummaryForm
        onSubmit={handleOnSubmit}
        summary={summary}
        />
      </Paper>
    </section>
  )
}

export default SummaryDevelopment;