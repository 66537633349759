import React from 'react';
import { Box, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import BenchmarkDevelopment from '../BenchmarkDevelopment';
import StudyProjectDevelopment from '../StudyProjectDevelopment';
import LessonEdit from '../LessonEdit';
import SummaryDevelopment from '../SummaryDevelopment';

const Curriculum = ({ course, curriculum, data }) => {
  switch (curriculum) {
    case 'benchmark':
      return <BenchmarkDevelopment course={course} benchmarkId={data.id} />;
    case 'study_project':
      return (
        <StudyProjectDevelopment
          course={course}
          studyProjectId={data.id}
          setShowCurriculum={data.setShowCurriculum}
        />
      );
    case 'lesson':
      return (
        <LessonEdit
          course={course}
          lessonId={data.id}
          setShowCurriculum={data.setShowCurriculum}
        />
      );
    case 'summary': 
      return (
        <SummaryDevelopment
        course={course}
        summaryId={data.id}/>
      )
    default:
      return (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <EditOutlinedIcon
            style={{ fontSize: 60, color: 'rgba(0, 0, 0, 0.54)' }}
          />

          <Typography variant="h4" color="textSecondary" gutterBottom>
            Let's Build a Course!
          </Typography>
          <Typography variant="body1">
            Get started by selecting the curriculum in the menu on the left.
          </Typography>
        </Box>
      );
  }
};

export default Curriculum;
