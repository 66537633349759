import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  bodyText: {
    marginBottom: theme.spacing(2),
  },
  helperText: {
    color: theme.palette.primary.main,
  },
}));
