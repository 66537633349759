import { useEffect, useState } from "react";

const useNormalizeQuickdeckContent = (quickResource) => {
  const [quickdeckContent, setQuickdeckContent] = useState(null);

  const handleNormalizeQuickdeckContent = (term, img, definition) => {
    let temp = document.createElement('div');
    let image = document.createElement('img');
    image.setAttribute('src', img);
    temp.innerHTML = term + image.outerHTML + definition;

    const elements = temp.getElementsByTagName('*');
    for (let index = 0; index < elements.length; index++) {
      elements[index].style.removeProperty('width');
    }

    setQuickdeckContent(temp.outerHTML);
  }

  useEffect(() => {
    if (quickResource) {
      handleNormalizeQuickdeckContent(
        quickResource.term,
        quickResource.image,
        quickResource.definition,
      );
    }
  }, [quickResource]);

  return quickdeckContent;
}

export default useNormalizeQuickdeckContent;