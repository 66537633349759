import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import {
  GET_ALL_PRODUCTS,
  CMS_GET_ALL_PRODUCTS,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  PUBLISH_PRODUCT,
  UNPUBLISH_PRODUCT,
} from '../apollo/queries';
import { adopt } from 'react-adopt';

const productData = ({ render }) => (
  <Query query={GET_ALL_PRODUCTS}>
    {({ data: { products } = {}, loading, error }) =>
      render({ products, loading, error })
    }
  </Query>
);

const cmsProductData = ({ render }) => (
  <Query query={CMS_GET_ALL_PRODUCTS}>
    {({ data: { products } = {}, loading, error }) =>
      render({ products, loading, error })
    }
  </Query>
);

const createProduct = ({ render }) => (
  <Mutation mutation={CREATE_PRODUCT}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const updateProduct = ({ render }) => (
  <Mutation mutation={UPDATE_PRODUCT}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const deleteProduct = ({ render }) => (
  <Mutation mutation={DELETE_PRODUCT}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const publishProduct = ({ render }) => (
  <Mutation mutation={PUBLISH_PRODUCT}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const unpublishProduct = ({ render }) => (
  <Mutation mutation={UNPUBLISH_PRODUCT}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const ProductContainer = adopt({
  productData,
  cmsProductData,
  updateProduct,
  createProduct,
  deleteProduct,
  publishProduct,
  unpublishProduct,
});

export default ProductContainer;
