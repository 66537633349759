import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { dateFormatString } from '../../../lib';

const JasperDateInput = (props) => {
  return (
    <KeyboardDatePicker
      placeholder="mm/dd/yyyy"
      format={dateFormatString}
      {...props}
    />
  );
};

export default JasperDateInput;
