import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.white.main,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerRoot: {
    zIndex: theme.zIndex.drawer
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: "auto"
  },
  headerNavContainer: {
    marginRight: 'auto',
    marginLeft: theme.spacing(2)
  },
  inlineListItem: {
    display: "inline-flex",
    width: "auto"
  },
  listItem: {
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  show: {
    display: 'block',
  },
  container: {
    position: "relative",
    marginLeft: "auto"
  },
  card: {
    minWidth: 300,
    position: "absolute",
    top: 30,
    right: 0,
  },
  mainButton: {
    '&:hover': {
      cursor: "pointer"
    }
  },
  notifcationIndicator: {
    backgroundColor: theme.palette.error.main,
    width: 8,
    height: 8,
    borderRadius: '100%',
    position: 'absolute',
    top: 0,
    right: 24
  }
}));

export default useStyles;
