import React, { useState, useEffect, Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import {
  FormControl,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { TinyEditor, CloudinaryMediaWidget } from '../../common';

const QuickdeckForm = ({ card = {}, open, onSubmit, onCancel, lessonId }) => {
  const [image, setImage] = useState(card ? card.image : '');

  useEffect(() => {

  }, [image]);

  useEffect(() => {
    setImage(card ? card.image : '');
  }, [card]);

  return (
    <Dialog open={open} onClose={onCancel} disableEnforceFocus={true}>
      <DialogTitle>
        {card && card.id ? 'Update' : 'Add'} Quickdeck Card
      </DialogTitle>
      <DialogContent>
        <Form
          initialValues={{ ...card, lesson_id: lessonId }}
          onSubmit={onSubmit}
          validate={values => {
            let errors = {};

            if (!values.term) errors.term = 'A Term is required';
            if (!values.definition)
              errors.definition = 'A Definition is required';

            return errors;
          }}
          render={({ handleSubmit, valid, pristine, submitting }) => (
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth style={{ marginBottom: 8, marginTop: 20 }}>
                <Field name="term">
                  {({ input, meta }) => (
                    <Fragment>
                      <Typography gutterBottom variant="subtitle2">
                        Term
                      </Typography>
                      <TinyEditor
                        value={input.value}
                        showTinyMCEMediaOptions={true}
                        showMediaLibraryButton={true}
                        onChange={(content) => {
                          input.onChange(content);
                        }}
                      />
                    </Fragment>
                  )}
                </Field>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: 8 }}>
                <Field name="definition">
                  {({ input, meta }) => (
                    <Fragment>
                      <Typography gutterBottom variant="subtitle2">
                        Definition
                      </Typography>
                      <TinyEditor
                        value={input.value}
                        showTinyMCEMediaOptions={true}
                        showMediaLibraryButton={true}
                        onChange={(content) => {
                          input.onChange(content);
                        }}
                      />
                    </Fragment>
                  )}
                </Field>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: 8 }}>
                <Field name="image">
                  {({ input, meta }) => (
                    <Fragment>
                      <Typography gutterBottom variant="subtitle2">
                        Add Image
                      </Typography>
                      <CloudinaryMediaWidget
                        onInsert={(assets) => {
                          if (assets.length === 1) {
                            input.onChange(assets[0].secure_url);
                            setImage(assets[0].secure_url)
                          }
                        }}
                        multiple={false}
                      />
                    </Fragment>
                  )}
                </Field>
              </FormControl>
              <img src={image} style={{ maxWidth: "100%", height: "auto" }} alt=""></img>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                  type="button"
                  onClick={onCancel}
                  variant="text"
                  color="primary"
                  disabled={submitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="text"
                  color="primary"
                  disabled={submitting || pristine || !valid}
                >
                  {card && card.id ? 'Update' : 'Create'}
                </Button>
              </Box>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default QuickdeckForm;
