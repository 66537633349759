import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SEARCH_USERS } from '../../../apollo/queries';
import { Paper, Box, Typography } from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import UserSearchBar from './UserSearchBar';
import UserSearchResults from './UserSearchResults';

const PAGE_SIZE = 20;

const UsersWithNoInstitution = ({ history, match }) => {
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [search, setSearch] = useState(null);

  let variables = {
    noInstitutionOnly: true,
  };
  if (search) variables.search = search;
  if (pageSize) variables.pageSize = +pageSize;

  const { data, loading, error, fetchMore } = useQuery(SEARCH_USERS, {
    variables,
  });

  return (
    <Paper style={{ padding: 16 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={1}
      >
        <Box display="flex" alignItems="center">
          <PersonOutlineOutlinedIcon style={{ marginRight: '15px' }} />
          <Typography variant="h6" component="span">
            Users without an Institution
          </Typography>
        </Box>
      </Box>
      <UserSearchBar
        search={search}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setSearch={setSearch}
      />
      <UserSearchResults
        data={data}
        loading={loading}
        error={error}
        fetchMore={fetchMore}
        history={history}
        match={match}
      />
    </Paper>
  );
};

export default UsersWithNoInstitution;
