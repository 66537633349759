/* /src/utils/authConst.js */
const url = window.origin;
//authority: window.location.hostname === 'app.jasperactive.com' ? "https://accounts.jasperactive.com" : "https://jasperactive-accounts-dev.azurewebsites.net",
//authority: "http://localhost:5000",
export const IDENTITY_CONFIG = {
  authority: window.location.hostname === 'app.jasperactive.com' || window.location.hostname === 'jasperactive-production-stage.azurewebsites.net' ? "https://accounts.jasperactive.com" : "https://jasperactive-accounts-dev.azurewebsites.net",
  client_id: 'Jasperactive',
  redirect_uri: `${url}/auth-callback`,
  post_logout_redirect_uri: `${url}/index.html`,
  response_type: 'code',
  scope: 'openid profile email JasperactiveWebApi',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: `${url}/silent-renew`,
  revokeAccessTokenOnSignout: true,
};
