import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  resultsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: 960,
    width: '100%'
  },
  resultsContainer_header: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start'
    },
  },
  header: {
    background: theme.palette.white.main,
    boxShadow: 'none',
  },
  headerToolbar: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    background: theme.palette.white.main,
  },
  toolbar: {
    justifyContent: 'flex-end',
  },
  submitButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '1em',
    padding: '0 1em'
  },
  submitButton: {
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      flex: 0,
    },
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1em 0',
    gap: '0.5em'
  },
  tableHead: {
    backgroundColor: '#3FAE2A',
    borderRadius: 4,
    height: 45,
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    display: 'flex',
    flex: 1,
    '&:nth-of-type(even)': {
      backgroundColor: '#F2F2F2'
    },
    borderRadius: 4
  },
  tableCell: {
    flex: 0.5,
    border: 'none',
    '& > h6': {
      fontWeight: 'bold',
      fontSize: 16,
      color: theme.palette.white.main
    },
    '& > p': {
      fontSize: 14,
      color: '#828282'
    },
    '& > div > h6': {
      fontSize: 14,
      color: '#828282'
    }
  },
  progressBarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2em 0',
    flexDirection: 'column'
  },
  buttonTryAgain: {
    color: '#FFFFFF', 
    backgroundColor: '#3fae2a',
    '&:hover': {
      backgroundColor: '#2d831d'
    }
  },
}));
