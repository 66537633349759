import gql from 'graphql-tag';
import {
  CourseFields,
  AllCourseFields,
  LessonFields,
  BenchmarkFields,
} from './fragments';

const GET_ALL_COURSES = gql`
  query allCourses {
    courses {
      ...CourseFields
    }
  }
  ${CourseFields}
`;

const CREATE_COURSE = gql`
  mutation createCourse($data: CourseCreateInput!) {
    createCourse(data: $data) {
      ...CourseFields
    }
  }
  ${CourseFields}
`;

const DELETE_COURSE = gql`
  mutation deleteCourse($id: ID!) {
    deleteCourse(id: $id)
  }
`;

const UPDATE_COURSE = gql`
  mutation updateCourse(
    $data: CourseUpdateInput!
    $where: CourseWhereUniqueInput!
  ) {
    updateCourse(data: $data, where: $where) {
      ...CourseFields
    }
  }
  ${CourseFields}
`;

//By course
const CREATE_ASSESSMENT_CUSTOM_SETTINGS = gql`
  mutation createAssessmentCustomSettings(
    $data: CreateAssessmentCustomSettingsInput!
  ) {
    createAssessmentCustomSettings(data: $data) {
      id
      assessment_id
      time
      questions_per_lesson
    }
  }
`;

const DELETE_ASSESSMENT_CUSTOM_SETTINGS = gql`
  mutation deleteAssessmentCustomSettings(
    $data: DeleteAssessmentCustomSettingsInput!
  ) {
    deleteAssessmentCustomSettings(data: $data)
  }
`

const GET_COURSE_BY_ID = gql`
  query getCourseById($where: CourseWhereUniqueInput!) {
    course(where: $where) {
      ...AllCourseFields
    }
  }
  ${AllCourseFields}
`;

const GET_GROUP_COURSE = gql`
  query groupCourse($courseId: ID!) {
    course(where: { id: $courseId }) {
      ...CourseFields
      benchmarks {
        id
        level
        published
      }
      lessons {
        ...LessonFields
        studyProjects {
          id
          level
          published
          introduction
          study_project_number
        }
      }
      summaries {
        id
        level
        published
      }
      instructorResources {
        id
        name
        url
        order
        description
        course_id
        type
        lesson_id
      }
    }
  }
  ${CourseFields}
  ${LessonFields}
`;

const PUBLISH_COURSE = gql`
  mutation publishCourse($id: ID!) {
    publishCourse(id: $id)
  }
`;

const UNPUBLISH_COURSE = gql`
  mutation unpublishCourse($id: ID!) {
    unpublishCourse(id: $id)
  }
`;

const GET_COURSE_BENCHMARK_ASSESSMENT = gql`
  query getBenchmarkAssessment(
    $courseId: ID!
    $benchmarkWhere: BenchmarkWhereInput
  ) {
    course(where: { id: $courseId }) {
      id
      name
      benchmarks(where: $benchmarkWhere) {
        ...BenchmarkFields
      }
    }
  }
  ${BenchmarkFields}
`;

const GET_STUDENT_COURSE_PROGRESS = gql`
  query getStudentCourseProgress(
    $courseId: ID!
    $studentId: ID!
    $groupId: ID!
  ) {
    studentCourseProgress(
      courseId: $courseId
      studentId: $studentId
      groupId: $groupId
    ) {
      progress
      benchmark {
        benchmark_id
        level
        complete
      }
      lessons {
        lesson_id
        locked
        complete
      }
      studyProjects {
        study_project_id
        level
        locked
        complete
        draft
      }
      summary {
        summary_id
        level
        complete
      }
    }
  }
`;

const FREE_COURSE_BENCHMARKS = gql`
  query freeCourseBenchmarks {
    freeCourseBenchmarks {
      ...CourseFields
    }
  }
  ${CourseFields}
`;

const ADD_FREE_COURSE_BENCHMARK = gql`
  mutation addFreeCourseBenchmark($ids: [ID!]!) {
    addFreeCourseBenchmark(ids: $ids)
  }
`;

const DELETE_FREE_COURSE_BENCHMARK = gql`
  mutation deleteFreeCourseBenchmark($id: ID!) {
    deleteFreeCourseBenchmark(id: $id)
  }
`;

export {
  FREE_COURSE_BENCHMARKS,
  ADD_FREE_COURSE_BENCHMARK,
  DELETE_FREE_COURSE_BENCHMARK,
  GET_ALL_COURSES,
  CREATE_COURSE,
  UPDATE_COURSE,
  GET_COURSE_BY_ID,
  DELETE_COURSE,
  PUBLISH_COURSE,
  UNPUBLISH_COURSE,
  GET_GROUP_COURSE,
  GET_COURSE_BENCHMARK_ASSESSMENT,
  GET_STUDENT_COURSE_PROGRESS,
  CREATE_ASSESSMENT_CUSTOM_SETTINGS,
  DELETE_ASSESSMENT_CUSTOM_SETTINGS,
};
