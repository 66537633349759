import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  freeLessonSection: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  modal: {
    position: 'absolute',
    padding: theme.spacing(3),
    background: theme.palette.white.main,
    maxHeight: 500,
    overflow: 'scroll',
    width: '50%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  actions: {
    padding: 0,
    position: 'sticky',
    background: theme.palette.white.main,
    top: 'auto',
    left: 0,
    bottom: -16,
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: -16,
  },
  listItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,

    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  alreadySelected: {
    opacity: 0.7,
    background: theme.palette.divider,
  },
  noLessonsContainer: {
    padding: theme.spacing(2),
  },
  addFreeLessonButton: {
    marginTop: theme.spacing(2),
    display: 'flex',
    margin: 'auto',
  },
}));

export default styles;
