import gql from 'graphql-tag';
import { CaseStudyFields } from './fragments';

const ADD_CASE_STUDY = gql`
  mutation addCaseStudy($studyProjectId: ID!) {
    createCaseStudy(data: { study_project_id: $studyProjectId }) {
      ...CaseStudyFields
    }
  }
  ${CaseStudyFields}
`;

const PUBLISH_CASE_STUDY = gql`
  mutation publishCaseStudy($id: ID!) {
    publishCaseStudy(id: $id)
  }
`;

const UNPUBLISH_CASE_STUDY = gql`
  mutation unpublishCaseStudy($id: ID!) {
    unpublishCaseStudy(id: $id)
  }
`;

const DELETE_CASE_STUDY = gql`
  mutation deleteCaseStudy($id: ID!) {
    deleteCaseStudy(id: $id)
  }
`;

export {
  ADD_CASE_STUDY,
  PUBLISH_CASE_STUDY,
  UNPUBLISH_CASE_STUDY,
  DELETE_CASE_STUDY,
};
