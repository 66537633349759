import React, { useState, Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import {
  FormControl,
  Button,
  Box,
  Card,
  Typography,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import styles from './styles';
import { GET_QUESTION_BY_ID, DELETE_ANSWER } from '../../../apollo/queries';
import { TinyEditor, CheckUserAction } from '../../../components';
import { withSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FieldArray } from 'react-final-form-arrays';
import { validate } from './helpers';
import { SelectTypeReinforcedQuestions } from './components';
import { Feedback } from './components/';
import { DeleteButton } from '../../common';

const HAS_FEEDBACK = ['reinforced', 'case study'];
const MULTIPLE_CHOICE = 'MULTIPLE_CHOICE';
const SEQUENCE = 'SEQUENCE';
const MATCHING = 'MATCHING';
const TRUEORFALSE = 'TRUE_OR_FALSE';

const QuestionForm = ({
  note = null,
  questionId,
  onSubmit,
  variant = null,
  enqueueSnackbar,
  onCancel = null,
}) => {
  const classes = styles();
  const types = [
    { name: 'MULTIPLE_CHOICE' },
    { name: 'SEQUENCE' },
    { name: 'MATCHING' },
    { name: 'TRUE_OR_FALSE' },
    { name: 'CORRECT_OR_INCORRECT' }
  ];
  const { data, loading, error } = useQuery(GET_QUESTION_BY_ID, {
    variables: { where: { id: questionId } },
    skip: !questionId,
  });
  const [deleteAnswer] = useMutation(DELETE_ANSWER);
  const [promptDelete, setPromptDelete] = useState(null);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);
  const [initialValues, setInitialValues] = useState({
    type: types[0].name,
    answers: [{ is_correct: false }, { is_correct: false }],
  });

  React.useEffect(() => {
    if (data) {
      setInitialValues(data.question);
    }
  }, [data]);

  const deleteQuestionAnswer = async () => {
    try {
      setDisableDeleteButton(true);

      if (promptDelete.answerId) {
        await deleteAnswer({
          variables: { id: promptDelete.answerId },
          refetchQueries: ['questionById'],
          awaitRefetchQueries: true,
        });
      } else {
        setInitialValues({
          ...promptDelete.values,
          answers: promptDelete.values.answers.filter(
            (item, index) => index !== promptDelete.index,
          ),
        });
      }

      setPromptDelete(null);
      setDisableDeleteButton(false);
    } catch (error) {
      console.error(error);
      setDisableDeleteButton(false);
      enqueueSnackbar(
        'Oops! Something went wrong. Please refresh your browser and try again. If problem persists contact Tech Support.',
        { variant: 'error' },
      );
    }
  };

  const handleDeleteAnswers = (values, index) => {
    if (values.answers.length <= 2) {
      enqueueSnackbar('A Question must have a minimum of 2 responses', {
        variant: 'warning',
        autoHideDuration: 1500,
      });
      return;
    }

    const answer = values.answers[index];

    if (answer.id) {
      setPromptDelete({ answerId: answer.id, index });
    } else {
      setPromptDelete({ values, index });
    }
  };

  const TheForm = React.useCallback(() => {
    return (
      <Form
        mutators={{ ...arrayMutators }}
        validate={(values) => validate(values, variant)}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({
          handleSubmit,
          values,
          form,
          submitting,
          pristine,
          valid,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {variant === 'case study' ||
                ('reinforced' && (
                  // form control for case study component

                  <FormControl fullWidth style={{ marginBottom: 16 }}>
                    {/* <Field name="type">
                    {({ input, meta }) => (
                      <SelectTypeCaseStudy
                        input={input}
                        meta={meta}
                        types={types}
                      />
                    )}
                  </Field> */}
                  </FormControl>
                ))}
              {note && note}
              <FormControl fullWidth>
                <Field name="question">
                  {({ input, meta }) => (
                    <React.Fragment>
                      {/* form control for reinforcement questions component */}
                      <SelectTypeReinforcedQuestions
                        input={input}
                        meta={meta}
                        types={types}
                      />
                      <FormLabel style={{ color: 'black', marginBottom: 8 }}>
                        Question
                      </FormLabel>
                      <TinyEditor
                        showMediaLibraryButton={true}
                        showTinyMCEMediaOptions={true}
                        value={input.value}
                        onChange={(content) => {
                          input.onChange(content);
                        }}
                      />
                    </React.Fragment>
                  )}
                </Field>
              </FormControl>
              {values.type === types[2].name && ( //MATCHING
                <React.Fragment>
                  <Box
                    display="flex"
                    className={classes.answerCard}
                    my={2}
                    justifyContent="space-between"
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      Add sentence part one
                    </Typography>
                    <Typography>
                      <b>Add continuation of sentence</b>
                      <br /> (This section will be presented randomly.)
                    </Typography>
                  </Box>
                  <FieldArray name="answers">
                    {({ fields }) =>
                      fields.map((name, index) => {
                        return (
                          <Card className={classes.answerCard} key={index}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-evenly"
                            >
                              <FormControl
                                className={classes.answerControls}
                                style={{ marginRight: 8 }}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                >
                                  <Box display="flex" flexDirection="column">
                                    <Field name={`${name}.answer`}>
                                      {({ input, meta }) => (
                                        <TinyEditor
                                          showMediaLibraryButton={true}
                                          showTinyMCEMediaOptions={true}
                                          value={input.value}
                                          onChange={(content) => {
                                            input.onChange(content);
                                          }}
                                          placeholder={getPlaceholder('SENTENCE', index)}
                                        />
                                      )}
                                    </Field>
                                    <Field name={`${name}.match`}>
                                      {({ input, meta }) => (
                                        <Box display="flex" mt={1}>
                                          <TinyEditor
                                            showMediaLibraryButton={true}
                                            showTinyMCEMediaOptions={true}
                                            value={input.value}
                                            onChange={(content) => {
                                              input.onChange(content);
                                            }}
                                            placeholder={getPlaceholder('CONTINUATION', index)}
                                          />
                                        </Box>
                                      )}
                                    </Field>
                                  </Box>
                                  <Box ml={2}>
                                    <DeleteButton
                                      onClick={() => {
                                        handleDeleteAnswers(values, index);
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </FormControl>
                            </Box>
                          </Card>
                        );
                      })
                    }
                  </FieldArray>
                </React.Fragment>
              )}

              {values.type !== types[2].name && (
                <FieldArray name="answers">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      return (
                        <Card
                          className={classes.answerCard}
                          key={index}
                          style={{}}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{ backgroundColor: '#DEF0FC' }}
                          >
                            <Typography
                              variant="subtitle2"
                              style={{ marginLeft: 8 }}
                            >
                              Write your question
                            </Typography>
                          </Box>
                          <FormControl
                            fullWidth
                            className={classes.answerControls}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                            >
                              <Field name={`${name}.answer`}>
                                {({ input, meta }) => (
                                  <TinyEditor
                                    showMediaLibraryButton={true}
                                    showTinyMCEMediaOptions={true}
                                    value={input.value}
                                    onChange={(content) => {
                                      input.onChange(content);
                                    }}
                                  />
                                )}
                              </Field>

                              {values.type === 'MULTIPLE_CHOICE' && (
                                <Field
                                  name={`${name}.is_correct`}
                                  type="checkbox"
                                >
                                  {({ input, meta }) => (
                                    <FormControlLabel
                                      style={{ marginLeft: '5px' }}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={input.checked}
                                          onChange={(value) =>
                                            input.onChange(value)
                                          }
                                        />
                                      }
                                      label="Correct"
                                      labelPlacement="end"
                                    />
                                  )}
                                </Field>
                              )}

                              {(values.type === 'TRUE_OR_FALSE' || values.type === 'CORRECT_OR_INCORRECT') && (
                                <Field
                                  name={`${name}.is_correct`}
                                  type="checkbox"
                                >
                                  {({ input, meta }) => {
                                    return (
                                      <React.Fragment>
                                        <FormControlLabel
                                          style={{ marginLeft: '5px' }}
                                          id="true"
                                          control={
                                            <Checkbox
                                              name={input.name}
                                              color="primary"
                                              checked={
                                                input.checked === true
                                                  ? true
                                                  : false
                                              }
                                              onChange={(value) =>
                                                input.onChange(true)
                                              }
                                              index={index}
                                            />
                                          }
                                          label={values.type === 'TRUE_OR_FALSE' ? 'True' : 'Correct'}
                                        />
                                        <FormControlLabel
                                          id="false"
                                          control={
                                            <Checkbox
                                              name={input.name}
                                              color="primary"
                                              onChange={(value) =>
                                                input.onChange(false)
                                              }
                                              checked={
                                                input.checked !== true
                                                  ? true
                                                  : false
                                              }
                                              index={index}
                                            />
                                          }
                                          label={values.type === 'TRUE_OR_FALSE' ? 'False' : 'Incorrect'}
                                        />
                                      </React.Fragment>
                                    );
                                  }}
                                </Field>
                              )}
                              <DeleteButton
                                onClick={() => {
                                  handleDeleteAnswers(values, index);
                                }}
                              />
                            </Box>
                          </FormControl>
                        </Card>
                      );
                    })
                  }
                </FieldArray>
              )}
              <FormControl>
                <Button
                  className={classes.addButton}
                  onClick={() =>
                    form.mutators.push('answers', {
                      is_correct: false,
                      answer: '',
                    })
                  }
                  variant="contained"
                  color="primary"
                >
                  Add {getAnswerName(values.type)}
                </Button>
              </FormControl>
              {HAS_FEEDBACK.includes(variant) && (
                <React.Fragment>
                  <FormControl fullWidth style={{ marginBottom: 16 }}>
                    <Field name="incorrect_msg">
                      {({ input, meta }) => (
                        <Feedback
                          text="1st Error Feedback - Hint"
                          input={input}
                        />
                      )}
                    </Field>
                  </FormControl>
                  {variant !== 'benchmark' && (
                    <Fragment>
                      <FormControl fullWidth style={{ marginBottom: 16 }}>
                        <Field name="incorrect_msg_two">
                          {({ input, meta }) => (
                            <Feedback
                              text="2nd Error Feedback - Answer Reveal"
                              input={input}
                            />
                          )}
                        </Field>
                      </FormControl>
                      <FormControl fullWidth style={{ marginBottom: 16 }}>
                        <Field name="correct_msg">
                          {({ input, meta }) => (
                            <Feedback text="Correct Feedback" input={input} />
                          )}
                        </Field>
                      </FormControl>
                    </Fragment>
                  )}
                </React.Fragment>
              )}
              <Box display="flex" justifyContent="flex-end" mb={2}>
                {onCancel && (
                  <Button
                    disabled={submitting}
                    type="button"
                    color="primary"
                    variant="text"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  disabled={!valid || submitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </form>
          );
        }}
      />
    );
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  return (
    <React.Fragment>
      <CheckUserAction
        open={Boolean(promptDelete)}
        title="Delete Answer"
        content="Are you sure you want to delete this answer?"
        onClose={() => setPromptDelete(null)}
        onClick={deleteQuestionAnswer}
        disableButtons={disableDeleteButton}
      />
      <TheForm />
    </React.Fragment>
  );
};

/**
 * function to get answer name
 * @param {string} type
 * @returns {string}
 */
const getAnswerName = (type) => {
  switch (type) {
    case MULTIPLE_CHOICE:
      return 'Choice';
    case SEQUENCE:
      return 'Sequence';
    case TRUEORFALSE:
      return 'Question';
    case MATCHING:
      return 'Matching Question';
    default:
      return 'Answer';
  }
};

const getPlaceholder = (variant, index) => {
  switch (variant) {
    case 'SENTENCE':
      return `Sentence Part ${index + 1}`;
    case 'CONTINUATION':
      return `Add Continuation ${index + 1}`;
    default:
      return '';
  }
};

export default withSnackbar(QuestionForm);
