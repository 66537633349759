import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  card: {
    height: '100%',
    width: '100%',
    boxShadow: 'none'
  },
  cardActionArea: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '0px 0px',
    overflowY: 'auto'
  },
  cardContent: {
    flex: 1,
    padding: '0px 0px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardContentTerm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    maxWidth: 745,
    padding: '0 1em'
  },
  box: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    maxWidth: 745,
    padding: '1em 1em 0 1em'
  },
  buttonActiveCC: {
    backgroundColor: '#BD161C',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#A11419'
    }
  },
  buttonCloseCC: {
    backgroundColor: '#717073',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#5D5D5F'
    }
  },
  videoContainer: {
    width: '100%',
    overflowY: 'auto',
    boxShadow:'0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0',
    flex: 1,
  }
}));

export default styles;