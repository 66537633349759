import React from 'react';
import { Button, Box, CircularProgress, Typography } from '@material-ui/core';
import styles from './styles';
import UserList from './UserList';

const InstitutionSearchResults = ({
  data,
  loading,
  error,
  fetchMore,
  history,
  match,
}) => {
  const classes = styles();

  if (loading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.userSearchResultsContainer}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (error || !data) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.userSearchResultsContainer}
      >
        <Typography variant="h6" color="textSecondary">
          Could not find any results.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.userSearchResultsContainer}>
      <UserList
        onSelect={(user) => {}}
        users={data.paginatedUsers ? data.paginatedUsers.users : []}
      />
      {data.paginatedUsers && data.paginatedUsers.hasMore && (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.fetchMoreButton}
            onClick={() =>
              fetchMore({
                variables: {
                  after: data.paginatedUsers.cursor,
                },
                updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    ...fetchMoreResult,
                    paginatedUsers: {
                      ...fetchMoreResult.paginatedUsers,
                      users: [
                        ...prev.paginatedUsers.users,
                        ...fetchMoreResult.paginatedUsers.users,
                      ],
                    },
                  };
                },
              })
            }
          >
            More Results
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default InstitutionSearchResults;
