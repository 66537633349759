import { useMutation } from "@apollo/react-hooks";
import { ADD_GROUP_MEMBERS, UPDATE_GROUP_MEMBERS } from "../queries";

export function useUpdateGroupMembers() {
  const [updateGroupMembers,{ data, loading }] = useMutation(UPDATE_GROUP_MEMBERS, {
    refetchQueries: ['getGroupById', 'getUserInstitutions']
  });

  return {
    updateGroupMembers, 
    data,
    loading
  }
}

export function useAddGroupMembers() {
  const [addGroupMembers, { data, loading }] = useMutation(ADD_GROUP_MEMBERS, {
    refetchQueries: ['getGroupById', 'getUserInstitutions']
  });

  return {
    addGroupMembers,
    data,
    loading
  }
}