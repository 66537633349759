import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Typography,
  Box,
  FormControl,
  TextField,
  FormHelperText,
  Button,
} from '@material-ui/core';
import styles from './styles';
import shortid from 'shortid';

const JoinGroupForm = ({ onCancel, onSubmit, joinError, resetError }) => {
  const classes = styles();

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        let error = {};
        if (!values.key) error.key = 'is required';
        if (values.key && !shortid.isValid(values.key))
          error.key = 'please enter a valid code';

        return error;
      }}
      render={({ handleSubmit, valid, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Typography className={classes.bodyText}>
            Enter the{' '}
            <Box component="span" fontStyle="bold">
              Group Key
            </Box>{' '}
            provided by your instructor to enroll now. Instructors wanting to
            join must be added to the group by the main instructor
          </Typography>
          <FormControl fullWidth>
            <Field name="key">
              {({ input }) => (
                <TextField
                  id="key"
                  name="key"
                  variant="filled"
                  label="Group Key"
                  type="text"
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  value={input.value}
                  onChange={(value) => {
                    if (joinError) {
                      resetError();
                    }
                    input.onChange(value);
                  }}
                />
              )}
            </Field>
            {joinError && (
              <FormHelperText className={classes.helperText}>
                {joinError}
              </FormHelperText>
            )}
            <FormHelperText>code is case-sensitive</FormHelperText>
          </FormControl>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mt={2}
          >
            <Box mr={2}>
              <Button
                type="button"
                variant="outlined"
                color="default"
                onClick={() => onCancel()}
                disabled={submitting}
              >
                Cancel
              </Button>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={pristine || !valid || submitting || Boolean(joinError)}
            >
              {submitting ? 'Joining...' : 'Join'}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default JoinGroupForm;
