import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  '@keyframes pulse': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(0.8)' },
    '100%': { transform: 'scale(1)' },
  },
  container: {
    height: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  benchmarkFormWrapper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  warningIcon: {
    color: theme.palette.primary.main,
    animation: '$pulse 2s linear 0s infinite',
    marginRight: theme.spacing(2),
  },
}));
