import React from 'react';
import {
  Chip,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';
import styles from './styles';

const InstitutionList = ({ onSelect, institutions, selectedInstitution }) => {
  const chipNoLisense = '#BF1212';
  const chipLisense = '#333434';
  const classes = styles();

  if (!institutions.length) {
    return (
      <Box p={2}>
        <Typography variant="h5">No Institutions</Typography>
      </Box>
    );
  }
  return (
    <List
      component="nav"
      aria-label="institution list"
      className={classes.root}
    >
      {institutions.map((item) => (
        <ListItem button key={item.id} divider={true}>
          <ListItemText
            key={item.id}
            onClick={() => onSelect(item)}
            primary={
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Box component="span">{item.name}</Box>
                </Grid>
                <Grid item>
                  <Chip
                    style={
                      item.licenses.filter(
                        (license) => license.days_to_expire > 0,
                      ).length > 0
                        ? { background: chipLisense }
                        : { background: chipNoLisense }
                    }
                    label={
                      item.licenses.filter(
                        (license) => license.days_to_expire > 0,
                      ).length > 0
                        ? item.licenses.filter(
                            (license) => license.days_to_expire > 0,
                          ).length + ' Active Licenses'
                        : 'No License'
                    }
                    className={classes.chipInfomation}
                  />
                </Grid>
              </Grid>
            }
            secondary={
              <Box component="span">
                Sales Manager:{' '}
                {item.sales_manager
                  ? `${item.sales_manager.first_name} ${item.sales_manager.last_name}`
                  : 'Not Assigned'}
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default InstitutionList;
