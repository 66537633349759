import React from 'react';
import {
  Card,
  CardActionArea,
  Box,
  CardHeader,
  Typography,
  Avatar,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import DoneIcon from '@material-ui/icons/Done';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import styles from './styles';

export const CourseDashboardBenchmarkCard = ({
  borderColor,
  level,
  onClick,
  complete,
  ...restProps
}) => {
  const classes = styles();
  return (
    <Card
      className={classes.card}
      style={{ borderTop: `2px solid ${borderColor}` }}
      {...restProps}
    >
      <CardActionArea onClick={onClick}>
        <CardHeader
          avatar={
            <AssessmentOutlinedIcon fontSize="large" className={classes.icon} />
          }
          title={
            level === 'all'
              ? `All Levels Benchmark Assessment`
              : `Level ${level} Benchmark Assessment`
          }
        />
        {complete && (
          <div className={classes.completedContainer}>
            <DoneIcon fontSize="small" color="inherit" />
            <Typography variant="h6" color="inherit">
              Completed
            </Typography>
          </div>
        )}
      </CardActionArea>
    </Card>
  );
};

export const CourseDashboardProjectCard = ({
  borderColor,
  level,
  logo,
  onClick,
  complete,
  locked,
  typeOfStudyProject,
  studyProjectNumber,
  draft,
  ...restProps
}) => {
  const classes = styles();

  const logoToStudyProjectCard = typeOfStudyProject?.search('Learn') >= 0 ? 'https://res.cloudinary.com/jasperactive/image/upload/v1638598152/Learn.svg': typeOfStudyProject?.search('Apply') >= 0 ? 'https://res.cloudinary.com/jasperactive/image/upload/v1638598169/Project.svg' : logo;
  const typeOf = typeOfStudyProject?.search('Learn') >= 0 ? 'Learn': typeOfStudyProject?.search('Apply') >= 0 ? 'Apply' : null;

  return (
    <Card
      className={classes.card}
      style={{ borderTop: `2px solid ${borderColor}` }}
      {...restProps}
    >
      <CardActionArea onClick={onClick}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CardHeader
            avatar={<Avatar src={logoToStudyProjectCard} />}
            title={`Study Project ${studyProjectNumber ? '#'+studyProjectNumber : ''}`}
            subheader={`${typeOf ? typeOf : ''}`}
          />
          {locked && (
            <Box mr={2}>
              <LockIcon className={classes.lockIcon} />
            </Box>
          )}
        </Box>
        {complete && !draft && (
          <div className={classes.completedContainer}>
            <DoneIcon fontSize="small" color="inherit" />
            <Typography variant="h6" color="inherit">
              Completed
            </Typography>
          </div>
        )}
        {draft && (
          <div className={classes.draftContainer}>
            <Typography variant="h6" color="inherit">
              Started
            </Typography>
          </div>
        )}
      </CardActionArea>
    </Card>
  );
};

export const CourseDashboardSummaryCard = ({
  borderColor,
  level,
  onClick,
  complete,
  ...restProps
}) => {
  const classes = styles();
  return (
    <Card
      className={classes.card}
      style={{ borderTop: `2px solid ${borderColor}` }}
      {...restProps}
    >
      <CardActionArea onClick={onClick}>
        <CardHeader
          avatar={
            <AssessmentOutlinedIcon fontSize="large" className={classes.icon} />
          }
          title={
            level === 'all'
              ? `All Levels Summary Assessment`
              : `Level ${level} Summary Assessment`
          }
        />
        {complete && (
          <div className={classes.completedContainer}>
            <DoneIcon fontSize="small" color="inherit" />
            <Typography variant="h6" color="inherit">
              Completed
            </Typography>
          </div>
        )}
      </CardActionArea>
    </Card>
  );
};