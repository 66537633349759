import React from 'react';
import { Button } from '@material-ui/core';
import { CloudinaryContext } from '../../../providers/CloudinaryProvider';

const CloudinaryMediaWidget = ({ onInsert, multiple = true, maxFiles = 0 }) => {
  const { getMediaWidgetConfig } = React.useContext(CloudinaryContext);
  const [loading, setLoading] = React.useState(false);
  const widgetRef = React.useRef(null);

  if (!multiple)
    maxFiles = 1;

  const options = {
    multiple
  };

  if (maxFiles !== 0)
    options["max_files"] = maxFiles;

  const openWidget = (e) => {
    e.stopPropagation();
    setLoading(true);

    if (!widgetRef.current) {
      widgetRef.current = window.cloudinary.createMediaLibrary(getMediaWidgetConfig(), { insertHandler: insertImageHandler, showHandler: () => { setLoading(false) } });
    }

    widgetRef.current.show(options);
  };

  const insertImageHandler = (data) => {
    onInsert(data.assets);
  };

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={loading}
      onClick={openWidget}>Media Library
    </Button>
  );
}

export default CloudinaryMediaWidget;