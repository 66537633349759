import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  
  chipInfomation:{
    /* Layout Propieties*/
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    right: '0px',
    backgroundColor: '#333434',

    /* UI Properties*/
    textAlign: 'center',
    letterSpacing:'0.3px',
    color: '#FFFFFF',
    opacity: 1,
    marginRight: '16px',
  },
 
}));
