import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  startContainer: {
    '& p': {
      marginBottom: theme.spacing(2),
    },
  },
  imageContainer: {
    width: '75%',
    '& img': {
      height: 'auto',
      width: '100%',
      maxWidth: 400,
    },
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  button: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  startButton: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      order: 1,
      marginBottom: 0,
    },
  },
  cancelButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  caseStudyText: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
}));
