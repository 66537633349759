import gql from 'graphql-tag';
import { UserFields } from './fragments';

const GET_INVITATIONS_BY_INSTITUTION_ID = gql`
  query getInstitutionInvitation($where: InstitutionInvitationInput!) {
    institutionInvitations(where: $where) {
      id
      first_name
      last_name
      email
      role
      created_by {
        ...UserFields
      }
      created_on
    }
  }
  ${UserFields}
`;

// TO BE DELETED - NOT DESCRIPTIVE ENOUGH
const CREATE_INVITATIONS = gql`
  mutation createInvitations(
    $institutionId: ID!
    $data: [InstitutionInvitationCreateInput!]!
  ) {
    createInstitutionInvitation(institutionId: $institutionId, data: $data) {
      id
      first_name
      last_name
      email
      role
      created_by {
        ...UserFields
      }
      created_on
    }
  }
  ${UserFields}
`;

const INVITE_INSTITUTION_MEMBER = gql`
  mutation inviteInstitutionMember($data: InstitutionInviteCreateInput!) {
    inviteInstitutionMember(data: $data) {
      id
      first_name
      last_name
      email
      role
      created_by {
        ...UserFields
      }
      created_on
    }
  }
  ${UserFields}
`;

const DELETE_INSTITUTE_INVITATION = gql`
  mutation deleteInstitutionInvitation($id: ID!) {
    deleteInstitutionInvite(id: $id) {
      success
      message
    }
  }
`;

const UPDATE_INSTITUTE_INVITATION = gql`
  mutation updateInvitedInstiutionMember(
    $id: ID!
    $data: InstitutionInviteUpdateInput!
  ) {
    updateInstitutionInvitationMember(id: $id, data: $data) {
      id
      first_name
      last_name
      email
      role
      created_by {
        ...UserFields
      }
      created_on
    }
  }
  ${UserFields}
`;

export {
  GET_INVITATIONS_BY_INSTITUTION_ID,
  INVITE_INSTITUTION_MEMBER,
  CREATE_INVITATIONS,
  DELETE_INSTITUTE_INVITATION,
  UPDATE_INSTITUTE_INVITATION,
};
