import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  groupCard: {
    boxShadow: theme.shadows[2],
    position: 'relative'
  },
  status: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(0.5),
    marginRight: theme.spacing(2)
  },
  statusIndicator: {
    width: 9,
    height: 9,
    borderRadius: '100%',
    background: theme.palette.grey[300],
    marginRight: theme.spacing(1)
  },
  statusGreen: {
    background: theme.palette.success.main
  },
  statusYellow: {
    background: theme.palette.warning.main
  },
  statusRed: {
    background: theme.palette.error.main
  },
}));
