import React, { Fragment, useState } from 'react';
import {
  DrawerShell,
  DarkChip,
  AddButton,
  QuickclipForm,
  EditButton,
  DeleteButton,
  CheckUserAction,
  ResourceCard,
} from '../../../../../../components';
import {
  Grid,
  Box,
  Typography,
  Chip,
  CardActionArea,
  Card,
  CardContent,
  CardActions,
  Button,
  Avatar,
} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  CMS_GET_LESSONS,
  CREATE_QUICKCLIP_CARD,
  UPDATE_QUICKCLIP_CARD,
  DELETE_QUICKCLIP_CARD,
  PUBLISH_QUICKCLIP_CARD,
  UNPUBLISH_QUICKCLIP_CARD,
} from '../../../../../../apollo/queries';
import styles from './styles';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { withSnackbar } from 'notistack';
import omitDeep from 'omit-deep';
import { clone } from 'ramda';
import { auditDataFields } from '../../../../../../lib';
import { useIsManager } from '../../../IsManager';

const DevelopCourseClip = ({ course }) => {
  const classes = styles();

  const { data, loading, error } = useQuery(CMS_GET_LESSONS, {
    variables: { courseId: course.id },
  });

  const [selectedContent, setSelectedContent] = useState(null);

  const handleClick = (lesson) => {
    const index = lessons.findIndex((l) => l.id === lesson.id);

    if (index !== -1) {
      setSelectedContent(index);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const lessons = data.lessons;

  return (
    <DrawerShell
      drawer={
        <Box p={2}>
          {lessons.map((lesson) => (
            <Card key={lesson.id} className={classes.card}>
              <CardActionArea onClick={() => handleClick(lesson)}>
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  className={classes.cardContent}
                >
                  <Avatar alt={lesson.name} src={lesson.logo} />

                  <div style={{ marginLeft: 8, marginRight: 'auto' }}>
                    <Typography variant="subtitle2">
                      Lesson #{lesson.display_order}
                    </Typography>
                    <Typography variant="caption">{lesson.name}</Typography>
                  </div>

                  {lesson.clip_cards.length <= 0 ? (
                    <DarkChip label="No Cards" size="small" />
                  ) : (
                    <Chip
                      size="small"
                      label={`${lesson.clip_cards.length} Card(s)`}
                      color="primary"
                    />
                  )}
                </Box>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      }
      content={
        <Fragment>
          {selectedContent !== null ? (
            <LessonCards lesson={lessons[selectedContent]} />
          ) : (
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <BookOutlinedIcon className={classes.icon} />
              <Typography variant="h3" color="textSecondary">
                Let's Get Started
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Select a Lesson on the left to manage its Quickclip Cards.
              </Typography>
            </Box>
          )}
        </Fragment>
      }
    />
  );
};

const LessonCards = withSnackbar(({ lesson, enqueueSnackbar }) => {
  const classes = styles();
  const cards = lesson.clip_cards;
  const refetchQueries = ['getCMSLessons'];
  const isManager = useIsManager();

  const [deleteCardId, setDeleteCardId] = useState(null);
  const [showCardForm, setShowCardForm] = useState([false, null]);

  const [createCard] = useMutation(CREATE_QUICKCLIP_CARD, {
    refetchQueries,
  });
  const [updateCard] = useMutation(UPDATE_QUICKCLIP_CARD);
  const [deleteCard] = useMutation(DELETE_QUICKCLIP_CARD, { refetchQueries });
  const [publishCard] = useMutation(PUBLISH_QUICKCLIP_CARD, { refetchQueries });
  const [unpublishCard] = useMutation(UNPUBLISH_QUICKCLIP_CARD, {
    refetchQueries,
  });

  const handleInsertTrackHtmlTagInVideo= (video, track) => {
    let templateVideo = document.createElement('template');
    let templateTrack = document.createElement('template');
    let tempVideo;
    let tempTrack;

    if (track) {
      templateVideo.innerHTML = video.trim();
      templateTrack.innerHTML = track.trim();
  
      const videoTag = templateVideo.content.querySelector('video');
      const trackTag = templateTrack.content.querySelector('track');

      if (videoTag) {
        if (videoTag.querySelector('track')) {
          templateVideo.content.querySelector('video').removeChild(videoTag.querySelector('track'));
        }

        if (trackTag) {
          const fixedEncodeURIComponent = (str)  => {
            return encodeURIComponent(str).replace(/[!'()]/g, escape).replace(/\*/g, "%2A");
          };

          templateTrack.content.querySelector('img').setAttribute('src', 'https://res.cloudinary.com/jasperactive/image/upload/v1642012327/insert_drive_file_black_24dp.svg');
          templateTrack.content.querySelector('img').setAttribute('data-mce-html', fixedEncodeURIComponent(trackTag.outerHTML));
          templateTrack.content.querySelector('img').setAttribute('data-mce-object', 'img');
          templateTrack.content.querySelector('img').setAttribute('data-mce-selected', '1');
          tempTrack = templateTrack.content.firstElementChild;

          templateVideo.content.querySelector('video').appendChild(trackTag.cloneNode(true));
          tempVideo = templateVideo.content.firstElementChild;
        } else {
          tempTrack = templateTrack.content.firstElementChild;
          tempVideo = templateVideo.content.firstElementChild;
        }
      } else {
        tempTrack = templateTrack.content.firstElementChild;
        tempVideo = templateVideo.content.firstElementChild;
      }
      
      const trackHtmlString = tempTrack.outerHTML;
      const videoHtmlString = tempVideo.outerHTML;
  
      return {
        trackHtmlString,
        videoHtmlString
      }
    } else {
      return {
        trackHtmlString: '',
        videoHtmlString: video
      }
    }
  };

  const handlePublish = async (id, published) => {
    try {
      if (published) {
        await unpublishCard({ variables: { id } });
      } else {
        await publishCard({ variables: { id } });
      }
      enqueueSnackbar(
        `Successfully ${published ? 'Unpublished' : 'Published'
        } Quickdeck Card!`,
        { variant: 'success', autoHideDuration: 1500 },
      );
    } catch (error) {
      enqueueSnackbar(
        'Failed to update Quickdeck Card. If problem persists please contact Tech Support.',
        { variant: 'error' },
      );
    }
  };

  const handleSubmit = async (values) => {
    const {videoHtmlString, trackHtmlString} = handleInsertTrackHtmlTagInVideo(values.video, values.video_with_subtitles);
    values['video'] = videoHtmlString;
    values['video_with_subtitles'] = trackHtmlString;

    try {
      const valuesToOmit = ['id', '__typename', ...auditDataFields];
      if (values.id) valuesToOmit.push('lesson_id');

      const data = omitDeep(clone(values), valuesToOmit);
      if (values.id) {
        await updateCard({ variables: { data, where: { id: values.id } } });
      } else {
        await createCard({ variables: { data } });
      }
      enqueueSnackbar(
        `Successfully ${values.id ? 'updated' : 'created'} Quickdeck Card`,
        { variant: 'success', autoHideDuration: 1500 },
      );
    } catch (error) {
      enqueueSnackbar(
        `Something went wrong! If the problem persist please contact Tech Support.`,
        { variant: 'error' },
      );
    }
    setShowCardForm([false, null]);
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <CheckUserAction
        open={Boolean(deleteCardId)}
        title="Delete Quickdeck Card"
        content={
          <Typography variant="body1">
            Are you sure you want to delete this card?
          </Typography>
        }
        onClose={() => setDeleteCardId(null)}
        onClick={async () => {
          try {
            await deleteCard({ variables: { id: deleteCardId } });
            setDeleteCardId(null);
            enqueueSnackbar('Successfuly deleted Card', {
              variant: 'success',
              autoHideDuration: 1500,
            });
          } catch (error) {
            enqueueSnackbar(
              'Something went wrong. If problem persist please contact Tech Support',
              {
                variant: 'error',
              },
            );
          }
        }}
      />
      <QuickclipForm
        open={showCardForm[0]}
        card={showCardForm[1]}
        onCancel={() => setShowCardForm([false, null])}
        lessonId={lesson.id}
        onSubmit={handleSubmit}
      />
      <Box display="flex" alignItems="center" p={2}>
        <Typography variant="h5" style={{ marginLeft: 8 }}>
          {lesson.name} Cards
        </Typography>
        <AddButton
          title="Add Quickclip Card"
          style={{ marginLeft: 'auto' }}
          onClick={() => setShowCardForm([true, null])}
        />
      </Box>
      {cards.length <= 0 && (
        <Grid item xs={12} className={classes.noCards}>
          <EditIcon className={classes.icon} />
          <Typography variant="h3" color="textSecondary">
            No Quickclip Cards
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            click Add Card to get started
          </Typography>
          <AddButton
            title="Add Quickclip Card"
            onClick={() => setShowCardForm([true, null])}
          />
        </Grid>
      )}
      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
        {cards.map((card) => (
          <Card style={{ margin: 8, width: "350px" }}>
            <CardActions>
              <Chip
                size="small"
                color={card.published ? 'primary' : 'default'}
                label={card.published ? 'Published' : 'Draft'}
              />
              <EditButton
                style={{ marginLeft: 'auto' }}
                onClick={() => setShowCardForm([true, card])}
              />
              {isManager && (
                <DeleteButton onClick={() => setDeleteCardId(card.id)} />
              )}
            </CardActions>
            <CardContent>
              <ResourceCard title={card.title} text1_label="Clip" text1={card.video} text2_label="Subtitles file" text2={card.video_with_subtitles} />
            </CardContent>
            {isManager && (
              <CardActions>
                <Button
                  style={{ marginLeft: 'auto' }}
                  onClick={() => handlePublish(card.id, card.published)}
                  variant="text"
                  color="primary"
                >
                  {card.published ? 'Unpublish' : 'Publish'}
                </Button>
              </CardActions>
            )}
          </Card>
        ))}
      </Box>
    </Box>
  );
});

export default DevelopCourseClip;
