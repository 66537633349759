export const getGradebookBenchmarks = (benchmarks, studentOverview) => {
  return benchmarks
    .filter((bm) => bm.published)
    .map((bm) => {
      const studentData = studentOverview.benchmarks.find(
        (benchmark) => benchmark.id === bm.id,
      );

      return {
        ...bm,
        ...studentData,
      };
    });
};

export const getGradebookSummaries = (benchmarks, studentOverview) => {
  return benchmarks
    .filter((bm) => bm.published)
    .map((bm) => {
      const studentData = studentOverview.summaries.find(
        (benchmark) => benchmark.id === bm.id,
      );

      return {
        ...bm,
        ...studentData,
      };
    });
};

export const getGradebookLessons = (lessons, studentOverview) => {
  return lessons
    .filter((lesson) => lesson.published)
    .map((lesson) => {
      const studentLessonData = studentOverview.lessons.find(
        (l) => l.id === lesson.id,
      );
      const studyProjects = lesson.studyProjects
        .filter((sp) => sp.published)
        .map((sp) => {
          const studentData = studentLessonData.studyProjects.find(
            (studyProject) => studyProject.id === sp.id,
          );

          return {
            ...sp,
            ...studentData,
          };
        });

      return {
        ...lesson,
        ...studentLessonData,
        studyProjects,
      };
    })
    .filter((lesson) => lesson.studyProjects.length >= 1);
};

export const getLessonLevelProgress = (studyProject) => {
  if(!studyProject.complete) return ;
  return Math.round((studyProject.correctAnswers / studyProject.totalQuestions) * 100);
};


export const getAssessmentLevelProgress = (assessment) => {
  const questions = assessment.totalQuestions;
  const answers = assessment.correctAnswers;

  return Math.round((answers / questions) * 100);
};

export const getAssessmentLevelAllProgress = (assessments) => {
  let progress = 0;
  let totalQuestions = 0;

  let assessmentsComplete =  assessments.filter((a) => a.complete);
  
  if(assessmentsComplete.length === 0){
    return progress = undefined;
  }

  assessmentsComplete.forEach((assessment) => {
    return totalQuestions += assessment.totalQuestions;
  });
  let totalAnswers = 0;
  assessmentsComplete.forEach((assessment) => {
    return totalAnswers += assessment.correctAnswers;
  });
  progress = Math.round((totalAnswers / totalQuestions) * 100);
  return progress;
};

export const getTotalQuestions = (benchmarks) => {
  let totalQuestions = 0;
  let benchmarkComplete = benchmarks.filter((bm) => bm.complete);

  benchmarkComplete.forEach((benchmark) => {
    return totalQuestions += benchmark.totalQuestions;
  });
  return totalQuestions;
};

export const getTotalCorrectAnswers = (benchmarks) => {
  let totalAnswers = 0;
  let benchmarkComplete = benchmarks.filter((bm) => bm.complete);

  benchmarkComplete.forEach((benchmark) => {
    return totalAnswers += benchmark.correctAnswers;
  });
  return totalAnswers;
};
