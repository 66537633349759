import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  answerItem: {
    position: 'relative',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    border: '2px solid rgba(0,0,0,0.42)',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#10AC84',
      transform: 'translateX(-100%)',
      transition: 'all 0.2s ease-in-out',
      zIndex: -1,
    },

    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& p': {
      margin: 0,
    },
  },
  answerItemActive: {
    border: `2px solid #10AC84`,
    color: '#fff',
    '&:after': {
      transform: 'translateX(0)',
    },
    backgroundColor: '#10AC84',
  },
  answerList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  answerMatching: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
