import React from 'react';
import { Button, Box, CircularProgress, Typography } from '@material-ui/core';
import styles from './styles';
import LicenseRenewalList from '../LicenseRenewalList';

const LicenseSearchResults = ({
  data,
  loading,
  error,
  fetchMore,
  history,
  match,
}) => {
  const classes = styles();

  if (loading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.licenseSearchResultsContainer}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (error || !data) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.licenseSearchResultsContainer}
      >
        <Typography variant="h6" color="textSecondary">
          Could not find any results.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.licenseSearchResultsContainer}>
      <LicenseRenewalList
        onSelect={(license) =>
          history.push(`${match.path}/institution/${license.institution.id}`)
        }
        licenses={data.paginatedLicenses ? data.paginatedLicenses.licenses : []}
      />
      {data.paginatedLicenses && data.paginatedLicenses.hasMore && (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.fetchMoreButton}
            onClick={() =>
              fetchMore({
                variables: {
                  after: data.paginatedLicenses.cursor,
                },
                updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    ...fetchMoreResult,
                    paginatedLicenses: {
                      ...fetchMoreResult.paginatedLicenses,
                      licenses: [
                        ...prev.paginatedLicenses.licenses,
                        ...fetchMoreResult.paginatedLicenses.licenses,
                      ],
                    },
                  };
                },
              })
            }
          >
            More Results
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default LicenseSearchResults;
