import { 
  Box, 
  Button, 
  Card, 
  CardContent,
  List,
  Typography
} from '@material-ui/core';
import React from 'react';
import { Heading } from '../../../../components';
import styles from './styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useCourse } from '../../providers/CourseProvider';
import ListItemResource from './ListItemResource';
import ListItemResourceByLesson from './ListItemResourceByLesson';

const InstructorResources = () => {
  const classes = styles();

  const course = useCourse();

  const allInstructorResourcesZip = course.instructorResources.find((resource) => resource.type === null);

  const sortResourcesByType = (instructorResources) => {
    const sortedResourcesByType = instructorResources.reduce((acc, resource) => {
      if (resource.type !== null) {
        if (acc[resource.type]) {
          acc[resource.type] = [
            ...acc[resource.type],
            resource
          ];
        } else {
          acc[resource.type] = [resource];
        }
      }

      return acc;
    }, {});

    return sortedResourcesByType;
  };

  return (
    <section className={classes.instructorResourcesSection}>
      <Box mb={2}>
        <Heading variant="h2" component="h2">
          Instructor Resources
        </Heading>
      </Box>
      <Box mb={6}>
        <Card variant="outlined">
          <Box className={classes.resourcesHeader}>
            {
              allInstructorResourcesZip &&             
                <a className={classes.linkToDowloadAll} href={allInstructorResourcesZip.url} download>
                  <Button color='primary' variant='contained' endIcon={<CloudDownloadIcon />} >DOWNLOAD ALL</Button>
                </a>
            }
          </Box>
          <CardContent style={{padding: '0 1em'}} >
            <div>
              <List dense={true}>
                {
                  course.instructorResources.length === 0 && <Typography variant='subtitle1' >There're no any resource yet</Typography>
                }
                {
                  //If there is one o more resource of type BENCHMARK showing
                  sortResourcesByType(course.instructorResources)['BENCHMARK'] && sortResourcesByType(course.instructorResources)['BENCHMARK'].length >= 1 ?
                  sortResourcesByType(course.instructorResources)['BENCHMARK'].map((resource, index) => {
                      return (
                        <ListItemResource key={resource.id} resource={resource} lastOne={index === sortResourcesByType(course.instructorResources).length - 1} />
                      )
                    }) : null
                }
                {
                  //All resources order by lesson
                  course.instructorResources.length >= 1 ? 
                    course.lessons.map((lesson) => {
                      return (
                        <ListItemResourceByLesson key={lesson.id}  lesson={lesson} instructorResources={course.instructorResources}/>
                      )
                    }) : null
                }
                {
                  //If there is one o more resource of type SUMMARY showing
                  sortResourcesByType(course.instructorResources)['SUMMARY'] && sortResourcesByType(course.instructorResources)['SUMMARY'].length >= 1 ?
                  sortResourcesByType(course.instructorResources)['SUMMARY'].map((resource, index) => {
                      return (
                        <ListItemResource key={resource.id} resource={resource} lastOne={index === sortResourcesByType(course.instructorResources).length - 1} />
                      )
                    }) : null
                }
                {
                  //If there is one o more resource of type ANY showing
                  sortResourcesByType(course.instructorResources)['ANY'] && sortResourcesByType(course.instructorResources)['ANY'].length >= 1 ?
                  sortResourcesByType(course.instructorResources)['ANY'].map((resource, index) => {
                      return (
                        <ListItemResource key={resource.id} resource={resource} lastOne={index === sortResourcesByType(course.instructorResources)['ANY'].length - 1} />
                      )
                    }) : null
                }
              </List>
            </div>
          </CardContent>
        </Card>
      </Box>
    </section>
  )
};

export default InstructorResources;