import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Button, Chip, Dialog, DialogTitle, DialogContent, FormControl, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { withSnackbar } from 'notistack';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import styles from './styles';
import { GET_INVITATIONS_BY_INSTITUTION_ID, CREATE_INVITATIONS } from '../../../apollo/queries';
import { useQuery, useMutation } from '@apollo/react-hooks';

const InstitutionInvitationForm = ({ open, institutionId, enqueueSnackbar, onCancel }) => {
    const classes = styles();
    const { data, loading, error } = useQuery(GET_INVITATIONS_BY_INSTITUTION_ID, { variables: { where: { id: institutionId } } });
    const [createInvitations] = useMutation(CREATE_INVITATIONS, {
        refetchQueries: [{ query: GET_INVITATIONS_BY_INSTITUTION_ID, variables: { where: { id: institutionId } } }],
        awaitRefetchQueries: true,
    });
    const [invitations, setInvitations] = useState(0);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error...</p>;

    if (data.institutionInvitations.length !== invitations)
        setInvitations(data.institutionInvitations.length);

    const addMemberButtonStatus = (values) => {
        let invalidFields = 0;

        if (values.email) {
            const expression = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

            if (!expression.test(String(values.email).toLowerCase()))
                invalidFields++;
        }
        else {
            invalidFields++;
        }

        if (!values.first_name) {
            invalidFields++;
        }

        if (!values.last_name) {
            invalidFields++;
        }

        if (!values.role) {
            invalidFields++;
        }

        return invalidFields !== 0;
    };

    const saveButtonStatus = (users) => {
        let enableButton = users.length !== invitations || users.some(user => user.is_new);
        return !enableButton;
    }

    const saveButtonText = (users) => {
        if (invitations === 0) {
            return "Add to Institution";
        }
        else {
            return "Save changes";
        }
    }

    const addInvitation = (form, values) => {
        if (values.users.find(user => user.email === values.email)) {
            enqueueSnackbar(`The user ${values.email} has already been added`, { variant: 'info', autoHideDuration: 3000 });
        }
        else {
            form.mutators.push('users', {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                role: values.role,
                is_new: true
            });
            form.change('first_name', undefined);
            form.change('last_name', undefined);
            form.change('email', undefined);
            form.change('role', undefined);
        }
    }

    return (
        <Dialog open={open} onClose={onCancel} maxWidth="xs" fullWidth={true}>
            <DialogTitle>
                <Typography>
                    <PermIdentityIcon style={{ transform: 'translateY(20%)' }} />
                    Invite Members to this Institution
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Form
                    validate={(values) => {
                        let error = {};

                        return error;
                    }}
                    onSubmit={async (values) => {
                        await createInvitations({
                            variables: {
                                institutionId,
                                data: values.users.map(x => { return { id: x.id, email: x.email, first_name: x.first_name, last_name: x.last_name, role: x.role }; })
                            }
                        });
                        enqueueSnackbar("The invitations were sent and saved correctly", { variant: 'success', autoHideDuration: 3000, });
                    }}
                    mutators={{ ...arrayMutators }}
                    initialValues={{
                        users: data.institutionInvitations //.map(x => { return { email: x.email, first_name: x.first_name, last_name: x.last_name, role: x.role, is_new: false }; })
                    }}
                    render={({ handleSubmit, form, values, submitting, pristine, valid }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <FormControl fullWidth style={{ marginBottom: 18 }}>
                                    <Field name="email">
                                        {({ input, meta }) => (
                                            <TextField
                                                type="email"
                                                label="Email"
                                                inputProps={{ autoComplete: 'off' }}
                                                value={input.value}
                                                onChange={(value) => { input.onChange(value); }}
                                                variant="filled"
                                            />
                                        )}
                                    </Field>
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: 18 }}>
                                    <Field name="first_name">
                                        {({ input, meta }) => (
                                            <TextField
                                                type="text"
                                                label="First Name"
                                                inputProps={{ autoComplete: 'off' }}
                                                value={input.value}
                                                onChange={(value) => input.onChange(value)}
                                                variant="filled"
                                            />
                                        )}
                                    </Field>
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: 18 }}>
                                    <Field name="last_name">
                                        {({ input, meta }) => (
                                            <TextField
                                                type="text"
                                                label="Last Name"
                                                inputProps={{ autoComplete: 'off' }}
                                                value={input.value}
                                                onChange={(value) => input.onChange(value)}
                                                variant="filled"
                                            />
                                        )}
                                    </Field>
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: 18 }}>
                                    <Field name="role">
                                        {({ input, meta }) => (
                                            <TextField
                                                select
                                                style={{ textTransform: 'capitalize' }}
                                                type="text"
                                                label="Institution Role"
                                                inputProps={{ autoComplete: 'off', }}
                                                value={input.value}
                                                onChange={(value) => input.onChange(value)}
                                                variant="filled">
                                                <MenuItem value="INSTRUCTOR" style={{ textTransform: 'capitalize' }}>Instructor</MenuItem>
                                                <MenuItem value="CENTER_ADMIN" style={{ textTransform: 'capitalize' }}>Center Administrator</MenuItem>
                                            </TextField>
                                        )}
                                    </Field>
                                </FormControl>
                                <Grid container direction="row" justify="flex-end">
                                    <Button color="primary" variant="contained"
                                        disabled={addMemberButtonStatus(values)}
                                        onClick={() => {
                                            addInvitation(form, values);
                                        }}>Add Member</Button>
                                </Grid>
                                <div style={{ height: "100px", overflowY: "auto", backgroundColor: "#F4F4F4", marginTop: "15px", padding: "5px" }}>
                                    <FieldArray name="users" style={{ marginTop: "15px", marginBottom: "15px" }}>
                                        {({ fields }) =>
                                            fields.map((name, index) => (
                                                <Chip
                                                    key={name}
                                                    color="default"
                                                    size="small"
                                                    label={values.users[index].email}
                                                    onDelete={() => { fields.remove(index); }}
                                                    className={classes.chip} />
                                            ))
                                        }
                                    </FieldArray>
                                </div>
                                <div style={{ paddingBottom: '25px', marginTop: '5px' }}>
                                    <small>
                                        The member(s) above will receive an invitation to the provided email.<br />
                                        Upon initial login to Jasperactive the new user  will be prompted to create a password.
                                    </small>
                                </div>
                                <Grid container direction="row" justify="space-between" style={{ marginBottom: "15px" }}>
                                    <Button color="default" variant="contained" onClick={() => { if (onCancel) onCancel(); }}>Cancel</Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={saveButtonStatus(values.users) || submitting}>
                                        {saveButtonText(values.users)}
                                    </Button>
                                </Grid>
                            </form>
                        );
                    }}>
                </Form>
            </DialogContent>
        </Dialog >
    );
}

export default withSnackbar(InstitutionInvitationForm);