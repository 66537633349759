import React, { Fragment } from 'react';
import { Box, Typography, Chip, Card, CardActionArea } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styles from './styles';
import StudyProjectLogo from '../../../../assets/logos/project.svg';

const Dashboard = ({ history, match, lesson }) => {
  const classes = styles();

  return (
    <Fragment>
      {lesson.studyProjects.map((project, index) => (
        <CourseDashboardCard
          key={project.id}
          className={classes.courseDashboardCard}
          level={project.level}
          borderColor={lesson.course.product.color}
          locked={false}
          complete={false}
          onClick={() =>
            history.push(`${match.url}/study-project/${project.id}`, {
              studyProjectNumber: index + 1,
              preview: true,
            })
          }
        >
          <Box display="flex" alignItems="center">
            <img
              src={StudyProjectLogo}
              width={40}
              height={40}
              alt="Study Project"
            />
            <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
              Study Project {index + 1}
            </Typography>
          </Box>
        </CourseDashboardCard>
      ))}
    </Fragment>
  );
};

const CourseDashboardCard = ({
  borderColor,
  children,
  level,
  locked,
  onClick,
  complete,
  ...restProps
}) => (
  <Card style={{ borderTop: `4px solid ${borderColor}` }} {...restProps}>
    <CardActionArea onClick={onClick}>
      <Box display="flex" alignItems="center" p={2}>
        <div>{children}</div>

        <Box display="flex" alignItems="center" ml="auto">
          <LevelChip level={level} style={{ marginLeft: 'auto' }} />
          {locked && <LockOutlinedIcon style={{ marginLeft: 8 }} />}
          {complete && (
            <CheckCircleIcon style={{ color: '#10AC84', marginLeft: 8 }} />
          )}
        </Box>
      </Box>
    </CardActionArea>
  </Card>
);

const LevelChip = ({ level, ...restProps }) => {
  const getColor = () => {
    if (level === 1) return '#2196F3';
    if (level === 2) return '#07639A';
    return '#555759';
  };
  return (
    <Chip
      size="small"
      label={level === 'all' ? 'ALL LEVELS' : `LEVEL ${level}`}
      style={{ color: '#FFF', background: getColor() }}
    />
  );
};

export default Dashboard;
