import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const FormToolbar = ({ title, leftIcon, buttonText, onClick }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      pb={1}
    >
      <Box display="flex" alignItems="center">
        {leftIcon}
        <Typography variant="h6" component="span">
          {title}
        </Typography>
      </Box>

      <Button
        size="small"
        startIcon={<AddIcon />}
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default FormToolbar;
