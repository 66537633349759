import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';

const CheckUserAction = ({
  onClose,
  open,
  onClick,
  title,
  content,
  confirmText,
  disableButtons = false
}) => {
  return (
    <Dialog onClose={() => onClose()} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="text" color="primary" disabled={disableButtons}>
          Cancel
        </Button>
        <Button onClick={() => onClick()} variant="text" color="primary" disabled={disableButtons}>
          {confirmText ? confirmText : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckUserAction;
