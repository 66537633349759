import { useMutation } from "@apollo/react-hooks";
import { CREATE_GROUP, JOIN_GROUP } from "../queries";

export function useCreateGroup() {
  const [createGroup, { data }] = useMutation(CREATE_GROUP, {
    refetchQueries: ["getUserInstitutionGroups"]
  });

  return { createGroup, data};
}

export function useJoinGroup() {
  const [joinGroup, { data }] = useMutation(JOIN_GROUP, {
    refetchQueries: ["getUserInstitutionGroups"]
  });

  return { joinGroup, data};
}
