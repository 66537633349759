import React, { useState, useEffect } from "react";
import styles from "./styles";
import { ChromePicker } from "react-color";

const ColorPicker = ({ onChange, value, label, ...restProps }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState("#000000");
  const classes = styles(color);

  useEffect(() => {
    setColor(value);
  }, [value]);

  const handleChange = color => {
    setColor(color.hex);
  };

  const handleClose = () => {
    setShowPicker(false);
  };

  return (
    <div className={classes.root} {...restProps}>
      {label && <label className={classes.label}>{label}</label>}
      <div className={classes.swatch} onClick={() => setShowPicker(true)}>
        <div className={classes.color} style={{ backgroundColor: color }} />
      </div>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>
          <span>{label}</span>
        </legend>
      </fieldset>
      {showPicker ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handleClose} />
          <ChromePicker
            color={color}
            onChange={handleChange}
            onChangeComplete={color => onChange(color.hex)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
