import React from 'react';
import { Avatar, Box, Card, CardContent, CardHeader } from '@material-ui/core';
import styles from './styles';
import { CourseDashboardProjectCard } from './Cards';

const CourseLessons = (props) => {
  const { course, studentCourseProgress, isPreview, history, match } = props;
  const classes = styles();

  const handleStudyProjectClick = (
    studyProject,
    isLocked,
    isComplete,
    projectNumber,
  ) => {
    if (!studyProject || isLocked) return;

    history.push(`${match.url}/study-project/${studyProject.id}`, {
      studyProjectNumber: projectNumber,
      complete: isComplete,
    });
  };

  const studyProgress = isPreview
    ? {}
    : studentCourseProgress.studyProjects.reduce((acc, project) => {
        acc[project.study_project_id] = {
          complete: project.complete,
          locked: project.locked,
          draft: project.draft,
        };
        return acc;
      }, {});

  return course.lessons
    .filter((l) => isPreview || l.published)
    .map((lesson) => (
      <Box mb={6} key={lesson.id}>
        <Card variant="outlined">
          <CardHeader className={classes.courseLesson_header}
            titleTypographyProps={{ variant: 'h4' }}
            avatar={<Avatar src={lesson.logo}/>}
            title={`Lesson ${lesson.display_order}`}
            subheader={lesson.name}
            subheaderTypographyProps={{variant: 'h4'}}
          />
          <CardContent>
            <LessonProjects
              lesson={lesson}
              projects={lesson.studyProjects}
              studyProgress={studyProgress}
              onClick={handleStudyProjectClick}
              course={course}
              isPreview={isPreview}
            />
          </CardContent>
        </Card>
      </Box>
    ));
};

export default CourseLessons;

const LessonProjects = ({
  lesson,
  projects,
  course,
  studyProgress,
  isPreview,
  onClick,
}) => {
  const classes = styles();

  const handleGetH1HtmlTagFromIntroductionField = (introduction) => {
    let temp = document.createElement('div');
    temp.innerHTML = introduction;
    const h2Elements = temp.getElementsByTagName('h2');
    const textContent = h2Elements[0]?.textContent;
    return textContent;
  }

  const sortStudyProjects = (projects) => {
    const studyProjectsSorted = projects.sort((a, b) => {return a.study_project_number - b.study_project_number});//Order by study project number asc
    return studyProjectsSorted;
  }

  return (
    <Box display="flex" flexWrap="wrap">
      {sortStudyProjects(projects)
        .filter((p) => isPreview || p.published)
        .map((project, index) => (
          <Box key={project.id} className={classes.flexitem} p={1}>
            <CourseDashboardProjectCard
              typeOfStudyProject={handleGetH1HtmlTagFromIntroductionField(project.introduction)}
              borderColor={course.product.color}
              logo={lesson.logo}
              level={project.level}
              locked={isPreview ? false : studyProgress[project.id].locked}
              complete={isPreview ? false : studyProgress[project.id].complete}
              draft={isPreview ? false : studyProgress[project.id].draft}
              onClick={() =>
                onClick(
                  project,
                  isPreview ? false : studyProgress[project.id].locked,
                  isPreview ? true : studyProgress[project.id].complete,
                  index + 1,
                )
              }
              studyProjectNumber={project.study_project_number}
            />
          </Box>
        )).sort()}
    </Box>
  );
};
