import React from 'react';
import { Box, Card, Avatar, CardHeader, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import AssessmentIcon from '@material-ui/icons/Assessment';
import styles from './styles';

const GradeLockedCard = ({ logo, borderColor, level, cardType, typeOfStudyProject, studyProjectNumber, draft }) => {
  const classes = styles();

  const getLogoEntity = (entity) => {
    switch (entity) {
      case 'studyproject':
        const logoToStudyProjectCard = typeOfStudyProject?.search('Learn') >= 0 ? 'https://res.cloudinary.com/jasperactive/image/upload/v1638598152/Learn.svg': typeOfStudyProject?.search('Apply') >= 0 ? 'https://res.cloudinary.com/jasperactive/image/upload/v1638598169/Project.svg' : logo;
        return <Avatar src={logoToStudyProjectCard} />
      case 'benchmark':
        return <Avatar className={classes.benchmarkLogo} ><AssessmentIcon/></Avatar>
      case 'summary':
        return <Avatar className={classes.benchmarkLogo} ><AssessmentIcon/></Avatar>
      default:
        break;
    }
  };

  const getTitleEntity = (entity) => {
    switch (entity) {
      case 'studyproject':
        return `Study Project ${studyProjectNumber ? '#'+studyProjectNumber : ''}`
      case 'benchmark':
        return `Level ${level} Benchmark Assessment`
      case 'summary':
        return `Level ${level} Summary Assessment`
      default:
        break;
    }
  }

  const getSubtitleEntity = (entity) => {
    switch (entity) {
      case 'studyproject':
        const typeOf = typeOfStudyProject?.search('Learn') >= 0 ? 'Learn': typeOfStudyProject?.search('Apply') >= 0 ? 'Apply' : null;
        return typeOf
      case 'benchmark':
        return ''
      case 'all':
        return ''
      default:
        break;
    }
  }

  return (
    <Box
      display="flex"
      width="100%"
      p={1}
      className={classes.flexitem}
      style={{ height: 'max-content', minWidth: 300 }}
    >
      <Card style={{ borderTop: `2px solid ${borderColor}`, flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CardHeader
          avatar={getLogoEntity(cardType)}
          title={getTitleEntity(cardType)}
          subheader={getSubtitleEntity(cardType)}
        />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding='1em'
          >
            {
              draft ?
              <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}>
                <QueryBuilderIcon style={{color: '#ECB322'}}/>
                <Typography variant='caption' style={{color: '#ECB322'}}>In progress</Typography>
              </Box> : <LockIcon className={classes.lockIcon} />
            }
          </Box>
      </Card>
    </Box>
  );
};

export default GradeLockedCard;
