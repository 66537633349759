import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  header: {
    background: theme.palette.white.main,
    boxShadow: 'none',
  },
  headerToolbar: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  submitButton: {
    flex: 1,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    background: theme.palette.white.main,
  },
  answerBar: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  button: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  assessmentCompleteTitle:{
    ...theme.typography.h3,
    color:'#209251'
  },
  checkResultTitle:{
    ...theme.typography.h1,
    fontWeight: 'bold',
    fontSize: 25,
    margin: '0.5em 0 0.5em 0',
  },
  questionsAndResultTitle:{
    ...theme.typography.h5,
  },
  progressBarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2em 0',
    flexDirection: 'column'
  },
  buttonTryAgain: {
    color: '#FFFFFF', 
    backgroundColor: '#3fae2a',
    '&:hover': {
      backgroundColor: '#2d831d'
    }
  },
  benchmarkResults_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1em',
    marginTop: '4em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      gap: '2em',
      alignItems: 'flex-start',
      marginTop: '2em'
    },
  },
}));
