export const validateInstitution = (values, countries) => {
  let error = {};
  if (!values.name) {
    error.name = "required";
  }
  if (!values.country_code) {
    error.country_code = "required";
  }
  if (values.country_code) {
    const currentCountry = countries.find(
      c => c.country_code === values.country_code,
    );
    if (currentCountry && currentCountry.states.length !== 0 && currentCountry.states.findIndex(s => s.state_code === values.state_code) === -1) {
      error.state_code = "must be a valid state/provice";
    }
  }

  return error;
};
