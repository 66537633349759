import React, { Fragment, useState, useContext } from 'react';
import {
  Typography,
  Button,
  Box,
  Modal,
  Paper,
  Chip,
  TextField,
  FormControl,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ProductForm,
  CourseForm,
  EditButton,
  DeleteButton,
} from '../../../../components';
import styles from './styles';
import { UserConfirmationContext } from '../../../../providers/UserConfirmationProvider';
import { useIsManager } from '../IsManager';
import { CourseContainer } from '../../../../containers';
import clsx from 'clsx';

const refetchQueries = ['allCMSProducts'];

const ProductSummary = ({
  product,
  deleteProduct,
  publishProduct,
  unpublishProduct,
  enqueueSnackbar,
}) => {
  const classes = styles();
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteProduct, setShowDeleteProduct] = useState(false);
  const [productToDelete, setProductToDelete] = useState('');
  const { showConfirmation } = useContext(UserConfirmationContext);
  const isManager = useIsManager();

  const handleProductPublish = async (id, published) => {
    try {
      if (published) {
        await unpublishProduct.mutation({ variables: { id }, refetchQueries });
      } else {
        await publishProduct.mutation({ variables: { id }, refetchQueries });
      }
      enqueueSnackbar(
        `Successfully ${published ? 'Unpublished' : 'Published'} Product!`,
        { variant: 'success', autoHideDuration: 1500 },
      );
    } catch (error) {
      enqueueSnackbar(
        'Failed to update Product. If problem persists please contact Tech Support.',
        { variant: 'error' },
      );
    }
  };

  const handleProductDelete = async (productId) => {
    try {
      await deleteProduct.mutation({
        variables: { id: productId },
        refetchQueries,
      });
      enqueueSnackbar('Successfully deleted Product', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong.', { variant: 'error' });
    }
    setProductToDelete('');
  };

  const onDeleteInputChange = (event) => {
    setProductToDelete(event.target.value);
  };

  const toggleEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleAddCourse = async (values, addCourse) => {
    try {
      await addCourse.mutation({
        variables: { 
          data: {
            ...values,
            display_order: parseInt(values.display_order)
          } 
        },
        refetchQueries,
      });
      enqueueSnackbar('Successfully added Course', {
        variant: 'success',
        autoHideDuration: 1500,
      });
      setShowForm(false);
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong.', { variant: 'error' });
    }
  };

  return (
    <Fragment>
      <Modal
        open={showDeleteProduct}
        onClose={(event) => {
          event.stopPropagation();
          setShowDeleteProduct(false);
        }}
      >
        <Paper className={clsx(classes.modal, classes.deleteProductModal)}>
          <Typography variant="h5" color="primary" gutterBottom>
            Danger Zone
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 16 }}>
            Are you sure you want to delete {product.name}. Institutions using
            this this product will lose all progress and access.
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl style={{ width: '70%', marginBottom: 16 }}>
              <TextField
                autoFocus
                value={productToDelete}
                onChange={onDeleteInputChange}
                label="Product Name"
                helperText="type the product name to continue"
              />
            </FormControl>
            <Button
              variant="outlined"
              color="primary"
              disabled={productToDelete !== product.name}
              onClick={() => handleProductDelete(product.id)}
            >
              Delete {product.name}
            </Button>
          </Box>
        </Paper>
      </Modal>
      <Modal
        open={showForm}
        onClose={(event) => {
          event.stopPropagation();
          toggleForm();
        }}
        disableEnforceFocus={true}
      >
        <Paper className={classes.modal}>
          <CourseContainer>
            {({ addCourse }) => (
              <CourseForm
                productId={product.id}
                onSubmit={(values) => handleAddCourse(values, addCourse)}
              />
            )}
          </CourseContainer>
        </Paper>
      </Modal>
      <Modal
        open={showEditForm}
        onClose={(event) => {
          event.stopPropagation();
          toggleEditForm();
        }}
        disableEnforceFocus={true}
      >
        <Paper className={classes.modal}>
          <ProductForm product={product} onCancel={() => toggleEditForm()} />
        </Paper>
      </Modal>
      <Modal
        open={showForm}
        onClose={(event) => {
          event.stopPropagation();
          toggleForm();
        }}
        disableEnforceFocus={true}
      >
        <Paper className={classes.modal}>
          <CourseContainer>
            {({ addCourse }) => (
              <CourseForm
                productId={product.id}
                onSubmit={(values) => handleAddCourse(values, addCourse)}
              />
            )}
          </CourseContainer>
        </Paper>
      </Modal>
      <Box display="flex" alignItems="center" width="100%">
        <img
          alt={product.name}
          src={product.logo}
          style={{ width: '100%', height: 'auto', maxWidth: 80 }}
        />
        <Typography variant="h5" style={{ marginLeft: 8 }}>
          {product.name}
        </Typography>
        {isManager && (
          <Fragment>
            <Button
              style={{ marginLeft: 'auto', marginRight: 8 }}
              startIcon={<AddIcon />}
              variant="outlined"
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                toggleForm();
              }}
            >
              Add Course
            </Button>
            {product.published ? (
              <Chip
                color="primary"
                label="Published"
                style={{ marginRight: 8 }}
              />
            ) : (
              <Button
                style={{ marginRight: 8 }}
                variant="contained"
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  showConfirmation({
                    title: `Publish ${product.name}`,
                    message: `Publishing a Product cannot be reversed. Please ensure you have checked that all content is complete and published. Would you like to continue?`,
                    confirmed: () => handleProductPublish(product.id),
                    confirmText: 'Publish',
                  });
                }}
              >
                {product.published ? 'Unpublish' : 'Publish'}
              </Button>
            )}
            <EditButton
              onClick={(event) => {
                event.stopPropagation();
                toggleEditForm();
              }}
            />
            <DeleteButton
              onClick={(event) => {
                event.stopPropagation();
                setShowDeleteProduct(true);
              }}
            />
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default ProductSummary;
