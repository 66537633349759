import React from "react";
import { Tooltip } from "@material-ui/core";
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import useImmersiveReaderLauncher from "./useImmersiveReaderLauncher";

const ImmersiveReaderButton = ({ text, title, lang, style, ...restProps }) => {
  const { handleOnLaunch } = useImmersiveReaderLauncher();

  return (
    <Tooltip title="Immersive Reader">
      <VolumeUpIcon
        fontSize="48"
        style={{ color: '#ffffff', cursor: "pointer", padding: 1, ...style, backgroundColor: '#3fae2a', borderRadius: 2 }}
        data-button-style="iconAndText"
        onClick={ async (event) => {
            event.stopPropagation();
            await handleOnLaunch(title, text);
        }}
        {...restProps}
      />
    </Tooltip>
  );
};

export default ImmersiveReaderButton;