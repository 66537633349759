import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  card: {
    borderTop: `4px solid transparent`,
  },
  courseLogo: {
    width: '100%',
    height: 'auto',
    maxWidth: 60,
    marginRight: theme.spacing(1),
  },
  courseDescription: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default styles;
