import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  activateOrRenewButton: {
    background: '#F44435',
    color: theme.palette.white.main,
  },
  activeLicenseText: {
    display: 'flex',
    color: theme.palette.success.main,
  },
  expiredText: {
    color: theme.palette.text.secondary,
  },
  expired: {
    color: theme.palette.primary.main,
  },
}));

export default styles;
