import { 
  Avatar,
  Box,
  Divider,
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Typography
} from '@material-ui/core';
import DownloadButton from './DownloadButton';
import styles from './styles';

import React, { Fragment } from 'react';

const ListItemResourceByLesson = ({lesson, instructorResources}) => {
  const classes = styles();

  const getInstructorResourceByLesson = (instructorResources, filter) => {
    const instructorResourceByFilter = instructorResources.filter((resource) => resource.name.toLowerCase().includes(filter));
    const resourceByLesson = instructorResourceByFilter.reduce((acc, resource) => {
      if (resource.lesson_id === lesson.id) {
        if (resource.type === 'LEARN_EXERCISE') {
          acc[0].push(resource);
        } else if (resource.type === 'APPLY_EXERCISE') {
          acc[1].push(resource);
        }
      }

      return acc;
    }, [[], []]);

    const learnResourcesFirst = resourceByLesson[0].concat(resourceByLesson[1]);
    return learnResourcesFirst;
  }

  return (
  <Fragment>
    <ListItem className={classes.listItemResourceByLesson_container}>
      <Box className={classes.listItemResourceByLesson_lessonInf}>
        <ListItemAvatar>
          <Avatar src={lesson.logo} />
        </ListItemAvatar>
        <ListItemText
          primary={`Lesson ${lesson.display_order}: ${lesson.name}`}
        />
      </Box>
      <Box className={classes.listItemResourceByLesson_contentParent}>
        <Box className={classes.listItemResourceByLesson_contentChild}>
          <Typography variant='subtitle1' >Answer Key:</Typography>
          <Box className={classes.listItemResourceByLesson_downloadButtons_container}>
            {
              getInstructorResourceByLesson(instructorResources, 'answer key').length >= 1 ?
                getInstructorResourceByLesson(instructorResources, 'answer key').map((resource) => {
                  return (
                  <Box key={resource.id} className={classes.listItemResourceByLesson_downloadButtons}>
                    <Typography variant='subtitle2'>
                      {
                        resource.type === 'LEARN_EXERCISE' && 'Learn'
                      }                
                      {
                        resource.type === 'APPLY_EXERCISE' && 'Apply'
                      }
                    </Typography>
                    <DownloadButton
                      url={resource.url}
                      name={resource.name}
                    />
                  </Box>
                  )
                }) : <Typography variant='subtitle2' >There're no any resource yet</Typography>
            }
          </Box>
        </Box>
        <Box className={classes.listItemResourceByLesson_contentChild}>
          <Typography variant='subtitle1'>Content Mapping:</Typography>
          <Box className={classes.listItemResourceByLesson_downloadButtons_container}>
            {
              getInstructorResourceByLesson(instructorResources, 'content mapping').length >= 1 ?
                getInstructorResourceByLesson(instructorResources, 'content mapping').map((resource) => {
                  return (
                  <Box key={resource.id} className={classes.listItemResourceByLesson_downloadButtons}>
                    <Typography variant='subtitle2'>
                      {
                        resource.type === 'LEARN_EXERCISE' && 'Learn'
                      }                
                      {
                        resource.type === 'APPLY_EXERCISE' && 'Apply'
                      }
                    </Typography>
                    <DownloadButton
                      url={resource.url}
                      name={resource.name}
                    />
                  </Box>
                  )
                }) : <Typography variant='subtitle2' >There're no any resource yet</Typography>
            }
          </Box>
        </Box>   
      </Box>
    </ListItem>
    <Divider component="li" />
  </Fragment> 
  )
}

export default ListItemResourceByLesson;