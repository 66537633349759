import React from 'react';
import NoCourseSelected from './NoCourseSelected';
import { Paper, Box, Typography, Divider } from '@material-ui/core';

const GradeOverviewCard = ({
  title,
  children,
  noCourseSelected,
  ...restProps
}) => {
  return (
    <Paper {...restProps}>
      <Box p={2}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Box>
      <Divider style={{ height: 1, backgroundColor: '#2196F3' }} />

      {noCourseSelected ? <NoCourseSelected /> : children}
    </Paper>
  );
};

export default GradeOverviewCard;
