import { useCallback } from "react";

const useSortQuickResourcesAsc = () => {
  //This function gets text from a html tag
  const handleGetContentHtmlString = (tag) => {
    let span = document.createElement('span');
    span.innerHTML = tag
    return span.textContent || span.innerText;
  }

  const normalizeNumberFromString = (numberFromString) => {
    if (numberFromString) {
      const numberSplited = numberFromString[0].split(/[.|,]/g);
      let numberSplitedNormalized = [];

      if (numberSplited.length === 3) {
        for (const digit of numberSplited) {
          if (digit.length <= 1) {
            const paddedDigit = digit.padStart(2,0); //Adds 0 before digit
            numberSplitedNormalized = [...numberSplitedNormalized, paddedDigit];
          } else {
            numberSplitedNormalized = [...numberSplitedNormalized, digit];
          }
        }
      } else if (numberSplited.length === 2) {
        for (const digit of numberSplited) {
          if (digit.length <= 1) {
            const paddedDigit = digit.padStart(2,0);
            numberSplitedNormalized = [...numberSplitedNormalized, paddedDigit];
          } else {
            numberSplitedNormalized = [...numberSplitedNormalized, digit];
          }
        }
        numberSplitedNormalized = [...numberSplitedNormalized, '00'];
      } else if (numberSplited.length === 1) {
        if (numberSplited[0].length <= 1) {
          const paddedDigit = numberSplited[0].padStart(2,0);
          numberSplitedNormalized = [paddedDigit, '00', '00'];
        } else {
          numberSplitedNormalized = [numberSplited[0], '00', '00'];
        }   
      }

      return numberSplitedNormalized.join('.');
    }
  }

  //this function sets sortNumberProp to the quickResource.
  const setSortNumberProp = (numberFromString, quickResource, normalizeNumberFromString) => {
    if (numberFromString) {
      const numberFromStringNormalized = normalizeNumberFromString(numberFromString);

      return {
        ...quickResource,
        sortNumber: numberFromStringNormalized
      }
    } else {
      return {
        ...quickResource,
        sortNumber: 0
      }
    }
  }

  //this function sorts quickresources by number title asc.
  const sortQuickresourceByNumberTitle = useCallback((quickResources) => {
    let normalizedValues = [];

    const quickResourcesWithNumberProp = quickResources.map((quickResource) => {
      const { term, title } = quickResource; 

      if (term) {
        const stringInHtmlTag = handleGetContentHtmlString(term);
        const numberFromString = stringInHtmlTag.match(/^[\d.,]+/g);
        if (normalizeNumberFromString(numberFromString)) {
          normalizedValues = [...normalizedValues, normalizeNumberFromString(numberFromString)]
        }

        return  setSortNumberProp(numberFromString, quickResource, normalizeNumberFromString);
      } else if (title) {
        const numberFromString = title.match(/^[\d.,]+/g);
        if (normalizeNumberFromString(numberFromString)) {
          normalizedValues = [...normalizedValues, normalizeNumberFromString(numberFromString)]
        }

        return setSortNumberProp(numberFromString, quickResource, normalizeNumberFromString);
      } else {
        return setSortNumberProp('', quickResource, normalizeNumberFromString(''));
      }
    });
    
    let normalizedValuesSorted = normalizedValues.sort();

    for (const quickResource of quickResourcesWithNumberProp) {
      const index = normalizedValuesSorted.findIndex((value) => value === quickResource.sortNumber);
      if (index >= 0) {
        normalizedValuesSorted[index] = quickResource;
      } else {
        normalizedValuesSorted.push(quickResource);
      }
    }

    return normalizedValuesSorted;
  }, []);

  return {
    sortQuickresourceByNumberTitle
  }
}

export default useSortQuickResourcesAsc;