import React from 'react';
import {
  Paper,
  Typography,
  Divider,
  FormControl,
  TextField,
  MenuItem,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import FilterIcon from '../../../../assets/icons/filter_icon.svg';
import TableChartIcon from '@material-ui/icons/TableChart';
//About export excel
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_EXCEL_DATA_GRADEBOOK } from '../../../../apollo/queries';
import { useSnackbar } from 'notistack';
import { buildExcelGradebook } from '../../../../lib';

const GradebookFilters = ({
  courses,
  students,
  selectedCourse,
  onCourseChange,
  selectedStudent,
  onStudentChange,
  levels,
  selectedLevel,
  onLevelChange,
  group,//About export excel
}) => {
  const onCourseSelected = (courseSelection) => {
    if (courseSelection === 'none' && selectedStudent !== 'all') {
      onStudentChange('all');
    }
    onCourseChange(courseSelection);
    onLevelChange('all');
  };

  const { enqueueSnackbar } = useSnackbar();

  //About export excel
  const [getExcel, { loading }] = useLazyQuery(GET_EXCEL_DATA_GRADEBOOK, {
    skip: !selectedCourse || !group,
    fetchPolicy: 'no-cache',
    onError: (error) => {
      enqueueSnackbar('Oops, something went wrong, reload the page and try again.', {
        variant: 'error'
      })
    },
    onCompleted: (data) => {
      buildExcelGradebook(courses, data, selectedCourse);
    },
  });
  //About export excel

  const handleOnClickExportExcel = () => {
    getExcel({
      variables: {
        courseId: selectedCourse,
        groupId: group.id,
      }
    });
  };

  const arrayLevels = (levels) => {
    const newArray = new Array(levels);
    for (let index = 0; index < newArray.length; index++) {
      newArray[index] = index + 1;
    }

    return newArray;
  }

  return (
    <Paper style={{ marginBottom: 16 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Gradebook Filters
        </Typography>
        <img src={FilterIcon} width={25} height={30} alt="filter" />
      </Box>
      <Divider style={{ height: 1, backgroundColor: '#2196F3' }} />
      <Box p={4} pt={2}>
        <form>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Box display="flex">
              <TextField
                style={{ flex: 1 }}
                label="View by Course"
                value={selectedCourse}
                onChange={(event) => onCourseSelected(event.target.value)}
                select
              >
                <MenuItem value="none">No Course Selected</MenuItem>
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.name}
                  </MenuItem>
                ))}
              </TextField>
              {selectedCourse !== 'none' && (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => onCourseSelected('none')}
                  style={{ alignSelf: 'flex-end', marginLeft: 16 }}
                >
                  Clear
                </Button>
              )}
            </Box>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Box display="flex">
              <TextField
                disabled={selectedCourse === 'none'}
                style={{ flex: 1 }}
                helperText={selectedCourse === 'none' ? 'Select a course' : ''}
                label="View by Learner"
                value={selectedStudent}
                onChange={(event) => onStudentChange(event.target.value)}
                select
              >
                <MenuItem value="all">All Learners</MenuItem>
                {students.map((student) => (
                  <MenuItem value={student.user.id} key={student.id}>
                    {student.user.first_name} {student.user.last_name}
                  </MenuItem>
                ))}
              </TextField>
              {selectedStudent !== 'all' && (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => onStudentChange('all')}
                  style={{ alignSelf: 'flex-end', marginLeft: 16 }}
                >
                  Clear
                </Button>
              )}
            </Box>
          </FormControl>
          <FormControl fullWidth>
            <Box display="flex">
              <TextField
                disabled={selectedCourse === 'none'}
                style={{ flex: 1 }}
                helperText={selectedCourse === 'none' ? 'Select a course' : ''}
                label="View by Level"
                value={selectedLevel}
                onChange={(event) => onLevelChange(event.target.value)}
                select
              >
                <MenuItem value="all">All Levels</MenuItem>
                {arrayLevels(levels).map((level, index) => (
                  <MenuItem value={level} key={level}>
                    Level {level}
                  </MenuItem>
                ))}
              </TextField>
              {selectedLevel !== 'all' && (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => onLevelChange('all')}
                  style={{ alignSelf: 'flex-end', marginLeft: 16 }}
                >
                  Clear
                </Button>
              )}
            </Box>
          </FormControl>
        </form>
      </Box>
      <Box
        style={{
          padding: '1em 2em',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5em',
        }}>
        {
          loading ? <CircularProgress size={36} /> :
            <Button disabled={selectedCourse !== 'none' && students.length >= 1 ? false : true} onClick={handleOnClickExportExcel} color='primary' endIcon={<TableChartIcon />} variant='contained' >Export to Excel</Button>
        }
        <Typography variant='caption' >Only if Learners are in the group</Typography>
      </Box>
    </Paper>
  );
};

export default GradebookFilters;
