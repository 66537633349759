import React, { useState, useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { SummaryStudentAnswerContext } from '../../providers/SummaryUserAnswerProvider';
import { SummaryContext } from '../../providers/SummaryProvider';
import BenchmarkContainer from '../../../Benchmark/components/BenchmarkContainer';
import { BenchmarkComplete, BenchmarkIntro, BenchmarkResults, BenchmarkTest } from '../../../Benchmark/components';

const SummaryAssessment = ({
  enqueueSnackbar,
  freeVersion,
  previewVersion,
  title,
  introductionText
}) => {
  const { 
    userAnswer,
    setUserAnswer,
    submitSummary,
    userResults,
    timeSpentUserAnswers,
    setTimeSpentUserAnswers
  } = useContext(SummaryStudentAnswerContext);

  const { 
    summaries,
    questions,
    time
  } = useContext(SummaryContext);

  const [timesUp, setTimesUp] = useState(false);
  const [showPage, setShowPage] = useState('start');
  const { params } = useRouteMatch();
  const history = useHistory();

  const handleTimerRunOut = async () => {
    try {
      await submitSummary();
      setTimesUp(true);
      setShowPage('results');
    } catch (error) {
      enqueueSnackbar(
        'Oops! Something went wrong. Please try again later! If problem persists try logging out and back in or contact Tech Support.',
        { variant: 'error' },
      );
    }
  };

  const courseLogo = summaries.map((benchmark) => {
    return benchmark.course.logo;
  })

  const courseName = summaries.map((benchmark) => {
    return benchmark.course.name;
  })

  switch (showPage) {
    case 'complete':
      return (
        <BenchmarkContainer>
          <BenchmarkComplete
            freeVersion={freeVersion}
            level={params.level}
            onClick={() =>
              history.replace(
                previewVersion
                  ? `/cms/preview/course/${params.courseId}`
                  : freeVersion
                    ? `/`
                    : `/group/${params.groupId}/course/${params.courseId}`,
              )
            }
            title={title}
          />
        </BenchmarkContainer>
      );
    case 'results':
      return (
        <BenchmarkResults
          courseLogo={courseLogo[0]}
          courseName={courseName[0]}
          onClick={() => setShowPage('complete')}
          timeRanOut={timesUp}
          title={title}
          questions={questions}
          userResults={userResults}
        />
      );
    case 'assessment':
      return (
        <BenchmarkTest
          courseLogo={courseLogo[0]}
          courseName={courseName[0]}
          level={params.level}
          enqueueSnackbar={enqueueSnackbar}
          showResultsPage={() => setShowPage('results')}
          onTimerComplete={handleTimerRunOut}
          title={title}
          questions={questions}
          time={time}
          userAnswer={userAnswer}
          setUserAnswer={setUserAnswer}
          submitEntity={submitSummary}
          setTimeSpentUserAnswers={setTimeSpentUserAnswers}
          timeSpentUserAnswers={timeSpentUserAnswers}
        />
      );
    default:
      return (
        <BenchmarkContainer>
          <BenchmarkIntro
            onClose={() => history.goBack()}
            onStart={() => setShowPage('assessment')}
            level={params.level}
            introductionText={introductionText}
            title={title}
            questions={questions}
          />
        </BenchmarkContainer>
      )
  }
};

export default withSnackbar(SummaryAssessment);