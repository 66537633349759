import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expansionPanel: {
    background: 'transparent',
    boxShadow: 'none',
  },
  benchmarkSummaryRoot: {
    background: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
  },
  courseDashboardCard: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));
