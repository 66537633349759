import React, { forwardRef } from "react";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const Heading = forwardRef(({ children, variant = "h1", ...restProps }, ref) => {
  return (
    <Typography ref={ref} variant={variant} {...restProps}>
      {children}
    </Typography>
  );
});

Heading.propTypes = {
  ...Typography.propTypes,
  variant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"])
};

export default Heading;
