import gql from "graphql-tag";

const GET_CLOUDINARY_SIGNATURE = gql`
    query getCloudinaryInfo {
        cloudinaryInfo {
            api_key
            unixtime
            mwsignature
            uwsignature            
        }
    }
`;

export { GET_CLOUDINARY_SIGNATURE };
