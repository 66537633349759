import gql from 'graphql-tag';
import { GroupFields, AllGroupFields } from './fragments';

const GET_GROUP_STATES = gql`
  query groupStatuses {
    __type(name: "GroupStatus") {
      name
      enumValues {
        name
      }
    }
  }
`;

const DELETE_GROUP = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id)
  }
`;

const CREATE_GROUP = gql`
  mutation createGroup($data: GroupCreateInput!) {
    createGroup(data: $data) {
      ...GroupFields
    }
  }
  ${GroupFields}
`;

const UPDATE_GROUP = gql`
  mutation updateGroup(
    $data: GroupUpdateInput!
    $where: GroupWhereUniqueInput!
  ) {
    updateGroup(data: $data, where: $where) {
      ...GroupFields
    }
  }
  ${GroupFields}
`;

const GET_USER_INSTITUTION_GROUPS = gql`
  query getUserInstitutionGroups($userId: ID!, $institutionId: ID!) {
    groups(where: { user_id: $userId, institution_id: $institutionId }) {
      ...GroupFields
    }
  }
  ${GroupFields}
`;

const GET_GROUP_BY_ID = gql`
  query getGroupById($groupId: ID) {
    group(where: { id: $groupId }) {
      ...AllGroupFields
    }
  }
  ${AllGroupFields}
`;

const GET_GROUP_BY_KEY = gql`
  query getGroupByKey($groupKey: String!) {
    group(where: { key: $groupKey }) {
      ...GroupFields
    }
  }
  ${GroupFields}
`;

const JOIN_GROUP = gql`
  mutation joinGroup($group_key: String!) {
    joinGroup(group_key: $group_key) {
      success
      result {
        ...AllGroupFields
      }
      message
    }
  }
  ${AllGroupFields}
`;

export {
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  GET_GROUP_STATES,
  GET_USER_INSTITUTION_GROUPS,
  GET_GROUP_BY_ID,
  GET_GROUP_BY_KEY,
  JOIN_GROUP,
};
