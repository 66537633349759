import React from "react";
import SvgIcon  from "@material-ui/core/SvgIcon";

const QuickClipIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M2.84377 9.00006H1.02704C0.756187 9.00126 0.495936 8.89189 0.303391 8.69595C0.110847 8.50002 0.00173559 8.23352 0 7.95493L0 1.04514C0.00173559 0.766555 0.110847 0.500055 0.303391 0.304118C0.495936 0.108181 0.756187 -0.00118782 1.02704 9.73196e-06H10.0276C10.5121 9.73196e-06 10.9376 0.488263 10.9376 1.04514V5.62504C10.9376 5.68472 10.9145 5.74195 10.8735 5.78414C10.8325 5.82634 10.7768 5.85004 10.7188 5.85004C10.6608 5.85004 10.6052 5.82634 10.5641 5.78414C10.5231 5.74195 10.5001 5.68472 10.5001 5.62504V1.04514C10.5001 0.709889 10.2463 0.450012 10.0276 0.450012H1.02704C0.872218 0.448812 0.723245 0.510769 0.612748 0.622315C0.50225 0.733861 0.439235 0.885903 0.437503 1.04514V7.95493C0.439235 8.11417 0.50225 8.26621 0.612748 8.37776C0.723245 8.4893 0.872218 8.55126 1.02704 8.55006H2.84377C2.90178 8.55006 2.95742 8.57377 2.99845 8.61596C3.03947 8.65816 3.06252 8.71539 3.06252 8.77506C3.06252 8.83474 3.03947 8.89197 2.99845 8.93416C2.95742 8.97636 2.90178 9.00006 2.84377 9.00006Z"/>
            <path d="M3.71875 6.44859C3.67983 6.44682 3.64207 6.4344 3.60938 6.41259C3.57647 6.39305 3.54908 6.36504 3.52991 6.33131C3.51073 6.29758 3.50042 6.25929 3.5 6.22021V2.35132C3.50061 2.31243 3.511 2.27438 3.53017 2.24086C3.54934 2.20734 3.57663 2.1795 3.60938 2.16007C3.64195 2.13911 3.67932 2.12734 3.71772 2.12596C3.75613 2.12458 3.7942 2.13363 3.82813 2.15219L7.45831 4.08833C7.49386 4.10731 7.52366 4.13598 7.54446 4.17119C7.56526 4.2064 7.57625 4.24681 7.57625 4.28802C7.57625 4.32922 7.56526 4.36963 7.54446 4.40484C7.52366 4.44006 7.49386 4.46872 7.45831 4.4877L3.81938 6.42384C3.78824 6.44025 3.75373 6.44874 3.71875 6.44859ZM3.9375 2.72144V5.85009L6.8819 4.28745L3.9375 2.72144Z"/>
            <path d="M13.9377 7.30019C13.2133 6.59921 12.2602 6.20232 11.2657 6.18756C10.056 6.18756 9.10769 6.81981 8.75003 7.08757C8.03049 6.51125 7.14633 6.19493 6.23439 6.18756C4.72173 6.18756 3.60938 7.24844 3.56891 7.29457C3.54725 7.31551 3.52997 7.34078 3.51812 7.36883C3.50627 7.39688 3.50011 7.42711 3.5 7.45769V14.1751C3.50007 14.2176 3.51182 14.2592 3.53391 14.2951C3.556 14.331 3.58752 14.3597 3.62482 14.378C3.66212 14.3962 3.70368 14.4032 3.74469 14.3982C3.7857 14.3931 3.82449 14.3763 3.85656 14.3495C3.86641 14.3416 4.89126 13.5046 6.23439 13.5046C7.09318 13.5087 7.92667 13.8041 8.60566 14.345C8.6205 14.3582 8.63707 14.3692 8.65487 14.3776C8.69216 14.3959 8.73373 14.403 8.77477 14.398C8.8158 14.3931 8.85463 14.3762 8.88675 14.3495C8.8966 14.3416 9.92144 13.5046 11.2646 13.5046C12.1231 13.5092 12.9563 13.8041 13.6358 14.3439C13.6757 14.38 13.727 14.4 13.7802 14.4001C13.8115 14.4001 13.8425 14.3932 13.871 14.3799C13.9094 14.3621 13.942 14.3334 13.9649 14.297C13.9878 14.2607 14 14.2184 14.0001 14.1751V7.45769C14.0001 7.39883 13.9777 7.34228 13.9377 7.30019ZM3.9375 13.7487V7.56119C4.18688 7.34407 5.09251 6.63756 6.23439 6.63756C7.0704 6.64989 7.87859 6.94835 8.53128 7.48582V13.7375C7.84277 13.2929 7.0473 13.0549 6.23439 13.0501C5.42163 13.0664 4.62818 13.3078 3.9375 13.7487ZM13.5626 13.7375C12.874 13.2929 12.0786 13.0549 11.2657 13.0501C10.4533 13.0651 9.6599 13.3048 8.96878 13.7442V7.49032C9.228 7.28444 10.1336 6.63756 11.2657 6.63756C12.4688 6.63756 13.3307 7.34294 13.5626 7.55557V13.7375Z"/>
        </SvgIcon>
    )
}


export default QuickClipIcon;