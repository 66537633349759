import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { LoadingScreen, EditButton } from '../../../../components';
import { GET_USER } from '../../../../apollo/queries';
import { Typography, Divider, Button, Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ViewerContext } from '../../../../providers/ViewerProvider';

const JasperactiveUser = ({ match, history }) => {
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { userId: match.params.userId },
  });

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error....</p>;

  return <User user={data.user} history={history} match={match} />;
};

export default JasperactiveUser;

const User = ({ user, history, match }) => {
  const { viewer } = useContext(ViewerContext);
  if (!user) return <p>No User</p>;

  return (
    <div>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          startIcon={<ArrowBackIcon />}
          color="primary"
          style={{ marginRight: 16 }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </header>
      <Box display="flex" alignItems="center">
        <Typography variant="h3">
          {user.first_name} {user.last_name}
        </Typography>
        <EditButton
          disabled={user.id === viewer.id}
          onClick={() => history.push(`${match.url}/edit`)}
        />
      </Box>
      <Divider style={{ margin: 6 }} />

      <Typography gutterBottom>email: {user.email}</Typography>
      <Typography gutterBottom>role: {user.role}</Typography>
      <Typography gutterBottom>groups: {user.groups.length}</Typography>
      <Typography gutterBottom>
        institutions: {user.institutions.length}
      </Typography>
    </div>
  );
};
