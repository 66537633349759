import React, { Fragment, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import GroupCourses from './GroupCourses';
import GroupSettings from './GroupSettings';
import GroupMembers from './GroupMembers';
import InstructorGradebook from '../InstructorGradebook';
import styles from './styles';
import { isInstructor } from '../../../../lib';
import { useGroupMember } from '../../providers/GroupMemberProvider';
import { useGroup } from '../../providers/GroupProvider';
import { Switch, Route, Redirect } from 'react-router-dom';
import GroupHeader from '../GroupHeader';
import { DashboardShell } from '../../../../components';

function a11yProps(tab) {
  return {
    id: `${tab}-tab`,
    'aria-controls': `tabpanel-${tab}`,
  };
}

const Dashboard = ({ history, match, location }) => {
  const classes = styles();
  const group = useGroup();
  const member = useGroupMember();

  let tabs = ['courses', 'group members'];
  if (member && isInstructor(member.role)) {
    tabs = ['courses', 'group members', 'gradebook', 'settings'];
  }

  

  const determineCurrentTab = () => {
    const routeArr = location.pathname.split('/');
    let currentTabIndex = 0;

    for (const route of routeArr.reverse()) {
      const _route = route === 'members' ? 'group members' : route;
      const index = tabs.findIndex((tab) => tab === _route);
      if (index > -1) {
        currentTabIndex = index;
        break;
      }
    }

    return currentTabIndex;
  };

  const [value, setValue] = useState(determineCurrentTab());
  const handleChange = (event, newValue) => {
    setValue(newValue);
    let route = tabs[newValue];
    if (route === 'group members') {
      route = 'members';
    }

    history.push(route === 'courses' ? match.url : `${match.url}/${route}`);
  };

  const _tabs = tabs.map((tab) => (
    <Tab key={tab} label={tab} {...a11yProps(tab)} className={classes.tab} />
  ));
  return (
    <DashboardShell
      header={
        <Fragment>
          <GroupHeader onBackClick={() => history.goBack()} group={group} />
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons='auto'
              variant="scrollable"
              className={classes.tabs}
              
            >
              {_tabs}
            </Tabs>
        </Fragment>
      }
      main={
        <Switch>
          <Route path={`${match.path}/members`} component={GroupMembers} />
          <Route
            path={`${match.path}/gradebook`}
            component={InstructorGradebook}
          />
          <Route path={`${match.path}/settings`} component={GroupSettings} />
          <Route exact path={`${match.path}`} component={GroupCourses} />
          <Redirect to={match.path} from={`${match.path}/*`} />
        </Switch>
      }
    />
  );
};

export default Dashboard;
