import gql from 'graphql-tag';
import { SummaryFields } from './fragments';

const GET_SUMMARY = gql`
  query getSummary($where: SummaryWhereUniqueInput!) {
    summary(where: $where) {
      ...SummaryFields
    }
  }
  ${SummaryFields}
`;

const GET_SUMMARIES = gql`
  query getSummaries($courseId: ID!, $level: String!) {
    summaries(courseId: $courseId, level: $level) {
      ...SummaryFields
    }
  }
  ${SummaryFields}
`;

const USER_COMPLETED_SUMMARY_IDS = gql`
  query getStudentProgress($userId: ID!, $groupId: ID!) {
    completedSummaries(userId: $userId, groupId: $groupId)
  }
`;

const UPDATE_SUMMARY = gql`
  mutation updateSummary(
    $data: SummaryUpdateInput!
    $where: SummaryWhereUniqueInput!
  ) {
    updateSummary(data: $data, where: $where) {
      ...SummaryFields
    }
  }
  ${SummaryFields}
`;

const CREATE_SUMMARY = gql`
  mutation createSummary(
    $data: SummaryCreateInput!
  ) {
    createSummary(data: $data)
  }
`;

const PUBLISH_SUMMARY = gql`
  mutation publishSummary($id: ID!) {
    publishSummary(id: $id)
  }
`;

const UNPUBLISH_SUMMARY = gql`
  mutation unpublishSummary($id: ID!) {
    unpublishSummary(id: $id)
  }
`;

const GET_SUMMARY_DEVELOPMENT = gql`
  query getSummaryDevelopoment($where: SummaryWhereUniqueInput!) {
    summary(where: $where) {
      id
      level
      published
      description
      time
      questions_per_lesson
      questions {
        id
        lesson {
          id
        }
      }
      benchmark_id
    }
  }
`;


const GET_TOTAL_QUESTIONS_NUMBER_SUMMARY = gql`
  query getTotalQuestionsNumber($summaryId: ID!, $groupId: ID!) {
    totalQuestionsSummary(summaryId: $summaryId, groupId: $groupId)
  }
`;

export {
  GET_SUMMARY,
  GET_SUMMARIES,
  USER_COMPLETED_SUMMARY_IDS,
  UPDATE_SUMMARY,
  PUBLISH_SUMMARY,
  UNPUBLISH_SUMMARY,
  GET_SUMMARY_DEVELOPMENT,
  CREATE_SUMMARY,
  GET_TOTAL_QUESTIONS_NUMBER_SUMMARY
};