import * as React from 'react';
import { LoadingScreen } from "../../components";
import { AuthConsumer } from '../../providers/AuthProvider';
import { Typography } from '@material-ui/core';

export const Callback = () => (
  <AuthConsumer>
    {({ login, authenticated, signinRedirect }) => {
      login();
      return (
        <LoadingScreen>
          <Typography align="center" variant="body2">
            Loading, please wait...
          </Typography>
        </LoadingScreen>
      );
    }}
  </AuthConsumer>
);
