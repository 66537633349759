import { Box, Button } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

import { EditorText } from '../../../../components';
import ImmersiveReaderButton from '../../../../components/immersiveReader/ImmersiveReaderButton';
import SelectionImmersiveReader from '../../../../components/immersiveReader/SelectionImmersiveReader';
import useNormalizeQuickdeckContent from '../../../../hooks/useNormalizeQuickdeckContent';
import styles from './styles';

const KeyElementsContentLibrary = ({type, quickResource, setQuickResource, quickResourcesByType}) => {
  const classes = styles();
  const scrollContainer = useRef(null);

  useEffect(() => {
    scrollContainer.current.scrollTop = 0;
  },[quickResource])

  return (
    <div className={classes.keyElementsContentLibrary}>
      <LibraryControls 
      setQuickResource={setQuickResource}
      quickResource={quickResource}
      quickResourcesByType={quickResourcesByType}/>
      <div ref={scrollContainer} className={classes.keyElementsContentLibrary_viewer}>
        { 
          type === 'EBOOK' ? <EbookResource quickResource={quickResource}/> : null
        }
        {
          type === 'QUICKDECK' ? <QuickdeckResource quickResource={quickResource}/> : null
        }
        {
          type === 'QUICKCLIP' ? <QuickclipResource quickResource={quickResource}/> : null
        }
      </div>
    </div>
  )
}

export default KeyElementsContentLibrary;

const LibraryControls = ({setQuickResource, quickResource, quickResourcesByType}) => {
  const classes = styles();
  const [currentItem, setCurrentItem] = useState(0);

  useEffect(() => {
    const getIndexOfCurrentQuickResource = () => {
      return quickResourcesByType?.findIndex((item) => item.id === quickResource.id);
    }
    setCurrentItem(getIndexOfCurrentQuickResource());
  }, [quickResource, quickResourcesByType])

  const handleNext = () => {
    setQuickResource(quickResourcesByType[currentItem+1]);
    setCurrentItem(currentItem+1);
  }

  const handlePrev = () => {
    setQuickResource(quickResourcesByType[currentItem-1]);
    setCurrentItem(currentItem-1);
  }

  return (
    <div className={classes.libraryControls}>
      <Button className={classes.libraryControls_buttons} onClick={handlePrev} disabled={currentItem <= 0} >PREV</Button>
      <Button className={classes.libraryControls_buttons} onClick={handleNext} disabled={currentItem >= quickResourcesByType?.length - 1}>NEXT</Button>
    </div>
  )
}


//This component show quickclips
const QuickclipResource = ({quickResource}) => {
  //Hook to active cc from video disabled
  /*const {
    activeCC,
    subTitles,
    tracksInVideo,
    videoPlayer,
    setSubtitles
  } = useHandleActiveCC(quickResource);


  useEffect(() => {
    setSubtitles(false);
  }, [quickResource, setSubtitles]);*/

  const classes = styles();
  return (
      <div className={classes.quickclipResource}>
        {
          quickResource ? 
          <Box className={classes.box}>
            <EditorText text={quickResource.video} style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: 'flex-start' }} />
          </Box> : null
        }
        {/*
          tracksInVideo >= 1 ? 
            <Button startIcon={<SubtitleClipIcon viewBox='-3 -3 24 24' />} className={ subTitles ? classes.buttonCloseCC : classes.buttonActiveCC} onClick={activeCC}>
              {subTitles ? 'close cc' : 'active cc'}
            </Button> : null
        */}
      </div>
  )
}

const QuickdeckResource = ({quickResource}) => {
  const classes = styles();

  const quickdeckContent = useNormalizeQuickdeckContent(quickResource);

  return (
    <div className={classes.quickdeckResource}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <ImmersiveReaderButton text={quickdeckContent}/>
        <SelectionImmersiveReader htmlText={quickdeckContent}/>
      </Box>
    </div>
  )
}

const EbookResource = ({quickResource}) => {
  const classes = styles();
  const [ebook, setEbook] = useState(null);

  const handleNormalizeEbook = (content) => {
    let temp = document.createElement('div');
    temp.innerHTML = content;
    const elements = temp.getElementsByTagName('*');
    for (let index = 0; index < elements.length; index++) {
      elements[index].style.removeProperty('width');
    }

    setEbook(temp.outerHTML);
  };

  useEffect(() => {
    if (quickResource) {
      handleNormalizeEbook(quickResource.content);
    }
  }, [quickResource])

  return (
    <div className={classes.ebookResource}>
      <Box m="auto">
        <ImmersiveReaderButton text={ebook}/>
        <SelectionImmersiveReader htmlText={ebook}/>
      </Box>
    </div>
  )
}