import gql from 'graphql-tag';

const GET_ALL_BENCHMARK_RESULTS = gql`
  query allStudentBenchmarkResults($courseId: ID!, $groupId: ID!) {
    instructorBenchmarkGradebookOverview(courseId: $courseId) {
      id
      level
      total_questions(groupId: $groupId)
      students(groupId: $groupId) {
        id
        first_name
        last_name
        complete
        correct_answers
        incorrect_answers
        seconds_spent
      }
    }
  }
`;

const GET_ALL_SUMMARY_RESULTS = gql`
  query allStudentSummaryResults($courseId: ID!, $groupId: ID!) {
    instructorSummaryGradebookOverview(courseId: $courseId) {
      id
      level
      total_questions(groupId: $groupId)
      students(groupId: $groupId) {
        id
        first_name
        last_name
        complete
        correct_answers
        incorrect_answers
        seconds_spent
      }
    }
  }
`;

const GET_ALL_LESSON_RESULTS = gql`
  query allStudentLessonResults($courseId: ID!, $groupId: ID!) {
    instructorLessonGradebookOverview(courseId: $courseId) {
      id
      name
      display_order
      level
      total_questions
      student_results(groupId: $groupId) {
        student_id
        student_name
        study_project_results {
          study_project_id
          study_project_number
          complete
          started
          total_questions_in_progress
          correct_answers
          total_questions
        }
      }
    }
  }
`;

//About export excel
const GET_EXCEL_DATA_GRADEBOOK = gql`
query getExcelDataGradebook($courseId: ID!, $groupId: ID!) {
  instructorExcelDataGradebook(courseId: $courseId, groupId: $groupId) {
    id
    student_name
    benchmarks {
      level
      benchmark_average
      complete
    }
    lessons {
      lesson_number
      lesson_average
      complete_study_projects
    }
    summaries {
      level
      summary_average
      complete
    }
  }
}
`;
//About export excel

export { GET_ALL_BENCHMARK_RESULTS, GET_ALL_LESSON_RESULTS, GET_EXCEL_DATA_GRADEBOOK, GET_ALL_SUMMARY_RESULTS };
