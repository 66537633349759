import React from 'react';
import styles from '../Benchmark/styles';
import BenchmarkSettingsProvider, { BenchmarkSettingsConsumer } from '../Benchmark/providers/BenchmarkSettingsProvider';
import SummaryProvider from './providers/SummaryProvider';
import SummaryProgressProvider from './providers/SummaryProgressProvider';
import SummaryUserAnswerProvider from './providers/SummaryUserAnswerProvider';
import { SummaryAssessment } from './Components';
import GroupProvider from '../GroupDashboard/providers/GroupProvider';

//Validate component depends on Benchmark providers
const Summary = ({ match }) => {
  const classes = styles();

  const courseId = match.params.courseId;
  const groupId = match.params.groupId;
  const summaryLevel = match.params.level;

  return (
    <GroupProvider groupId={groupId}>
      <BenchmarkSettingsProvider>
        <BenchmarkSettingsConsumer>
          {({ freeVersion, previewVersion }) => (
            <SummaryProgressProvider groupId={groupId}>
              <SummaryProvider
                courseId={courseId}
                summaryLevel={summaryLevel}
              >
                <SummaryUserAnswerProvider groupId={groupId}>
                  <section className={classes.benchmarkSection}>
                    <SummaryAssessment
                      freeVersion={freeVersion}
                      previewVersion={previewVersion}
                      title={'Summary Assessment'}
                      introductionText={'This test will evaluate your new skills.'}
                    />
                  </section>
                </SummaryUserAnswerProvider>
              </SummaryProvider>
            </SummaryProgressProvider>
          )}
        </BenchmarkSettingsConsumer>
      </BenchmarkSettingsProvider>
    </GroupProvider>
  );
};

export default Summary;