import React from 'react';

const IframeCourse = () => {
    // const courseId = sessionStorage.getItem("iframe_course_id");   

    return (
        <p>test...</p>
    );
};

export default IframeCourse;