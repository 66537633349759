import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  courseCurriculumSection: {
    height: '100%',
    display: 'flex',
  },
  curriculumDrawer: {
    position: 'relative',
    minWidth: 400,
    maxWidth: 400,
    height: '100%',
    borderRight: `1px solid ${theme.palette.divider}`,
    boxShadow: '1px 2px 2px #E0E0E0',
    overflowY: 'scroll',
    zIndex: theme.zIndex.drawer,
    paddingBottom: theme.spacing(2),
  },

  curriculumContent: {
    flexGrow: 1,
    height: '100%',
  },
}));

export default styles;
