import { IDENTITY_CONFIG } from '../utils/authConst';
import { UserManager, WebStorageStateStore } from 'oidc-client';

export default class AuthService {
  UserManager;
  constructor() {
    this.UserManager = new UserManager({
      response_mode: 'query',
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    });

    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.indexOf('auth-callback') !== -1) {
        this.navigateToScreen();
      }
    });

    this.UserManager.events.addAccessTokenExpiring((e) => {
      this.refreshAccessToken();
    });

    this.UserManager.events.addSilentRenewError((e) => {
      this.signinRedirect();
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      this.refreshAccessToken();
    });
  }

  refreshAccessToken = () => {
    const isRefreshingAccessToken = sessionStorage.getItem("isRefreshingAccessToken");
    if (!isRefreshingAccessToken) {
      sessionStorage.setItem("isRefreshingAccessToken", "true");
      this.oldUserInfo = this.getSessionInfo();
      this.signinSilent(() => { sessionStorage.removeItem("isRefreshingAccessToken"); });
    }
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
      const redirectURL = sessionStorage.getItem('url');
      sessionStorage.removeItem('url');
      if (redirectURL)
        window.location.replace('/' + redirectURL);
      console.log('signinRedirectCallback');
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    const redirectMediaLibrary = localStorage.getItem('redirectMediaLibrary');

    if (redirectMediaLibrary) {
      window.location.replace(`${redirectMediaLibrary}`);
    } else {
      window.location.replace('/');
    }
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(`oidc.user:${IDENTITY_CONFIG}:Jasperactive`),
    );
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = (onFinish) => {
    try {
      this.UserManager.signinSilent().then(user => {
        if (this.oldUserInfo && user.profile.sub !== this.oldUserInfo.profile.sub) {
          this.signinRedirect();
        }
        onFinish(user);
      }).catch(error => {
        console.error("sign in silent failed:", error);
        onFinish();
      });
    } catch (error) {
      console.error(error);
      onFinish();
    }
  }

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback().catch((err) => {
      console.log("signinSilentCallback error:", err);
    });
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('code'),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      this.navigateToScreen();
      //  window.location.replace(process.env.REACT_APP_PUBLIC_URL);
    });
    this.UserManager.clearStaleState();
  };

  getLogoutUrl = () => {
    return this.UserManager.createSignoutRequest();
  };

  getSessionInfo() {
    return JSON.parse(
      sessionStorage.getItem(`oidc.user:${IDENTITY_CONFIG.authority}:Jasperactive`),
    );
  }

  clearLocalStorage() {
    this.UserManager.clearStaleState();
    localStorage.clear();
    sessionStorage.clear();
  }
}
