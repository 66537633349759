import XLSX from 'sheetjs-style';

export const buildExcelGradebook = (courses, data, selectedCourse) => {
  let workSheets = [];
  const wb = XLSX.utils.book_new();
  const course = courses.find((course) => course.id === selectedCourse);

  const normalizedBenchmarksAndSummariesData = data.instructorExcelDataGradebook.map((item) => {
    return {
      Learners: item.student_name,
      ...item.benchmarks.reduce((acc, benchmark) => {
        acc[`Level ${benchmark.level} Benchmark Assessment Result`] = benchmark.complete ? `${benchmark.benchmark_average}%` : 'Not started';
        return acc;
      }, {}),
      ...item.summaries.reduce((acc, summary) => {
        acc[`Level ${summary.level} Summary Assessment Result`] = summary.complete ? `${summary.summary_average}%` : 'Not started';
        return acc;
      }, {}),
    }
  });

  const ws = XLSX.utils.json_to_sheet(normalizedBenchmarksAndSummariesData, {
    origin: "A2",
  });

  workSheets = [...workSheets, { title: 'Benchmarks and Summaries', ws }];

  //Build a worksheet with lesson average for each lv in course
  for (let i = 0; i < course.levels; i++) {
    const normalizedLessonData = data.instructorExcelDataGradebook.map((item) => {
      return {
        Learners: item.student_name,
        ...item.lessons.reduce((acc, lesson) => {
          if ( typeof lesson.lesson_average[i] !== 'undefined' ) {
            acc[`Level ${i + 1} - Lesson ${lesson.lesson_number}`] = lesson.lesson_average[i] !== null ? `${lesson.lesson_average[i]}%` : 'Not started';
          }
          return acc;
        }, {}),
      }
    });

    const ws = XLSX.utils.json_to_sheet(normalizedLessonData, {
      origin: "A2",
    });

    workSheets = [...workSheets, { title: `Lessons Level ${i + 1}`, ws }];
  }

  for (const workSheet of workSheets) {
    const sheetRange = workSheet.ws['!ref'];

    workSheet.ws["A1"] = { t: 's', v: course.name }

    const columns = (sheetRange) => {
      const cols = [];
      let range = XLSX.utils.decode_range(sheetRange);
      for (var i = 0; i <= range.e.c; ++i) {
        cols.push(i);
      }

      return cols;
    }

    const colsWidth = columns(sheetRange).map((col) => {
      return {
        wpx: 200
      }
    });

    workSheet.ws['!cols'] = colsWidth;

    for (const i in workSheet.ws) {
      let cell = XLSX.utils.decode_cell(i);
      if (cell.r === 1) {
        workSheet.ws[i].s = {
          fill: {
            patternType: "solid",
            bgColor: { rgb: "FFEB9C" },
            fgColor: { rgb: "FFEB9C" }
          },
          border: {
            top: {
              style: 'thin',
              color: { auto: 1 },
            },
            bottom: {
              style: 'thin',
              color: { auto: 1 },
            },
            left: {
              style: 'thin',
              color: { auto: 1 },
            },
            right: {
              style: 'thin',
              color: { auto: 1 },
            },
          }
        }
      }

      if (cell.r !== 0 && cell.c !== -1 && cell.r !== 1) {
        workSheet.ws[i].s = {
          fill: {
            patternType: "solid",
            bgColor: { rgb: "D9E1F2" },
            fgColor: { rgb: "D9E1F2" }
          },
          alignment: {
            horizontal: "right"
          },
          border: {
            top: {
              style: 'thin',
              color: { auto: 1 },
            },
            bottom: {
              style: 'thin',
              color: { auto: 1 },
            },
            left: {
              style: 'thin',
              color: { auto: 1 },
            },
            right: {
              style: 'thin',
              color: { auto: 1 },
            },
          }
        }
      }

      if (cell.r === 0) {
        workSheet.ws[i].s = {
          fill: {
            patternType: "solid",
            bgColor: { rgb: "A9D08E" },
            fgColor: { rgb: "A9D08E" }
          },
          border: {
            top: {
              style: 'thin',
              color: { auto: 1 },
            },
            bottom: {
              style: 'thin',
              color: { auto: 1 },
            },
            left: {
              style: 'thin',
              color: { auto: 1 },
            },
            right: {
              style: 'thin',
              color: { auto: 1 },
            },
          }
        }
      }
    }

    XLSX.utils.book_append_sheet(wb, workSheet.ws, workSheet.title);
  }

  XLSX.writeFile(wb, 'gradebook.xlsx');
};