import React from 'react';
import { FormControl, TextField, Box, Button, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import TimeInput from './TimeInput';
import useGetQuestionsInAssessmentNumber from '../../../hooks/useGetQuestionsInAssessmentNumber';

const BenchmarkForm = ({ benchmark, onSubmit }) => {
  const { questionsNumber } = useGetQuestionsInAssessmentNumber(benchmark);

  return (
    <Form
      initialValues={
        benchmark
          ? { description: benchmark.description, time: benchmark.time, questions_per_lesson: benchmark.questions_per_lesson}
          : null
      }
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FormControl style={{ marginBottom: 16 }}>
            <Field name="time">
              {({ input, meta }) => {
                return (
                  <TimeInput
                    {...input}
                    label="Assessment Time"
                    helperText="specify length of the assessment in minutes"
                    variant="filled"
                  />
                );
              }}
            </Field>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Field name="description">
              {({ input, meta }) => (
                <TextField
                  id="description"
                  label="Description"
                  value={input.value}
                  onChange={input.onChange}
                  variant="outlined"
                  multiline
                  rows="3"
                  rowsMax="3"
                  helperText="Brief description of your content. Max 150 characters."
                />
              )}
            </Field>
          </FormControl>
          <Typography style={{ marginBottom: 16, fontSize: '0.75em' }} variant='subtitle2'>Show the user the following number of random questions from each lesson:</Typography>
          <FormControl style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1em'}}>
            <Field name="questions_per_lesson">
              {({ input, meta }) => (
                <TextField
                  id="questions_per_lesson"
                  label="Questions per Lesson"
                  value={input.value}
                  onChange={input.onChange}
                  variant="outlined"
                  type='number'
                  helperText="Leave empty to show all questions."
                />
              )}
            </Field>
            <Typography style={{ marginBottom: 16, fontSize: '0.75em' }} variant='subtitle2'>Total questions in assessment: {questionsNumber}</Typography>
          </FormControl>
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              disabled={submitting || pristine}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default BenchmarkForm;
