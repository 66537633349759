import React, { useState } from 'react';
import { JoinGroupForm, PrimaryButton } from '../../../../components';
import GroupIcon from '@material-ui/icons/Group';
import useDialog from '../../../../hooks/useDialog';
import { useJoinGroup } from '../../../../apollo/api';
import { useInstitution } from '../../../../providers/InstitutionProvider';
import { useSnackbar } from 'notistack';

const JoinGroup = ({ ...props }) => {
  const {
    isOpen,
    toggleDialog,
    Dialog,
    DialogTitle,
    DialogContent,
  } = useDialog();
  const { joinGroup } = useJoinGroup();
  const { updateUserInstitutions } = useInstitution();
  const [joinError, setJoinError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleJoinGroup = async (values) => {
    const result = await joinGroup({ variables: { group_key: values.key } });

    if (result.data.joinGroup.success) {
      enqueueSnackbar(
        `Successfully joined ${result.data.joinGroup.result.name}`,
        { variant: 'success', autoHideDuration: 1500 },
      );
      await updateUserInstitutions(result.data.joinGroup.result.institution);
      toggleDialog();
    } else {
      setJoinError(result.data.joinGroup.message);
    }
  };

  const handleClose = () => {
    setJoinError(null);
    toggleDialog();
  };

  return (
    <>
      <PrimaryButton
        startIcon={<GroupIcon />}
        onClick={() => handleClose()}
        {...props}
      >
        Join Group
      </PrimaryButton>

      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Join Group</DialogTitle>
        <DialogContent>
          <JoinGroupForm
            onSubmit={handleJoinGroup}
            onCancel={handleClose}
            joinError={joinError}
            resetError={() => setJoinError(null)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default JoinGroup;
