import React from 'react';
import { useCourse } from '../../providers/CourseProvider';
import { useStudentCourseProgress } from '../../providers/StudentCourseProgressProvider';
import CourseBenchmarks from './CourseBenchmarks';
import CourseLessons from './CourseLessons';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import styles from './styles';
import { Heading } from '../../../../components';
import CourseSummaries from './CourseSummaries';

const Dashboard = ({ history, match }) => {
  const classes = styles();
  const studentCourseProgress = useStudentCourseProgress();
  const course = useCourse();
  const isPreview = match.url.split('/').includes('preview');
  return (
    <section className={classes.courseDashboard}>
      <Box mb={2}>
        <Heading variant="h2" component="h2">
          Exercises
        </Heading>
      </Box>
      <Box mb={6}>
        <Card variant="outlined">
          <CardHeader
            titleTypographyProps={{ variant: 'h3' }}
            title="Benchmark"
            subheaderTypographyProps={{ variant: 'body2' }}
            subheader="Tests your current skill level & builds custom learning plan for you."
          />
          <CardContent>
            <CourseBenchmarks
              history={history}
              match={match}
              isPreview={isPreview}
              course={course}
              studentCourseProgress={studentCourseProgress}
            />
          </CardContent>
        </Card>
      </Box>

      <CourseLessons
        course={course}
        studentCourseProgress={studentCourseProgress}
        isPreview={isPreview}
        history={history}
        match={match}
      />
      <Box mb={6}>
        <Card variant="outlined">
          <CardHeader
            titleTypographyProps={{ variant: 'h3' }}
            title="Summary"
            subheaderTypographyProps={{ variant: 'body2' }}
            subheader="Tests your new skill level"
          />
          <CardContent>
            <CourseSummaries
              history={history}
              match={match}
              isPreview={isPreview}
              course={course}
              studentCourseProgress={studentCourseProgress}
            />
          </CardContent>
        </Card>
      </Box>
    </section>
  );
};

export default Dashboard;
