import React, { Fragment, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_INSTITUTION_LICENSES,
  CREATE_LICENSE,
  UPDATE_LICENSE,
  RENEW_LICENSE,
} from '../../../../../apollo/queries';
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Button,
  Modal,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';
import {
  FormToolbar,
  AddButton,
  LicenseFormTwo,
} from '../../../../../components';
import SubtitlesOutlinedIcon from '@material-ui/icons/SubtitlesOutlined';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import styles from './styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';

const InstitutionLicenses = ({ institution, enqueueSnackbar }) => {
  const classes = styles();
  const { data, loading, error } = useQuery(GET_INSTITUTION_LICENSES, {
    variables: { institutionId: institution.id },
  });
  const [createLicense] = useMutation(CREATE_LICENSE, {
    refetchQueries: ['institutionLicenses', 'getInstitutionActivities'],
  });
  const [updateLicense] = useMutation(UPDATE_LICENSE, {
    refetchQueries: ['getInstitutionActivities'],
  });
  const [renewLicense] = useMutation(RENEW_LICENSE, {
    refetchQueries: ['getInstitutionActivities'],
  });

  const inititalLicenseFormData = {
    show: false,
    isQuote: false,
    isEdit: false,
    isActivation: false,
    isRenewal: false,
    license: null,
  };
  const [licenseForm, setLicenseForm] = useState(inititalLicenseFormData);

  if (loading)
    return (
      <Box
        width="100%"
        height={400}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  if (error) return <p>Error...</p>;

  const handleLicenseSubmit = async (values) => {
    if (licenseForm.isQuote) {
      await createLicense({
        variables: {
          data: {
            ...values,
            products: values.products.map((product) => ({
              product_id: product,
            })),
          },
        },
      });
      enqueueSnackbar(`Created new license ${values.name}`, {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } else if (licenseForm.isActivation) {
      await updateLicense({
        variables: {
          data: {
            activation_date: values.activation_date,
            expiry_date: values.expiry_date,
            purchase_order: values?.purchase_order,
          },
          where: {
            id: licenseForm.license.id,
          },
        },
      });
      enqueueSnackbar(`Activated ${values.name}`, {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } else if (licenseForm.isRenewal) {
      await renewLicense({
        variables: {
          data: {
            id: licenseForm.license.id,
            expiry_date: values.expiry_date,
            purchase_order: values?.purchase_order,
          },
        },
      });
      enqueueSnackbar(`Renewed ${values.name}`, {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } else if (licenseForm.isEdit) {
      delete values.institution_id;

      await updateLicense({
        variables: {
          data: {
            ...values,
            products: values.products.map((product) => ({
              product_id: product,
            })),
          },
          where: {
            id: licenseForm.license.id,
          },
        },
      });

      enqueueSnackbar(`Updated ${values.name}`, {
        variant: 'success',
        autoHideDuration: 1500,
      });
    }
    resetLicenseForm();
  };

  const resetLicenseForm = () => setLicenseForm(inititalLicenseFormData);
  const showLicenseForm = (options) => {
    setLicenseForm({ ...inititalLicenseFormData, show: true, ...options });
  };

  const licenses = data.licenses;

  return (
    <Fragment>
      <Modal
        open={licenseForm.show}
        onClose={() => resetLicenseForm()}
        aria-labelledby="form-dialog-title"
      >
        <Paper
          style={{
            padding: 16,
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 500,
          }}
        >
          <Box display="flex" mb={2}>
            <SubtitlesOutlinedIcon style={{ marginRight: 16 }} />
            <Typography>
              {licenseForm.isQuote
                ? 'Quote a New License'
                : licenseForm.isActivation
                ? 'Activate License'
                : licenseForm.isRenewal
                ? 'Renew License'
                : 'Edit License'}
            </Typography>
          </Box>
          <LicenseFormTwo
            onCancel={() => resetLicenseForm()}
            onSubmit={(values) => handleLicenseSubmit(values)}
            institution={institution}
            license={licenseForm.license}
            isQuote={licenseForm.isQuote}
            isActivation={licenseForm.isActivation}
            isRenewal={licenseForm.isRenewal}
            isEdit={licenseForm.isEdit}
          />
        </Paper>
      </Modal>
      <Paper style={{ padding: 16, height: '100%' }}>
        <FormToolbar
          title="Licenses"
          leftIcon={<SubtitlesOutlinedIcon style={{ marginRight: 15 }} />}
          buttonText="Quote License"
          onClick={() => showLicenseForm({ isQuote: true })}
        />
        {licenses.length === 0 ? (
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <SubtitlesOutlinedIcon style={{ fontSize: 120, color: '#CCC' }} />
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginBottom: 16 }}
            >
              Quote the first license
            </Typography>
            <AddButton
              onClick={() => showLicenseForm({ isQuote: true })}
              variant="contained"
              color="primary"
              title="Quote License"
            />
          </Box>
        ) : (
          <List style={{ maxHeight: 640, overflowY: 'scroll' }}>
            {licenses.map((license) => (
              <ListItem
                style={{ borderBottom: `2px solid #D0D0D0` }}
                key={license.id}
                button
                onClick={() =>
                  showLicenseForm({ isEdit: true, license: license })
                }
              >
                <ListItemText
                  component="div"
                  primary={license.name}
                  secondary={<LicenseExpiryText license={license} />}
                />
                <ListItemSecondaryAction>
                  {license.is_quoted ? (
                    <Button
                      className={classes.activateOrRenewButton}
                      onClick={() =>
                        showLicenseForm({
                          isActivation: true,
                          license: license,
                        })
                      }
                    >
                      Activate
                    </Button>
                  ) : license.days_to_expire <= 90 ? (
                    <Button
                      className={classes.activateOrRenewButton}
                      onClick={() =>
                        showLicenseForm({ isRenewal: true, license: license })
                      }
                    >
                      Renew
                    </Button>
                  ) : (
                    <Box className={classes.activeLicenseText}>
                      <CheckCircleIcon
                        color="inherit"
                        style={{ marginRight: 8 }}
                      />
                      <Typography color="inherit" component="span">
                        Active Now
                      </Typography>
                    </Box>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    </Fragment>
  );
};

const LicenseExpiryText = ({ license }) => {
  const classes = styles();
  const expires = license.expiry_date;
  const isExpired = moment(expires).isBefore(moment());

  return (
    <Typography
      component="span"
      variant="body2"
      className={clsx({
        [classes.expired]: license.days_to_expire <= 90 && expires !== null,
      })}
    >
      <Typography
        component="span"
        variant="body2"
        className={clsx(classes.expiredText, { [classes.expired]: isExpired })}
      >
        {expires ? `Expire${isExpired ? 'd' : 's'} on: ` : ''}{' '}
      </Typography>
      {expires ? moment(expires).format('MMM DD YYYY') : 'Requires Activation'}
    </Typography>
  );
};

export default withSnackbar(InstitutionLicenses);
