import React from 'react';
import ProductContainer from '../../../../containers/ProductContainer';
import { Typography, Box } from '@material-ui/core';
import { ExpansionPanel } from '../../../../components';
import styles from './styles';
import { withSnackbar } from 'notistack';
import ProductSummary from './ProductSummary';
import ProductCourseList from './ProductCourseList';

const ProductList = ({ match, history, enqueueSnackbar }) => {
  const classes = styles();
  return (
    <ProductContainer>
      {({
        cmsProductData: { products, loading, error },
        updateProduct,
        deleteProduct,
        publishProduct,
        unpublishProduct,
      }) => {
        if (loading) return <p>Loading...</p>;
        if (error) {
          return <p>error...</p>;
        }

        if (!products.length) {
          return (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={4}
            >
              <Typography variant="h3" color="textSecondary">
                No Products
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Click Create Product to get started!
              </Typography>
            </Box>
          );
        }

        return products.map((product, index) => (
          <ExpansionPanel
            showExpandIcon
            defaultExpanded={index === 0}
            key={product.id}
            className={classes.productExpansionPanel}
            summaryProps={{ className: classes.productSummaryPanel }}
            summary={
              <ProductSummary
                product={product}
                enqueueSnackbar={enqueueSnackbar}
                updateProduct={updateProduct}
                deleteProduct={deleteProduct}
                publishProduct={publishProduct}
                unpublishProduct={unpublishProduct}
              />
            }
            details={
              <ProductCourseList
                product={product}
                match={match}
                history={history}
                enqueueSnackbar={enqueueSnackbar}
              />
            }
          />
        ));
      }}
    </ProductContainer>
  );
};

export default withSnackbar(ProductList);
