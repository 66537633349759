import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  questionContainer: {
    padding: '1em 0',
    marginTop:theme.spacing(2)
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    background: theme.palette.white.main,
  },
  toolbar: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  button: {
    width: '100%',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  skipAllBtn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    marginRight: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));
