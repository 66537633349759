import React from 'react';
import { DeleteButton } from '../../../../../../../components';
import { KeyElementContainer } from '../../../../../../../containers';
import { useContext } from 'react';
import { UserConfirmationContext } from '../../../../../../../providers/UserConfirmationProvider';

const DeleteKeyElementResource = ({
  resourceId,
  keyElementId,
  enqueueSnackbar,
}) => {
  const { showConfirmation } = useContext(UserConfirmationContext);

  const handleSubmit = (removeKeyElementResource) =>
    showConfirmation({
      title: 'Remove Resource',
      message: 'Are you sure you want to remove this resource?',
      confirmed: async () => {
        await removeKeyElementResource.mutation({
          variables: {
            keyElementId,
            resourceId,
          },
          refetchQueries: ['getKeyElements'],
        });
        enqueueSnackbar(`Successfully removed resource`, {
          variant: 'success',
          autoHideDuration: 1500,
        });
      },
    });

  return (
    <KeyElementContainer>
      {({ removeKeyElementResource }) => (
        <DeleteButton onClick={() => handleSubmit(removeKeyElementResource)} />
      )}
    </KeyElementContainer>
  );
};

export default DeleteKeyElementResource;
