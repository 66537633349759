import React from "react";
import PropTypes from "prop-types";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Button } from "@material-ui/core";

const PrimaryButton = ({ showIcon = false, children, ...restProps }) => {
  return (
    <Button variant="contained" color="primary" startIcon={showIcon ? <ChevronRightIcon /> : null} {...restProps}>
      {children}
    </Button>
  );
};

PrimaryButton.propTypes = {
  ...Button.propTypes,
  showIcon: PropTypes.bool,
  children: PropTypes.any.isRequired
};

export default PrimaryButton;
