import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_STUDENT_COURSE_PROGRESS } from '../../../apollo/queries';
import { LoadingScreen } from '../../../components';
import { useContext } from 'react';

const StudentCourseProgressContext = React.createContext();

const StudentCourseProgressProvider = ({
  studentId,
  courseId,
  groupId,
  children,
}) => {
  const { data, loading, error } = useQuery(GET_STUDENT_COURSE_PROGRESS, {
    fetchPolicy: 'network-only',
    variables: { courseId, studentId, groupId },
    skip: !courseId || !studentId || !groupId,
  });

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error...</p>;

  const studentCourseProgress = data ? data.studentCourseProgress : null;

  return (
    <StudentCourseProgressContext.Provider value={{ studentCourseProgress }}>
      {children}
    </StudentCourseProgressContext.Provider>
  );
};

export const useStudentCourseProgress = () => {
  const { studentCourseProgress } = useContext(StudentCourseProgressContext);

  return studentCourseProgress;
};

export default StudentCourseProgressProvider;
