import React, { useState } from "react";
import axios from 'axios';
import { CircularProgress, IconButton } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const DownloadButton = ({url, name}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClickDownload = (url) => {
    setIsLoading(true);
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      setIsLoading(false);
    });
  };
  
  return (
    <IconButton onClick={() => handleOnClickDownload(url)} edge="end" aria-label="delete">
      {
        isLoading ? <CircularProgress size={24} /> : <CloudDownloadIcon color='primary' />
      }
    </IconButton>
  )
};

export default DownloadButton;
