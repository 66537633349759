import React from 'react';
import { useViewer } from '../../providers/ViewerProvider';
import { AppBar, Box, Grid } from '@material-ui/core';
import {
  Heading,
  InstitutionLicenseList,
  InstitutionMembers,
} from '../../components';
import {
  UserInstitutionGroups,
  NoInstitutionPage,
  UserInstitutionList,
} from './components';
import styles from './styles';
import { useInstitution } from '../../providers/InstitutionProvider';
import useLaunchedInIFrame from '../../hooks/useLauchedInIframe';
import AddGroup from './components/AddGroup';
import JoinGroup from './components/JoinGroup';
import { useTabs } from '../../hooks/useTabs';
import clsx from 'clsx';

const StudyGroupDashboard = ({ history }) => {
  useLaunchedInIFrame(history);
  const classes = styles();
  const viewer = useViewer();
  const {
    institutions,
    currentInstitution,
    setCurrentInstitution,
    currentMember,
  } = useInstitution();
  const { Tabs, Tab, TabPanel, handleTabChange, currentTab } = useTabs();

  if (institutions.length === 0) {
    return <NoInstitutionPage history={history} viewer={viewer}/>;
  }

  const isSelected = (id) => {
    return currentInstitution && currentInstitution.id === id;
  };

  return (
    <section className={classes.studyGroupSection}>
      <aside className={classes.drawer}>
        <Heading variant="h2" className={classes.heading}>
          My Institution
        </Heading>
        <UserInstitutionList
          institutions={institutions}
          currentInstitution={currentInstitution}
          onClick={setCurrentInstitution}
          isSelected={isSelected}
          currentMember={currentMember}
        />
      </aside>
      <main className={classes.main}>
        {currentMember && currentMember.role !== 'STUDENT' && (
          <Box mb={4}>
            <AppBar className={classes.tabBar} position="static">
              <Tabs
                variant="fullWidth"
                indicatorColor="primary"
                onChange={handleTabChange}
                value={currentTab}
              >
                <Tab
                  component="a"
                  label="My Groups"
                  className={clsx({ [classes.selected]: currentTab === 0 })}
                />
                <Tab
                  component="a"
                  label="Institution Members"
                  className={clsx({ [classes.selected]: currentTab === 1 })}
                />
                <Tab
                  component="a"
                  label="Licenses"
                  className={clsx({ [classes.selected]: currentTab === 2 })}
                />
              </Tabs>
            </AppBar>
          </Box>
        )}
        <TabPanel
          currentTab={
            currentMember && currentMember.role !== 'STUDENT' ? currentTab : 0
          }
          index={0}
        >
          <Heading variant="h2" component="h2" className={classes.heading}>
            My Groups
          </Heading>
          <Grid container spacing={1} className={classes.buttonContainer}>
            {currentMember && currentMember.role !== 'STUDENT' && (
              <Grid item>
                <AddGroup
                  member={currentMember}
                  licenses={
                    currentInstitution ? currentInstitution.licenses : []
                  }
                />
              </Grid>
            )}
            <Grid item>
              <JoinGroup />
            </Grid>
          </Grid>
          <div className={classes.scrollWrapper}>
            <UserInstitutionGroups
              institution={currentInstitution}
              member={currentMember}
              viewer={viewer}
              history={history}
            />
          </div>
        </TabPanel>
        {currentMember && currentMember.role !== 'STUDENT' && (
          <>
            <TabPanel currentTab={currentTab} index={1}>
              <InstitutionMembers
                institutionId={
                  currentInstitution ? currentInstitution.id : null
                }
              />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={2}>
              <Heading variant="h2" component="h2" className={classes.heading}>
                My Licenses
              </Heading>
              <div className={classes.scrollWrapper}>
                <InstitutionLicenseList
                  institutionId={
                    currentInstitution ? currentInstitution.id : null
                  }
                />
              </div>
            </TabPanel>
          </>
        )}
      </main>
    </section>
  );
};

export default StudyGroupDashboard;
