import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createMuiTheme, useTheme } from "@material-ui/core/styles";

const neutralColors = {
  50: "#FFFFFF",
  100: "#F7F8F9",
  200: "#EBECEE",
  300: "#DEDFE3",
  400: "#717073",
  500: "#39363A",
  600: "#010101"
};

let theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiFilledInput: {
      // Name of the rule
      root: {
        // Some CSS
        backgroundColor: neutralColors[100],
      },
    },
  },
  breakpoints: {
    keys: [
      "xs",
      "sm",
      "md",
      "lg",
      "xl",
      "iphone5",
    ],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      iphone5: 321
    }
  },
  typography: {
    fontFamily: [
      '"Segoe UI"',
      "Open Sans",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    h1: {
      fontSize: 32,
      fontWeight: "normal",
      color: neutralColors[600],
      lineHeight: "40px",
      letterSpacing: 0,
      textTransform: "none"
    },
    h2: {
      fontSize: 26,
      fontWeight: "normal",
      color: neutralColors[600],
      lineHeight: "36px",
      letterSpacing: 0,
      textTransform: "none"
    },
    h3: {
      fontSize: 20,
      fontWeight: "normal",
      color: neutralColors[600],
      lineHeight: "28px",
      letterSpacing: 0,
      textTransform: "none"
    },
    h4: {
      fontSize: 16,
      fontWeight: "bold",
      color: neutralColors[500],
      lineHeight: "24px",
      letterSpacing: 0,
      textTransform: "none"
    },
    h5: {
      fontSize: 14,
      fontWeight: "bold",
      color: neutralColors[500],
      lineHeight: "24px",
      letterSpacing: 0,
      textTransform: "none"
    },
    h6: {
      fontSize: 14,
      fontWeight: "bold",
      color: neutralColors[500],
      lineHeight: "24px",
      letterSpacing: 0,
      textTransform: "none"
    },
    body1: {
      fontSize: 16,
      fontWeight: "normal",
      color: neutralColors[500],
      lineHeight: "24px",
      letterSpacing: 0,
      textTransform: "none"
    },
    body2: {
      fontSize: 16,
      fontWeight: "normal",
      color: neutralColors[500],
      lineHeight: "20px",
      letterSpacing: 0,
      textTransform: "none"
    },
    subtitle1: {
      fontSize: 13,
      fontWeight: 600,
      color: neutralColors[500],
      lineHeight: "20px",
      letterSpacing: 0,
      textTransform: "none"
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: "normal",
      color: neutralColors[500],
      lineHeight: "20px",
      letterSpacing: 0,
      textTransform: "none"
    },
    button: {
      fontSize: 13,
      fontWeight: "normal",
      color: "#0C83DE",
      lineHeight: "24px",
      letterSpacing: "1.25px",
      textTransform: "uppercase"
    },
    caption: {
      fontSize: 10,
      fontWeight: "normal",
      color: neutralColors[400],
      lineHeight: "16px",
      letterSpacing: 0,
      textTransform: "none"
    },
    overline: {
      fontSize: 10,
      fontWeight: "normal",
      color: neutralColors[400],
      lineHeight: "16px",
      letterSpacing: 0,
      textTransform: "uppercase"
    }
  },
  palette: {
    info: {
      light: "#CEE6F8",
      main: "#0C83DE",
      dark: "#0A69B2"
    },
    success: {
      light: "#D9EFD4",
      main: "#3FAE2A",
      dark: "#266819"
    },
    warning: {
      light: "#FBE7B5",
      main: "#ECB322",
      dark: "#CC7300"
    },
    error: {
      light: "#FCE8EB",
      main: "#DE1B3B",
      dark: "#B2162F"
    },
    primary: {
      contrastText: "#FFF",
      main: "#BF1212",
    },
    secondary: {
      contrastText: "#FFF",
      light: "#FBD4BC",
      main: "#F37121",
      dark: "#C25A1A"
    },
    accent: {
      blue: "#22BCB9",
      green: "#C1CD23",
      purple: "#C051D5"
    },
    product: {
      cci: "#F37121",
      jasperactive: "#BF1212",
      "digital-literacy": "#3FAE2A",
      "ms-word": {
        light: "#D5DDEA",
        main: "#2B5797",
        dark: "#1A345B"
      },
      "ms-excel": {
        light: "#CFE5D9",
        main: "#107C41",
        dark: "#0B572E"
      },
      "ms-ppt": {
        light: "#F1DAD4",
        main: "#B7472A",
        dark: "#80321D"
      }
    },
    jasperactiveGreen: {
      main: '#3FAE2A',
    },
    grey: {
      ...neutralColors
    },
    text: {
      title: "#010101",
      primary: "#39363A",
      secondary: "#717073"
    },
    jasperOrange: {
      light: "#F69051",
      main: "#F37121"
    },
    white: {
      main: "#FFF"
    },
    dark: {
      main: "#555759"
    },
    black: {
      main: "#000000"
    },
    background: {
      light: "#F1F1F1",
      main: "#EEEEEE",
      dark: "#555759",
      default: "#FFF"
    }
  }
});

export * from './globalStyles';
export { theme };

export const ThemeDisplay = () => {
  const theme = useTheme();
  return (
    <Box height="90%" overflow="auto" p={4} mb={6} display="flex">
      <Box>
        <Typography variant="h1">Heading One</Typography>
        <Typography variant="h2">Heading Two</Typography>
        <Typography variant="h3">Heading Three</Typography>
        <Typography variant="h4">Heading Four</Typography>
        <Typography variant="h5">Heading Five</Typography>
        <Typography variant="h6">Heading Six</Typography>
        <Typography variant="subtitle1">Subtitle 1</Typography>
        <Typography variant="subtitle2">Subtitle 2</Typography>
        <Typography variant="body1">Body 1</Typography>
        <Typography variant="body2">Body 2</Typography>
        <Typography variant="button" style={{ display: "block" }}>
          Button
        </Typography>
        <Typography variant="caption" style={{ display: "block" }}>
          Caption
        </Typography>
        <Typography variant="overline" style={{ display: "block" }}>
          Overline
        </Typography>
      </Box>
      <Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="info.light">
          Information Light
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="info.main" color="#FFF">
          Information
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="info.dark" color="#FFF">
          Information Dark
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="success.light">
          Success Light
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="success.main" color="#FFF">
          Success
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="success.dark" color="#FFF">
          Success Dark
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="warning.light">
          Warning Light
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="warning.main">
          Warning
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="warning.dark" color="#FFF">
          Warning Dark
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="error.light">
          Error Light
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="error.main" color="#FFF">
          Error
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="error.dark" color="#FFF">
          Error Dark
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="primary.light">
          Primary Light
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="primary.main" color="#FFF">
          Primary
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="primary.dark" color="#FFF">
          Primary Dark
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="secondary.light">
          Secondary Light
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="secondary.main" color="#FFF">
          Secondary
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="secondary.dark" color="#FFF">
          Secondary Dark
        </Box>
      </Box>
      <Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="accent.blue">
          Accent Blue
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="accent.green">
          Accent Green
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="accent.purple">
          Accent Purple
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.cci">
          Product CCI
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.jasperactive" color="#FFF">
          Product Jasperactive
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.digital-literacy">
          Product Jasperactive Digital Literacy
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.ms-word.light">
          Product MS Word Light
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.ms-word.main" color="#FFF">
          Product MS Word
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.ms-word.dark" color="#FFF">
          Product MS Word Dark
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.ms-excel.light">
          Product MS Excel Light
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.ms-excel.main" color="#FFF">
          Product MS Excel
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.ms-excel.dark" color="#FFF">
          Product MS Excel Dark
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.ms-ppt.light">
          Product MS Powerpoint Light
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.ms-ppt.main" color="#FFF">
          Product MS Powerpoint
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="product.ms-ppt.dark" color="#FFF">
          Product MS Powerpoint Dark
        </Box>
      </Box>
      <Box>
        <Box p={1} display="flex" height={30} width={400} style={{ backgroundColor: theme.palette.grey[50] }}>
          Neutral Palette Grey/50
        </Box>
        <Box p={1} display="flex" height={30} width={400} style={{ backgroundColor: theme.palette.grey[100] }}>
          Neutral Palette Grey/100
        </Box>
        <Box p={1} display="flex" height={30} width={400} style={{ backgroundColor: theme.palette.grey[200] }}>
          Neutral Palette Grey/200
        </Box>
        <Box p={1} display="flex" height={30} width={400} style={{ backgroundColor: theme.palette.grey[300] }}>
          Neutral Palette Grey/300
        </Box>
        <Box p={1} display="flex" height={30} width={400} style={{ backgroundColor: theme.palette.grey[400] }}>
          Neutral Palette Grey/400
        </Box>
        <Box
          p={1}
          display="flex"
          height={30}
          width={400}
          style={{ backgroundColor: theme.palette.grey[500] }}
          color="#FFF"
        >
          Neutral Palette Grey/500
        </Box>
        <Box
          p={1}
          display="flex"
          height={30}
          width={400}
          style={{ backgroundColor: theme.palette.grey[600] }}
          color="#FFF"
        >
          Neutral Palette Grey/600
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="text.title" color="#FFF">
          Text Titles
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="text.primary" color="#FFF">
          Text Primary
        </Box>
        <Box p={1} display="flex" height={30} width={400} bgcolor="text.secondary">
          Text Secondary
        </Box>
      </Box>
    </Box>
  );
};

