import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  searchInput: {
    flex: `100% 0 0`,
    marginBottom: theme.spacing(1),
  },
  viewInput: {
    flex: `30% 0 0`,
    marginRight: 'auto',
  },
  institutionSearchResultsContainer: {
    marginTop: theme.spacing(1),
    minHeight: 300,
    maxHeight: 300,
    overflowY: 'scroll',
  },
}));

export default styles;
