import moment from 'moment';

export const validate = (values, licenses) => {
  let errors = {};

  if (!values.name) errors.name = 'A Group Name is required';
  if (!values.license_id) errors.license_id = 'You must select a license';
  if (!values.group_size) errors.group_size = 'A Group size is required';
  if (values.group_size && values.license_id) {
    const groupSize = +values.group_size;
    const { max_users, slots_used } = licenses.find(
      (l) => l.id === values.license_id,
    );
    if (max_users < slots_used + groupSize) {
      errors.group_size =
        'The group size has exceeded the remaining license accounts';
    } else if (groupSize <= 0 || groupSize + slots_used > max_users) {
      errors.group_size = `Group size should be between 1 and ${
        max_users - slots_used
      }`;
    }
  }
  if (!values.courses.length)
    errors.courses =
      'You must select at least one Course or Product to include';
  if (!values.start_date) errors.start_date = 'A start date is required';
  if (!values.end_date) errors.end_date = 'An end date is required';
  if (
    values.start_date &&
    moment(values.start_date).isBefore(moment().startOf('day'))
  )
    errors.start_date = 'Group Start date must be today or later.';
  if (values.start_date && values.end_date) {
    if (moment(values.start_date).isAfter(moment(values.end_date))) {
      errors.start_date = 'Start date cannot be after the end date';
    }
  }
  if (!values.status) errors.status = 'A status is required';
  return errors;
};

export const updateGroupValidate = (values, license) => {
  let errors = {};

  if (!values.name) errors.name = 'A Group Name is required';
  if (!values.group_size) errors.group_size = 'A Group size is required';
  if (values.group_size && values.license_id) {
    const groupSize = +values.group_size;
    const { max_users, slots_used } = license;
    if (max_users < slots_used + groupSize) {
      errors.group_size =
        'The group size has exceeded the remaining license accounts';
    } else if (groupSize <= 0 || groupSize + slots_used > max_users) {
      errors.group_size = `Group size should be between 1 and ${
        max_users - slots_used
      }`;
    }
  }
  if (!values.courses.length)
    errors.courses =
      'You must select at least one Course or Product to include';
  if (!values.start_date) errors.start_date = 'A start date is required';
  if (!values.end_date) errors.end_date = 'An end date is required';
  if (values.start_date && values.end_date) {
    if (moment(values.start_date).isAfter(moment(values.end_date))) {
      errors.start_date = 'Start date cannot be after the end date';
    }
  }
  if (!values.status) errors.status = 'A status is required';
  return errors;
};

export const getGroupUpdateValues = (group) => {
  return {
    name: group.name,
    group_size: group.group_size,
    courses: group.courses.map((course) => course.id),
    status: group.status,
    start_date: group.start_date,
    end_date: group.end_date,
  };
};
