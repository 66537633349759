import React, { Fragment, useContext } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  ListItemSecondaryAction,
  Typography,
  Chip,
  Divider,
  Paper,
  Box,
  CircularProgress,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import styles from './styles';
import { ViewerContext } from '../../../../providers/ViewerProvider';

const UserSearchResults = ({
  data,
  loading,
  error,
  fetchMore,
  history,
  match,
}) => {
  const classes = styles();
  const { viewer } = useContext(ViewerContext);

  if (loading) return <CircularProgress />;
  if (error || !data) return <p>No Results</p>;

  return (
    <Paper style={{ padding: 10, marginTop: 16 }}>
      <div className={classes.resultsCount}>
        <Typography variant="subtitle1" style={{ flex: 1 }}>
          Search Results
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography>No Results</Typography>
        ) : (
          <Typography>{data.paginatedUsers.totalCount} result(s)</Typography>
        )}
      </div>
      <List>
        {data.paginatedUsers.users.map((user) => (
          <Fragment key={user.id}>
            <ListItem
              dense
              button
              onClick={() => history.push(`${match.path}/${user.id}`)}
            >
              <ListItemText
                primary={`${user.first_name} ${user.last_name}`}
                secondary={user.email}
              />
              {user.id === viewer.id && (
                <Chip
                  label="Me"
                  color="secondary"
                  style={{ marginRight: 16, color: '#FFF' }}
                />
              )}
              <Chip
                label={user.role}
                color="primary"
                style={{ marginRight: 16 }}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="comments"
                  disabled={user.id === viewer.id}
                  onClick={() => history.push(`${match.path}/${user.id}/edit`)}
                >
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      {data.paginatedUsers && data.paginatedUsers.hasMore && (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.fetchMoreButton}
            onClick={() =>
              fetchMore({
                variables: {
                  after: data.paginatedUsers.cursor,
                },
                updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    ...fetchMoreResult,
                    paginatedUsers: {
                      ...fetchMoreResult.paginatedUsers,
                      users: [
                        ...prev.paginatedUsers.users,
                        ...fetchMoreResult.paginatedUsers.users,
                      ],
                    },
                  };
                },
              })
            }
          >
            More Results
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default UserSearchResults;
