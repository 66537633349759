const useGetLabelResource = (resource) => {
  let label;

  switch (resource.type) {
    case 'BENCHMARK':
      label = 'Answer Key'
      break;
    case 'SUMMARY':
      label = 'Answer Key'
      break;
    case 'LEARN_EXERCISE':
      label = 'Answer Key'
      break;
    case 'APPLY_EXERCISE':
      label = 'Answer Key'
      break;
    case 'ANY':
      label = 'Resource'
      break;
    default:
      label = 'Resource'
      break;
  }

  return { label }
}

export default useGetLabelResource;