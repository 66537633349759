import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { EditorText } from '..';

const ResourceCard = ({ title, text1_label, text1, text2_label, text2, image }) => {
    return (
        <Box display="flex" flexDirection="column" padding={1} style={{ backgroundColor: "#F1F1F1" }}>
            {title &&
                <React.Fragment>
                    <Typography variant="subtitle2">Title</Typography>
                    <Card variant="outlined" style={{ marginBottom: "15px" }}>
                        <CardContent>
                            {title ?? "No Title"}
                        </CardContent>
                    </Card>
                </React.Fragment>
            }
            <Typography variant="subtitle2">{text1_label}</Typography>
            <Card variant="outlined" style={{ marginBottom: "15px" }}>
                <CardContent>
                    <EditorText text={text1} />
                </CardContent>
            </Card>
            {text2 &&
                <React.Fragment>
                    <Typography variant="subtitle2">{text2_label}</Typography>
                    <Card variant="outlined" style={{ marginBottom: "15px" }}>
                        <CardContent>
                            <EditorText text={text2} />
                        </CardContent>
                    </Card>
                </React.Fragment>
            }
            {image &&
                <React.Fragment>
                    <Typography variant="subtitle2">Image</Typography>
                    <Card variant="outlined">
                        <CardContent>
                            <img src={image} style={{ maxWidth: '100%', height: 'auto' }} alt="resource card"></img>
                        </CardContent>
                    </Card>
                </React.Fragment>
            }
        </Box>
    );
};

export default ResourceCard;