import React, { useState, Fragment } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import styles from './styles';
import QuickCardControls from '../../common/QuickCardControls/QuickCardControls';
import useNormalizeQuickdeckContent from '../../../hooks/useNormalizeQuickdeckContent';
import SelectionImmersiveReader from '../../immersiveReader/SelectionImmersiveReader';
import ImmersiveReaderButton from '../../immersiveReader/ImmersiveReaderButton';

const LibraryCards = ({ cards = [] }) => {
  const classes = styles();
  const [cardNumber, setCardNumber] = useState(0);
  const [showDefinition, setShowDefinition] = useState(false);
  let cardsNumberLimit = 1;
  const [maxCardsNumberLimit, setMaxCardsNumberLimit] = useState(4);
  const [minCardsNumberLimit, setMinCardsNumberLimit] = useState(0);

  const handleNextCard = () => {
    setCardNumber(cardNumber + 1);
    setShowDefinition(false);

    if (cardNumber + 2 > maxCardsNumberLimit) {
      setMaxCardsNumberLimit(maxCardsNumberLimit + cardsNumberLimit);
      setMinCardsNumberLimit(minCardsNumberLimit + cardsNumberLimit);
    }
  };
  const handlePrevCard = () => {
    setCardNumber(cardNumber - 1);
    setShowDefinition(false);

    if (cardNumber - 1 < minCardsNumberLimit) {
      setMaxCardsNumberLimit(maxCardsNumberLimit - cardsNumberLimit);
      setMinCardsNumberLimit(minCardsNumberLimit - cardsNumberLimit);
    }
  };
  const handleSelectCard = (cardIndex) => {
    setCardNumber(cardIndex);
    setShowDefinition(false);
  }


  const toggleCard = () => {
    setShowDefinition(!showDefinition);
  };

  const quickdeckContent = useNormalizeQuickdeckContent(cards[cardNumber]);

  if (!cards?.length) {
    return <Typography>No Cards</Typography>;
  }

  return (
    <Fragment>
      <QuickCardControls
        nextCard={handleNextCard}
        prevCard={handlePrevCard}
        currentCardNumber={cardNumber}
        cards={cards}
        disableNext={cardNumber >= cards.length - 1}
        disablePrev={cardNumber === 0}
        selectCard={handleSelectCard}
        maxCardsNumberLimit={maxCardsNumberLimit}
        minCardsNumberLimit={minCardsNumberLimit}
        mt={2}
        mb={2}
      />
      <Box 
      width='100%' 
      overflow='auto' 
      m="auto"
      boxShadow='0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'>
        <Card className={classes.card} onClick={toggleCard}>
          <CardContent className={classes.cardContent}>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              position='relative'
            >
              <ImmersiveReaderButton text={quickdeckContent}/>
              <SelectionImmersiveReader htmlText={quickdeckContent}/>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
};

export default LibraryCards;
