import React from 'react';
import { BenchmarkAssessment } from './components';
import BenchmarkProvider from './providers/BenchmarkProvider';
import BenchmarkProgressProvider from './providers/BenchmarkProgressProvider';
import styles from './styles';
import BenchmarkUserAnswerProvider from './providers/BenchmarkUserAnswerProvider';
import BenchmarkSettingsProvider, {
  BenchmarkSettingsConsumer,
} from './providers/BenchmarkSettingsProvider';
import GroupProvider from '../GroupDashboard/providers/GroupProvider';

const Benchmark = ({ match }) => {
  const classes = styles();

  const courseId = match.params.courseId;
  const groupId = match.params.groupId;
  const benchmarkLevel = match.params.level;

  return (
    <GroupProvider groupId={groupId}>
      <BenchmarkSettingsProvider>
        <BenchmarkSettingsConsumer>
          {({ freeVersion, previewVersion }) => (
            <BenchmarkProgressProvider groupId={groupId}>
              <BenchmarkProvider 
                courseId={courseId}
                benchmarkLevel={benchmarkLevel}
              >
                <BenchmarkUserAnswerProvider groupId={groupId}>
                  <section className={classes.benchmarkSection}>
                    
                    <BenchmarkAssessment
                      freeVersion={freeVersion}
                      previewVersion={previewVersion}
                      introductionText={'The benchmark will evaluate your current skills and build a custom learning plan for you.'}
                      title={'Benchmark Assessment'}
                    />
                  </section>
                </BenchmarkUserAnswerProvider>
              </BenchmarkProvider>
            </BenchmarkProgressProvider>
          )}
        </BenchmarkSettingsConsumer>
      </BenchmarkSettingsProvider>
    </GroupProvider>
  );
};

export default Benchmark;
