import React, { Component } from 'react';
import { Query } from '@apollo/react-components';
import { VIEWER_QUERY } from '../apollo/queries';
import { LoadingScreen } from '../components';
import { useContext } from 'react';

export const ViewerContext = React.createContext();

export const ViewerProvider = ({ children }) => {
  const isSilentRenew = window.location.pathname === '/silent-renew';

  return (
    <Query query={VIEWER_QUERY} fetchPolicy="network-only" skip={isSilentRenew}>
      {({ data, loading, error }) => {

        if (loading) return <LoadingScreen />;
        if (error) return <p>Error...</p>;

        const viewer = data?.viewer;
        return (
          <ViewerContext.Provider
            value={{
              viewer,
            }}
          >
            {children}
          </ViewerContext.Provider>
        );
      }}
    </Query>
  );
};

export const useViewer = () => {
  const { viewer } = useContext(ViewerContext);

  return viewer;
};

export const ViewerConsumer = ViewerContext.Consumer;

export const withViewer = (WrappedComponent) => {
  return class extends Component {
    render() {
      return (
        <ViewerConsumer>
          {({ viewer }) => <WrappedComponent {...this.props} viewer={viewer} />}
        </ViewerConsumer>
      );
    }
  };
};
