import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_GROUP_MEMBER } from '../../../apollo/queries';
import { LoadingScreen } from '../../../components';
import { useContext } from 'react';

const GroupMemberContext = React.createContext();

const GroupMemberProvider = ({ groupId, userId, children }) => {
  const { data, loading, error } = useQuery(GET_GROUP_MEMBER, {
    variables: { userId, groupId },
  });

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error...</p>;

  const groupMember = data.groupMember;

  return (
    <GroupMemberContext.Provider value={{ groupMember }}>
      {children}
    </GroupMemberContext.Provider>
  );
};

export const useGroupMember = () => {
  const { groupMember } = useContext(GroupMemberContext);

  return groupMember;
};

export default GroupMemberProvider;
