import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { CMS_GET_BOOKS, CREATE_BOOK } from '../../../../apollo/queries';
import {
  Card,
  CardContent,
  Box,
  CardActionArea,
  Typography,
  Grid,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import styles from './styles';
import {
  AddButton,
  CloseButton,
  EBookCreateForm,
} from '../../../../components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useIsManager } from '../IsManager';

const EBook = ({ match, history }) => {
  const classes = styles();
  const isManager = useIsManager();
  const [showForm, setShowForm] = useState(false);

  const { data, loading, error } = useQuery(CMS_GET_BOOKS);
  const [createBook] = useMutation(CREATE_BOOK, {
    refetchQueries: ['allCMSbooks'],
  });

  if (loading) return <p>Loading...</p>;
  if (error) {
    return <p>Error...</p>;
  }

  return (
    <Container maxWidth="md" style={{ marginTop: 16, overflow: 'auto' }}>
      <Dialog
        open={Boolean(showForm)}
        onClose={() => setShowForm(false)}
        aria-labelledby="form-dialog-title"
      >
        {showForm && (
          <DialogTitle
            disableTypography
            id="form-dialog-title"
            className={classes.dialogTitle}
          >
            <Typography variant="h6">Create an E-Book</Typography>
            <CloseButton onClick={() => setShowForm(false)} color="primary" />
          </DialogTitle>
        )}
        <DialogContent>
          {showForm && (
            <EBookCreateForm
              onSubmit={async (values) => {
                await createBook({ variables: { data: values } });
                setShowForm(false);
              }}
            />
          )}
        </DialogContent>
      </Dialog>
      <Button
        variant="text"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <Box pt={2} pb={2} display="flex" justifyContent="space-between">
        <Typography variant="h4">E-Books</Typography>

        {isManager && (
          <AddButton title="Add E-Book" onClick={() => setShowForm(true)} />
        )}
      </Box>
      <Grid container spacing={2}>
        {data.books.map((book) => (
          <Grid item xs={6} key={book.id}>
            <Card style={{ borderTop: `4px solid ${book.product.color}` }}>
              <CardActionArea
                onClick={() => history.push(`${match.url}/${book.id}`)}
              >
                <CardContent>
                  <Typography>{book.name}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default EBook;
