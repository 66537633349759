import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  buttonLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none'
  },
  appBarSpacer: {
    marginBottom: theme.spacing(2),
  },
  appBarToolbar: {
    justifyContent: 'space-between',
    padding: 0,
    marginTop: '1em'
  },
  keyElementTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  keyElementBody: {
    width: '100%',
    maxWidth: theme.breakpoints.values.md,
    height: '100%',
    overflowY: 'hidden',
    background: theme.palette.background.main,
    padding: theme.spacing(0),
    display: 'flex',
    justifyContent: 'stretch',

    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      maxHeight: '80%',
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: 1024,
    },
  },
  keyElementBody_container: {
    padding: '0',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '100%',
    maxWidth: 'none',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      height: '100%',
    },
  },
  keyElementsDashboard: {
    display: 'flex',
    borderRadius: '4px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '2em 0',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: '#FFFFFF'
  },
  keyElementsDashboard_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#3FAE2A',
    padding: '0.5em',
    '& > h6': {
      color: '#FFFFFF',
      fontSize: '24px'
    },
  },
  keyElementsDashboard_main: {
    display: 'flex',
    marginTop: '2em',
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'column'
  },
  keyElementsList: {
    display: 'flex',
    gap: '0.5em',
    margin: '0 0 1em 0',
    alignItems: 'stretch',
    borderBottom: '1px solid #BDBDBD',
    '& > div > span': {
      display: 'none'
    }
  },
  keyElementsCard: {
    position: 'relative',
    maxWidth: 250,

    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
    },
    backgroundColor: '#F2F2F2',
    boxShadow: 'none',
    borderRadius: '4px 4px 0 0',
    margin: '0 5px',
    color: '#4F4F4F',
    fontWeight: 'normal',
    textTransform: 'none',
    opacity: 1,
    fontFamily: '"Segoe UI",Open Sans,Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    letterSpacing: 0,
    fontSize: 15
  },
  keyElementsCardActive: {
    position: 'relative',
    maxWidth: 250,

    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
    },
    backgroundColor: '#FFFFFF',
    borderTop: '2px solid #3FAE2A',
    boxShadow: 'none',
    borderLeft: '1px solid #BDBDBD',
    borderRight: '1px solid #BDBDBD',
    borderRadius: '4px 4px 0 0',
    margin: '0 5px',
    color: '#4F4F4F',
    fontWeight: 'bold',
    textTransform: 'none',
    opacity: 1,
    fontFamily: '"Segoe UI",Open Sans,Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    letterSpacing: 0,
    fontSize: 15
  },
  keyElementsContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '1em',
    flex: 1,
  },
  keyElementsContent_layout: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    gap: '1em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  keyElementsContent_titleListContainer: {
    display: 'flex',
    flex: 0.3,
    position: 'relative',
    flexDirection: 'column',
    '& > h5': {
      color: '#828282',
      paddingBottom: '0.5em',
      borderBottom: '1px solid #3FAE2A'
    },
    gap: '1em',
    height: 590,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 'none',
      maxHeight: 400,
      height: 'unset',
    }
  },
  mediaLibrary_navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 0,
    margin: 0,
    gap: '0.5em',
  },
  mediaLibrary_navbarItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5em',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 25%);',
    borderRadius: '4px',
    fontSize: 13,
    color: '#4F4F4F',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#3FAE2A',
      color: '#FFFFFF',
    },
    transition: '0.2s ease'
  },
  mediaLibrary_navbarItemActive: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5em',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 25%);',
    borderRadius: '4px',
    backgroundColor: '#3FAE2A',
    fontSize: 13,
    color: '#FFFFFF',
    '&:hover': {
      cursor: 'pointer'
    },
    transition: '0.2s ease'
  },
  keyElementsContent_itemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 0.75,
    flexDirection: 'column',
    '& > h5': {
      color: '#828282',
      paddingBottom: '0.5em',
      borderBottom: '1px solid #3FAE2A'
    },
    gap: '1em',
    height: 590,
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
    }
  },
  keyElementsContent_titleList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    margin: 0,
    padding: '0.5em',
    gap: '0.5em',
    borderRadius: '4px',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 25%)',
    overflowY: 'auto'
  },
  titleItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#4F4F4F',
    '& > span': {
      fontSize: '1.5em',
      color: '#FFFFFF',
    },
    '& > p': {
      margin: '0 0 0 0.5em'
    },
    padding: '0.5em',
    borderRadius: '4px',
    transition: '0.2s ease',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F2F2'
    }
  },
  titleItemActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#4F4F4F',
    justifyContent: 'flex-start',
    '& > span': {
      fontSize: '1.5em',
      color: '#3FAE2A',
    },
    '& > p': {
      margin: '0 0 0 0.5em',
      fontWeight: 'bold'
    },
    backgroundColor: '#F2F2F2',
    padding: '0.5em',
    borderRadius: '4px',
    transition: '0.2s ease',
    fontSize: '14px',
    cursor: 'pointer',
  },
  libraryControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 25%)',
  },
  libraryControls_buttons: {
    color: '#3FAE2A'
  },
  keyElementsContentLibrary: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 25%)',
    borderRadius: '4px',
    flex: 1,
    overflow: 'hidden'
  },
  keyElementsContentLibrary_viewer: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
    overflowY: 'auto',
    justifyContent: 'center'
  },
  quickclipResource: {
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  buttonActiveCC: {
    backgroundColor: '#3FAE2A',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#2d831d'
    }
  },
  buttonCloseCC: {
    backgroundColor: '#CCC',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#BDBDBD'
    }
  },
  quickdeckResource: {
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  ebookResource: {
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
}));

export default styles;
