import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  avatar: {
    color: theme.palette.grey[400]
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}));

export default useStyles;