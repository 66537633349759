import gql from 'graphql-tag';
import { LessonFields, AllLessonFields, BookPageFields } from './fragments';

const CREATE_LESSON = gql`
  mutation createLesson($data: LessonCreateInput!) {
    createLesson(data: $data) {
      ...LessonFields
    }
  }
  ${LessonFields}
`;

const ALL_LESSONS = gql`
  query allLessons {
    lessons {
      ...LessonFields
    }
  }
  ${LessonFields}
`;

const UPDATE_LESSON = gql`
  mutation updateLesson(
    $data: LessonUpdateInput!
    $where: LessonWhereUniqueInput!
  ) {
    updateLesson(data: $data, where: $where) {
      ...AllLessonFields
    }
  }
  ${AllLessonFields}
`;

const CMS_GET_LESSONS = gql`
  query getCMSLessons($courseId: ID!) {
    lessons(where: { course_id: $courseId }) {
      ...AllLessonFields
    }
  }
  ${AllLessonFields}
`;

const CMS_GET_LESSON = gql`
  query getCMSLesson($lessonId: ID!) {
    lesson(where: { id: $lessonId }) {
      ...AllLessonFields
    }
  }
  ${AllLessonFields}
`;

const SET_LESSON_PAGES = gql`
  mutation setLessonPages(
    $ebookPageIds: [ID!]!
    $where: LessonWhereUniqueInput!
  ) {
    setLessonPages(ebookPageIds: $ebookPageIds, where: $where) {
      ...BookPageFields
    }
  }
  ${BookPageFields}
`;

const PUBLISH_LESSON = gql`
  mutation publishLesson($id: ID!) {
    publishLesson(id: $id)
  }
`;

const UNPUBLISH_LESSON = gql`
  mutation unpublishLesson($id: ID!) {
    unpublishLesson(id: $id)
  }
`;

const DELETE_LESSON = gql`
  mutation deleteLesson($id: ID!) {
    deleteLesson(id: $id)
  }
`;

const FREE_LESSONS = gql`
  query getFreeLessons {
    freeLessons {
      ...LessonFields
      studyProjects {
        id
      }
    }
  }
  ${LessonFields}
`;

const ADD_FREE_LESSON = gql`
  mutation addFreeLesson($ids: [ID!]) {
    addFreeLesson(ids: $ids)
  }
`;

const DELETE_FREE_LESSON = gql`
  mutation deleteFreeLesson($id: ID!) {
    deleteFreeLesson(id: $id)
  }
`;

const GET_LESSON = gql`
  query getLesson($lessonId: ID!) {
    lesson(where: { id: $lessonId }) {
      ...AllLessonFields
    }
  }
  ${AllLessonFields}
`;

export {
  ALL_LESSONS,
  CREATE_LESSON,
  CMS_GET_LESSONS,
  CMS_GET_LESSON,
  SET_LESSON_PAGES,
  UPDATE_LESSON,
  PUBLISH_LESSON,
  UNPUBLISH_LESSON,
  DELETE_LESSON,
  FREE_LESSONS,
  ADD_FREE_LESSON,
  DELETE_FREE_LESSON,
  GET_LESSON,
};
