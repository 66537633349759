import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { EditorText } from '../../common';
import CardImage from '../CardImage';

const LibraryCardList = ({ cards = [] }) => {
  if (!cards.length) {
    return <Typography>No Cards</Typography>;
  }
  return (
    <Grid container spacing={2}>
      {cards.map((card) => (
        <Grid key={card.id} item xs={12}>
          <Paper style={{ padding: 16 }}>
            <EditorText text={card.term} />
            {card.image && <CardImage src={card.image} alt={card.term} />}
            <EditorText text={card.definition} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default LibraryCardList;
