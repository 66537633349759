import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  dateFormControlContainer: {
    display: 'flex',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  dateFormControl: {
    flex: 1,
  },
  buttonFormControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  productExpansionPanel: {
    border: '1px solid #ccc',
  },
  productSummaryPanel: { height: 20, borderBottom: '1px solid #ccc' },
  productPanelDetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    '&:not(:last-child)': {
      borderBottom: '1px solid #ccc',
    },
  },
  checkboxLabel: {
    ...theme.typography.body1,
  },
  courseContainer: {
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  formButtons: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: theme.spacing(1),
    },
  },
}));
