import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  courseDashboardSection: {
    display: 'flex',
    flexGrow: 1,
    overflow: "auto"
  },
}));

export default styles;
