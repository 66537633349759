import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  studyProjectFormWrapper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  caseStudyPanel: {
    boxShadow: 'none',
  },
  caseSummaryPanel: {
    border: `1px solid ${theme.palette.divider}`,
  },
  caseDetailsPanel: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  slideDialogContent: {
    height: '100%',
    overflowY: 'scroll',
    background: theme.palette.background.main,
  },
  scrollDialogContent: {
    background: theme.palette.background.main,
  },
  allResourcesTaken: {
    padding: theme.spacing(2, 3),
    textAlign: 'center',
    background: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
  },
  quickResourceHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& > h4': {
      color: '#BD161C'
    }
  },
  cardContent: {
    backgroundColor: '#f1f1f1',
    margin: '1em',
  },
  cardContent_subcard: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none',
    marginBottom: 16
  }
}));
