import React from 'react';
import { useCourse } from '../../providers/CourseProvider';
import { useGroup } from '../../../GroupDashboard/providers/GroupProvider';
import { useViewer } from '../../../../providers/ViewerProvider';
import StudentGradebook from './StudentGradebook';

const Gradebook = () => {
  const viewer = useViewer();
  const course = useCourse();
  const group = useGroup();

  return <StudentGradebook viewer={viewer} course={course} group={group} />;
};

export default Gradebook;
