import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 200;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  navItem: {
    borderLeft: `4px solid transparent`,
    display: 'flex',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    alignItems: 'center',
    flex: 1,
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.54)',

    '& span': {
      ...theme.typography.body1,
    },
  },
  navIcon: {
    fontSize: 30,
    marginRight: theme.spacing(2),
  },
  active: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
}));
