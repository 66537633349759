import React, { Fragment, forwardRef, useState, useEffect } from 'react';
import styles from './styles';
import { ReactSortable } from 'react-sortablejs';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { getCorrectAnswer } from '../../../lib';
import { clone } from 'ramda';
import clsx from 'clsx';
import Matching from './components/Matching.js';
import TrueOrFalse from './components/TrueOrFalse';
import { Card } from '@material-ui/core';
import SelectionImmersiveReader from '../../immersiveReader/SelectionImmersiveReader';
import ImmersiveReaderButton from '../../immersiveReader/ImmersiveReaderButton';

const StudentQuestion = ({
  question,
  onChange,
  userAnswers,
  showAnswer,
  children,
  ...restProps
}) => {
  const type = question.type;
  const [matchingOptions, setMatchingOptions] = useState({});

  const getAnswers = (type) => {
    if (type === 'MULTIPLE_CHOICE' || type === 'TRUE_OR_FALSE' || type === 'CORRECT_OR_INCORRECT' || type === 'MATCHING') {
      return question.answers;
    } else if (userAnswers[question.id] && userAnswers[question.id].length >= 1) {
      return userAnswers[question.id].map((ansId) =>
        question.answers.find((ans) => ans.id === ansId),
      );
    } else {
      return question.answers;
    }
  };

  const correctAnswer = question.type === 'SEQUENCE' ? clone(question.answers).sort((a, b) => a.sequence - b.sequence) : getCorrectAnswer(question);

  const getUserAnswer = () => {
    if (showAnswer && (question.type === "MULTIPLE_CHOICE" || question.type === "SEQUENCE")) {
      return correctAnswer;
    }

    return userAnswers[question.id] || [];
  };

  const answers = getAnswers(type);

  const getHTMLQuestionContent = (question, answers) => {
    let temp = document.createElement('div');
    let answersContainer = document.createElement('div');

    answers.forEach(element => {
      let tempAnswer = document.createElement('div');
      tempAnswer.innerHTML = element.answer;
      answersContainer.appendChild(tempAnswer);
    });

    temp.innerHTML = question.question + answersContainer.outerHTML;
    return temp.outerHTML;
  }

  const getHTMLMatchingQuestionContent = (question, matchingOptions) => {
    if(matchingOptions['concepts'] && matchingOptions['descriptions']) {
      let temp = document.createElement('div');
      let conceptsAndDescriptionsContainer = document.createElement('div');
  
      const concepts = Object.keys(matchingOptions['concepts']).map((concept) => {
        return matchingOptions['concepts'][concept].text;
      });
  
      const descriptions = Object.keys(matchingOptions['descriptions']).map((description) => {
        return matchingOptions['descriptions'][description].text;
      });
  
      concepts.forEach(element => {
        let tempConcept = document.createElement('div');
        tempConcept.innerHTML = element;
        conceptsAndDescriptionsContainer.appendChild(tempConcept);
      });
  
      descriptions.forEach(element => {
        let tempDescription = document.createElement('div');
        tempDescription.innerHTML = element;
        conceptsAndDescriptionsContainer.appendChild(tempDescription);
      });
  
      temp.innerHTML = question.question + conceptsAndDescriptionsContainer.outerHTML;
      return temp.outerHTML;
    } else {
      return '';
    }
  }

  return (
    <div {...restProps}>
      {
        question.type === 'MATCHING' ?
          <ImmersiveReaderButton text={getHTMLMatchingQuestionContent(question, matchingOptions)}/> : 
            <ImmersiveReaderButton text={getHTMLQuestionContent(question, answers)}/>
      }
      <QuestionInstructions question={question} />
      <Card style={{padding: '1em'}}>
        {children}
        <Answers
          answers={showAnswer && question.type === 'SEQUENCE' ? correctAnswer : answers}
          onChange={showAnswer ? () => { } : onChange}
          userAnswer={getUserAnswer()}
          type={type}
          showAnswer={showAnswer}
          setMatchingOptions={setMatchingOptions}
        />
      </Card>
    </div>
  );
};

const SortableList = forwardRef((props, ref) => {
  const classes = styles();
  return (
    <ul className={classes.answerList} ref={ref}>
      {props.children}
    </ul>
  );
});

const Answers = ({ answers, userAnswer, onChange, type, showAnswer, setMatchingOptions }) => {
  const classes = styles();
  const [sequenceList, setSequenceList] = useState([]);

  const handleListChange = (userAnswer) => {
    const answer = userAnswer.map((ans) => ans.id);
    onChange(answer);
  };

  const handleUserChoice = (answerId) => {
    let answer = userAnswer;
    if (isActive(answerId)) {
      answer = answer.filter((id) => id !== answerId);
    } else {
      answer.push(answerId);
    }
    onChange(answer);
  };

  const isActive = (answerId) => {
    return userAnswer.includes(answerId);
  };

  useEffect(() => {
    setSequenceList(answers);
  }, [answers, type]);

  switch (type) {
    case 'SEQUENCE':
      return (
        <ReactSortable
          tag={SortableList}
          list={sequenceList}
          onEnd={() => handleListChange(sequenceList)}
          setList={setSequenceList}
          animation={200}
          delayOnTouchStart={true}
          delay={2}
        >
          {sequenceList.map((ans) => (
            <li className={classes.answerItem} key={ans.id}>
              <DragIndicatorIcon style={{ marginRight: 8 }} />
              <SelectionImmersiveReader htmlText={ans.answer}/>
            </li>
          ))}
        </ReactSortable>
      );
    case 'TRUE_OR_FALSE':
    case 'CORRECT_OR_INCORRECT':
      return <TrueOrFalse answers={answers} userAnswer={userAnswer} onChange={onChange} questionType={type} showAnswer={showAnswer} />
    case 'MATCHING':
      return <Matching 
      answers={answers} 
      userAnswer={userAnswer} 
      onChange={onChange} 
      showAnswer={showAnswer}
      setMatchingOptions={setMatchingOptions} 
      />
    default:
      return (
        <Fragment>
          <ul className={classes.answerList}>
            {answers.map((answer) => (
              <li
                key={answer.id}
                className={clsx(classes.answerItem, {
                  [classes.answerItemActive]: isActive(answer.id),
                })}
                onClick={() => {
                  handleUserChoice(answer.id);
                }}
              >
                <SelectionImmersiveReader htmlText={answer.answer}/>
              </li>
            ))}
          </ul>
        </Fragment>
      );
  }
};

const QuestionInstructions = ({ question }) => {
  return (
    <SelectionImmersiveReader htmlText={question.question} style={{ marginBottom: 8, color: '#6A6A6A', fontSize: '18px' }}/>
  );
};

export default StudentQuestion;
