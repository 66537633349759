import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  expansionPanelRoot: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    '&:before': {
      height: 0,
    },
  },
  expansionSummaryRoot: {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.white.main,
    transition: 'none',
    minHeight: 48,
  },
  expansionSummaryExpanded: {
    minHeight: 48,
  },
  expansionDetails: {
    flexDirection: 'column',
    width: '100%',
    padding: 0,
    marginTop: theme.spacing(1),
  },
}));
