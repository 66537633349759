import React, { Fragment, useState } from 'react';
import {
  DrawerShell,
  DarkChip,
  AddButton,
  QuickdeckForm,
  EditButton,
  DeleteButton,
  CheckUserAction,
  ResourceCard,
} from '../../../../../../components';
import {
  Grid,
  Box,
  Typography,
  Chip,
  CardActionArea,
  Card,
  CardContent,
  CardActions,
  Button,
  Avatar,
} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  CMS_GET_LESSONS,
  CREATE_QUICKDECK_CARD,
  UPDATE_QUICKDECK_CARD,
  DELETE_QUICKDECK_CARD,
  PUBLISH_QUICKDECK_CARD,
  UNPUBLISH_QUICKDECK_CARD,
} from '../../../../../../apollo/queries';
import styles from './styles';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { withSnackbar } from 'notistack';
import omitDeep from 'omit-deep';
import { clone } from 'ramda';
import { auditDataFields } from '../../../../../../lib';
import { useIsManager } from '../../../IsManager';

const DevelopCourseDeck = ({ course }) => {
  const classes = styles();

  const { data, loading, error } = useQuery(CMS_GET_LESSONS, {
    variables: { courseId: course.id },
  });

  const [selectedContent, setSelectedContent] = useState(null);

  const handleClick = (lesson) => {
    const index = lessons.findIndex((l) => l.id === lesson.id);

    if (index !== -1) {
      setSelectedContent(index);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const lessons = data.lessons;

  return (
    <DrawerShell
      drawer={
        <Box p={2}>
          {lessons.map((lesson) => (
            <Card key={lesson.id} className={classes.card}>
              <CardActionArea onClick={() => handleClick(lesson)}>
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  className={classes.cardContent}
                >
                  <Avatar alt={lesson.name} src={lesson.logo} />

                  <div style={{ marginLeft: 8, marginRight: 'auto' }}>
                    <Typography variant="subtitle2">
                      Lesson #{lesson.display_order}
                    </Typography>
                    <Typography variant="caption">{lesson.name}</Typography>
                  </div>

                  {lesson.cards.length <= 0 ? (
                    <DarkChip label="No Cards" size="small" />
                  ) : (
                    <Chip
                      size="small"
                      label={`${lesson.cards.length} Card(s)`}
                      color="primary"
                    />
                  )}
                </Box>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      }
      content={
        <Fragment>
          {selectedContent !== null ? (
            <LessonCards lesson={lessons[selectedContent]} />
          ) : (
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <BookOutlinedIcon className={classes.icon} />
              <Typography variant="h3" color="textSecondary">
                Let's Get Started
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Select a Lesson on the left to manage its Quickdeck Cards.
              </Typography>
            </Box>
          )}
        </Fragment>
      }
    />
  );
};

const LessonCards = withSnackbar(({ lesson, enqueueSnackbar }) => {
  const classes = styles();
  const cards = lesson.cards;
  const refetchQueries = ['getCMSLessons'];
  const isManager = useIsManager();

  const [deleteCardId, setDeleteCardId] = useState(null);
  const [showCardForm, setShowCardForm] = useState([false, null]);

  const [createCard] = useMutation(CREATE_QUICKDECK_CARD, {
    refetchQueries,
  });
  const [updateCard] = useMutation(UPDATE_QUICKDECK_CARD);
  const [deleteCard] = useMutation(DELETE_QUICKDECK_CARD, { refetchQueries });
  const [publishCard] = useMutation(PUBLISH_QUICKDECK_CARD, { refetchQueries });
  const [unpublishCard] = useMutation(UNPUBLISH_QUICKDECK_CARD, {
    refetchQueries,
  });

  const handlePublish = async (id, published) => {
    try {
      if (published) {
        await unpublishCard({ variables: { id } });
      } else {
        await publishCard({ variables: { id } });
      }
      enqueueSnackbar(
        `Successfully ${published ? 'Unpublished' : 'Published'
        } Quickdeck Card!`,
        { variant: 'success', autoHideDuration: 1500 },
      );
    } catch (error) {
      enqueueSnackbar(
        'Failed to update Quickdeck Card. If problem persists please contact Tech Support.',
        { variant: 'error' },
      );
    }
  };

  const handleSubmit = async (values) => {
    try {
      const valuesToOmit = ['id', '__typename', ...auditDataFields];
      if (values.id) valuesToOmit.push('lesson_id');

      const data = omitDeep(clone(values), valuesToOmit);
      if (values.id) {
        await updateCard({ variables: { data, where: { id: values.id } } });
      } else {
        await createCard({ variables: { data } });
      }
      enqueueSnackbar(
        `Successfully ${values.id ? 'updated' : 'created'} Quickdeck Card`,
        { variant: 'success', autoHideDuration: 1500 },
      );
    } catch (error) {
      enqueueSnackbar(
        `Something went wrong! If the problem persist please contact Tech Support.`,
        { variant: 'error' },
      );
    }
    setShowCardForm([false, null]);
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <CheckUserAction
        open={Boolean(deleteCardId)}
        title="Delete Quickdeck Card"
        content={
          <Typography variant="body1">
            Are you sure you want to delete this card?
          </Typography>
        }
        onClose={() => setDeleteCardId(null)}
        onClick={async () => {
          try {
            await deleteCard({ variables: { id: deleteCardId } });
            setDeleteCardId(null);
            enqueueSnackbar('Successfuly deleted Card', {
              variant: 'success',
              autoHideDuration: 1500,
            });
          } catch (error) {
            enqueueSnackbar(
              'Something went wrong. If problem persist please contact Tech Support',
              {
                variant: 'error',
              },
            );
          }
        }}
      />
      <QuickdeckForm
        open={showCardForm[0]}
        card={showCardForm[1]}
        onCancel={() => setShowCardForm([false, null])}
        lessonId={lesson.id}
        onSubmit={handleSubmit}
      />
      <Box display="flex" alignItems="center" p={2}>
        <Typography variant="h5" style={{ marginLeft: 8 }}>
          {lesson.name} Cards
        </Typography>
        <AddButton
          title="Add Quickdeck Card"
          style={{ marginLeft: 'auto' }}
          onClick={() => setShowCardForm([true, null])}
        />
      </Box>
      {cards.length <= 0 && (
        <Grid item xs={12} className={classes.noCards}>
          <EditIcon className={classes.icon} />
          <Typography variant="h3" color="textSecondary">
            No Quickdeck Cards
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            click Add Quickdeck Card to get started
          </Typography>
          <AddButton
            title="Add Quickdeck Card"
            onClick={() => setShowCardForm([true, null])}
          />
        </Grid>
      )}

      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
        {cards.map((card) => (
          <Card style={{ margin: 8, width: "350px" }}>
            <CardActions>
              <Chip
                size="small"
                color={card.published ? 'primary' : 'default'}
                label={card.published ? 'Published' : 'Draft'}
              />
              <EditButton
                style={{ marginLeft: 'auto' }}
                onClick={() => setShowCardForm([true, card])}
              />
              {isManager && (
                <DeleteButton onClick={() => setDeleteCardId(card.id)} />
              )}
            </CardActions>
            <CardContent>
              <ResourceCard text1_label="Card Term" text1={card.term} text2_label="Card Definition" text2={card.definition} image={card.image} />
            </CardContent>
            {isManager && (
              <CardActions>
                <Button
                  style={{ marginLeft: 'auto' }}
                  onClick={() => handlePublish(card.id, card.published)}
                  variant="text"
                  color="primary"
                >
                  {card.published ? 'Unpublish' : 'Publish'}
                </Button>
              </CardActions>
            )}
          </Card>
        ))}
      </Box>
    </Box>
  );
});

export default DevelopCourseDeck;
