import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    background: theme.palette.background.light,
    overflow: "auto"
  },
}));
