import React, { useState } from 'react';
import { Box, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import {
  useDeleteInstitutionMember,
  useDeleteInvitations,
  useInstitutionInvitations,
  useInstitutionMembers,
  useUpdateInstitutionMember,
  useUpdateInvitedInstitutionMember,
} from '../../../apollo/api';
import { Heading } from '../../common';
import InviteInstitutionMember from './InviteInstitutionMember';
import useStyles from './styles';
import { useUserConfirmation } from '../../../providers/UserConfirmationProvider';
import { useSnackbar } from 'notistack';
import useDialog from '../../../hooks/useDialog';
import InstitutionMemberEditForm from './InstitutionMemberEditForm';
import { SALE_ACCESS } from '../../../lib';
import { useViewer } from '../../../providers/ViewerProvider';
import TableMembersList from './TableMemberList';
import MobileMemberList from './MobileMemberList';
import useMemberFilter from './useMemberFilter';

const combineResults = (members, invitations) => {
  if (!members.length && !invitations.length) return [];

  const simplifiedMembers = members.map((member) => ({
    member_id: member.id,
    user_id: member.user.id,
    name: `${member.user.first_name} ${member.user.last_name}`,
    email: member.user.email,
    role: member.role,
    isInvitation: false,
  }));
  const simplifiedInvitations = invitations.map((invitation) => ({
    invitation_id: invitation.id,
    name: `${invitation.first_name} ${invitation.last_name}`,
    email: invitation.email,
    role: invitation.role,
    isInvitation: true,
  }));

  return [...simplifiedInvitations, ...simplifiedMembers].sort((a, b) =>
    a.name.localeCompare(b.name),
  );
};

const useInsitutionMembersAndInvitations = (institutionId) => {
  const {
    members,
    loading: loadingOne,
    error: errorOne,
  } = useInstitutionMembers(institutionId);
  const {
    invitations,
    loading: loadingTwo,
    error: errorTwo,
  } = useInstitutionInvitations(institutionId);

  const loading = loadingOne && loadingTwo;
  const error = errorOne || errorTwo;
  const institutionMembers = combineResults(members, invitations);

  return { loading, error, members: institutionMembers };
};

const InstitutionMembers = ({ institutionId }) => {
  const classes = useStyles();
  const viewer = useViewer();
  const { members, loading } = useInsitutionMembersAndInvitations(
    institutionId,
  );
  const { deleteInstitutionMember } = useDeleteInstitutionMember();
  const { updateInstitutionMember } = useUpdateInstitutionMember();
  const { deleteInstitutionInvite } = useDeleteInvitations();
  const {
    updateInvitedInstitutionMember,
  } = useUpdateInvitedInstitutionMember();
  const {
    filters,
    dispatch,
    InstitutionMemberFilter,
    getFilteredMembers,
  } = useMemberFilter();

  const filteredMembers = getFilteredMembers(filters, members);

  //Get currentMember from selected institution from sales management
  const currentMember = filteredMembers.find((member) => member.user_id === viewer.id);

  const { showConfirmation } = useUserConfirmation();
  const {
    isOpen,
    toggleDialog,
    Dialog,
    DialogContent,
    DialogTitle,
  } = useDialog();
  const [memberToEdit, setMemberToEdit] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onDelete = (member) => {
    showConfirmation({
      title: 'Remove Member',
      message: `Are you sure you want to remove ${member.name}?`,
      confirmed: () => deleteMember(member),
      confirmText: 'Remove Member',
    });
  };

  const deleteMember = async (member) => {
    if (member.member_id) {
      const { data } = await deleteInstitutionMember({
        variables: { id: member.member_id },
      });

      if (data.deleteInstitutionMember.success) {
        enqueueSnackbar(data.deleteInstitutionMember.message, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(data.deleteInstitutionMember.message, {
          variant: 'error',
        });
      }
    } else if (member.invitation_id) {
      const { data } = await deleteInstitutionInvite({
        variables: { id: member.invitation_id },
      });

      if (data.deleteInstitutionInvite.success) {
        enqueueSnackbar(data.deleteInstitutionInvite.message, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(
          'Something went wrong! Please check your connection and try again.',
          { variant: 'error' },
        );
      }
    }
  };

  const onEdit = (member) => {
    setMemberToEdit(member);
    toggleDialog();
  };

  const disableButton = (member) => {
    if (member.user_id === currentMember?.user_id) return true;
    if (
      SALE_ACCESS.includes(viewer.role) ||
      currentMember?.role === 'CENTER_ADMIN'
    ) {
      return false;
    }
    if (
      currentMember?.role === 'INSTRUCTOR' &&
      ['CENTER_ADMIN', 'INSTRUCTOR'].includes(member.role)
    ) {
      return true;
    }
    return false;
  };

  const editMember = async (values) => {
    if (memberToEdit.member_id) {
      try {
        await updateInstitutionMember({
          variables: {
            id: memberToEdit.member_id,
            data: { role: values.role },
          },
        });
        toggleDialog();
      } catch (error) {
        enqueueSnackbar('You cannot Edit yourself', { variant: 'error' });
      }
    } else if (memberToEdit.invitation_id) {
      try {
        await updateInvitedInstitutionMember({
          variables: {
            id: memberToEdit.invitation_id,
            data: { role: values.role },
          },
        });
        enqueueSnackbar(`Successfully updated member`, { variant: 'success' });
        toggleDialog();
      } catch (error) {
        enqueueSnackbar(
          'Something went wrong! Please check your connection and try again.',
          { variant: 'error' },
        );
      }
    }
  };

  return (
    <Paper className={classes.memberListContainer}>
      <Box
        p={2}
        pt={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading variant="h2">My Members</Heading>
        <InviteInstitutionMember
          institutionId={institutionId}
          members={members}
        />
        <Dialog open={isOpen} onClose={toggleDialog} fullScreen={isMobile}>
          <DialogTitle onClose={toggleDialog}>Edit Member</DialogTitle>
          <DialogContent>
            <InstitutionMemberEditForm
              onSubmit={editMember}
              onCancel={toggleDialog}
              member={memberToEdit}
            />
          </DialogContent>
        </Dialog>
      </Box>

      <InstitutionMemberFilter filters={filters} dispatch={dispatch} />

      {isMobile ? (
        <MobileMemberList
          loading={loading}
          members={filteredMembers}
          onDelete={onDelete}
          onEdit={onEdit}
          disableButton={disableButton}
          currentMember={currentMember}
        />
      ) : (
        <TableMembersList
          loading={loading}
          members={filteredMembers}
          onDelete={onDelete}
          onEdit={onEdit}
          disableButton={disableButton}
          currentMember={currentMember}
        />
      )}
    </Paper>
  );
};

export default InstitutionMembers;
