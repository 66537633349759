import React, { Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import {
  FormControl,
  TextField,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Paper,
} from '@material-ui/core';
import moment from 'moment';
import styles from './styles';
import { GROUP_STATUSES } from '../../../lib';
import { LicenseDetails } from '../../license';
import { validate } from '../helpers';
import CollapsingList from './CollapsingList';
import { JasperDateInput } from '../../common';

const today = moment().startOf('day');

const getNextThreeMonthsOrExpiry = (expiryDate) => {
  const threeMonths = moment().add(3, 'months').endOf('day');
  if (threeMonths.isAfter(expiryDate)) {
    return expiryDate;
  }
  return threeMonths;
};

const CreateGroupForm = ({ onSubmit, member, licenses, onCancel }) => {
  const classes = styles();

  const handleProductClick = (form, product) => {
    const { value, change } = form.getFieldState('courses');
    let newValue = [];
    isAllProductCoursesSelected(product, value)
      ? (newValue = value.filter(
          (v) =>
            !product.courses
              .filter((c) => c.published)
              .map((c) => c.id)
              .includes(v),
        ))
      : (newValue = [
          ...value,
          ...product.courses.filter((c) => c.published).map((c) => c.id),
        ]);

    change(newValue.filter((a, b) => newValue.indexOf(a) === b));
  };

  const isAllProductCoursesSelected = (product, selectedCourses) => {
    const productCourseIds = product.courses
      .filter((c) => c.published)
      .map((c) => c.id);

    let allChosen = true;
    productCourseIds.forEach((id) => {
      if (!allChosen) return false;
      allChosen = selectedCourses.includes(id);
    });

    return allChosen;
  };

  const handleCourseClick = (course, value, input) => {
    input.onChange(value);
  };

  const groupStatusList = GROUP_STATUSES;

  return (
    <Form
      initialValues={{
        group_size: '1',
        courses: [],
        status: 'STARTED',
        user_id: member.user.id,
        createNew: false,
      }}
      mutators={{
        setCreateNew: (args, state, utils) => {
          utils.changeValue(state, 'createNew', () => true);
        },
      }}
      onSubmit={onSubmit}
      validate={(values) => validate(values, licenses)}
      render={({ handleSubmit, values, form, submitting, pristine, valid }) => (
        <form>
          <Typography gutterBottom>
            Select on of your purchases licenses to start creating a new group.
          </Typography>
          <FormControl fullWidth className={classes.formControl}>
            <Field name="license_id">
              {({ input }) => (
                <TextField
                  required
                  id="name"
                  name="name"
                  select
                  variant="filled"
                  label="Select License"
                  type="text"
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  disabled={licenses.length === 0}
                  value={input.value}
                  onChange={(value) => {
                    form.batch(() => {
                      form.change('start_date', today);
                      const license = licenses.find(
                        (l) => l.id === value.target.value,
                      );

                      form.change(
                        'end_date',
                        getNextThreeMonthsOrExpiry(
                          moment(license.expiry_date).endOf('day'),
                        ),
                      );
                    });
                    input.onChange(value);
                  }}
                >
                  {licenses.map((license) => (
                    <MenuItem
                      component="div"
                      key={license.id}
                      value={license.id}
                    >
                      {license.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
          </FormControl>
          {values.license_id && (
            <Fragment>
              <Box mb={2}>
                <LicenseDetails
                  license={licenses.find((l) => l.id === values.license_id)}
                  groupSize={values.group_size}
                />
              </Box>

              <FormControl fullWidth className={classes.formControl}>
                <Field name="name">
                  {({ input, meta }) => (
                    <TextField
                      required
                      error={!pristine && meta.invalid}
                      id="name"
                      name="name"
                      variant="filled"
                      label="Enter Group Name"
                      type="text"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      helperText={
                        !pristine && meta.invalid
                          ? meta.error
                          : 'Choose the name of this group. example: Summer 2020'
                      }
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                    />
                  )}
                </Field>
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <Field name="group_size">
                  {({ input, meta }) => {
                    return (
                      <TextField
                        required
                        error={meta.invalid}
                        id="group_size"
                        name="group_size"
                        variant="filled"
                        label="Number of Group Members"
                        type="number"
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        helperText={
                          meta.invalid
                            ? meta.error
                            : 'Choose how many learners will be allowed to join this group'
                        }
                        value={input.value}
                        onChange={(value) => input.onChange(value)}
                      />
                    );
                  }}
                </Field>
              </FormControl>

              <Paper className={classes.courseContainer}>
                <Typography variant="caption" component="p" gutterBottom>
                  Available Courses<span style={{ color: 'red' }}>*</span>
                </Typography>
                <Typography component="p" gutterBottom>
                  Select the Course(s) that you want to include in this Group.
                </Typography>

                {licenses
                  .find((l) => l.id === values.license_id)
                  .products.map((product) => (
                    <Box mb={1} key={product.id}>
                      <CollapsingList
                        heading={
                          <FormControlLabel
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                              <Checkbox
                                size="small"
                                color="primary"
                                checked={isAllProductCoursesSelected(
                                  product,
                                  values.courses,
                                )}
                                onChange={() =>
                                  handleProductClick(form, product)
                                }
                              />
                            }
                            label={product.name}
                            labelPlacement="end"
                          />
                        }
                      >
                        <Box ml={6} display="flex" flexDirection="column">
                          {product.courses
                            .filter((c) => c.published)
                            .map((course) => (
                              <Field
                                key={course.id}
                                name="courses"
                                type="checkbox"
                                value={course.id}
                              >
                                {({ input, meta }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={input.checked}
                                        onChange={(value) =>
                                          handleCourseClick(
                                            product,
                                            value,
                                            input,
                                          )
                                        }
                                      />
                                    }
                                    label={course.name}
                                    labelPlacement="end"
                                    classes={{ label: classes.checkboxLabel }}
                                  />
                                )}
                              </Field>
                            ))}
                        </Box>
                      </CollapsingList>
                    </Box>
                  ))}
              </Paper>

              <Box mb={2} className={classes.dateFormControlContainer}>
                <FormControl className={classes.dateFormControl}>
                  <Field name="start_date" type="date">
                    {({ input, meta }) => (
                      <JasperDateInput
                        required
                        error={meta.invalid}
                        minDate={today}
                        maxDate={moment(
                          licenses.find((l) => l.id === values.license_id)
                            .expiry_date,
                        )}
                        helperText={meta.invalid ? meta.error : ''}
                        variant="inline"
                        inputVariant="filled"
                        label="Group Start Date"
                        value={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </FormControl>
                <Box display="flex" alignItems="center" mx={2}>
                  <Typography variant="h2">{'-'}</Typography>
                </Box>
                <FormControl className={classes.dateFormControl}>
                  <Field name="end_date" type="date">
                    {({ input, meta }) => (
                      <JasperDateInput
                        required
                        minDate={today}
                        maxDate={moment(
                          licenses.find((l) => l.id === values.license_id)
                            .expiry_date,
                        )}
                        variant="inline"
                        inputVariant="filled"
                        label="Group End Date"
                        value={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </FormControl>
              </Box>
              <FormControl fullWidth className={classes.formControl}>
                <Field name="status">
                  {({ input }) => (
                    <TextField
                      required
                      id="status"
                      name="status"
                      select
                      variant="filled"
                      label="Select a status"
                      type="text"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                    >
                      {groupStatusList.map((role) => (
                        <MenuItem key={role.value} value={role.value}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </FormControl>
            </Fragment>
          )}
          <div className={classes.buttonFormControl}>
            <Box mr={1}>
              <Button
                className={classes.formButtons}
                disabled={submitting}
                type="button"
                onClick={onCancel}
                variant="outlined"
                color="default"
              >
                Cancel
              </Button>
            </Box>
            <Box mr={1}>
              <Button
                className={classes.formButtons}
                onClick={() => {
                  form.mutators.setCreateNew();
                  handleSubmit();
                }}
                disabled={submitting || pristine || !valid}
                variant="outlined"
                color="primary"
              >
                Save & Create New
              </Button>
            </Box>
            <Button
              className={classes.formButtons}
              onClick={() => {
                handleSubmit();
              }}
              disabled={submitting || pristine || !valid}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default CreateGroupForm;
