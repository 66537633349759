import React from 'react';
import { Box } from '@material-ui/core';
import { ReactComponent as JasperactiveIcon } from '../../../assets/logos/brand/jasperactive-icon.svg';
import clsx from 'clsx';
import styles from './styles';

const LoadingScreen = ({ children, className, red, ...restProps }) => {
  const classes = styles();

  return (
    <Box
      className={clsx(classes.loadingScreen, {
        [className]: Boolean(className),
      })}
      {...restProps}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <figure className={classes.iconWrapper}>
          <JasperactiveIcon />
        </figure>
        {children}
      </Box>
    </Box>
  );
};

export default LoadingScreen;
