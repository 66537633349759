import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  '@keyframes pulse': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(0.8)' },
    '100%': { transform: 'scale(1)' },
  },
  loadingScreen: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
    width: '100%',
    background: theme.palette.white.main,
    zIndex: 10001,
  },
  iconWrapper: {
    width: 200,
    animation: '$pulse 2s linear 0s infinite',
    marginBottom: theme.spacing(2),
  },
}));

export default styles;
