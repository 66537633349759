import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  courseSettings: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxHeight: '80%',
    height: 'auto',
    maxWidth: 1200,
    padding: '1em 2em',
  },
  courseSettings_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > h4': {
      fontSize: 26
    }
  },
  courseSettings_main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '1em'
  },
  assessmentSettings_footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '1em 0',
  },
}));