import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  JasperactiveUser,
  JasperactiveUsers,
  JasperactiveEditUser,
} from './components';
import { ViewerContext } from '../../providers/ViewerProvider';
import { MANAGE_JASPERACTIVE_ACCESS } from '../../lib';
import {
  Typography,
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import clsx from 'clsx';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 64px)',

    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 56px)',
    },
  },
  heading: {
    padding: theme.spacing(2),
  },
  drawer: {
    width: '100%',
    maxWidth: 320,
    borderRight: `1px solid ${theme.palette.divider}`,
    overflowY: 'scroll',
    overflowX: 'hidden',
    background: theme.palette.common.white,
  },
  main: {
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: theme.spacing(2),
  },
  listItem: {
    borderRight: `4px solid transparent`,
  },
  active: {
    borderRightColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
}));

const Heading = ({ children, ...restProps }) => (
  <Typography variant="h6" {...restProps}>
    {children}
  </Typography>
);

const ManageJasperactive = ({ history, match, location }) => {
  const classes = styles();
  const { viewer } = useContext(ViewerContext);

  if (!MANAGE_JASPERACTIVE_ACCESS.includes(viewer.role)) {
    return <Redirect to={{ pathname: '/', from: location.state }} />;
  }

  return (
    <section className={classes.root}>
      <aside className={classes.drawer}>
        <Heading className={classes.heading}>Manage Jasperactive</Heading>
        <Divider />
        <List component="nav" aria-label="main mailbox folders">
          <ListItem
            className={clsx(classes.listItem, {
              [classes.active]: location.pathname.split('/').includes('users'),
            })}
            button
            onClick={() => history.push(`${match.path}/users`)}
          >
            <ListItemIcon>
              <PeopleOutlineOutlinedIcon
                className={clsx({
                  [classes.activeIcon]: location.pathname
                    .split('/')
                    .includes('users'),
                })}
              />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </List>
      </aside>
      <main className={classes.main}>
        <Switch>
          <Route
            exact
            path={`${match.path}/users/:userId/edit`}
            component={JasperactiveEditUser}
          />
          <Route
            exact
            path={`${match.path}/users/:userId`}
            component={JasperactiveUser}
          />
          <Route
            exact
            path={`${match.path}/users`}
            component={JasperactiveUsers}
          />
          <Redirect to={`${match.path}/users`} from={`${match.path}`} />
          <Redirect to={`${match.path}/users`} from={`${match.path}/*`} />
        </Switch>
      </main>
    </section>
  );
};

export default ManageJasperactive;
