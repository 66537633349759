import { INSTITUTION_ROLES, SALE_ACCESS } from '../../../lib';
import { useInstitution } from '../../../providers/InstitutionProvider';
import { useViewer } from '../../../providers/ViewerProvider';

export function useInstitutionRoles() {
  const { currentMember } = useInstitution();
  const viewer = useViewer();

  if (SALE_ACCESS.includes(viewer.role)) {
    return INSTITUTION_ROLES;
  }

  if (!currentMember) {
    return [];
  } else {
    if (currentMember.role === 'CENTER_ADMIN') {
      return INSTITUTION_ROLES;
    } else {
      return INSTITUTION_ROLES.filter((role) => role.value !== 'CENTER_ADMIN');
    }
  }
}
