import React from 'react';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import styles from './styles';
import LibraryCard from './LibraryCard';

import { useCourse } from '../../providers/CourseProvider';
import { Heading } from '../../../../components';

const LearningLibrary = ({ history, location }) => {
  const course = useCourse();
  const classes = styles();

  const lessons = course.lessons.filter((l) => l.published);

  return (
    <section className={classes.librarySection}>
      <Box mb={2}>
        <Heading variant="h2" component="h2">
          Study Materials
        </Heading>
      </Box>
      <Box mb={6}>
        <Card variant="outlined">
          <CardHeader
            titleTypographyProps={{ variant: 'h3' }}
            title="eBooks"
            subheaderTypographyProps={{ variant: 'body2' }}
            subheader="Lesson material available for your reference."
          />
          <CardContent>
            <Box display="flex" flexWrap="wrap">
              {lessons.map((lesson) => (
                <Box key={lesson.id} className={classes.flexitem} p={1}>
                  <LibraryCard
                    lesson={lesson}
                    onClick={() =>
                      history.push(
                        `library/e-book/${lesson.name}`,
                        location.state,
                      )
                    }
                  />
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box mb={6}>
        <Card variant="outlined">
          <CardHeader
            titleTypographyProps={{ variant: 'h3' }}
            title="QuickDecks"
            subheaderTypographyProps={{ variant: 'body2' }}
            subheader="Use these QuickDecks to learn key concepts"
          />
          <CardContent>
            <Box display="flex" flexWrap="wrap">
              {lessons.map((lesson) => (
                <Box key={lesson.id} className={classes.flexitem} p={1}>
                  <LibraryCard
                    lesson={lesson}
                    onClick={() =>
                      history.push(
                        `library/quickdeck/${lesson.name}`,
                        location.state,
                      )
                    }
                  />
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box mb={6}>
        <Card variant="outlined">
          <CardHeader
            titleTypographyProps={{ variant: 'h3' }}
            title="QuickClips"
            subheaderTypographyProps={{ variant: 'body2' }}
            subheader="Use these QuickClips to learn key concepts"
          />
          <CardContent>
            <Box display="flex" flexWrap="wrap">
              {lessons.map((lesson) => (
                <Box key={lesson.id} className={classes.flexitem} p={1}>
                  <LibraryCard
                    lesson={lesson}
                    onClick={() =>
                      history.push(
                        `library/quickclip/${lesson.name}`,
                        location.state,
                      )
                    }
                  />
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </section>
  );
};

export default LearningLibrary;
