import React from 'react';
import { Paper, Typography, Box } from '@material-ui/core';
import moment from 'moment';
import styles from './styles';
import { Heading } from '../../common';

const LicenseDetails = ({ license, groupSize = 0 }) => {
  const classes = styles();

  if (!license) return null;
  return (
    <Paper className={classes.container}>
      <Heading variant="h4">License Accounts</Heading>
      <Box my={1} className={classes.licenseInfoContainer}>
        <div className={classes.licenseInfo}>
          <Typography variant="caption">
            License Accounts
          </Typography>
          <Typography>
            {license.max_users} Accounts ({license.max_users - license.slots_used - groupSize} Remaining)
          </Typography>
        </div>

        <div>
          <Typography variant="caption">
            License Duration
          </Typography>
          <Typography>
            {moment(license.created_on).format('MMM Do YYYY')} to{' '}
            {moment(license.expiry_date).format('MMM Do YYYY')}
          </Typography>
        </div>
        <Box>

        </Box>
      </Box>
    </Paper>
  );
};

export default LicenseDetails;
