import React, { Component, Fragment, useContext } from 'react';
import { CheckUserAction } from '../components';

export const UserConfirmationContext = React.createContext();

export default class UserConfirmationProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmation: null,
    };
  }

  showConfirmation = ({
    title,
    message,
    confirmed,
    cancel,
    confirmText = null,
  }) => {
    this.setState({
      confirmation: { title, message, confirmed, cancel, confirmText },
    });
  };

  reset = () => {
    this.setState({ confirmation: null });
  };

  cancel = () => {
    if (this.state.confirmation && this.state.confirmation.cancel) {
      this.state.confirmation.cancel();
    }
    this.reset();
  };

  confirm = () => {
    this.state.confirmation.confirmed();
    this.reset();
  };

  render() {
    const { confirmation } = this.state;

    return (
      <UserConfirmationContext.Provider
        value={{ showConfirmation: this.showConfirmation }}
      >
        <Fragment>
          <CheckUserAction
            open={Boolean(confirmation)}
            onClose={() => this.cancel()}
            title={confirmation ? confirmation.title : ''}
            content={confirmation ? confirmation.message : ''}
            confirmText={
              confirmation && confirmation.confirmText
                ? confirmation.confirmText
                : null
            }
            onClick={() => this.confirm()}
          />
          {this.props.children}
        </Fragment>
      </UserConfirmationContext.Provider>
    );
  }
}

export const useUserConfirmation = () => {
  const context = useContext(UserConfirmationContext);

  return context;
}

export const UserConfirmationConsumer = UserConfirmationContext.Consumer;
