import React from 'react';
import { KeyElementContainer } from '../../../../../../../containers';
import { useContext } from 'react';
import { UserConfirmationContext } from '../../../../../../../providers/UserConfirmationProvider';
import { DeleteButton } from '../../../../../../../components';

const DeleteKeyElement = ({ keyElement, enqueueSnackbar }) => {
  const { showConfirmation } = useContext(UserConfirmationContext);

  const handleSubmit = (deleteKeyElement) => {
    showConfirmation({
      title: `Delete ${keyElement.name}`,
      message: `Are you sure you want to delete this key element? This action cannot be undone.`,
      confirmed: async () => {
        await deleteKeyElement.mutation({
          variables: { id: keyElement.id },
          refetchQueries: ['getKeyElements'],
        });
        enqueueSnackbar(`Successfully deleted ${keyElement.name}`, {
          variant: 'success',
          autoHideDuration: 1500,
        });
      },
    });
  };

  return (
    <KeyElementContainer>
      {({ deleteKeyElement }) => (
        <DeleteButton
          onClick={(event) => {
            event.stopPropagation();
            handleSubmit(deleteKeyElement);
          }}
        />
      )}
    </KeyElementContainer>
  );
};

export default DeleteKeyElement;
