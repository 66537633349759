import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Divider,
  Typography,
  makeStyles,
  FormControl,
} from '@material-ui/core';
import { ALL_USER_ROLES } from '../../../../lib';
import { GET_USER, UPDATE_USER } from '../../../../apollo/queries';
import { LoadingScreen } from '../../../../components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withSnackbar } from 'notistack';

const styles = makeStyles((theme) => ({
  roleInput: {
    minWidth: 220,
  },
  formControl: {
    display: 'block',
    width: 300,
    marginBottom: theme.spacing(1),
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
}));

const JasperactiveEditUser = ({ match, history, enqueueSnackbar }) => {
  const classes = styles();
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { userId: match.params.userId },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: ['getUser'],
  });

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error....</p>;

  const user = data.user;

  const initialValues = {
    role: user.role,
  };

  return (
    <div>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          startIcon={<ArrowBackIcon />}
          color="primary"
          style={{ marginRight: 16 }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </header>
      <Box display="flex" alignItems="center">
        <Typography variant="h3">
          {user.first_name} {user.last_name}
        </Typography>
      </Box>
      <Divider style={{ margin: 6 }} />

      <Typography gutterBottom>email: {user.email}</Typography>
      <Form
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            await updateUser({
              variables: {
                data: values,
                where: { id: user.id },
              },
            });
            enqueueSnackbar('Successfully updated user', {
              variant: 'success',
              autoHideDuration: 1500,
            });
            history.goBack();
          } catch (error) {
            enqueueSnackbar(
              'Failed to update user. If problem persists please contact IT department',
              { variant: 'error' },
            );
          }
        }}
        render={({ handleSubmit, pristine, submitting, valid }) => (
          <form onSubmit={handleSubmit}>
            <FormControl className={classes.formControl}>
              <Field name="role">
                {({ input, meta }) => {
                  return (
                    <TextField
                      label="Role"
                      className={classes.roleInput}
                      id="userRole"
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      select
                    >
                      {ALL_USER_ROLES.map((role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </TextField>
                  );
                }}
              </Field>
            </FormControl>
            <Typography>groups: {user.groups.length}</Typography>
            <Typography>institutions: {user.institutions.length}</Typography>
            <Button
              className={classes.saveButton}
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitting || pristine || !valid}
            >
              {submitting ? 'Updating...' : 'Save'}
            </Button>
          </form>
        )}
      />
    </div>
  );
};

export default withSnackbar(JasperactiveEditUser);
