import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';

const TimeInput = ({ value, onChange, ...restProps }) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    let val = value / 60;
    if (val <= 0) val = 0;
    setTime(val);
  }, [value]);

  return (
    <TextField
      {...restProps}
      type="number"
      value={time}
      onChange={(event) => {
        let val = +event.target.value * 60;
        if (val <= 0) val = 0;
        onChange(val);
      }}
    />
  );
};

export default TimeInput;
