import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  circularProgressBar_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    marginBottom: '2em'
  },
  circularProgressBar: {
    position: 'relative',
    width: 363,
    '& > svg': {
      width: 364,
      height: 364,
      position: 'relative'
    },
    '& > svg > circle': {
      width: 353,
      height: 353,
      fill: 'none',
      strokeWidth: 13,
      stroke: '#000',
      strokeDasharray: '660 1100',
      strokeDashoffset: 660,
      strokeLinecap: "round",
      transform: 'translate(-7px, 0px) rotate(135deg)',
      transformOrigin: 'center'
    },
    '& > svg > circle:nth-child(1)': {
      strokeDashoffset: 0,
      stroke: '#f3f3f3',
    }
  },
  percentNumber: {
    position: 'absolute',
    top: '5em',
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2em',
    justifyContent: 'center',
    '& > p': {
      fontSize: 64,
      margin: 0,
    },
    '& > span': {
      color: '#828282'
    }
  },
  feedback_description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1em',
    flexDirection: 'column',
    position: 'relative',
    bottom: '2em',
    width: '100%',
    '& > p': {
      color: '#4F4F4F',
      fontWeight: 'bold',
      fontSize: 24,
      textAlign: 'center',
      margin: 0,
    },
    padding: '0 1em',
  },
  positionEmoji: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      borderRadius: '1em',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.12)'
    }
  },
}))