import { useEffect, useState } from "react";


const useGetQuestionsInAssessmentNumber = (assessment) => {
  const [questionsNumber, setQuestionsNumber] = useState('');

  //
  useEffect(() => {
    const questionsWithLesson = assessment.questions.filter((question) => question.lesson !== null);

    if (assessment.questions_per_lesson !== null) {
      const questionsOrderByLesson = questionsWithLesson.reduce((acc, question) => {
        if (acc[question.lesson.id]) {
          acc[question.lesson.id] = [...acc[question.lesson.id], question];
        } else {
          acc[question.lesson.id] = [question];
        }
      
        return acc;
      }, {});

      const questionsPerLesson = Object.keys(questionsOrderByLesson).reduce((acc, key) => {
        acc = [...acc, ...questionsOrderByLesson[key].slice(0, assessment.questions_per_lesson)];
        return acc;
      }, []);

      setQuestionsNumber(questionsPerLesson.length);
    } else {
      setQuestionsNumber(questionsWithLesson.length);
    }
  }, [assessment]);

  return {
    questionsNumber
  }
}

export default useGetQuestionsInAssessmentNumber;