import { useQuery } from '@apollo/react-hooks';
import { Box, Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GET_QUESTION_BY_ID } from '../../../apollo/queries';
import { LoadingScreen } from '../../../components';
import QuestionResources from './QuestionResources';
import QuestionResourcesDashboard from './QuestionResourcesDashboard';
import styles from '../styles';
import JasperactiveLogo from '../../../assets/logos/brand/Jasperactive.svg';

const MediaLibrary = () => {
  const {questionId, type, courseId, caseStudyNumber, course_id: courseIdFromLesson} = useParams();
  const history = useHistory();
  const classes = styles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    localStorage.removeItem('redirectMediaLibrary');
  }, []);

  const {data, loading, error} = useQuery(GET_QUESTION_BY_ID, {
    variables: {
      where: {
        id: questionId
      }
    }
  });

  //course Ids where to show ResourcesDashboard component.
  const gs6CourseID = ['0b256b0e-0571-4f5c-99a6-948422b59bf5', '18038e80-837d-44f8-9c72-9dbda87dd368', '747f5c1c-47f1-4cad-b1a8-e60999ebdc3c'];

  if (loading) {
    return (
      <LoadingScreen/>
    )
  }

  if (error) {
    return <span>Error...</span>
  }

  return (
    <Box className={classes.mediaLibrary_container}>
      <Box className={classes.mediaLibrary_header} color="default">
        <Box className={classes.mediaLibrary_headerContent}>
          <img
            src={JasperactiveLogo}
            alt="Jasperactive"
            style={{ width: isMobile ? 125 : 175, cursor: 'pointer' }}
            onClick={() => history.push("/")}
          />
          <Typography variant='h3' >Question resource</Typography>
        </Box>
      </Box>
      {
        gs6CourseID.includes(courseId ? courseId : courseIdFromLesson) ?  
        <Container style={{maxWidth: 960, display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flex: 1}}>
          <QuestionResourcesDashboard caseStudyNumber={caseStudyNumber}  question={data.question} response={{case: type}}/>
        </Container> 
          : <QuestionResources response={{case: type}} question={data.question} />
      }
    </Box>
  )
};

export default MediaLibrary;