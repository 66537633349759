import React from 'react';
import {
  ExpansionPanel as MUIExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import styles from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpansionPanel = ({
  summary,
  details,
  showExpandIcon,
  summaryProps = {},
  detailProps = {},
  ...restProps
}) => {
  const classes = styles();

  return (
    <MUIExpansionPanel
      {...restProps}
      classes={{ root: classes.expansionPanelRoot }}
    >
      <ExpansionPanelSummary
        {...summaryProps}
        classes={{
          root: classes.expansionSummaryRoot,
          expanded: classes.expansionSummaryExpanded,
        }}
        expandIcon={showExpandIcon && <ExpandMoreIcon />}
      >
        {summary}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        {...detailProps}
        classes={{ root: classes.expansionDetails }}
      >
        {details}
      </ExpansionPanelDetails>
    </MUIExpansionPanel>
  );
};

export default ExpansionPanel;
