import React from 'react';
import { Typography, AppBar, Toolbar } from '@material-ui/core';
import styles from './styles';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined';
import { NavLink } from 'react-router-dom';

const LessonMobileNavBar = ({ match }) => {
  const classes = styles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.bottomNav}>
        <NavLink
          exact
          to={match.url}
          className={classes.bottomNavItem}
          activeClassName={classes.active}
        >
          <LocalLibraryOutlinedIcon />
          <Typography variant="caption">Course</Typography>
        </NavLink>
        <NavLink
          to={`${match.url}/library`}
          className={classes.bottomNavItem}
          activeClassName={classes.active}
        >
          <LibraryBooksOutlinedIcon />
          <Typography variant="caption">eBook</Typography>
        </NavLink>
        <NavLink
          to={`${match.url}/quickdeck`}
          className={classes.bottomNavItem}
          activeClassName={classes.active}
        >
          <FlashOnIcon />
          <Typography variant="caption">Quickdeck</Typography>
        </NavLink>
      </Toolbar>
    </AppBar>
  );
};

export default LessonMobileNavBar;
