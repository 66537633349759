import React from "react";
import { Avatar } from "@material-ui/core";
import AssessmentIcon from '@material-ui/icons/Assessment';
import DescriptionIcon from '@material-ui/icons/Description';
import styles from './styles';

const useGetLogoResource = (resource) => {
  let logoResource;
  const classes = styles();

  switch (resource.type) {
    case 'BENCHMARK':
      logoResource = <Avatar className={classes.benchmarkLogo}><AssessmentIcon/></Avatar>
      break;
    case 'SUMMARY':
      logoResource = <Avatar className={classes.benchmarkLogo}><AssessmentIcon/></Avatar>
      break;
    case 'LEARN_EXERCISE':
      logoResource = <Avatar src={'https://res.cloudinary.com/jasperactive/image/upload/v1638598152/Learn.svg'} />
      break;
    case 'APPLY_EXERCISE':
      logoResource = <Avatar src={'https://res.cloudinary.com/jasperactive/image/upload/v1638598169/Project.svg'} />
      break;
    default:
      logoResource = <Avatar className={classes.benchmarkLogo}><DescriptionIcon/></Avatar>
      break;
  }

  return { logoResource }
}

export default useGetLogoResource;