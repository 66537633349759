import gql from "graphql-tag";

const GET_COUNTRIES_AND_STATES = gql`
  query getAllCountries {
    countries {
      country_code
      country_name
      states {
        state_code
        state_name
      }
    }
  }
`;

export { GET_COUNTRIES_AND_STATES };
