import React from 'react';
import { GET_CLOUDINARY_SIGNATURE } from '../apollo/queries';
import { LoadingScreen } from '../components';
import { useQuery } from '@apollo/react-hooks';

export const CloudinaryContext = React.createContext();

export const CloudinaryProvider = ({ children }) => {
    const { data, loading, error } = useQuery(GET_CLOUDINARY_SIGNATURE, { variables: { where: { operation: "OPEN_LIBRARY" } }, pollInterval: 3300000 });
    const [cloudinaryInfo, setCloudinaryInfo] = React.useState(null);

    React.useEffect(() => {
        if (data) {
            setCloudinaryInfo({
                cloud_name: 'jasperactive',
                username: 'dsweeney@ccilearning.com',
                api_key: data.cloudinaryInfo.api_key,
                unixtime: data.cloudinaryInfo.unixtime,
                mwsignature: data.cloudinaryInfo.mwsignature,
                uwsignature: data.cloudinaryInfo.uwsignature,
            });
        }
    }, [data]);

    const getMediaWidgetConfig = () => {
        return {
            cloud_name: 'jasperactive',
            username: 'dsweeney@ccilearning.com',
            api_key: cloudinaryInfo.api_key,
            timestamp: cloudinaryInfo.unixtime,
            signature: cloudinaryInfo.mwsignature
        };
    };

    if (loading)
        return <LoadingScreen />;

    if (error) {
        return <p>Error...</p>;
    }

    return (
        <CloudinaryContext.Provider value={{ cloudinaryInfo, getMediaWidgetConfig }}>
            {children}
        </CloudinaryContext.Provider>
    );
};
