import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, CardHeader, Avatar, CardActions, Button, Modal } from '@material-ui/core';
import { CircularProgressWithLabel } from '../../../../components';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import AssessmentIcon from '@material-ui/icons/Assessment';
import styles from './styles';
import AssessmentDetails from './AssessmentDetails';
import { STUDENT_SUMMARY_RESULTS } from '../../../../apollo/queries';
import { useViewer } from '../../../../providers/ViewerProvider';
import { useCourse } from '../../providers/CourseProvider';

const GradeOverviewCard = ({
  level,
  bordercolor,
  progress,
  totalquestion,
  correctanswers,
  cardType,
  logo,
  groupId,
  typeOfStudyProject,
  studyProjectNumber,
  summaryAssessmentId,
}) => {
  const classes = styles();
  const [assessmentDetails, setAssessmentDetails] = useState(false);
  const viewer = useViewer();
  const course = useCourse();

  const getLogoEntity = (entity) => {
    switch (entity) {
      case 'studyproject':
        const logoToStudyProjectCard = typeOfStudyProject?.search('Learn') >= 0 ? 'https://res.cloudinary.com/jasperactive/image/upload/v1638598152/Learn.svg': typeOfStudyProject?.search('Apply') >= 0 ? 'https://res.cloudinary.com/jasperactive/image/upload/v1638598169/Project.svg' : logo;
        return <Avatar src={logoToStudyProjectCard} />
      case 'benchmark':
        return <Avatar className={classes.benchmarkLogo} ><AssessmentIcon/></Avatar>
      case 'summary':
        return <Avatar className={classes.benchmarkLogo} ><AssessmentIcon/></Avatar>
      case 'all_benchmarks':
        return <Avatar className={classes.benchmarkLogo} ><AssessmentIcon/></Avatar>
      case 'all_summaries':
        return <Avatar className={classes.benchmarkLogo} ><AssessmentIcon/></Avatar>
      default:
        break;
    }
  };

  const getTitleEntity = (entity) => {
    switch (entity) {
      case 'studyproject':
        return `Study Project ${studyProjectNumber ? '#'+studyProjectNumber : ''}`
      case 'benchmark':
        return `Level ${level} Benchmark Assessment`
      case 'summary':
        return `Level ${level} Summary Assessment`
      case 'all_benchmarks':
        return `All Level Benchmark Assessment`
      case 'all_summaries':
        return `All Level Summary Assessment`
      default:
        break;
    }
  }

  const getSubtitleEntity = (entity) => {
    switch (entity) {
      case 'studyproject':
        const typeOf = typeOfStudyProject?.search('Learn') >= 0 ? 'Learn': typeOfStudyProject?.search('Apply') >= 0 ? 'Apply' : null;
        return typeOf
      case 'benchmark':
        return ''
      case 'summary':
        return ''
      case 'all':
        return ''
      default:
        break;
    }
  };

  const handleOnClickViewDetails = () => {
    if (assessmentDetails) {
      setAssessmentDetails(false);
    } else {
      setAssessmentDetails(true);
    }
  };

  return (
    <Box display="flex" width="100%" p={1} className={classes.flexitem}>
      <Modal
      open={assessmentDetails}
      onClose={handleOnClickViewDetails}
      className={classes.assessmentDetails_container}
      >
        {
          cardType === 'summary' && 
            <AssessmentDetails
            groupId={groupId}
            query={STUDENT_SUMMARY_RESULTS}
            viewer={viewer.id}
            course_id={course.id}
            handleOnClickViewDetails={handleOnClickViewDetails}
            summaryAssessmentId={summaryAssessmentId}
            />
        }
      </Modal>
      <Card
        style={{
          borderTop: `2px solid ${bordercolor}`,
          width: '100%',
          minWidth: 250,
        }}
      >
        <CardContent>
          <CardHeader
          avatar={getLogoEntity(cardType)}
          title={getTitleEntity(cardType)}
          subheader={getSubtitleEntity(cardType)}
          />
          <Box display="flex" justifyContent="space-between" p={2}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
                Progress
              </Typography>
              <CircularProgressWithLabel progress={progress} />
              <Typography
                style={{
                  fontSize: '8px',
                  lineHeight: '16px',
                  color: '#39363A',
                }}
              >
                {correctanswers}/{totalquestion}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                Performance
              </Typography>
              {progress === undefined ? (
                <Box mt={2} >
                  <Typography color="#39363A" >NA</Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                  width="100%"
                  gridGap='0.5em'
                  mt={2}
                >
                  {
                    progress < 75 && <SentimentSatisfiedIcon style={{color: '#ECB322'}} />
                  }
                  {
                     progress >= 75 && progress < 100 && <SentimentSatisfiedAltIcon style={{color: '#3FAE2A'}}/>
                  }
                  {
                    progress === 100 && <SentimentVerySatisfiedIcon style={{color: '#3FAE2A'}}/>
                  }
                  <Typography
                    style={{
                      fontSize: '10px',
                      lineHeight: '16px',
                      color: '#39363A',
                    }}
                  >
                    {progress < 75
                      ? 'Review needed'
                      : progress >= 75 && progress < 100
                      ? 'Good'
                      : progress === 100
                      ? 'Excellent'
                      : ''}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
        {
          cardType === 'summary' &&
            <CardActions
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
            >
              <Button onClick={handleOnClickViewDetails} style={{color: 'rgb(63, 174, 42)'}} size="small" color="primary">
                View details
              </Button>
            </CardActions>
        }
      </Card>
    </Box>
  );
};

export default GradeOverviewCard;
