import React from 'react';
import useDialog from '../../../hooks/useDialog';
import { PrimaryButton } from '../../common';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import InstitutionInviteForm from './InstitutionInviteForm';
import { useInviteInstitutionMember } from '../../../apollo/api';
import { useSnackbar } from 'notistack';

const InviteInstitutionMember = ({ institutionId, members }) => {
  const {
    isOpen,
    toggleDialog,
    Dialog,
    DialogTitle,
    DialogContent,
  } = useDialog();
  const { inviteInstitutionMember } = useInviteInstitutionMember();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    try {
      const inviteData = {
        ...values,
        email: String(values.email).toLocaleLowerCase(),
        institution_id: institutionId,
      };

      await inviteInstitutionMember({
        variables: {
          data: inviteData,
        },
      });
      enqueueSnackbar(`Member invitation sent!`, { variant: 'success' });
      toggleDialog();
    } catch (error) {
      /* ignore */
    }
  };

  return (
    <>
      <PrimaryButton
        startIcon={<PersonAddOutlinedIcon />}
        onClick={() => toggleDialog()}
      >
        Invite Member
      </PrimaryButton>
      <Dialog open={isOpen} onClose={() => toggleDialog()}>
        <DialogTitle onClose={() => toggleDialog()}>Invite Member</DialogTitle>
        <DialogContent>
          <InstitutionInviteForm
            onSubmit={(values) => handleSubmit(values)}
            onCancel={() => toggleDialog()}
            members={members}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InviteInstitutionMember;
