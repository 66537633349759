import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  freeOfferSection: {
    height: '100%',
    overflow: 'auto',
  },
}));

export default useStyles;
