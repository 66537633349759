import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  submitButton: {
    flex: 1,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    background: theme.palette.white.main,
  },
  answerBar: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  button: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  reviewBtn:{
    color:theme.palette.primary.main,
    border:`1px solid ${theme.palette.primary.main}`,
  },
  reviewTitle:{
    ...theme.typography.h4,
    color:theme.palette.grey[400],
    marginBottom:theme.spacing(4)
  }
}));
