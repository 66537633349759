import React from 'react';
import { Box, Typography, Breadcrumbs } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { Heading } from '../../../../components';
import styles from './styles';
import { NavLink, useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';

function GroupHeader({ group, enqueueSnackbar }) {
  const classes = styles();
  const location = useLocation();

  const isGroupRoute = location.pathname.includes('group');

  return (
    <div className={classes.groupHeader}>
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink to="/" className={clsx(classes.navlink, classes.homeLink)}>
          <ArrowBackIcon
            fontSize="inherit"
            color="primary"
            style={{ marginRight: 4 }}
          />
          Home
        </NavLink>
        <NavLink
          onClick={(e) => {
            e.preventDefault();
          }}
          to={''}
          className={clsx(classes.navlink, { [classes.active]: isGroupRoute })}
        >
          {group.name}
        </NavLink>
      </Breadcrumbs>
      <Box mt={4} mb={2}>
        <Heading variant="h2" component="h2" style={{ fontWeight: 'bold', display: 'flex', gap: '0.5em', alignItems: 'center'}}>
          {group.name}
          {
            group.external_id ?
              <Chip
                size="small"
                label="GMetrix Group"
                color="secondary"
              /> : null
          }
        </Heading>
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box display="flex" className={classes.groupInfo}>
          <Typography variant="h3" component="div">
            Group Key:{' '}
            <Typography
              variant="h3"
              component="span"
              className={classes.groupKey}
            >
              {group.key}{' '}
              <CopyToClipboard text={group.key}>
                <FileCopyOutlinedIcon
                  className={classes.copyIcon}
                  color="primary"
                  onClick={() =>
                    enqueueSnackbar('Copied!', {
                      variant: 'info',
                      autoHideDuration: 1500,
                    })
                  }
                />
              </CopyToClipboard>
            </Typography>{' '}
          </Typography>
        </Box>
        <Box className={classes.groupInfo}>
          <Typography variant="h3">Members: {group.members.filter((member) => member.deletedBy === null).length}</Typography>
        </Box>
        <Box className={classes.groupInfo}>
          <Typography variant="h3">
            Instructor: {group.owner.first_name} {group.owner.last_name}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default withSnackbar(GroupHeader);
