import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_GROUP_BY_ID } from '../../../apollo/queries';
import { LoadingScreen } from '../../../components';

const GroupContext = React.createContext();

const GroupProvider = ({ groupId, children }) => {
  const { data, loading, error } = useQuery(GET_GROUP_BY_ID, {
    variables: { groupId },
  });

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error...</p>;

  const group = data.group;

  return (
    <GroupContext.Provider value={{ group }}>{children}</GroupContext.Provider>
  );
};

export const useGroup = () => {
  const data = useContext(GroupContext);

  return data ? data.group : null;
};

export default GroupProvider;
