import gql from "graphql-tag";

const CREATE_RESULT_HISTORY = gql`
  mutation createResultHistoryProject($data: ResultHistoryCreateInput!) {
    createResultHistory(data: $data) {
      id
      type
      exercise_id
      group_id
      corrects
      incorrects
      user_id
      is_latest
      attempt
    }
  }
`

export {
  CREATE_RESULT_HISTORY
}