import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  FormControl,
  TextField,
  MenuItem,
  Button,
  makeStyles,
  Box,
} from '@material-ui/core';

const styles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));

const validate = (values) => {
  let errors = {};
  if (!values.name) errors.name = 'required';
  if (!values.type) errors.type = 'required';

  return errors;
};

const KeyElementForm = ({ onSubmit, keyElement }) => {
  const classes = styles();

  return (
    <Form
      initialValues={
        keyElement
          ? {
              name: keyElement.name,
              type: keyElement.type,
              order_key: keyElement.order_key
            }
          : {}
      }
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth className={classes.formControl}>
            <Field name="name">
              {({ input, meta }) => (
                <TextField
                  label="Name"
                  name="name"
                  value={input.value}
                  onChange={input.onChange}
                  variant="filled"
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <Field name="type">
              {({ input, meta }) => (
                <TextField
                  label="Type"
                  name="type"
                  value={input.value}
                  onChange={input.onChange}
                  select
                  variant="filled"
                >
                  <MenuItem value="quickdeck" selected>
                    Quickdeck Cards
                  </MenuItem>
                  <MenuItem value="ebook">eBook Pages</MenuItem>
                  <MenuItem value="quickclip">Quickclip Cards</MenuItem>
                </TextField>
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <Field name="order_key">
              {({ input, meta }) => (
                <TextField
                  label="Order"
                  name="order_key"
                  value={input.value}
                  onChange={input.onChange}
                  type='number'
                  variant="filled"
                >
                </TextField>
              )}
            </Field>
          </FormControl>
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting || pristine || !valid}
            >
              {keyElement ? 'Update' : 'Add'}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default KeyElementForm;
