import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  gridItem: {
    padding: theme.spacing(2),
    backgroundColor: "pink",
  },
  productContainer: {
    flexGrow: 1,
    overflowY: "scroll",
  },
  list: {
    overflowY: "scroll",
    maxHeight: 700,
  },
}));
