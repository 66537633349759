import React, { useState, useContext } from 'react';
import { checkAnswer, removeTextFromUserAnswer } from '../../../lib';
import { ViewerContext } from '../../../providers/ViewerProvider';
import { withSnackbar } from 'notistack';
import { CREATE_RESULT_HISTORY, CREATE_SUMMARY_RESULT } from '../../../apollo/queries';
import { useMutation } from '@apollo/react-hooks';
import { SummaryContext } from './SummaryProvider';

export const SummaryStudentAnswerContext = React.createContext();

const SummaryUserAnswerProvider = ({ groupId, enqueueSnackbar, children }) => {
  const { viewer } = useContext(ViewerContext);
  const { questions, summaries } = useContext(SummaryContext);
  const [_userAnswer, _setUserAnswer] = useState({});
  const [userResults, setUserResults] = useState([]);
  const [timeSpentUserAnswers, setTimeSpentUserAnswers] = useState({});

  const [createSummaryResult] = useMutation(CREATE_SUMMARY_RESULT, {
    refetchQueries: ['getStudentProgress', 'getStudentCourseProgress'],
  });

  const [ createResultHistory ] = useMutation(CREATE_RESULT_HISTORY);

  const setUserAnswer = (answers) => {
    _setUserAnswer(answers);
  };

  const getSummary = (summaries, question) => {
    const summary = summaries.reduce((acc, summary) => {
      const isQuestionInSummary = summary.questions.find((questionSm) => questionSm.id === question.id);
      if (isQuestionInSummary) {
        acc.push(summary);
      }

      return acc;
    }, []);
    
    return summary[0];
  };

  const getStudentResults = (question) => {
    const isCorrect = checkAnswer(question, _userAnswer[question.id]);
    const summary = getSummary(summaries, question);
    const answer = _userAnswer[question.id] ? _userAnswer[question.id] : [];
    const studentAnswer = JSON.stringify(removeTextFromUserAnswer(question.type, answer));

    return {
      result: isCorrect,
      question_id: question.id,
      summary_id: summary.id,
      group_id: groupId,
      user_id: viewer.id,
      answer: studentAnswer,
      seconds_spent: timeSpentUserAnswers[question.id]
    };
  };

  const handleCreateResultHistory = async (results) => {

    const resultsBySummaryId = results.reduce((acc, result) => {
      if (acc[result.summary_id]) {
        acc[result.summary_id] = [...acc[result.summary_id], result]
      } else {
        acc[result.summary_id] = [result];
      }

      return acc;
    }, {});

    const summariesData = Object.keys(resultsBySummaryId).reduce((acc, key) => {
      const data = {
        type: 'SUMMARY',
        exercise_id: key,
        group_id: groupId,
        corrects: resultsBySummaryId[key].reduce((acc, result) => {
          if (result.result === true) {
            acc++;
          }
          return acc;
        }, 0),
        incorrects: resultsBySummaryId[key].reduce((acc, result) => {
          if (result.result === false) {
            acc++;
          }
          return acc;
        }, 0),
        user_id: viewer.id
      }

      acc.push(data);
      return acc;
    }, []); 

    for (const summary of summariesData) {  
      await createResultHistory({
        variables: {
          data: summary
        }
      });
    }
  }

  const submitSummary = async () => {
    try {
      const results = questions.map(getStudentResults);
      if (groupId) {
        await createSummaryResult({
          variables: { data: results },
          refetchQueries: ['getStudentCourseProgress']
        });
        await handleCreateResultHistory(results);
      }
      setUserResults(results);
      return true;
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong!', { variant: 'error' });
      return false;
    }
  }

  return (
    <SummaryStudentAnswerContext.Provider
      value={{
        userAnswer: _userAnswer,
        setUserAnswer,
        submitSummary,
        userResults,
        timeSpentUserAnswers,
        setTimeSpentUserAnswers
      }}
    >
      {children}
    </SummaryStudentAnswerContext.Provider>
  );
};

export default withSnackbar(SummaryUserAnswerProvider);
