import React from 'react';
import { makeStyles, MenuItem, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  selectInput: {
    width: 320,
  },
}));

const LessonMenu = ({ lessons, history, currentLesson }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    history.push(event.target.value);
  };

  if (!lessons) {
    return null;
  }

  return (
    <div>
      <TextField
        className={classes.selectInput}
        select
        label="Lesson Selected"
        onChange={handleChange}
        value={currentLesson.name}
        variant="filled"
      >
        {lessons
          .filter((l) => l.published)
          .map((lesson) => (
            <MenuItem
              key={lesson.id}
              disabled={currentLesson.id === lesson.id}
              value={lesson.name}
            >
              {lesson.name}
            </MenuItem>
          ))}
      </TextField>
    </div>
  );
};

export default LessonMenu;
