import React, { useState, Fragment } from 'react';
import { Paper, Typography, Box } from '@material-ui/core';
import StarsIcon from '@material-ui/icons/Stars';
import styles from './styles';
import {
  EditButton,
  CloseButton,
  InstitutionForm,
} from '../../../../../components';
import InputPlaceholder from './InputPlaceholder';
import { UPDATE_INSTITUTION } from '../../../../../apollo/queries';
import { useMutation } from '@apollo/react-hooks';

const InstitutionInformation = ({ institution }) => {
  const [showForm, setShowForm] = useState(false);
  const classes = styles();
  const [updateInstitution] = useMutation(UPDATE_INSTITUTION);

  return (
    <Paper style={{ padding: 16 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" gutterBottom>
          {showForm ? 'Edit Institution' : 'Institution Information'}
        </Typography>
        {showForm ? (
          <CloseButton onClick={() => setShowForm(false)} color="primary" />
        ) : (
          <EditButton onClick={() => setShowForm(true)} color="primary" />
        )}
      </Box>
      {showForm ? (
        <InstitutionForm
          institution={institution}
          onSubmit={(values) => {
            const id = values.id;
            delete values.id;
            updateInstitution({
              variables: {
                data: {
                  ...values,
                  address:
                    values.address && values.address !== ''
                      ? values.address
                      : null,
                  city: values.city && values.city !== '' ? values.city : null,
                },
                where: { id },
              },
              refetchQueries: ['getInstitutionActivities'],
            });
          }}
        />
      ) : (
        <Fragment>
          <Typography variant="subtitle2" gutterBottom>
            Logo
          </Typography>
          <Box display="flex" justifyContent="center">
            {institution.logo ? (
              <img
                alt="Institution Logo"
                src={institution.logo}
                style={{
                  maxWidth: '65%',
                  marginBottom: '15px',
                  height:
                    institution.logo &&
                    institution.logo.toLowerCase().indexOf('svg') > -1
                      ? '250px'
                      : 'auto',
                }}
              />
            ) : (
              <StarsIcon
                className={classes.logoInstitutionFrm}
                color="primary"
                style={{ fontSize: 120, marginBottom: '15px' }}
              ></StarsIcon>
            )}
          </Box>
          <InputPlaceholder
            label="Name"
            text={institution.name}
            style={{ marginBottom: 8 }}
          />
          <InputPlaceholder
            label="Country"
            text={institution.country}
            style={{ marginBottom: 8 }}
          />
          <InputPlaceholder
            label="State/Province"
            text={institution.state ? `${institution.state}` : 'N/A'}
            style={{ marginBottom: 8 }}
          />
          <InputPlaceholder
            label="Address"
            text={institution.address ? `${institution.address}` : 'N/A'}
            style={{ marginBottom: 8 }}
          />
          <InputPlaceholder
            label="City"
            text={institution.city ? `${institution.city}` : 'N/A'}
            style={{ marginBottom: 8 }}
          />
          <InputPlaceholder
            label="Assigned Sales Manager"
            text={
              institution.sales_manager
                ? `${institution.sales_manager.first_name} ${institution.sales_manager.last_name}`
                : 'Not Assigned'
            }
            style={{ marginBottom: 8 }}
          />
        </Fragment>
      )}
    </Paper>
  );
};

export default InstitutionInformation;
