import { makeStyles } from '@material-ui/core'
import {theme} from '../../../../theme'

const styles = makeStyles({
    courseTitle:{
        ...theme.typography.h2,
        color:theme.palette.grey[500],
        fontWeight:600,
        marginLeft:theme.spacing(1),
    },
    container:{
        [theme.breakpoints.down('xs')]:{
            flexDirection:"column"
        }
    },
  benchmarkLevelTitle:{
    ...theme.typography.h3,
    color:theme.palette.grey[500],
    marginTop:theme.spacing(1)
  }
})


export default styles;