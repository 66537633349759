import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  navigationButtonSelected: {
    backgroundColor: '#BD161C',
    padding: '0.1em 0.4em',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#A11419'
    }
  },
  buttonGroupNav: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
    maxWidth: 300,
    width: '100%',
    justifyContent: 'center'
  },
  navigationButton: {
    backgroundColor: '#C4C4C4',
    padding: '0.1em 0.4em',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#A11419'
    }
  }
}));

export default styles;