import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  Dashboard,
  CourseDevelopment,
  EBookDevelopment,
  EBook,
} from './screens';
import { ViewerContext } from '../../providers/ViewerProvider';
import { CMS_ACCESS } from '../../lib';
import CourseDashboard from '../CourseDashboard';
import StudyProject from '../StudyProject';
import Benchmark from '../Benchmark';
import Summary from '../Summary';
import { CloudinaryProvider } from '../../providers/CloudinaryProvider';

const ContentManagement = ({ match, location }) => {
  const { viewer } = useContext(ViewerContext);

  if (!CMS_ACCESS.includes(viewer.role)) {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  return (
    <CloudinaryProvider>
      <Switch>
        <Route
          path={`${match.path}/ebook/:ebookId`}
          component={EBookDevelopment}
        />
        <Route path={`${match.path}/ebook`} component={EBook} />
        <Route
          path={`${match.path}/preview/course/:courseId/study-project/:projectId`}
          component={StudyProject}
        />
        <Route
          path={`${match.path}/preview/course/:courseId/benchmark/:level`}
          component={Benchmark}
        />
        <Route
          path={`${match.path}/preview/course/:courseId/summary/:level`}
          component={Summary}
        />
        <Route
          path={`${match.path}/preview/course/:courseId`}
          component={CourseDashboard}
        />
        <Route
          path={`${match.path}/course/:courseId`}
          component={CourseDevelopment}
        />
        <Route exact path={`${match.path}`} component={Dashboard} />
      </Switch>
    </CloudinaryProvider>
  );
};

export default ContentManagement;
