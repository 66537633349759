import gql from 'graphql-tag';
import { QuickClipCardFields } from './fragments';

const CREATE_QUICKCLIP_CARD = gql`
  mutation createQuickclipCard($data: QuickClipCardCreateInput!) {
    createQuickClipCard(data: $data) {
      ...QuickClipCardFields
    }
  }
  ${QuickClipCardFields}
`;

const UPDATE_QUICKCLIP_CARD = gql`
  mutation updateQuickclipCard(
    $data: QuickClipCardUpdateInput!
    $where: QuickClipWhereUniqueInput!
  ) {
    updateQuickClipCard(data: $data, where: $where) {
      ...QuickClipCardFields
    }
  }
  ${QuickClipCardFields}
`;

const PUBLISH_QUICKCLIP_CARD = gql`
  mutation publishQuickClipCard($id: ID!) {
    publishQuickClipCard(id: $id)
  }
`;

const UNPUBLISH_QUICKCLIP_CARD = gql`
  mutation unpublishQuickClipCard($id: ID!) {
    unpublishQuickClipCard(id: $id)
  }
`;

const DELETE_QUICKCLIP_CARD = gql`
  mutation deleteQuickClipCard($id: ID!) {
    deleteQuickClipCard(id: $id)
  }
`;

const GET_LESSON_CLIPCARDS = gql`
  query getLessonCards($lessonId: ID!) {
    quickClipCards(where: { lesson_id: $lessonId }) {
      ...QuickClipCardFields
    }
  }
  ${QuickClipCardFields}
`;

export {
  CREATE_QUICKCLIP_CARD,
  UPDATE_QUICKCLIP_CARD,
  PUBLISH_QUICKCLIP_CARD,
  UNPUBLISH_QUICKCLIP_CARD,
  DELETE_QUICKCLIP_CARD,
  GET_LESSON_CLIPCARDS,
};
