import React, { Fragment, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useContext } from 'react';
import { ViewerContext } from '../../providers/ViewerProvider';
import {
  CourseHeading,
  Dashboard,
  LearningLibrary,
  LearningLibraryBook,
  LearningLibraryCards,
  Gradebook,
  LearningLibraryClips
} from './components';
import useStyles from './styles';
import CourseProvider from './providers/CourseProvider';
import StudentCourseProgressProvider from './providers/StudentCourseProgressProvider';
import { Tab, Tabs } from '@material-ui/core';
import { DashboardShell } from '../../components';
import InstructorResources from './components/InstructorResources';
import GroupMemberProvider, { useGroupMember } from '../GroupDashboard/providers/GroupMemberProvider';
import { isInstructor } from '../../lib';

const CourseDashboard = ({ match, location, history }) => {
  const classes = useStyles();
  const courseId = match.params.courseId;
  const groupId = match.params.groupId;
  const { viewer } = useContext(ViewerContext);
  
  const preview = location.state?.preview || match.url.split('/').includes('preview');

  if (preview) {
    return (
      <CourseDashboardPreview
      courseId={courseId}
      viewer={viewer}
      groupId={groupId}
      classes={classes}
      match={match}
      location={location}
      history={history}
      />
    )
  }
  
  return (
    <CourseDashboardNoPreview
    courseId={courseId}
    viewer={viewer}
    groupId={groupId}
    classes={classes}
    match={match}
    location={location}
    history={history}
    />
  );
};


const CourseDashboardPreview = ({viewer, groupId, courseId, classes, match, location, history}) => {

  let tabs = ['exercises', 'study materials', 'gradebook', 'instructor resources'];

  return (
    <CourseProviderComponent
    courseId={courseId}
    viewer={viewer}
    groupId={groupId}
    classes={classes}
    match={match}
    tabs={tabs}
    location={location}
    history={history}
    />
  )
}

const CourseDashboardNoPreview = ({viewer, groupId, courseId, classes, match, location, history}) => {
  const member = useGroupMember();

  let tabs = ['exercises', 'study materials', 'gradebook'];

  if (member && isInstructor(member.role)) {
    tabs = ['exercises', 'study materials', 'gradebook', 'instructor resources'];
  };

  return (
    <GroupMemberProvider userId={viewer.id} groupId={groupId}>
      <CourseProviderComponent
      courseId={courseId}
      viewer={viewer}
      groupId={groupId}
      tabs={tabs}
      match={match}
      classes={classes}
      location={location}
      history={history}/>
    </GroupMemberProvider> 
  )
}

const CourseProviderComponent = ({courseId, viewer, groupId, tabs, match, classes, location, history}) => {

  const _tabs = tabs.map((tab) => (
    <Tab key={tab} label={tab} className={classes.tab} />
  ));

  const determineCurrentTab = () => {
    const routeArr = location.pathname.split('/');
    let currentTabIndex = 0;

    for (const route of routeArr.reverse()) {
      let _route;
      switch (route) {
        case 'library':
          _route = 'study materials';
          break;
        case 'resources':
          _route = 'instructor resources';
          break;
        case 'gradebook':
          _route = 'gradebook';
          break;
        default:
          _route = route
          break;
      }

      const index = tabs.findIndex((tab) => tab === _route);
      if (index > -1) {
        currentTabIndex = index;
        break;
      }
    }

    return currentTabIndex;
  };

  const [value, setValue] = useState(determineCurrentTab());

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let route = tabs[newValue];

    if (route === 'study materials') {
      route = 'library';
    }

    if (route === 'instructor resources') {
      route = 'resources';
    }

    history.push(route === 'exercises' ? match.url : `${match.url}/${route}`);
  };

  return (
    <CourseProvider courseId={courseId}>
      <StudentCourseProgressProvider
        courseId={courseId}
        studentId={viewer.id}
        groupId={groupId}
      >
        <DashboardShell
          header={
            <Fragment>
              <CourseHeading history={history} />
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons='auto'
                variant="scrollable"
                className={classes.tabs}
              >
                {_tabs}
              </Tabs>
            </Fragment>
          }
          main={
            <Switch>
              <Route path={`${match.path}/gradebook`} component={Gradebook} />
              <Route
                path={`${match.path}/library/quickdeck/:lessonName`}
                component={LearningLibraryCards}
              />
              <Route
                path={`${match.path}/library/e-book/:lessonName`}
                component={LearningLibraryBook}
              />
              <Route
                path={`${match.path}/library/quickclip/:lessonName`}
                component={LearningLibraryClips}
              />
              <Route
                exact
                path={`${match.path}/library`}
                component={LearningLibrary}
              />
              <Route
                exact
                path={`${match.path}/resources`}
                component={InstructorResources}
              />
              <Route exact path={match.path} component={Dashboard} />
              <Redirect from={`${match.path}/*`} to={match.path} />
            </Switch>
          }
        />
      </StudentCourseProgressProvider>
    </CourseProvider>
  )
}

export default CourseDashboard;
