import { makeStyles } from '@material-ui/core/styles';
import pattern from '../../../assets/background/jasper-pattern.svg';

export default (color) =>
  makeStyles((theme) => ({
    jasperactiveBackgroundSection: {
      flexGrow: 1,
      position: 'relative',
      height: '100%',
      background: '#F1F1F1',
      overflow: 'scroll',
    },
    hero: {
      backgroundImage: `url(${pattern})`,
      top: 0,
      left: 0,
      width: '100%',
      height: 150,
      position: 'absolute',
      background: color ? color : theme.palette.dark.main,
      zIndex: 1,
      '&:after': {
        content: '""',
        background: 'rgba(0,0,0,0.2)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 3,
      },
    },
  }))();
