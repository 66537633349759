import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bookSection: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  icon: {
    color: theme.palette.success.main,
  },
}));

export default useStyles;
