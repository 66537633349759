import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  completeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      height: 150,
      width: 150,
      marginBottom: theme.spacing(2),
    },
    '& p': {
      marginBottom: theme.spacing(2),
    },
    '& button': {
      width: '100%',
      maxWidth: 300,
    },
  },
  benchmarkCompleteTitle:{
    ...theme.typography.h1,
    color:theme.palette.grey[500]
  },
  planCreateTitle:{
    ...theme.typography.h3,
    color:theme.palette.grey[400]
  },
  goToButton:{
    backgroundColor:theme.palette.primary.main,
    color:theme.palette.common.white,
    '&:hover':{
      backgroundColor:theme.palette.primary.main
    }
  }
}));
