import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import DevelopCourseBook from '../DevelopCourseBook';
import DevelopCourseDeck from '../DevelopCourseDeck';
import DevelopCourseClip from '../DevelopCourseClip';
import styles from './styles';

const TabPanel = ({ value, index, children }) => {
  if (value !== index) return null;
  return <Box flexGrow={1}>{children}</Box>;
};

const CourseLibrary = ({ course }) => {
  const classes = styles();

  const [currentTab, setCurrentTab] = useState(
    +localStorage.getItem('currentLibraryTab') || 0,
  );
  const handleTabChange = (event, newValue) => {
    localStorage.setItem('currentLibraryTab', newValue);
    setCurrentTab(newValue);
  };

  return (
    <section className={classes.courseLibrarySection}>
      <div className={classes.tabWrapper}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          className={classes.tabs}
          scrollButtons="auto"
        >
          <Tab label="EBOOK" />
          <Tab label="QUICKDECKS" />
          <Tab label="QUICKCLIPS" />
        </Tabs>
      </div>
      <TabPanel value={currentTab} index={0}>
        <DevelopCourseBook course={course} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <DevelopCourseDeck course={course} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <DevelopCourseClip course={course} />
      </TabPanel>
    </section>
  );
};

export default CourseLibrary;
