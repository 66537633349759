import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import {
  GET_LICENSES_UPCOMING_RENEWALS_BY_DAYS,
  GET_FORM_LICENSEPRODUCT_BY_ID_LICENSE,
  GET_FORM_PRODUCTS,
  GET_LICENSES_BY_ID_INSTITUTION,
  CREATE_LICENSE,
  UPDATE_LICENSES_STATUS_ACTIVE,
  UPDATE_LICENSES_STATUS_RENEW,
  UPDATE_LICENSES_STATUS_MODIFY,
} from '../apollo/queries';
import { adopt } from 'react-adopt';

  const gatAllLicensesUpcomingRenewals = ({ render, days , creator }) => {
    return (
            <Query 
            query={GET_LICENSES_UPCOMING_RENEWALS_BY_DAYS}
            variables={{ where: { days: days, creator: creator } }}
            >
              {({ data: { licenseComming } = {}, loading, error }) =>
                render({ licenseComming, loading, error })
              }
            </Query>
    );
    
  };

  const getFomProductsItemSelected = ({ render, licenseId }) => {
    return (
            <Query 
            query={GET_FORM_LICENSEPRODUCT_BY_ID_LICENSE}
            variables={{ where: { licenseId: licenseId } }}
            >
              {({ data: { products } = {}, loading, error }) =>
                render({ products, loading, error })
              }
            </Query>
    );
    
  };

  const getFomProducts = ({ render }) => (
    <Query query={GET_FORM_PRODUCTS} >
      {({ data: { products } = {}, loading, error }) =>
        render({ products, loading, error })
      }
    </Query>
  );

  const updateLicenseActive = ({ render }) => (
    <Mutation mutation={UPDATE_LICENSES_STATUS_ACTIVE}>
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  );

  const updateLicenseRenew = ({ render }) => (
    <Mutation mutation={UPDATE_LICENSES_STATUS_RENEW}>
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  );

  const updateLicenseModify = ({ render }) => (
    <Mutation mutation={UPDATE_LICENSES_STATUS_MODIFY}>
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  );
  
  const addLicense = ({ render }) => (
    <Mutation mutation={CREATE_LICENSE}>
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  );

  const getAllLicensesByIdInstitution = ({ render, institution_id}) => {
    return (
            <Query 
            query={GET_LICENSES_BY_ID_INSTITUTION}
            variables={{ where: { institution_id: institution_id } }}
            >
              {({ data: { licensesByIdInstitution } = {}, loading, error }) =>
                render({ licensesByIdInstitution, loading, error })
              }
            </Query>
    );
    
  };

  const LicenseContainer = adopt({
    gatAllLicensesUpcomingRenewals,
    getFomProductsItemSelected,
    getFomProducts,
    getAllLicensesByIdInstitution,
    updateLicenseActive,
    updateLicenseRenew,
    updateLicenseModify,
    addLicense,
  });
  export default LicenseContainer;