import gql from 'graphql-tag';
import {
  QuickDeckCardFields,
  BookPageFields,
  QuickClipCardFields,
} from './fragments';

const CREATE_QUESTION_RESOURCE = gql`
  mutation createQuestionResource($data: QuestionResourceCreateInput!) {
    createQuestionResource(data: $data)
  }
`;

const DELETE_QUESTION_RESOURCE = gql`
  mutation deleteQuestionResource($id: ID!) {
    deleteQuestionResource(id: $id)
  }
`;

const GET_QUESTION_RESOURCES = gql`
  query getQuestionResources($questionId: ID!) {
    questionResources(questionId: $questionId) {
      id
      card {
        ...QuickDeckCardFields
      }
      clip_card {
        ...QuickClipCardFields
      }
      page {
        ...BookPageFields
      }
      type
      resource_type
    }
  }
  ${QuickDeckCardFields}
  ${QuickClipCardFields}
  ${BookPageFields}
`;

export {
  GET_QUESTION_RESOURCES,
  CREATE_QUESTION_RESOURCE,
  DELETE_QUESTION_RESOURCE,
};
