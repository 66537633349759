import React from 'react';
import { FormControl, TextField, MenuItem } from '@material-ui/core';
import { Field } from 'react-final-form';
import { formatEnum } from '../../../../lib';

const SelectReinforcedQuestions = ({ input, meta, types }) => {
  return (
    <>
      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <Field name="type">
          {({ input, meta }) => (
            <TextField
              id="type"
              select
              style={{ textTransform: 'capitalize' }}
              type="text"
              label="Type"
              inputProps={{
                autoComplete: 'off',
              }}
              value={input.value}
              onChange={(value) => {
                input.onChange(value);
              }}
              variant="filled"
            >
              {types.map((item) => (
                <MenuItem
                  key={item.name}
                  value={item.name}
                  style={{ textTransform: 'capitalize' }}
                >
                  {formatEnum(item.name.toLowerCase())}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Field>
      </FormControl>
    </>
  );
};

export default SelectReinforcedQuestions;
