import React from 'react';
import AssessmentComplete from '../../../../assets/logos/il_completed.svg';
import { Typography, Box, Button } from '@material-ui/core';
import styles from './styles';

const StudyProjectComplete = ({ onClick, preview }) => {
  const classes = styles();
  return (
    <Box className={classes.completeContainer} p={2}>
      <img src={AssessmentComplete} alt="Assessment Complete" />
      <Typography component="p" variant="h5" gutterBottom color="primary">
        Learning Completed!
      </Typography>
      <Typography component="p" variant="body1">
        Good job! Keep up the great work!
      </Typography>

      <Button onClick={onClick} variant="outlined" color="primary">
        {preview ? 'Back to Lesson' : 'Go To My Course'}
      </Button>
    </Box>
  );
};

export default StudyProjectComplete;
