import React from 'react';
import { Box } from '@material-ui/core';
import styles from './styles';

const JasperBackground = ({ children, color, ...restProps }) => {
  const classes = styles(color);
  return (
    <section className={classes.jasperactiveBackgroundSection}>
      <div className={classes.hero} />
      <Box
        {...restProps}
        style={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        {children}
      </Box>
    </section>
  );
};

export default JasperBackground;
