import gql from 'graphql-tag';

const CREATE_SUMMARY_RESULT = gql`
  mutation createSummaryResult($data: [SummaryResultCreateInput!]!) {
    createSummaryResult(data: $data) {
      id
      result
      answer
      question {
        id
        question
      }
      summary {
        id
        level
      }
    }
  }
`;

const STUDENT_SUMMARY_RESULTS = gql`
  query getStudentSummaryResults($where: SummaryResultWhereInput!) {
    summaryResults(where: $where) {
      id
      result
      answer
      question {
        id
        question
        answers {
          id
          answer
          is_correct
          match
        }
        type
      }
      summary {
        id
        level
      }
    }
  }
`;

export { CREATE_SUMMARY_RESULT, STUDENT_SUMMARY_RESULTS };