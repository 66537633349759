import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { launchAsync } from '@microsoft/immersive-reader-sdk';
import { useState } from "react";

const GET_IMMERSIVE_READER_TOKEN = gql`
    query getImmersiveReaderToken {
        immersiveReaderAuth {
        token
        subdomain
        }
    }
`;

const useImmersiveReaderLauncher = () => {
    const [subdomain, setSubdomain] = useState('');
    const [token, setToken] = useState('');

    const { loading } = useQuery(GET_IMMERSIVE_READER_TOKEN, {
        onCompleted: (data) => {
            setSubdomain(data.immersiveReaderAuth.subdomain);
            setToken(data.immersiveReaderAuth.token);
        }, 
        onError: (error) => {
            console.error(error.message);
        }
    });

    const handleOnLaunch = async (title, text) => {
        const options = { "uiZIndex": 2000 };
        const data = { title: title, chunks: [{ content: text, mimeType: "text/html", lang: 'en' }] };

        launchAsync(token, subdomain, data, options).catch(function (error) {
            console.error("Error in launching the Immersive Reader");
            console.error(error);
        });
    }

    return {
        handleOnLaunch,
        loading
    }
};


export default useImmersiveReaderLauncher;