import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  // Instructor Gradebook
  instructorContainer: {
    padding: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  // Entity
  showResultButton: {
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(2),
    background: theme.palette.dark.main,
    color: theme.palette.white.main,
  },
  entityOverviewCard: {
    marginBottom: theme.spacing(2),
  },
  table_lessons: {
    borderCollapse: 'collapse',
    width: 'auto',
    tableLayout: 'fixed',
    border: 'none',
    '& > thead > tr > th': {
      borderLeft: '1px solid #000',
    },
    '& > thead > tr > th:first-child': {
      borderLeft: 'none',
    },
    '& > tr > td': {
      borderLeft: '1px solid #000',
      minWidth: 300
    },
    '& > tr > td:first-child': {
      borderLeft: 'none',
      minWidth: 300
    }
  },
}));

export default styles;
