import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    background: theme.palette.white.main,
  },
  bottomNav: {
    width: '100%',
  },
  bottomNavItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.54)',
  },
  active: {
    color: theme.palette.primary.main,
  },
}));
