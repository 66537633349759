import React from 'react';
import { Box } from '@material-ui/core';
import styles from './styles';
import { CourseDashboardBenchmarkCard } from './Cards';

const CourseBenchmarks = ({
  course,
  studentCourseProgress,
  isPreview,
  history,
  match,
}) => {
  const classes = styles();

  const handleBenchmarkClick = (benchmark, isComplete) => {
    if (!isComplete) {
      if (!benchmark) {
        history.push(`${match.url}/benchmark/all`);
      } else {
        history.push(`${match.url}/benchmark/${benchmark.level}`);
      }
    }
  };

  const benchmarkProgress = isPreview
    ? {}
    : studentCourseProgress.benchmark.reduce((acc, benchmark) => {
        acc[benchmark.benchmark_id] = benchmark.complete;
        return acc;
      }, {});

  benchmarkProgress.allComplete = isPreview
    ? {}
    : studentCourseProgress.benchmark.filter((benchmark) => benchmark.complete)
        .length === studentCourseProgress.benchmark.length;

  const benchmarks = isPreview
    ? course.benchmarks
    : course.benchmarks.filter((b) => b.published);

  return (
    <Box display="flex" flexWrap="wrap">
      {benchmarks.map((benchmark) => (
        <Box key={benchmark.id} className={classes.flexitem} p={1}>
          <CourseDashboardBenchmarkCard
            borderColor={course.product.color}
            level={benchmark.level}
            complete={isPreview ? false : benchmarkProgress[benchmark.id]}
            onClick={() =>
              handleBenchmarkClick(
                benchmark,
                isPreview ? false : benchmarkProgress[benchmark.id],
              )
            }
          />
        </Box>
      ))}

      {/*
        <Box className={classes.flexitem} p={1}>
          <CourseDashboardBenchmarkCard
            level="all"
            borderColor={course.product.color}
            onClick={() =>
              handleBenchmarkClick(
                null,
                isPreview ? false : benchmarkProgress.allComplete,
              )
            }
            complete={isPreview ? false : benchmarkProgress.allComplete}
          />
        </Box>
          */}
    </Box>
  );
};

export default CourseBenchmarks;
