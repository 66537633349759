import React, { useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  ButtonGroup,
  Button,
  TextField,
} from '@material-ui/core';
import {
  InstitutionActivityList,
  InstitutionCommentList,
} from '../../../../../components';
import SubtitlesOutlinedIcon from '@material-ui/icons/SubtitlesOutlined';
import styles from './styles';
import clsx from 'clsx';
import { useMutation } from '@apollo/react-hooks';
import { ADD_INSTITUTION_COMMENT } from '../../../../../apollo/queries';

const InstitutionActivity = ({ institution }) => {
  const classes = styles();
  const [showComments, setShowComments] = useState(false);

  return (
    <Paper style={{ padding: 16 }}>
      <Box display="flex" alignItems="center">
        <SubtitlesOutlinedIcon style={{ marginRight: 15 }} />
        <Typography variant="h6">Institution Activity</Typography>
      </Box>
      <Box p={1} display="flex" justifyContent="center" alignItems="center">
        <ButtonGroup size="small" className={classes.buttonGroup}>
          <Button
            onClick={() => setShowComments(false)}
            className={[
              classes.button,
              clsx({ [classes.selected]: !showComments }),
            ]}
          >
            Activity
          </Button>
          <Button
            onClick={() => setShowComments(true)}
            className={[
              classes.button,
              clsx({ [classes.selected]: showComments }),
            ]}
          >
            Comments
          </Button>
        </ButtonGroup>
      </Box>
      {showComments && (
        <InstitutionCommentInput institutionId={institution.id} />
      )}
      <Box className={classes.contentWrapper}>
        {!showComments && (
          <InstitutionActivityList institutionId={institution.id} />
        )}
        {showComments && (
          <InstitutionCommentList institutionId={institution.id} />
        )}
      </Box>
    </Paper>
  );
};

const InstitutionCommentInput = ({ institutionId }) => {
  const [value, setValue] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [addComment] = useMutation(ADD_INSTITUTION_COMMENT, {
    refetchQueries: ['getInstitutionComments'],
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClick = async () => {
    try {
      setSubmitting(true);
      await addComment({
        variables: {
          data: {
            comment: value,
            institution_id: institutionId,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
    setValue('');
    setSubmitting(false);
  };

  return (
    <Box display="flex" flexDirection="column">
      <TextField
        fullWidth
        label="New Comment"
        value={value}
        onChange={handleChange}
        variant="filled"
        multiline
        style={{ marginBottom: 8 }}
      />
      <Button
        style={{ marginLeft: 'auto' }}
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={value === '' || submitting}
      >
        Add Comment
      </Button>
    </Box>
  );
};

export default InstitutionActivity;
