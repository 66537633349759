import React, { useContext } from 'react';
import { ViewerContext } from '../../../providers/ViewerProvider';
import { useQuery } from '@apollo/react-hooks';
import { USER_COMPLETED_BENCHMARK_IDS } from '../../../apollo/queries';
import { LoadingScreen } from '../../../components';

export const BenchmarkProgressContext = React.createContext();

const BenchmarkProgressProvider = ({ groupId, children }) => {
  const { viewer } = useContext(ViewerContext);

  const { data, loading, error } = useQuery(USER_COMPLETED_BENCHMARK_IDS, {
    variables: {
      groupId,
      userId: viewer?.id,
    },
    skip: !groupId,
  });

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error...</p>;

  const state = {
    complete_benchmark_ids: !groupId ? [] : data.completedBenchmarks,
  };

  return (
    <BenchmarkProgressContext.Provider value={state}>
      {children}
    </BenchmarkProgressContext.Provider>
  );
};

export default BenchmarkProgressProvider;
