import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  courseCard: {
    borderTop: `4px solid ${theme.palette.jasperactiveGreen.main}`,
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  courseLogo: {
    width: '100%',
    height: 'auto',
    maxWidth: 60,
    marginRight: theme.spacing(1),
  },
  cardFooter: {
    '& button': {
      marginLeft: 'auto',
    },
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '0.5em',
  }
}));
