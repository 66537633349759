export default function useLaunchedInIFrame(history) {
  const iframeGroupId = sessionStorage.getItem('iframe_group_id');
  const iframeCourseId = sessionStorage.getItem('iframe_course_id');

  if (iframeGroupId && iframeCourseId) {
    sessionStorage.removeItem('iframe_course_id');
    sessionStorage.removeItem('iframe_group_id');
    localStorage.setItem('loaded_from_iframe', true);
    history.push(`/group/${iframeGroupId}/course/${iframeCourseId}`);
  }

  return null;
}