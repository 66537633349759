import React, { useReducer } from 'react';
import {
  Box,
  MenuItem,
  TextField,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { INSTITUTION_ROLES, studentToLearner } from '../../../lib';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Heading } from '../../common';

const initialFilters = {
  search: '',
  role: 'ALL',
  status: 'ALL',
};

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_SEARCH':
      return {
        ...state,
        search: action.payload,
      };
    case 'UPDATE_ROLE':
      return {
        ...state,
        role: action.payload,
      };
    case 'UPDATE_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'RESET_FILTERS': {
      return initialFilters;
    }
    default:
      return state;
  }
}

const getFilteredMembers = (filters, members) => {
  const regex = new RegExp(filters.search, 'gi');
  let filtered = members.filter(
    (member) => member.name.match(regex) || member.email?.match(regex),
  );
  if (filters.role !== 'ALL') {
    filtered = filtered.filter((member) => member.role === filters.role);
  }
  if (filters.status !== 'ALL') {
    if (filters.status === 'INVITED') {
      filtered = filtered.filter((member) => member.isInvitation);
    } else if (filters.status === 'ACCEPTED') {
      filtered = filtered.filter((member) => !member.isInvitation);
    }
  }

  return filtered;
};

const InstitutionMemberFilter = ({ filters, dispatch }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box p={2} display="flex" flexDirection={isTablet ? 'column' : 'row'}>
      <Box flex={1}>
        <TextField
          fullWidth
          style={{ marginBottom: 8 }}
          variant="outlined"
          size="small"
          label="Search"
          value={filters.search}
          onChange={(e) =>
            dispatch({ type: 'UPDATE_SEARCH', payload: e.target.value })
          }
        />
      </Box>
      <Box
        flex={2}
        display="flex"
        alignItems={isMobile ? 'stretch' : 'center'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box
          flex={1}
          display="flex"
          justifyContent={isMobile ? 'flex-start' : 'flex-end'}
          mb={isMobile ? 2 : 0}
          mr={isMobile ? 0 : 2}
        >
          <FilterListIcon />
          <Heading variant="h4" component="h4">
            Filters
          </Heading>
        </Box>
        <Box flex={1} mb={isMobile ? 2 : 0} mr={isMobile ? 0 : 2}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            select
            label="Role"
            value={filters.role}
            onChange={(e) =>
              dispatch({ type: 'UPDATE_ROLE', payload: e.target.value })
            }
          >
            <MenuItem value="ALL">All</MenuItem>
            {INSTITUTION_ROLES.map((role) => (
              <MenuItem key={role.value} value={role.value}>
                {studentToLearner(role.name)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box flex={1}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            select
            label="Status"
            value={filters.status}
            onChange={(e) =>
              dispatch({ type: 'UPDATE_STATUS', payload: e.target.value })
            }
          >
            <MenuItem value="ALL">All</MenuItem>
            <MenuItem value="INVITED">Invited</MenuItem>
            <MenuItem value="ACCEPTED">Accepted</MenuItem>
          </TextField>
        </Box>
      </Box>
    </Box>
  );
};

const useMemberFilter = (initValues) => {
  const [state, dispatch] = useReducer(
    reducer,
    initValues ? initValues : initialFilters,
  );

  return {
    filters: state,
    dispatch,
    InstitutionMemberFilter,
    getFilteredMembers,
  };
};

export default useMemberFilter;
