import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SEARCH_USERS } from '../../../../apollo/queries';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import UserSearchBar from '../UserSearchBar';
import UserSearchResults from '../UserSearchResults';
import config from '../../manage_jasperactive_config.json';

const JasperactiveUsers = ({ match, history }) => {
  const queryParams = useQueryParams();
  const pageSize = queryParams.get('pageSize') || config.PAGE_SIZE;
  const search = queryParams.get('search') || null;
  let filter = queryParams.get('filter') || null;

  let variables = {};
  if (search) variables.search = search;
  if (pageSize) variables.pageSize = +pageSize;
  if (filter && filter !== 'ALL') variables.filter = filter;

  const { data, loading, error, fetchMore } = useQuery(SEARCH_USERS, {
    variables,
  });

  return (
    <div>
      <UserSearchBar
        data={data}
        loading={loading}
        error={error}
        search={search}
        pageSize={pageSize}
      />
      <UserSearchResults
        data={data}
        loading={loading}
        error={error}
        fetchMore={fetchMore}
        history={history}
        match={match}
      />
    </div>
  );
};

export default JasperactiveUsers;
