import { shuffle } from '../../lib';
import { sum, map } from 'ramda';

export const getAssessmentQuestions = (assessments, assessmentCustomSettings) => {
  let questionsPerLessonByAssessment = [];

  const getQuestionsPerLesson = (questionsSortedByLesson, questionsPerLessonNumber) => {
    const questionsPerLesson = Object.keys(questionsSortedByLesson).reduce((acc, key) => {
      acc = [...acc, ...questionsSortedByLesson[key].slice(0, questionsPerLessonNumber)];
      return acc;
    }, []);

    return questionsPerLesson;
  };

  const sortQuestionsPerLesson = (questions) => {
    const questionsSortedByLesson = questions.reduce((acc, question) => {
      if (acc[question.lesson.id]) {
        acc[question.lesson.id] = [...acc[question.lesson.id], question];
      } else {
        acc[question.lesson.id] = [question];
      }
    
      return acc;
    }, {});

    return questionsSortedByLesson;
  };

  const getQuestionsInAssessment = (assessment) => {
    const questions = assessment.questions.map((question) => {
      if (question.type === "SEQUENCE") {        
        const shuffleAnswers = suffleSequenceAnswers(question);
        let questionClone = {...question};

        questionClone['answers'] = shuffleAnswers;
        return questionClone;
      } else {
        shuffle(question.answers);
        return question;
    
      }
    }).filter((question) => question.lesson !== null);
    shuffle(questions);

    return questions;
  };

  for (const assessment of assessments) {
    if (assessmentCustomSettings) {
      const isAssessmentInAssessmentCustom = assessmentCustomSettings.find((assessmentCustom) => assessmentCustom.assessment_id === assessment.id);

      if (isAssessmentInAssessmentCustom) {
        const questions = getQuestionsInAssessment(assessment);
        const questionsSortedByLesson = sortQuestionsPerLesson(questions);
        const questionsPerLesson = getQuestionsPerLesson(questionsSortedByLesson, isAssessmentInAssessmentCustom.questions_per_lesson);

        questionsPerLessonByAssessment = [...questionsPerLessonByAssessment, ...questionsPerLesson];
      } else {
        const questions = getQuestionsInAssessment(assessment);
        const questionsSortedByLesson = sortQuestionsPerLesson(questions);
  
        if (assessment.questions_per_lesson !== null) {
          const questionsPerLesson = getQuestionsPerLesson(questionsSortedByLesson, assessment.questions_per_lesson);
          questionsPerLessonByAssessment = [...questionsPerLessonByAssessment, ...questionsPerLesson];
        } else {
          questionsPerLessonByAssessment = [...questionsPerLessonByAssessment, ...questions];
        }
      }
    } else {
      const questions = getQuestionsInAssessment(assessment);
      const questionsSortedByLesson = sortQuestionsPerLesson(questions);
      
      if (assessment.questions_per_lesson !== null) {
        const questionsPerLesson = getQuestionsPerLesson(questionsSortedByLesson, assessment.questions_per_lesson);
        questionsPerLessonByAssessment = [...questionsPerLessonByAssessment, ...questionsPerLesson];
      } else {
        questionsPerLessonByAssessment = [...questionsPerLessonByAssessment, ...questions];
      }
    }
  }

  return questionsPerLessonByAssessment;
};

export const getTotalAssessmentTime = (assessments, assessmentCustomSettings) => {
  let assessmentTimes = [];

  for (const assessment of assessments) {
    if (assessmentCustomSettings) {
      const isAssessmentInAssessmentCustom = assessmentCustomSettings.find((assessmentCustom) => assessmentCustom.assessment_id === assessment.id);

      if (isAssessmentInAssessmentCustom) {
        assessmentTimes = [...assessmentTimes, {
          time: isAssessmentInAssessmentCustom.time
        }];
      } else {
        assessmentTimes = [...assessmentTimes, {
          time: assessment.time
        }];
      }
    } else {
      assessmentTimes = [...assessmentTimes, {
        time: assessment.time
      }];
    }
  }

  return sum(map((assessmentTime) => assessmentTime.time, assessmentTimes));
};


export const suffleSequenceAnswers = (question) => {
  let areAnswersShuffle = false;
  let answers = question.answers;

  if (question.answers.length >= 2) {
    const verifyShuffleAnswers = (question) => {
      let orinalAnswers = question.answers;
      let shuffleAnswers = [...orinalAnswers];
      shuffle(shuffleAnswers);
      
      for (let index = 0; index < shuffleAnswers.length; index++) {
        const shuffleAnswer = shuffleAnswers[index];
        const originalAnswer = orinalAnswers[index];
  
        if (shuffleAnswer.id !== originalAnswer.id) {
          areAnswersShuffle = true;
          answers = shuffleAnswers;
          break;
        }
      }
    };
  
    while (!areAnswersShuffle) {
      verifyShuffleAnswers(question);
    };
  }

  return answers;
}
