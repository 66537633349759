import React, { Component, useContext } from 'react';
import AuthService from '../services/AuthService';

export const AuthContext = React.createContext({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => ({}),
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({})
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
  authService;
  constructor(props) {
    super(props);
    this.client = this.props.client;
    this.authService = new AuthService();
  }
  render() {
    return (
      <AuthContext.Provider
        value={{
          signinRedirect: this.authService.signinRedirect,
          authenticated: this.authService.isAuthenticated,
          login: this.authService.signinRedirectCallback,
          logout: async () => {
            await this.client.clearStore();
            localStorage.clear();
            this.authService.logout();
          },
          service: this.authService,
          signinSilentCallback: this.authService.signinSilentCallback
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export const useAuthentication = () => {
  const context = useContext(AuthContext);

  return context;
}
