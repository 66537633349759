import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { Typography } from '@material-ui/core';
import { LoadingScreen } from "../../components";
// import { inIframe } from '../../lib';

const Login = () => {
  const auth = useContext(AuthContext);
  const loadedFromIframe = localStorage.getItem('loaded_from_iframe');

  const urlParams = new URLSearchParams(window.location.search);
  const redirectURL = urlParams.get('url');
  sessionStorage.removeItem('url');
  if (redirectURL) sessionStorage.setItem('url', redirectURL);

  useEffect(() => {
    // if (loadedFromIframe) {

    //   const isIframe = inIframe();

    //   if (!isIframe && window.location.hostname !== 'localhost') {
    //     auth.service.getLogoutUrl().then((data) => {
    //       localStorage.removeItem('loaded_from_iframe');
    //       setTimeout(() => {
    //         window.location.replace(data.url);
    //       }, 3000);
    //     });
    //   }
    //   else {
    //     auth.signinRedirect()
    //   }
    // }
    // else {
    auth.signinRedirect()
    // };
  }, [loadedFromIframe, auth]);

  return (
    <LoadingScreen>
      <Typography align="center" variant="body2">
        Checking Authentication...
      </Typography>
    </LoadingScreen>
  );
};

export default Login;
