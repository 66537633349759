import React, { Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import {
  TextField,
  FormControl,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { GET_FORM_PRODUCTS } from '../../../apollo/queries';
import moment from 'moment';
import { validateLicenseForm } from './helpers';
import { JasperDateInput } from '../../common';

const MAX_USERS = [1, 100, 300, 500, 1000, 1500];

const formatLicense = (license, isActivation, isRenewal) => {
  const initValues = {
    name: license.name,
    max_users: license.max_users,
    is_gmetrix: license.is_gmetrix,
    products: license.products.map((product) => product.id),
    activation_date:
      license.activation_date !== null
        ? moment(license.activation_date)
        : moment(),
    expiry_date: isActivation
      ? license.activation_date !== null
        ? moment(license.activation_date).add(365, 'days')
        : moment().add(365, 'days')
      : isRenewal
        ? moment(
          license.days_to_expire > 0 ? license.expiry_date : new Date(),
        ).add(365, 'days')
        : license.expiry_date,
  };
  return initValues;
};
const LicenseForm = ({
  institution,
  license,
  isQuote,
  isEdit,
  isActivation,
  isRenewal,
  onCancel,
  onSubmit = () => { },
}) => {
  const { data, loading, error } = useQuery(GET_FORM_PRODUCTS);

  const initialValues = license
    ? {
      institution_id: institution.id,
      ...formatLicense(license, isActivation, isRenewal),
    }
    : {
      institution_id: institution.id,
      products: [],
      is_gmetrix: false,
    };

  if (error) return <p>Error...</p>;

  return (
    <Form
      initialValues={initialValues}
      validate={(values) =>
        validateLicenseForm(values, isQuote, isEdit, isActivation, isRenewal)
      }
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h6">License Information</Typography>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Field name="name">
              {({ input, meta }) => (
                <TextField
                  disabled={isActivation || isRenewal}
                  id="license-name"
                  label="Name"
                  value={input.value}
                  onChange={input.onChange}
                  variant="filled"
                />
              )}
            </Field>
          </FormControl>
          {!isRenewal && (
            <FormControl fullWidth style={{ marginBottom: 16 }}>
              <Field name="max_users">
                {({ input, meta }) => (
                  <TextField
                    style={{ flex: 1, marginRight: 16 }}
                    id="max_users"
                    type="text"
                    label="Max Users"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    disabled={isActivation}
                    select
                    value={input.value}
                    onChange={(value) => input.onChange(value)}
                    variant="filled"
                    fullWidth
                  >
                    {MAX_USERS.map((amount) => (
                      <MenuItem key={amount} value={amount}>
                        {amount}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Field>
            </FormControl>
          )}
          {(isActivation || isRenewal || isEdit) && (
            <Fragment>
              {(isActivation || isEdit) && (
                <FormControl fullWidth style={{ marginBottom: 18 }}>
                  <Field name="activation_date" type="date">
                    {({ input, meta }) => (
                      <JasperDateInput
                        variant="filled"
                        inputVariant="filled"
                        label="Start Date"
                        minDate={moment()}
                        value={input.value}
                        onChange={(date) => input.onChange(date)}
                      />
                    )}
                  </Field>
                </FormControl>
              )}
              {isEdit && license && license.expiry_date === null ? null : (
                <FormControl fullWidth style={{ marginBottom: 18 }}>
                  <Field name="expiry_date" type="date">
                    {({ input, meta }) => (
                      <JasperDateInput
                        variant="filled"
                        inputVariant="filled"
                        label={isRenewal ? 'New End Date' : 'End Date'}
                        minDate={moment().add(30, 'days')}
                        value={input.value}
                        onChange={(date) => input.onChange(date)}
                      />
                    )}
                  </Field>
                </FormControl>
              )}

              {(isActivation || isRenewal) && (
                <Fragment>
                  <Typography variant="h6">Confirm Purchase</Typography>
                  <FormControl fullWidth style={{ marginBottom: 6 }}>
                    <Field name="purchase_order">
                      {({ input, meta }) => (
                        <TextField
                          id="confirm_purchase"
                          type="text"
                          label="Purchase Order (Optional)"
                          inputProps={{
                            autoComplete: 'off',
                          }}
                          value={input.value}
                          onChange={(value) => input.onChange(value)}
                          variant="filled"
                        />
                      )}
                    </Field>
                  </FormControl>
                </Fragment>
              )}

              {isActivation && (
                <Typography variant="caption">
                  By activating this license, the institution{' '}
                  <strong>{institution.name} </strong>
                  will now have access to the product(s) assigned to it. It will
                  also notify Accounting, Customer Success and Product
                  Management that a new license has been activated and requires
                  follow-up.
                </Typography>
              )}
              {isRenewal && (
                <Typography variant="caption">
                  By renewing this license, the institution{' '}
                  <strong>{institution.name} </strong>
                  will continue to have access to the product(s) assigned to it.
                </Typography>
              )}
            </Fragment>
          )}
          {(isQuote || isEdit) && (
            <Fragment>
              <Typography variant="h6">Integrations</Typography>

              <FormControl fullWidth style={{ marginBottom: 16 }}>
                <Field name="is_gmetrix" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={license?.is_gmetrix}
                          color="primary"
                          checked={input.checked}
                          onChange={(value) => input.onChange(value)}
                        />
                      }
                      label="GMetrix"
                      labelPlacement="end"
                    />
                  )}
                </Field>
              </FormControl>

              <Typography variant="h6">Products</Typography>

              {loading ? (
                <Box>
                  <p>Loading...</p>
                </Box>
              ) : (
                <FormControl fullWidth>
                  {data.products.map((product, index) => {
                    return (
                      <Field
                        key={product.id}
                        name="products"
                        type="checkbox"
                        value={product.id}
                      >
                        {({ input, meta }) => (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FormControlLabel
                              style={{ marginBottom: 6 }}
                              control={
                                <Checkbox
                                  color="primary"
                                  disabled={
                                    isEdit &&
                                    license.products
                                      .map((product) => product.id)
                                      .includes(product.id)
                                  }
                                  checked={input.checked}
                                  onChange={input.onChange}
                                />
                              }
                              label={product.name}
                              labelPlacement="end"
                            />
                            <img
                              src={product.logo}
                              alt={product.name}
                              width={40}
                              height={40}
                            />
                          </Box>
                        )}
                      </Field>
                    );
                  })}
                </FormControl>
              )}
            </Fragment>
          )}
          <Box display="flex" alignItems="center" mt={2}>
            {onCancel && (
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={(isEdit && pristine) || submitting || !valid}
              style={{ marginLeft: 'auto' }}
            >
              {isActivation
                ? 'Activate License'
                : isRenewal
                  ? 'Renew License'
                  : isEdit
                    ? 'Save Changes'
                    : 'Quote License'}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default LicenseForm;
