import React from 'react';
import { Typography } from '@material-ui/core';

const InputPlaceholder = ({ label, text, ...restProps }) => {
  return (
    <div {...restProps}>
      <Typography variant="subtitle2" gutterBottom>
        {label}
      </Typography>
      <Typography
        style={{
          fontSize: 20,
          padding: 8,
          background: '#EBEBEB',
          borderRadius: 4,
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

export default InputPlaceholder;
