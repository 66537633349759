import React from 'react';
import { Tabs, Tab } from '@material-ui/core';

const LEVELS = [1, 2, 3, 4];

const LevelTabs = ({ course, currentLevel, handleTabChange, selectedLevel }) => {
  if (!course) return null;
  const tabs = LEVELS.slice(0, course.levels);

  return (
    <Tabs
      variant="standard"
      value={ selectedLevel && selectedLevel !== 'all' ? 0 : currentLevel}
      indicatorColor="primary"
      onChange={handleTabChange}
      style={{ marginBottom: 16 }}
    >
      {
        selectedLevel === 'all' ? 
        tabs.map((level) => (
          <Tab key={level} label={`Level ${level}`} />
        )) : <Tab key={selectedLevel} label={`Level ${selectedLevel}`} />
      }
    </Tabs>
  );
};

export default LevelTabs;
