import React, { useState } from 'react';
import {
  Typography,
  Divider,
  Box,
  Collapse,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import styles from './styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const EntityGradeOverview = ({ entity, student }) => {
  const classes = styles();
  const [showResults, setShowResults] = useState(false);

  if (student) {
    return (
      <Box p={3} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2em',
      }}>
        <Box style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
          <Typography variant="h3">
            <Typography
              variant="inherit"
              component="span"
              style={{ color: '#0BF100' }}
            >
              {student.complete ? student.correct_answers : '-'}
            </Typography>
            /{student.complete ? student.correct_answers + student.incorrect_answers : '-'}
          </Typography>
          <Typography variant="overline" color="textSecondary">
            Score
          </Typography>
        </Box>
        <Box style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
          <Typography
            variant="h3">
            {student.complete ? Math.round(student.correct_answers / (student.correct_answers + student.incorrect_answers) * 100) : ' * '}%
          </Typography>
          <Typography variant="overline" color="textSecondary">
            Average Score
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <Box p={2} textAlign="center">
        <Typography variant="h3">
          <Typography
            variant="inherit"
            component="span"
            style={{ color: '#0BF100' }}
          >
            {entity.students.filter((student) => student.complete).length}
          </Typography>
          /{entity.students.length}
        </Typography>
        <Typography variant="overline" color="textSecondary">
          Learners have completed it
        </Typography>
      </Box>
      <Collapse style={{ overflow: 'auto', marginBottom: '1em' }} in={showResults}>
        <Table style={{ marginBottom: 16 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Learner
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Total Questions
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Average Score
                </Typography>
              </TableCell>
              <TableCell align="center">
                <CheckIcon style={{ color: '#0BF100' }} />
              </TableCell>
              <TableCell align="center">
                <CloseIcon style={{ color: '#D32F2F' }} />
              </TableCell>
              <TableCell align="center" >
                Time Spent (mm:ss)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entity.students
              .map((student) => {
                const minutes = student.seconds_spent ? Math.floor(student.seconds_spent / 60) : 0;
                const seconds = student.seconds_spent ? student.seconds_spent - minutes * 60 : 0;

                return (
                  <TableRow key={student.id}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body1">
                        {student.first_name} {student.last_name}
                      </Typography>
                    </TableCell>
                    <TableCell align='center' component="th" scope="row">
                      <Typography variant="body1">
                        {
                          student.complete ? student.correct_answers + student.incorrect_answers : entity.total_questions
                        }
                      </Typography>
                    </TableCell>
                    <TableCell align='center' component="th" scope="row">
                      <Typography variant="body1">
                        {
                          student.complete ? Math.round(student.correct_answers / (student.correct_answers + student.incorrect_answers) * 100) : 0
                        }%
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1">
                        {student.correct_answers}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1">
                        {student.incorrect_answers}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1">
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        <Typography
          component="div"
          variant="caption"
          color="textSecondary"
          align="center"
        >
          showing{' '}
          {entity.students.length} of{' '}
          {entity.students.length} students
        </Typography>
      </Collapse>
      <div>
        <Button
          variant="contained"
          size="small"
          className={classes.showResultButton}
          onClick={() => setShowResults(!showResults)}
        >
          {showResults ? 'Close List' : 'Show Results'}
        </Button>
      </div>
      <Divider />
    </div>
  );
};

export default EntityGradeOverview;