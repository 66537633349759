import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import {
  FREE_LESSONS,
  ADD_FREE_LESSON,
  DELETE_FREE_LESSON,
  CMS_GET_LESSONS,
  ALL_LESSONS,
  GET_LESSON,
} from '../apollo/queries';
import { adopt } from 'react-adopt';

const allLessons = ({ render }) => (
  <Query query={ALL_LESSONS}>
    {({ data: { lessons } = {}, loading, error }) =>
      render({ lessons, loading, error })
    }
  </Query>
);

const cmsLessons = ({ render, courseId }) => (
  <Query query={CMS_GET_LESSONS} variables={{ courseId }} skip={!courseId}>
    {({ data: { freeLessons } = {}, loading, error }) =>
      render({ freeLessons, loading, error })
    }
  </Query>
);

const lessonData = ({ render, lessonId }) => (
  <Query query={GET_LESSON} variables={{ lessonId }} skip={!lessonId}>
    {({ data: { lesson } = {}, loading, error }) =>
      render({ lesson, loading, error })
    }
  </Query>
);

const freeLessons = ({ render }) => (
  <Query query={FREE_LESSONS}>
    {({ data: { freeLessons } = {}, loading, error }) =>
      render({ freeLessons, loading, error })
    }
  </Query>
);

const addFreeLesson = ({ render }) => (
  <Mutation mutation={ADD_FREE_LESSON}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const deleteFreeLesson = ({ render }) => (
  <Mutation mutation={DELETE_FREE_LESSON}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const LessonContainer = adopt({
  lessonData,
  allLessons,
  cmsLessons,
  addFreeLesson,
  deleteFreeLesson,
  freeLessons,
});

export default LessonContainer;
