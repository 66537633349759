import React from 'react';
import { FormLabel } from '@material-ui/core';
import { TinyEditor } from '../../..';

const Feedback = ({ text, input }) => {
  return (
    <>
      <FormLabel style={{ color: 'black', marginBottom: 8 }}>{text}</FormLabel>
      <TinyEditor
        showMediaLibraryButton={true}
        showTinyMCEMediaOptions={true}
        value={input.value}
        onChange={(content) => {
          input.onChange(content);
        }}
      />
    </>
  );
};

export default Feedback;
