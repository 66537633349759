import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  answerControls: {
    display: 'flex',
  },
  answerCard: {
    background: '#e3f2fd',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    boxShadow: 'none',
  },
  addButton: {
    margin: `${theme.spacing(2)}px 0`,
    background: theme.palette.dark.main,
    color: theme.palette.white.main,
  },
}));

export default styles;
