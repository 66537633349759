import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  eBookSection: {
    height: '100%',
    marginTop: theme.spacing(2),
    overflow: 'auto',
  },
  ebookDrawer: {
    width: 346,
  },
  ebookContent: {
    flexGrow: 1,
  },
}));
