import { Card } from '@material-ui/core';
import React from 'react';
import { CloseButton } from '../..';
import styles from './styles';
import AssessmentSettings from './AssessmentSettings';

const CourseSettings = ({handleOpenModalSettings, course, groupId}) => {
  const classes = styles();

  const { benchmarks, summaries, lessons, id, assessmentCustomSettings } = course;

  return (
    <Card className={classes.courseSettings}>
      <div className={classes.courseSettings_header}>
        <h4>Course Settings</h4>
        <CloseButton iconProps={{fontSize: "large"}} color='primary' onClick={handleOpenModalSettings}/>
      </div>
      <div className={classes.courseSettings_main}>
        <AssessmentSettings 
        benchmarks={benchmarks} 
        summaries={summaries}
        lessons={lessons}
        assessmentCustomSettings={assessmentCustomSettings}
        id={id}
        groupId={groupId} />
      </div>
    </Card>
  )
}

export default CourseSettings;