import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  assessmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: 960,
    width: '100%'
  },
  questionWrapper: {
    height: '100%',
    paddingBottom: theme.spacing(7),
  },
  questionContainer: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  questionHeader: {
    background: theme.palette.white.main,
    boxShadow: 'none',
  },
  questionToolbar: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  questionTimer: {
    ...theme.typography.h6,
  },
}));
