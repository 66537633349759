import React, { Fragment } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  bottom: {
    color: '#E0E0E0',
  },
  top: {
    position: 'absolute',
    left: 0,
  },
  inProgress: {
    color: '#F57C00',
  },
  done: {
    color: '#0BF100',
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

const CircularProgressWithLabel = ({
  progress,
  restProps,
  children,
  size = 40,
}) => {
  const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex" alignItems="center">
      <CircularProgress
        size={size}
        className={classes.bottom}
        variant="determinate"
        value={100}
        thickness={5}
      />
      <CircularProgress
        size={size}
        variant="static"
        {...restProps}
        value={progress}
        className={clsx(
          classes.top,
          { [classes.done]: progress === 100 },
          { [classes.inProgress]: progress > 0 && progress < 100 },
        )}
        classes={{
          circle: classes.circle,
        }}
        thickness={5}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {children ? (
          children
        ) : (
          <Fragment>
            {progress === 100 ? (
              <Typography
                style={{ color: '#39363A', fontSize: 12, lineHeight: 16 }}
              >
                {progress}%
              </Typography>
            ) : progress === 0 ? (
              <Typography
                style={{ color: '#39363A', fontSize: 12, lineHeight: 16 }}
              >
                {progress}%
              </Typography>
            ) : progress === undefined ? (
              <Typography
                style={{ color: '#39363A', fontSize: 12, lineHeight: 16 }}
              >
                0%
              </Typography>
            ) : (
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >{`${Math.round(progress)}%`}</Typography>
            )}
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
