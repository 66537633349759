import React from 'react';
import { useRouteMatch } from 'react-router-dom';

export const BenchmarkSettingsContext = React.createContext();

const BenchmarkSettingsProvider = ({ children }) => {
  const { url } = useRouteMatch();

  const isFreeVersion = url.split('/').includes('free');
  const isPreview = url.split('/').includes('preview');

  const state = {
    freeVersion: isFreeVersion,
    previewVersion: isPreview,
  };

  return (
    <BenchmarkSettingsContext.Provider value={state}>
      {children}
    </BenchmarkSettingsContext.Provider>
  );
};

export const BenchmarkSettingsConsumer = BenchmarkSettingsContext.Consumer;

export default BenchmarkSettingsProvider;
