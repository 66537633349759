import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_LESSON_CLIPCARDS } from '../../../../apollo/queries';
import { Heading, LibraryCardsClip } from '../../../../components';
import { Redirect } from 'react-router-dom';
import { LessonMenu } from '..';
import { Box, Button, CircularProgress, Card, Avatar } from '@material-ui/core';
import { useCourse } from '../../providers/CourseProvider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from './styles';
import useSortQuickResourcesAsc from '../../../../hooks/useSortQuickResourcesAsc';

const LearningLibraryClips = ({ match, history }) => {
  const classes = useStyles();
  const course = useCourse();
  const lessonName = match.params.lessonName;
  const lesson = course.lessons.find((lesson) => lesson.name === lessonName);

  const { data, loading, error } = useQuery(GET_LESSON_CLIPCARDS, {
    variables: { lessonId: lesson ? lesson.id : null },
    skip: !lesson,
  });

  const { sortQuickresourceByNumberTitle } = useSortQuickResourcesAsc();

  if (!lesson)
    return (
      <Redirect to={`${match.url.split('/').slice(0, 5).join('/')}/library`} />
    );

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  if (error) return <p>Error...</p>;

  const cards = data.quickClipCards.filter((card) => card.published);

  return (
    <section className={classes.cardSection}>
      <Box mb={2}>
        <Button
          startIcon={<ArrowBackIcon className={classes.icon} />}
          variant="text"
          onClick={() =>
            history.push(
              `${match.url.split('/').slice(0, 5).join('/')}/library`,
            )
          }
        >
          Quickclips
        </Button>
      </Box>
      <Card variant="outlined">
        <Box p={2}>
          <LessonMenu
            lessons={course.lessons}
            history={history}
            currentLesson={lesson}
          />
        </Box>
        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5em'}}>
          <Avatar src={lesson.logo} />
          <Heading variant="h2" component="h2" align="center">
            {lesson.name}
          </Heading>
        </Box>

        <Box style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }} p={2}>
          <LibraryCardsClip cards={sortQuickresourceByNumberTitle(cards)} />
        </Box>
      </Card>
    </section>
  );
};

export default LearningLibraryClips;