import { Card, CardHeader, Grid, useTheme } from '@material-ui/core';
import React from 'react';
import { useInstitutionLicenses } from '../../../apollo/api';
import LicenseCard from '../../license/LicenseCard';
import ReceiptIcon from '@material-ui/icons/Receipt';

const InstitutionLicenseList = ({ institutionId }) => {
  const theme = useTheme();
  const { data, loading } = useInstitutionLicenses(institutionId);

  const licenses = data ? data.licenses : [];

  if (!licenses.length && !loading) {
    return (
      <Card variant="outlined">
        <CardHeader
          avatar={
            <ReceiptIcon
              style={{ fontSize: 48, color: theme.palette.grey[400] }}
            />
          }
          title="You currently have no licenses. Please contact the Sales team for assistance"
          titleTypographyProps={{ variant: 'h3', color: 'textSecondary' }}
        />
      </Card>
    );
  }

  return (
    <Grid container spacing={2}>
      {(loading ? Array.from(new Array(3)) : licenses).map((license, index) => (
        <Grid
          key={license ? license.id : index}
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <LicenseCard license={license} />
        </Grid>
      ))}
    </Grid>
  );
};

export default InstitutionLicenseList;
