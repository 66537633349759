import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  card: {
    height: 350,
    width: '100%',
  },
  cardActionArea: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    boxShadow:'0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
  },
  cardContent: {
    flex: 1,
    maxHeight: 315,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 16,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  cardContentTerm: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardActions: {
    background: theme.palette.grey[100],
    justifyContent: 'center',
  },
}));

export default styles;
