import React from 'react';
import styles from '../styles';
import { Button, Box } from '@material-ui/core';
import clsx from 'clsx';

const AnswerBar = ({
  onClick,
  lastQuestion,
  onSubmit,
  correctAnswer,
  incorrectAnswer,
  incorrectAnswerCount,
  onSaveProgress,
  viewer,
  preview
}) => {
  const classes = styles();

  const getText = () => {
    if (lastQuestion && !incorrectAnswer) return 'Submit Answers';
    if (incorrectAnswer && incorrectAnswerCount < 2) return 'Try Again';
    if (
      (!lastQuestion && correctAnswer) ||
      (incorrectAnswer && incorrectAnswerCount >= 2)
    )
      return 'Continue';
    return 'Check Answer';
  };

  return (
    <Box className={classes.answerBar}>
      <Button
        onClick={onClick}
        variant="outlined"
        color="primary"
        className={clsx(classes.button, classes.projectButton)}
      >
        Show Project
      </Button>

      <Box style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1em',
      }}>
        {
          viewer && !preview ?
            <Button
            onClick={onSaveProgress}
            variant="outlined"
            color="primary"
            disabled={incorrectAnswerCount >= 2}
            className={clsx(classes.button, classes.projectButton)}
            >
              Save progress
            </Button> : null
        }
        <Button
          className={clsx(classes.button)}
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          {getText()}
        </Button>
      </Box>
    </Box>
  );
};

export default AnswerBar;
