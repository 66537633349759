import React, { Fragment } from 'react';
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DeleteButton, EditButton, Heading } from '../../common';
import Skeleton from '@material-ui/lab/Skeleton';
import { readableInstitutionRole, studentToLearner } from '../../../lib';

const TableMemberList = ({
  loading,
  members,
  onDelete,
  onEdit,
  disableButton,
  currentMember,
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Heading variant="h4" component="h4">
              Name
            </Heading>
          </TableCell>
          <TableCell>
            <Heading variant="h4" component="h4">
              Email
            </Heading>
          </TableCell>
          <TableCell>
            <Heading variant="h4" component="h4">
              Role
            </Heading>
          </TableCell>
          <TableCell>
            <Heading variant="h4" component="h4">
              Status
            </Heading>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          [1, 2, 3].map((x) => (
            <TableRow key={x}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>
                    <Skeleton />
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <EditButton onClick={() => {}} />
                    <DeleteButton onClick={() => {}} />
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))
        ) : members.length > 0 ? (
          members.map((member) => (
            <TableRow key={member.email}>
              <TableCell>{member.name}</TableCell>
              <TableCell>{member.email}</TableCell>
              <TableCell>{studentToLearner(readableInstitutionRole(member.role))}</TableCell>
              <TableCell>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>
                    {member.isInvitation ? 'Invited' : 'Accepted'}
                  </Typography>
                  {member.member_id &&
                  member.user_id === currentMember?.user_id ? (
                    <Chip label="Me" color="primary" />
                  ) : (
                    <Box display="flex" alignItems="center">
                      {
                        currentMember?.role === 'CENTER_ADMIN' &&
                          <Fragment>
                            <Tooltip
                              title={
                                disableButton(member) ? `Unable to Edit` : `Edit`
                              }
                            >
                              <div>
                                <EditButton
                                  onClick={() => onEdit(member)}
                                  disabled={disableButton(member)}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip
                              title={
                                disableButton(member) ? `Unable to Delete` : `Delete`
                              }
                            >
                              <div>
                                <DeleteButton
                                  onClick={() => onDelete(member)}
                                  disabled={disableButton(member)}
                                />
                              </div>
                            </Tooltip>
                          </Fragment>
                      }
                    </Box>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan="4">
              <Box display="flex" justifyContent="center">
                <Typography>No Matching Results</Typography>
              </Box>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TableMemberList;
