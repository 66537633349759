import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import {
  PUBLISH_COURSE,
  UNPUBLISH_COURSE,
} from '../../../../../../apollo/queries';
import styles from './styles';

import { withSnackbar } from 'notistack';
const refetchQueries = ['getCourseById'];

const Publish = ({ course, enqueueSnackbar }) => {
  const classes = styles();

  const [published, setPublished] = useState(
    course.published ? 'published' : 'draft',
  );
  const [publishCourse] = useMutation(PUBLISH_COURSE, {
    refetchQueries,
  });
  const [unpublishCourse] = useMutation(UNPUBLISH_COURSE, {
    refetchQueries,
  });

  const handleChange = event => {
    setPublished(event.target.value);
  };

  const handleClick = async () => {
    const isPublished = published === 'published';
    const variables = { id: course.id };
    try {
      if (isPublished) {
        await publishCourse({ variables });
      } else {
        await unpublishCourse({ variables });
      }
      enqueueSnackbar(
        isPublished
          ? `${course.name} set to Published!`
          : `${course.name} set to Draft!`,
        { variant: 'success', autoHideDuration: 1500 },
      );
    } catch (error) {}
  };

  const isPristine = () => {
    if (!course.published && published === 'published') return false;
    if (course.published && published === 'draft') return false;
    return true;
  };

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={3}
        mb={3}
      >
        <Typography variant="h4">Publish</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          disabled={isPristine()}
        >
          Save
        </Button>
      </Box>
      <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.title}>
          Publish Status
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="published"
            name="course-published"
            value={published}
            onChange={handleChange}
          >
            <FormControlLabel
              className={classes.radioButton}
              value="draft"
              control={<Radio color="primary" />}
              label={
                <Box>
                  <Typography variant="body1">Draft</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Students, Instructors and Administrators can't see the
                    content in their Dashboard.
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              value="published"
              control={<Radio color="primary" />}
              label={
                <Box>
                  <Typography variant="body1">Published</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Everyone can see the Course Content in their Dashboard.
                  </Typography>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      </Paper>
    </Container>
  );
};

export default withSnackbar(Publish);
