import gql from 'graphql-tag';
import {
  UserFields,
  AllUserFields,
  InstitutionFields,
} from './fragments';

const AUTHENTICATE_USER = gql`
  mutation authenticate($idToken: String!) {
    authenticate(idToken: $idToken)
  }
`;

const VIEWER_QUERY = gql`
  query viewer {
    viewer {
      id
      first_name
      last_name
      email
      role
      avatar
    }
  }
`;

const USER_INVITATIONS = gql`
  query userInvitations($email: String!) {
    userInvitations(email: $email) {
      id
      first_name
      last_name
      email
      role
      institution {
        ...InstitutionFields
      }
      created_by {
        ...UserFields
      }
      created_on
    }
  }
  ${InstitutionFields}
  ${UserFields}
`;

const GET_USER_INSTITUTIONS = gql`
  query getUserInstitutions($userId: ID!) {
    institutions(where: { user_id: $userId }) {
      id
      name
      licenses {
        id
        name
        expiry_date
        max_users
        slots_used
        days_to_expire
        created_on
        products {
          id
          name
          courses {
            id
            name
            published
          }
        }
      }
      members(where: { user_id: $userId }) {
        id
        user {
          id
        }
        role
      }
    }
  }
`;

const GET_USERS_BY_ROLE = gql`
  query getUserByRole($where: UsersWhereInput!) {
    users(where: $where) {
      id
      first_name
      last_name
    }
  }
`;

const GET_USER = gql`
  query getUser($userId: ID!) {
    user(where: { id: $userId }) {
      ...AllUserFields
    }
  }
  ${AllUserFields}
`;

const SEARCH_USERS = gql`
  query searchUsers(
    $pageSize: Int
    $after: String
    $search: String
    $filter: UserRole
    $noInstitutionOnly: Boolean
  ) {
    paginatedUsers(
      pageSize: $pageSize
      after: $after
      search: $search
      filter: $filter
      noInstitutionOnly: $noInstitutionOnly
    ) {
      cursor
      hasMore
      totalCount
      users {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

const UPDATE_USER = gql`
  mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      ...AllUserFields
    }
  }
  ${AllUserFields}
`;

export {
  AUTHENTICATE_USER,
  VIEWER_QUERY,
  GET_USER,
  GET_USER_INSTITUTIONS,
  GET_USERS_BY_ROLE,
  SEARCH_USERS,
  UPDATE_USER,
  USER_INVITATIONS,
};
