import React, { useState } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  Divider,
  Button,
  Modal,
} from '@material-ui/core';
import styles from './styles';
import clsx from 'clsx';
import SettingsIcon from '@material-ui/icons/Settings';
import CourseSettings from '../CourseSettings';
import { useGroupMember } from '../../../pages/GroupDashboard/providers/GroupMemberProvider';


const CourseCard = ({ course, groupId, onClick, className = null, ...restProps }) => {
  const classes = styles();
  const [settingsModal, setSettingsModal] = useState(false);
  const member = useGroupMember();

  const handleOpenModalSettings = () => {
    if (settingsModal) {
      setSettingsModal(false);
    } else {
      setSettingsModal(true);
    }
  }

  return (
    <Card
      className={clsx(classes.courseCard, { [className]: className !== null })}
      {...restProps}
    >
      <Modal
      open={settingsModal}
      onClose={handleOpenModalSettings}
      >
        <CourseSettings groupId={groupId} course={course} handleOpenModalSettings={handleOpenModalSettings}/>
      </Modal>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <div className={classes.cardTitle}>
            <img
              className={classes.courseLogo}
              src={course.logo}
              alt={course.name}
            ></img>
            <Typography variant="h4">{course.name}</Typography>
          </div>
          <Box mb={2} style={{ minHeight: 71 }}>
            <Typography variant="subtitle2">{course.description}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <Divider />
      {
        member.role === 'MAIN_INSTRUCTOR' ? 
          <Box className={classes.actionButtons}>
            <Button onClick={handleOpenModalSettings} endIcon={<SettingsIcon/>} style={{color: '#3FAE2A'}} variant='content'>settings</Button>
          </Box> : null
      }
    </Card>
  );
};

export default CourseCard;
