import React, { Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import {
  FormControl,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
} from '@material-ui/core';
import { TinyEditor } from '../../common';

const QuickclipForm = ({ card = {}, open, onSubmit, onCancel, lessonId }) => {
  return (
    <Dialog open={open} onClose={onCancel} disableEnforceFocus={true}>
      <DialogTitle>
        {card && card.id ? 'Update' : 'Add'} Quickclip Card
      </DialogTitle>
      <DialogContent>
        <Form
          initialValues={{ ...card, lesson_id: lessonId }}
          onSubmit={onSubmit}
          validate={(values) => {
            let errors = {};

            if (!values.video) errors.video = 'A Video is required';

            return errors;
          }}
          render={({ handleSubmit, valid, pristine, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth style={{ marginBottom: 8 }}>
                  <Field name="title">
                    {({ input, meta }) => (
                      <TextField
                        error={!pristine && Boolean(meta.error)}
                        id="title"
                        label="Title"
                        value={input.value}
                        onChange={(value) => input.onChange(value)}
                        variant="filled"
                        helperText={!pristine && meta.error ? meta.error : ''}
                      />
                    )}
                  </Field>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: 8 }}>
                  <Field name="video">
                    {({ input, meta }) => (
                      <Fragment>
                        <Typography gutterBottom variant="subtitle2">
                          Clip
                        </Typography>
                        <TinyEditor
                          showTinyMCEMediaOptions={true}
                          showMediaLibraryButton={true}
                          value={input.value}
                          onChange={(content) => {
                            input.onChange(content);
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: 8 }}>
                  <Field name="video_with_subtitles">
                    {({ input, meta }) => (
                      <Fragment>
                        <Typography gutterBottom variant="subtitle2">
                          Clip with subtitle
                        </Typography>
                        <TinyEditor
                          showTinyMCEMediaOptions={true}
                          showMediaLibraryButton={true}
                          value={input.value}
                          onChange={(content) => {
                            input.onChange(content);
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                </FormControl>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    type="button"
                    onClick={onCancel}
                    variant="text"
                    color="primary"
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    disabled={submitting || pristine || !valid}
                  >
                    {card && card.id ? 'Update' : 'Create'}
                  </Button>
                </Box>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default QuickclipForm;
