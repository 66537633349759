import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  librarySection: {
    padding: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  flexitem: {
    flex: '0 1 20%',

    [theme.breakpoints.down('sm')]: {
      flex: '0 1 100%',
    },
  },
  card: {
    position: 'relative',
    minWidth: 250,

    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
    },
  },
}));
