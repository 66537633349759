import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';

// Material-ui
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './theme';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

// Custom Providers
import { ViewerProvider } from './providers/ViewerProvider';
import { AuthProvider } from './providers/AuthProvider';
import InstitutionProvider from './providers/InstitutionProvider';

// Apollo
import { ApolloProvider } from '@apollo/react-components';
import client from './apollo';
import UserConfirmationProvider from './providers/UserConfirmationProvider';

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const App = () => (
  <ApolloProvider client={client}>
    <AuthProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ViewerProvider>
            <InstitutionProvider>
              <UserConfirmationProvider>
                <SnackbarProvider
                  ref={notistackRef}
                  maxSnack={1}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  action={(key) => (
                    <Button onClick={onClickDismiss(key)}>
                      <CloseIcon style={{ color: '#FFF' }} />
                    </Button>
                  )}
                >
                  <Router>
                    <Routes />
                  </Router>
                </SnackbarProvider>
              </UserConfirmationProvider>
            </InstitutionProvider>
          </ViewerProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </AuthProvider>
  </ApolloProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
