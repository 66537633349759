import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  courseDashboardSection: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'auto',
  },
  tab:{
    flexGrow:1
  },
  tabs: {
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
  },
}));

export default useStyles;
