import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_GROUP_COURSE } from '../../../apollo/queries';
import { LoadingScreen } from '../../../components';

const CourseContext = React.createContext();

const CourseProvider = ({ courseId, children }) => {
  const { data, loading, error } = useQuery(GET_GROUP_COURSE, {
    variables: { courseId },
  });
  if (loading) return <LoadingScreen />;
  if (error) return <p>Error...</p>;

  return (
    <CourseContext.Provider value={{ course: data.course }}>
      {children}
    </CourseContext.Provider>
  );
};

export const CourseConsumer = CourseContext.Consumer;

export const useCourse = () => {
  const { course } = useContext(CourseContext);
  return course;
};
export default CourseProvider;
