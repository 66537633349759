import React from 'react';
import { Box, Typography, Breadcrumbs } from '@material-ui/core';
import { Heading } from '../../../../components';
import styles from './styles';
import { NavLink, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import { useCourse } from '../../providers/CourseProvider';
import { useGroup } from '../../../GroupDashboard/providers/GroupProvider';
import { inIframe } from '../../../../lib';

function CourseHeader() {
  const classes = styles();
  const location = useLocation();
  const course = useCourse();
  const group = useGroup();

  //flag to watch if is in iframe or not.
  const isIframe = inIframe();

  const isCourseRoute = location.pathname.includes('course');

  return (
    <div className={classes.groupHeader}>
      {
        !isIframe ? 
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink to="/" className={clsx(classes.navlink, classes.homeLink)}>
              <ArrowBackIcon
                fontSize="inherit"
                color="primary"
                style={{ marginRight: 4 }}
              />
              Home
            </NavLink>
            {group &&
              <NavLink to={`/group/${group.id}`} className={clsx(classes.navlink)}>
                {group.name}
              </NavLink>
            }
            <NavLink
              onClick={(e) => {
                e.preventDefault();
              }}
              to={''}
              className={clsx(classes.navlink, { [classes.active]: isCourseRoute })}
            >
              {course.name}
            </NavLink>
          </Breadcrumbs> : null
      }
      <Box display="flex" mt={4} mb={2}>
        <img src={course.logo} style={{ width: 39, marginRight: 8 }} alt="" />
        <Heading variant="h2" component="h2" style={{ fontWeight: 'bold' }}>
          {course.name}
        </Heading>
      </Box>

      <Typography variant="body1" component="div">
        {course.description}
      </Typography>
    </div>
  );
}

export default CourseHeader;
