import React from 'react';
import { Button, TextField, MenuItem, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import styles from './styles';

const PAGE_SIZE = 20;

const InstitutionSearchBar = ({ search, pageSize, setPageSize, setSearch }) => {
  const value = search || '';
  const _pageSize = pageSize;
  const classes = styles();

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const clearSearch = () => {
    setSearch(null);
    setPageSize(PAGE_SIZE);
  };

  return (
    <div className={classes.root}>
      <TextField
        className={classes.searchInput}
        size="small"
        variant="outlined"
        label="Search"
        name="search"
        id="institution-search"
        onChange={handleChange}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        className={classes.viewInput}
        size="small"
        variant="outlined"
        label="View"
        name="pageSize"
        id="pageSize"
        onChange={handlePageSizeChange}
        value={_pageSize}
        select
      >
        {new Array(5).fill(PAGE_SIZE).map((size, index) => (
          <MenuItem key={index} value={size + index * 10}>
            {size + index * 10}
          </MenuItem>
        ))}
      </TextField>
      {(value !== '' || _pageSize !== PAGE_SIZE) && (
        <Button onClick={() => clearSearch()}>Clear</Button>
      )}
    </div>
  );
};

export default InstitutionSearchBar;
