import React, { Fragment } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  useTheme,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import useStyles from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import ReorderIcon from '@material-ui/icons/Reorder';
import JasperactiveLogo from '../../../assets/logos/brand/Jasperactive.svg';
import MainNav from './MainNav';
import { inIframe } from '../../../lib';
import useDrawer from '../../../hooks/useDrawer';
import UserMenu from './UserMenu';
import { useViewer } from '../../../providers/ViewerProvider';


const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { isOpen, toggleDrawer, Drawer} = useDrawer();
  const viewer = useViewer();

  const isIframe = inIframe();

  return (
    <Fragment>
      <AppBar position="fixed" className={classes.appBar} color="default">
        <Toolbar
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        >
          {!isIframe && (isMobile || isTablet) &&
            <IconButton onClick={() => toggleDrawer()}>
              <ReorderIcon color="primary" />
            </IconButton>
          }
          <img
            src={JasperactiveLogo}
            alt="Jasperactive"
            style={{ width: isMobile ? 125 : 175 }}
            onClick={!isIframe ? () => history.push("/") : () => {}}
          />
          {!isIframe && viewer && (
            <Fragment>
              {!isTablet && <MainNav history={history} location={location} />}
            </Fragment>
          )
          }
          {
            viewer ? <UserMenu isIframe={isIframe}/> : <Button onClick={() => history.push('/login')} variant='contained' color='primary' >Sign in</Button>
          }
        </Toolbar>
      </AppBar>
      <Toolbar />
      {
        viewer &&
          (isMobile || isTablet) && (
            <Drawer ModalProps={{keepMounted: true}} open={isOpen} onClose={toggleDrawer} className={classes.drawer} classes={{
              paper: classes.drawerPaper}} style={{ zIndex: 1200 }}>
                <MainNav history={history} location={location} onClose={toggleDrawer} isDrawer />
            </Drawer>
          )
      }
    </Fragment>
  );
};

export default Header;
