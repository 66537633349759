import React, { useContext } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { SalesDashboard, Institution } from './screens';
import styles from './styles';
import { ViewerContext } from '../../providers/ViewerProvider';
import { SALE_ACCESS } from '../../lib';
import { CloudinaryProvider } from '../../providers/CloudinaryProvider';
import AddInstitution from './screens/AddInstitution';

const SalesManagement = ({ match, location }) => {
  const { viewer } = useContext(ViewerContext);
  const classes = styles();

  if (!SALE_ACCESS.includes(viewer.role)) {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  return (
    <section className={classes.root}>
      <CloudinaryProvider>
        <Switch>
          <Route
            path={`${match.path}/institution/add`}
            component={AddInstitution}
          />
          <Route
            path={`${match.path}/institution/:institutionId`}
            component={Institution}
          />
          <Route path={match.path} component={SalesDashboard} />
          <Redirect
            to={location.key ? location.pathname : match.path}
            from="*"
          />
        </Switch>
      </CloudinaryProvider>
    </section>
  );
};

export default SalesManagement;
