import React from 'react';
import { Card, CardActionArea, Avatar, CardHeader } from '@material-ui/core';
import styles from './styles'

const LibraryCard = ({ lesson, onClick }) => {

  const classes = styles();

  return (
    <Card style={{ borderTop: `2px solid ${lesson.course.product.color}` }}  className={classes.card} >
      <CardActionArea onClick={onClick}>
        <CardHeader
          avatar={<Avatar src={lesson.logo} />}
          title={`Lesson-${lesson.display_order} ${lesson.name}`}
        />
      </CardActionArea>
    </Card>
  );
};

export default LibraryCard;
