import gql from 'graphql-tag';
import { ProductFields, AllProductFields } from './fragments';

const GET_FORM_PRODUCTS = gql`
  query formProducts {
    products {
      id
      name
      logo
    }
  }
`;

const GET_FORM_LICENSEPRODUCT_BY_ID_LICENSE = gql`
  query products($where: ProductWhereInput!) {
    products(where: $where) {
      id
      name
      licensedeleted_by
    }
  }
`;

const GET_ALL_PRODUCTS = gql`
  query allProducts {
    products {
      ...ProductFields
    }
  }
  ${ProductFields}
`;

const CMS_GET_ALL_PRODUCTS = gql`
  query allCMSProducts {
    products {
      ...AllProductFields
    }
  }
  ${AllProductFields}
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($data: ProductCreateInput!) {
    createProduct(data: $data) {
      ...ProductFields
    }
  }
  ${ProductFields}
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $data: ProductUpdateInput!
    $where: ProductWhereUniqueInput!
  ) {
    updateProduct(data: $data, where: $where) {
      ...ProductFields
    }
  }
  ${ProductFields}
`;

const GET_PRODUCT_BY_ID = gql`
  query getProductById($where: ProductWhereUniqueInput!) {
    product(where: $where) {
      ...AllProductFields
    }
  }
  ${AllProductFields}
`;

const PUBLISH_PRODUCT = gql`
  mutation publishProduct($id: ID!) {
    publishProduct(id: $id)
  }
`;

const UNPUBLISH_PRODUCT = gql`
  mutation unpublishProduct($id: ID!) {
    unpublishProduct(id: $id)
  }
`;

const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id)
  }
`;

export {
  GET_FORM_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_FORM_LICENSEPRODUCT_BY_ID_LICENSE,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT_BY_ID,
  CMS_GET_ALL_PRODUCTS,
  PUBLISH_PRODUCT,
  UNPUBLISH_PRODUCT,
  DELETE_PRODUCT,
};
