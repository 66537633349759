import React, { Fragment, useState } from 'react';
import { 
  Button, 
  FormControl, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TextField, 
  Typography
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import styles from './styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { 
  CREATE_ASSESSMENT_CUSTOM_SETTINGS, 
  DELETE_ASSESSMENT_CUSTOM_SETTINGS,
  GET_TOTAL_QUESTIONS_NUMBER_BENCHMARK, 
  GET_TOTAL_QUESTIONS_NUMBER_SUMMARY 
} from '../../../../apollo/queries';

import { useSnackbar } from 'notistack';
import { memo } from 'react';

const AssessmentSettings = ({benchmarks, summaries, lessons, id, groupId, assessmentCustomSettings}) => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const [keepDirty, setKeepDirty] = useState(true);

  const [createAssessmentCustomSettings] = useMutation(CREATE_ASSESSMENT_CUSTOM_SETTINGS);
  const [deleteAssessmentCustomSettings] = useMutation(DELETE_ASSESSMENT_CUSTOM_SETTINGS);

  const handleOnSubmitAssessmentSettings = async (values) => {
    setKeepDirty(true);

    let assessmentData = Object.keys(values).reduce((acc, key) => {
      const idsObject = values[key]; //an object with all ids of benchmarks or summaries.

      const items = Object.keys(idsObject).reduce((items, id) => {
        if (idsObject[id].questions_per_lesson !== null) {
          const item = {
            id: id,
            time: (parseInt(idsObject[id].time) * 60),
            questions_per_lesson: parseInt(idsObject[id].questions_per_lesson)
          };
          
          items.push(item);
        }

        return items;
      }, []);

      acc[key] = [...items];

      return acc;
    }, {});


    assessmentData.course_id = id;
    assessmentData.group_id = groupId;

    try {
     await createAssessmentCustomSettings({
        variables: {
          data: assessmentData
        },
        refetchQueries: ['getGroupById', 'getTotalQuestionsNumber']
      });
      enqueueSnackbar(
        `Assessments have been updated!`,
        { variant: 'success', autoHideDuration: 1500 },
      );
    } catch (error) {
      enqueueSnackbar(
        `Oops!, something went wrong. Reload the page and try again`,
        { variant: 'error', autoHideDuration: 4000 },
      );
    }
  };

  const getInitialValues = (data) => {
    const initialValues = data.map((item) => {
      const isItemInAssessmentCustom = assessmentCustomSettings.find((assessmentCustom) => assessmentCustom.assessment_id === item.id);

      if (isItemInAssessmentCustom) {
        return {
          [isItemInAssessmentCustom.assessment_id]: {
            time: isItemInAssessmentCustom.time / 60,
            questions_per_lesson: isItemInAssessmentCustom.questions_per_lesson
          },
        }
      } else {
        return {
          [item.id]: {
            time: item.time / 60,
            questions_per_lesson: item.questions_per_lesson
          },
        }
      }
    }).reduce((acc, item) => {
      acc = {
        ...acc,
        ...item
      }
      return acc;
    }, {});

    return initialValues;
  }

  const handleOnReset = async () => {
    setKeepDirty(false);

    const benchmarkIds = benchmarks.map((benchmark) => {
      return benchmark.id;
    });

    const summariesIds = summaries.map((summary) => {
      return summary.id;
    });

    try {
      await deleteAssessmentCustomSettings({
        variables: {
          data: {
            assessments: benchmarkIds.concat(summariesIds),
            group_id: groupId
          }
        },
        refetchQueries: ['getGroupById', 'getTotalQuestionsNumber']
      });
      enqueueSnackbar(
        `Assessments have been reset!`,
        { variant: 'success', autoHideDuration: 2000 },
      );
    } catch (error) {
      enqueueSnackbar(
        `Oops!, something went wrong. Reload the page and try again`,
        { variant: 'error', autoHideDuration: 4000 },
      );
    }
  }
  
  return (
    <Form
    initialValues={
      {
        benchmarks: {
          ...getInitialValues(benchmarks)
        },
        summaries: {
          ...getInitialValues(summaries)
        }
      }
    }
    onSubmit={handleOnSubmitAssessmentSettings}
    keepDirtyOnReinitialize={keepDirty}
    render={({handleSubmit, pristine, submitting}) => {
      return (
        <form onSubmit={handleSubmit}>
          <Typography variant='h5' className={classes.titleSection} >Assessment settings</Typography>
          <TableContainer component={Paper}>
            <Table size='small' >
              <TableHead>
                <TableRow>
                  <TableCell>Assessment</TableCell>
                  <TableCell>Assessment Time (Minutes)</TableCell>
                  <TableCell>Questions per Lessons (10 maximum)</TableCell>
                  <TableCell align='center'>Total questions in assessment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <BenchmarkAssessmentSettings 
                benchmarks={benchmarks} 
                lessons={lessons}
                assessmentCustomSettings={assessmentCustomSettings}
                groupId={groupId}
                />
                <SummaryAssessmentSettings 
                summaries={summaries} 
                lessons={lessons}
                assessmentCustomSettings={assessmentCustomSettings}
                groupId={groupId}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.assessmentSettings_footer}>
            <Button
            variant='outlined'
            color='primary'
            onClick={handleOnReset}>
              reset
            </Button>
            <Button
            type="submit"
            color='primary' 
            variant='contained'
            disabled={pristine || submitting} >Save</Button>
          </div>
        </form>
      )
    }}
    />
  )
}

const BenchmarkAssessmentSettings = ({benchmarks, lessons, assessmentCustomSettings, groupId}) => {
  return (
    benchmarks.map((benchmark) => {
      const isBenchmarkInAssessmentCustom = assessmentCustomSettings.find((assessmentCustom) => assessmentCustom.assessment_id === benchmark.id);

      if (benchmark.published) {
        return (
          <BenchmarkAssessmentSettingsRowInput 
          key={benchmark.id}
          level={benchmark.level}
          id={benchmark.id}
          questions_per_lesson={isBenchmarkInAssessmentCustom ? isBenchmarkInAssessmentCustom.questions_per_lesson : benchmark.questions_per_lesson}
          lessonsNumber={lessons.length}
          groupId={groupId}
          />
        )
      } else {
        return null;
      }
    })
  )
}

const SummaryAssessmentSettings = ({summaries, lessons, assessmentCustomSettings, groupId}) => {
  return (
    summaries.map((summary) => {
      const isSummaryInAssessmentCustom = assessmentCustomSettings.find((assessmentCustom) => assessmentCustom.assessment_id === summary.id);

      if (summary.published) {
        return (
          <SummaryAssessmentSettingsSRowInput 
          key={summary.id} 
          level={summary.level}
          id={summary.id}
          questions_per_lesson={isSummaryInAssessmentCustom ? isSummaryInAssessmentCustom.questions_per_lesson : summary.questions_per_lesson}
          lessonsNumber={lessons.length}
          groupId={groupId}
          />
        )
      } else {
        return null;
      }
    })
  )
}

const BenchmarkAssessmentSettingsRowInput = ({level, id, groupId}) => {
  const {data} = useQuery(GET_TOTAL_QUESTIONS_NUMBER_BENCHMARK, {
    variables: {
      benchmarkId: id,
      groupId: groupId
    },
    fetchPolicy: 'network-only'
  });

  return (
    <AssessmentSettingsTableRow
    type={'benchmarks'}
    queryData={data}
    level={level}
    id={id}
    />
  )
}

const SummaryAssessmentSettingsSRowInput = ({level, id, groupId}) => {
  const { data } = useQuery(GET_TOTAL_QUESTIONS_NUMBER_SUMMARY, {
    variables: {
      summaryId: id,
      groupId: groupId
    },
    fetchPolicy: 'network-only'
  });

  return (
    <AssessmentSettingsTableRow
    type={'summaries'}
    queryData={data}
    level={level}
    id={id}
    />
  )
}

const AssessmentSettingsTableRow = ({type, queryData, level, id}) => {
  const mustBeNumber = (value) => (isNaN(value) ? "Must be a number" : undefined);
  const required = (value) => (value ? undefined : "Required");
  const maxValue = max => value => isNaN(value) || value <= max ? undefined : `Must be maximum ${max}`
  const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);

  return (
    <TableRow>
      <TableCell align="left">
        {
          type === 'summaries' && `Summary Level ${level}`
        }
        {
          type === 'benchmarks' && `Benchmark Level ${level}`
        }
      </TableCell>
      <TableCell align="left">
        <FormControl fullWidth>
          <Field validate={composeValidators(mustBeNumber, required)} name={`${type}.${id}.time`}>
            {({ input, meta }) => (
              <Fragment>
                <TextField
                  fullWidth
                  id={`${type}.${id}.time`}
                  value={input.value}
                  onChange={(value) => input.onChange(value)}
                  variant="outlined"
                  size='small'
                  type='number'
                  error={meta.error}
                />
                {meta.error && meta.touched && <Typography variant='caption' color='primary' style={{marginTop: '0.1em'}}>{meta.error}</Typography>}
              </Fragment>
            )}
          </Field>
        </FormControl>
      </TableCell >
      <TableCell align="left">
        <FormControl fullWidth>
          <Field validate={composeValidators(mustBeNumber, required, maxValue(10))} name={`${type}.${id}.questions_per_lesson`}>
            {({ input, meta }) => (
              <Fragment>
                <TextField
                  fullWidth
                  id={`${type}.${id}.questions_per_lesson`}
                  value={input.value}
                  onChange={(value) => {
                    input.onChange(value);
                  }}
                  variant="outlined"
                  size='small'
                  type='number'
                  error={meta.error}
                />
                {meta.error && meta.touched && <Typography variant='caption' color='primary' style={{marginTop: '0.1em'}}>{meta.error}</Typography>}
              </Fragment>
            )}
          </Field>
        </FormControl>
      </TableCell>
      <TableCell align="center">
        {
          type === 'summaries' && queryData ? queryData.totalQuestionsSummary : ''
        }
        {
          type === 'benchmarks' && queryData ? queryData.totalQuestionsBenchmark : ''
        }
      </TableCell>
    </TableRow>
  )
}

export default memo(AssessmentSettings);