import { shuffle } from '../../lib';

export const formatAndShuffleReinforcedQuestions = (acc, caseStudy) => {
  const reinforcedQuestions = caseStudy.reinforcedQuestions.map((question) => ({
    isScenario: false,
    ...question,
  }));
  const caseQuestion = [{ isScenario: true, ...caseStudy.case }];
  shuffle(reinforcedQuestions);
  acc = [...acc, ...caseQuestion, ...reinforcedQuestions];
  return acc;
};
