import React, { useState } from 'react';
import { useGroup } from '../../providers/GroupProvider';
import BenchmarkGradebook from './BenchmarkGradebook';
import LessonGradebook from './LessonGradebook';
import GradebookFilters from './GradebookFilters';
import styles from './styles';
import SummaryGradebook from './SummaryGradebook';

const InstructorGradebook = () => {
  const classes = styles();
  const group = useGroup();
  const courses = group.courses.filter((c) => c.published);
  const students = group.members.filter((m) => m.role === 'STUDENT');

  const [selectedCourse, setSelectedCourse] = useState('none');
  const [selectedStudent, setSelectedStudent] = useState('all');
  const [selectedLevel, setSelectedLevel] = useState('all');

  const course =
    selectedCourse !== 'none'
      ? courses.find((course) => course.id === selectedCourse)
      : null;

  return (
    <div className={classes.instructorContainer}>
      <GradebookFilters
        selectedCourse={selectedCourse}
        selectedStudent={selectedStudent}
        courses={courses}
        levels={course?.levels}
        students={students}
        onCourseChange={setSelectedCourse}
        onStudentChange={setSelectedStudent}
        onLevelChange={setSelectedLevel}
        selectedLevel={selectedLevel}
        group={group}//About export excel
      />

      <BenchmarkGradebook
        group={group}
        selectedLevel={selectedLevel}
        selectedCourse={course}
        selectedStudent={selectedStudent}
      />

      <LessonGradebook
        group={group}
        selectedCourse={course}
        selectedLevel={selectedLevel}
        selectedStudent={selectedStudent}
        students={students}
      />

      <SummaryGradebook
        group={group}
        selectedCourse={course}
        selectedLevel={selectedLevel}
        selectedStudent={selectedStudent}
      />
    </div>
  );
};

export default InstructorGradebook;
