import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Divider,
  CardHeader,
  Avatar,
} from '@material-ui/core';
import styles from './styles';

import DoneIcon from '@material-ui/icons/Done';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { CircularProgressBar } from '../../../../components';

const Results = ({ project, studentResults, onClick, preview, tryAgainOnClick, studyProjectNumber }) => {
  const classes = styles();

  const results = studentResults.reduce((acc, result) => {
    acc[result.question_id] = result.result;
    return acc;
  }, {});

  const caseStudies = project.caseStudies;

  const computeScore = (results) => {
    let totalQuestions = Object.keys(results).length;
    let correctAnswers = 0;

    // eslint-disable-next-line
    Object.keys(results).some((key) => {
      if (results[key] === true) {
        correctAnswers++;
      }
    });

    return {
      averageScore: (Math.round((correctAnswers / totalQuestions) * 100)),
      totalQuestions,
      correctAnswers
    }
  }

  return (
    <Box className={classes.resultsContainer}>
      <Box className={classes.resultsContainer_header} >
        <Box display="flex" flexDirection="column" p={2} marginBottom={2}>
          <Typography variant="overline" 
          style={{fontWeight: 'bold', fontSize: 16, color: '#219653', marginBottom: '0.5em'}}>
            Learning Completed
          </Typography>
          <Typography variant="h5" 
          style={{fontWeight: 'bold', fontSize: 25, color: '#4F4F4F', margin: '0.5em 0 0.5em 0'}}>Check Your Results</Typography>
          <Typography variant="overline" color="textSecondary" style={{fontSize: 16, marginBottom: '0.5em'}}>
            Let's Review Your Study Project Results
          </Typography>
          <Typography variant="caption" color="secondary" style={{fontSize: 16}}>
            Study Project {studyProjectNumber}
          </Typography>
          {preview && (
            <Typography variant="caption" color="primary">
              Reminder: No information is recorded during a free lesson
            </Typography>
          )}
        </Box>
        <CardHeader
        avatar={<Avatar src={project.lesson.logo} />}
        title={`Lesson ${project.lesson.display_order}`}
        subheader={project.lesson.name}/>
      </Box>
      <Divider></Divider>
      <Box className={classes.progressBarContainer}>
        <CircularProgressBar correctAnswers={computeScore(results).correctAnswers} showFeedback={true} totalQuestions={computeScore(results).totalQuestions} percentNumber={computeScore(results).averageScore}/>
        {
          computeScore(results).averageScore < 75 && <Button onClick={tryAgainOnClick} className={classes.buttonTryAgain}>Review needed</Button>
        }
      </Box>
      <Box pl={2} pr={2}>
        <Typography style={{color: '#4F4F4F', fontSize: 24, fontWeight: 'bolder', marginBottom: '1em'}}>Details</Typography>
        <Divider></Divider>
        {caseStudies
          .filter((cs) => cs.published)
          .map((caseStudy, index) => (
            <Table size='small' key={caseStudy.id} className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    <Typography  variant="subtitle2">
                      Case Study {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle2">Status</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="body2">Case Question</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <IsCorrect result={results[caseStudy.case.id]} />
                  </TableCell>
                </TableRow>
                {caseStudy.reinforcedQuestions.map((question, index) => (
                  <TableRow key={question.id} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="body2">
                        Question {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <IsCorrect result={results[question.id]} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ))}
      </Box>
      <Box className={classes.submitButtonContainer}>
        <Button
          onV
          className={classes.submitButton}
          onClick={onClick}
          variant="contained"
          color="primary"
        >
          Done
        </Button>
      </Box>
    </Box>
  );
};

const IsCorrect = ({ result }) => {
  return (
    <Box display="flex" alignItems="center">
      {result ? (
        <DoneIcon style={{ color: '#10AC84' }} />
      ) : (
        <CancelOutlinedIcon color="primary" />
      )}
      <Typography
        style={{ marginLeft: 8 }}
        variant="subtitle2"
        color="textSecondary"
      >
        {result ? 'Correct' : 'Incorrect'}
      </Typography>
    </Box>
  );
};

export default Results;
