import React from 'react';
import { Chip } from '@material-ui/core';
import styles from './styles';

const ResourceCountChip = ({ keyElement }) => {
  const classes = styles();

  const handleTypeKeyElement = (type) => {
    switch (type) {
      case 'quickclip':
        return `${keyElement.clip_cards.length} Quick Clip(s)`;
      case 'quickdeck':
        return `${keyElement.cards.length} Quick Deck(s)`;
      default:
        return `${keyElement.pages.length} eBook Page(s)`;
    }
  };

  return (
    <Chip
      className={classes.resourceCountChip}
      label={`${handleTypeKeyElement(keyElement.type)}`}
      color="primary"
    />
  );
};

export default ResourceCountChip;
