import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SEARCH_INSTITUTIONS } from '../../../apollo/queries';
import { Paper } from '@material-ui/core';
import InstitutionSearchBar from './InstitutionSearchBar';
import InstitutionSearchResults from './InstitutionSearchResults';
import Business from '@material-ui/icons/BusinessOutlined';
import { FormToolbar } from '../../common';

const PAGE_SIZE = 20;

const InstitutionSearch = ({ history, match }) => {
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [search, setSearch] = useState(null);

  let variables = {};
  if (search) variables.search = search;
  if (pageSize) variables.pageSize = +pageSize;

  const { data, loading, error, fetchMore } = useQuery(SEARCH_INSTITUTIONS, {
    variables,
  });

  return (
    <Paper style={{ padding: 16 }}>
      <FormToolbar
        title="Institutions"
        leftIcon={<Business style={{ marginRight: '15px' }}></Business>}
        buttonText="Institution"
        onClick={() => history.push(`${match.path}/institution/add`)}
      />

      <InstitutionSearchBar
        search={search}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setSearch={setSearch}
      />
      <InstitutionSearchResults
        data={data}
        loading={loading}
        error={error}
        fetchMore={fetchMore}
        history={history}
        match={match}
      />
    </Paper>
  );
};

export default InstitutionSearch;
