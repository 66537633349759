import React, { useContext, useEffect, useState, Fragment } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { IDENTITY_CONFIG } from '../../utils/authConst';
import { LoadingScreen } from '../../components';
import { Typography } from '@material-ui/core';

/*
Do not use pendo in this component
*/

const IframeLoad = ({ match }) => {
  const auth = useContext(AuthContext);
  const authCode = match.params.authCode;
  const groupId = match.params.groupId;
  const courseId = match.params.courseId;
  const [iframeInfo, setIframeInfo] = useState({
    url: '',
    loadCount: 0,
    message: 'Please wait...',
  });

  useEffect(() => {
    if (iframeInfo.url) {
      if (
        iframeInfo.url.indexOf('/account/autlogout') > -1 &&
        iframeInfo.loadCount === 1
      ) {
        setIframeInfo({
          url: `${IDENTITY_CONFIG.authority}/account/autlogin?ac=${authCode}`,
          loadCount: 0,
          message: 'Logging in...',
        });
      } else if (
        iframeInfo.url.indexOf('/account/autlogin') > -1 &&
        iframeInfo.loadCount === 1
      ) {
        auth.service.UserManager.querySessionStatus()
          .then((sessionInfo) => {
            setTimeout(() => {
              sessionStorage.setItem('iframe_course_id', courseId);
              sessionStorage.setItem('iframe_group_id', groupId);
              window.location.replace('/login');
            }, 1000);
          })
          .catch((err) => {
            console.error('querySessionStatus error:', err);
            setTimeout(() => {
              sessionStorage.setItem('iframe_course_id', courseId);
              sessionStorage.setItem('iframe_group_id', groupId);
              window.location.replace('/login');
            }, 1000);
          });
      }
    } else {
      auth.service.clearLocalStorage();
      setIframeInfo({
        url: `${IDENTITY_CONFIG.authority}/account/autlogout`,
        loadCount: 0,
        message: 'Checking Authentication...',
      });
    }
  }, [
    auth.service,
    iframeInfo.url,
    iframeInfo.loadCount,
    authCode,
    groupId,
    courseId,
  ]);

  return (
    <Fragment>
      <LoadingScreen>
        <Typography align="center" variant="body2">
          {iframeInfo.message}
        </Typography>
      </LoadingScreen>
      <iframe
        src={iframeInfo.url}
        onLoad={() => {
          setIframeInfo({
            url: iframeInfo.url,
            loadCount: iframeInfo.loadCount + 1,
            message: iframeInfo.message,
          });
        }}
        title="Jasperactive iframe"
        style={{ width: '100%', height: '600px' }}
      ></iframe>
    </Fragment>
  );
};

export default IframeLoad;
