import React from 'react';
import FreeLesson from './FreeLesson';
import FreeBenchmark from './FreeBenchmark';
import { Divider, Typography } from '@material-ui/core';
import useStyles from './styles';

const Title = ({ children }) => (
  <Typography variant="h6" gutterBottom>
    {children}
  </Typography>
);

const FreeOffers = () => {
  const classes = useStyles();
  return (
    <section className={classes.freeOfferSection}>
      <Title>Free Course Benchmarks</Title>
      <FreeBenchmark />
      <Divider />
      <Title>Free Lessons</Title>
      <FreeLesson />
    </section>
  );
};

export default FreeOffers;
