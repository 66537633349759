import React from 'react';
import { Paper, Box, Typography, Button, Avatar } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const LessonHeading = ({ lesson, history, ...restProps }) => {
  return (
    <div {...restProps}>
      <Button
        startIcon={<ChevronLeftIcon />}
        variant="text"
        size="small"
        style={{
          color: '#FFF',
          marginBottom: 8,
        }}
        onClick={() => history.push(`/`)}
      >
        Back to Home
      </Button>

      <Paper>
        <Box p={2}>
          <Box display="flex" alignItems="center">
            <Avatar
              src={lesson.logo}
              alt={lesson.name}
              style={{ width: '100%', height: 'auto', maxWidth: 80 }}
            />
            <div>
              <Typography style={{ marginLeft: 8 }} variant="h4">
                {lesson.name}
              </Typography>
              <Typography style={{ marginLeft: 8 }} variant="subtitle2">
                Lesson {lesson.display_order}
              </Typography>
            </div>
          </Box>
          <Box pt={2} pb={2}>
            <Typography variant="subtitle2">{lesson.description}</Typography>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default LessonHeading;
