import React from 'react';
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import styles from './styles';
import clsx from 'clsx';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';

const Intro = ({ onClose, onStart, level, title, introductionText, questions }) => {
  const classes = styles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      maxWidth="lg"
      p={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      justifySelf="center"
      marginTop={isMobile ? 0 : 16}
    >
      <Box p={1} alignSelf="center" justifyContent="center" display="flex">
        <AssessmentOutlinedIcon className={classes.assessmentIcon} />
      </Box>
      <Box p={1} display="flex" alignItems="center" justifyContent="center">
        <Typography className={classes.bechmarkTitle}>
          {level === 'all'
            ? `All Levels ${title}`
            : `Level ${level} ${title}`}
        </Typography>
      </Box>
      <Box p={1}>
        <Typography variant="body1" className={classes.benchmarkDescription}>
          {introductionText}
        </Typography>
      </Box>
      <div className={classes.buttonContainer}>
        <Button
          disabled={questions.length === 0}
          onClick={onStart}
          variant="contained"
          color="primary"
          className={clsx(classes.button, classes.startButton)}
        >
          Start
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
          className={clsx(classes.button, classes.cancelButton)}
        >
          Cancel
        </Button>
      </div>

      <Box p={2}>
        {questions.length === 0 && (
          <Typography variant="caption" color="primary">
            Oops! There are no questions. Please check back later.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Intro;
