export const dateFormatString = 'MM/DD/YYYY';

export const INSTRUCTOR_ACCESS = [
  'CENTER_ADMIN',
  'INSTRUCTOR',
  'MAIN_INSTRUCTOR',
  'DEVELOPER',
  'SYSTEM_ADMIN',
];

export const CMS_ACCESS = [
  'PRODUCT_MANAGER',
  'CONTENT_MANAGER',
  'CONTENT_DEVELOPER',
  'DEVELOPER',
  'SYSTEM_ADMIN',
];

export const SALE_ACCESS = ['SALES_MANAGER', 'DEVELOPER', 'SYSTEM_ADMIN'];

export const CUSTOMER_SUCCESS_ACCESS = [
  'CUSTOMER_SUCCESS',
  'DEVELOPER',
  'SYSTEM_ADMIN',
];

export const TECH_SUPPORT_ACCESS = [
  'TECH_SUPPORT',
  'CUSTOMER_SUCCESS',
  'DEVELOPER',
  'SYSTEM_ADMIN',
];

export const PRODUCT_MANAGER_ACCESS = [
  'PRODUCT_MANAGER',
  'DEVELOPER',
  'SYSTEM_ADMIN',
];

export const MANAGE_JASPERACTIVE_ACCESS = [
  'TECH_SUPPORT',
  'CUSTOMER_SUCCESS',
  'DEVELOPER',
  'SYSTEM_ADMIN',
  'PRODUCT_MANAGER',
];

export const SYS_ADMIN_ACCESS = ['SYSTEM_ADMIN', 'DEVELOPER'];

export const EVERYONE = [
  'USER',
  'STUDENT',
  'CENTER_ADMIN',
  'INSTRUCTOR',
  'MAIN_INSTRUCTOR',
  'PRODUCT_MANAGER',
  'CONTENT_MANAGER',
  'CONTENT_DEVELOPER',
  'SALES_MANAGER',
  'TECH_SUPPORT',
  'CUSTOMER_SUCCESS',
  'SYSTEM_ADMIN',
  'DEVELOPER',
];

export const ALL_USER_ROLES = [
  'USER',
  'SYSTEM_ADMIN',
  'TECH_SUPPORT',
  'CONTENT_MANAGER',
  'CONTENT_DEVELOPER',
  'SALES_MANAGER',
  'CUSTOMER_SUCCESS',
  'DISTRIBUTOR',
  'RESELLER',
  'DEVELOPER',
  'PRODUCT_MANAGER',
];

export const COURSE_DATA = [
  'id',
  'name',
  'logo',
  'levels',
  'product_id',
  'description',
];

export const GROUP_STATUSES = [
  {
    value: 'STARTED',
    name: 'Active',
  },
  {
    value: 'CLOSED',
    name: 'Inactive',
  },
  {
    value: 'ENDED',
    name: 'Expired',
  },
];

export const INSTITUTION_ROLES = [
  {
    value: 'CENTER_ADMIN',
    name: 'Center Administrator',
  },
  {
    value: 'INSTRUCTOR',
    name: 'Instructor',
  },
  {
    value: 'STUDENT',
    name: 'Student',
  },
];

export const GROUP_ROLES = [
  {
    value: 'MAIN_INSTRUCTOR',
    name: 'Main Instructor',
  },
  {
    value: 'INSTRUCTOR',
    name: 'Instructor',
  },
  {
    value: 'STUDENT',
    name: 'Student',
  },
];

export const readableInstitutionRole = (role) => {
  return INSTITUTION_ROLES.filter((r) => r.value === role)[0].name;
};

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
