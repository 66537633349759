import React from 'react';
import { CreateGroupForm, PrimaryButton } from '../../../../components';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import useDialog from '../../../../hooks/useDialog';
import { useCreateGroup } from '../../../../apollo/api';
import { Slide } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddGroup = ({ member, licenses }) => {
  const {
    isOpen,
    toggleDialog,
    Dialog,
    DialogTitle,
    DialogContent,
  } = useDialog();
  const { createGroup } = useCreateGroup();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateGroup = async (values) => {
    let groupData = { ...values };
    delete groupData.createNew;

    await createGroup({
      variables: {
        data: {
          ...groupData,
          group_size: Number(groupData.group_size),
        },
      },
    });
    enqueueSnackbar(`Successfully created group: ${values.name}`, {
      variant: 'success',
    });
    if (!values.createNew) {
      toggleDialog();
    }
  };

  return (
    <>
      <PrimaryButton
        startIcon={<GroupAddIcon />}
        variant="outlined"
        onClick={() => toggleDialog()}
        style={{ background: 'white' }}
      >
        Create Group
      </PrimaryButton>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={toggleDialog}
        TransitionComponent={Transition}
      >
        <DialogTitle onClose={toggleDialog}>Create Group</DialogTitle>
        <DialogContent>
          <CreateGroupForm
            member={member}
            licenses={licenses.filter((license) => license.days_to_expire > 0)}
            onSubmit={handleCreateGroup}
            onCancel={toggleDialog}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddGroup;
