import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SEARCH_LICENSES } from '../../../apollo/queries';
import { Paper, Box, Typography } from '@material-ui/core';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import LicenseSearchBar from './LicenseSearchBar';
import { useViewer } from '../../../providers/ViewerProvider';
import LicenseSearchResults from './LicenseSearchResults';

const PAGE_SIZE = 20;
const DAYS_TO_RENEWAL = 30;

const LicenseSearch = ({ history, match }) => {
  const viewer = useViewer();
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const [daysToRenewal, setDaysToRenewal] = useState(DAYS_TO_RENEWAL);
  const [assignedToMe, setAssignedToMe] = useState(false);

  let variables = {
    filter: {
      daysToRenewal: daysToRenewal,
    },
  };

  if (pageSize) variables.pageSize = +pageSize;
  if (assignedToMe) {
    variables.filter.assignedTo = viewer.id;
  }

  const { data, loading, error, fetchMore } = useQuery(SEARCH_LICENSES, {
    variables,
  });

  return (
    <Paper style={{ padding: 16 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={1}
      >
        <Box display="flex" alignItems="center">
          <AccessAlarmOutlinedIcon
            style={{ marginRight: '15px' }}
          ></AccessAlarmOutlinedIcon>
          <Typography variant="h6" component="span">
            Upcoming Renewals
          </Typography>
        </Box>
      </Box>
      <LicenseSearchBar
        daysToRenewal={daysToRenewal}
        setDaysToRenewal={setDaysToRenewal}
        pageSize={pageSize}
        setPageSize={setPageSize}
        assignedToMe={assignedToMe}
        setAssignedToMe={setAssignedToMe}
      />
      <LicenseSearchResults
        data={data}
        loading={loading}
        error={error}
        fetchMore={fetchMore}
        history={history}
        match={match}
      />
    </Paper>
  );
};

export default LicenseSearch;
