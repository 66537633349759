import gql from 'graphql-tag';
import { BookFields, CMSBookFields, BookPageFields } from './fragments';

const GET_BOOKS = gql`
  query allbooks {
    books {
      ...BookFields
    }
  }
  ${BookFields}
`;

const CMS_GET_BOOKS = gql`
  query allCMSbooks {
    books {
      ...CMSBookFields
    }
  }
  ${CMSBookFields}
`;

const CREATE_BOOK = gql`
  mutation createBook($data: BookCreateInput!) {
    createBook(data: $data) {
      ...CMSBookFields
    }
  }
  ${CMSBookFields}
`;

const GET_BOOK_BY_ID = gql`
  query getBook($id: ID!) {
    book(where: { id: $id }) {
      ...BookFields
    }
  }
  ${BookFields}
`;

const CREATE_BOOK_PAGE = gql`
  mutation createPage($data: BookPageCreateInput!) {
    createPage(data: $data) {
      ...BookPageFields
    }
  }
  ${BookPageFields}
`;

const UPDATE_BOOK_PAGE = gql`
  mutation updatePage(
    $data: BookPageUpdateInput!
    $where: BookPageWhereUniqueInput!
  ) {
    updatePage(data: $data, where: $where) {
      ...BookPageFields
    }
  }
  ${BookPageFields}
`;

const GET_PRODUCT_BOOK = gql`
  query getProductBooks($productId: ID!) {
    book(where: { product_id: $productId }) {
      ...BookFields
    }
  }
  ${BookFields}
`;

const GET_LESSON_PAGES = gql`
  query lessonBookPages($lessonId: ID!) {
    bookPages(where: { lesson_id: $lessonId }) {
      ...BookPageFields
    }
  }
  ${BookPageFields}
`;

export {
  GET_BOOKS,
  CMS_GET_BOOKS,
  CREATE_BOOK,
  GET_BOOK_BY_ID,
  CREATE_BOOK_PAGE,
  UPDATE_BOOK_PAGE,
  GET_PRODUCT_BOOK,
  GET_LESSON_PAGES,
};
