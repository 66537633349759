import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  courseCurriculumSection: {
    height: '100%',
    display: 'flex',
  },
  curriculumDrawer: {
    position: 'relative',
    width: 346,
    height: '100%',
    borderRight: `1px solid ${theme.palette.divider}`,
    boxShadow: '1px 2px 2px #E0E0E0',
  },

  curriculumContent: {
    flexGrow: 1,
    height: '100%',
  },
  curriculumCard: {
    border: `1px solid #CCC`,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default styles;
