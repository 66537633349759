import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import {
  GET_GROUP_COURSE,
  GET_STUDENT_COURSE_PROGRESS,
  CREATE_COURSE,
  UPDATE_COURSE,
  DELETE_COURSE,
  GET_ALL_COURSES,
  FREE_COURSE_BENCHMARKS,
  ADD_FREE_COURSE_BENCHMARK,
  DELETE_FREE_COURSE_BENCHMARK,
} from '../apollo/queries';
import { adopt } from 'react-adopt';

const allCourses = ({ render }) => (
  <Query query={GET_ALL_COURSES}>
    {({ data: { courses } = {}, loading, error }) =>
      render({ courses, loading, error })
    }
  </Query>
);

const courseData = ({ render, courseId }) => (
  <Query query={GET_GROUP_COURSE} variables={{ courseId }} skip={!courseId}>
    {({ data: { course } = {}, loading, error }) =>
      render({ course, loading, error })
    }
  </Query>
);

const studentProgress = ({ render, courseId, userId, groupId }) => (
  <Query
    query={GET_STUDENT_COURSE_PROGRESS}
    fetchPolicy="network-only"
    variables={{ courseId, studentId: userId, groupId }}
    skip={!courseId || !userId || !groupId}
  >
    {({ data: { studentCourseProgress } = {}, loading, error }) =>
      render({ studentCourseProgress, loading, error })
    }
  </Query>
);

const addCourse = ({ render }) => (
  <Mutation mutation={CREATE_COURSE}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);
const updateCourse = ({ render }) => (
  <Mutation mutation={UPDATE_COURSE}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);
const deleteCourse = ({ render }) => (
  <Mutation mutation={DELETE_COURSE}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const freeCourseBenchmarks = ({ render }) => (
  <Query query={FREE_COURSE_BENCHMARKS}>
    {({ data: { freeCourseBenchmarks } = {}, loading, error }) =>
      render({ freeCourseBenchmarks, loading, error })
    }
  </Query>
);

const addFreeCourseBenchmark = ({ render }) => (
  <Mutation mutation={ADD_FREE_COURSE_BENCHMARK}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const deleteFreeCourseBenchmark = ({ render }) => (
  <Mutation mutation={DELETE_FREE_COURSE_BENCHMARK}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const CourseContainer = adopt({
  allCourses,
  freeCourseBenchmarks,
  addFreeCourseBenchmark,
  deleteFreeCourseBenchmark,
  courseData,
  studentProgress,
  addCourse,
  updateCourse,
  deleteCourse,
});

export default CourseContainer;
