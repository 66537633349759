import gql from 'graphql-tag';
import {
  InstitutionFields,
  AllInstitutionFields,
  LicenseFields,
  UserFields,
} from './fragments';

const GET_ALL_INSTITUTIONS = gql`
  query getAllInstitutions {
    institutions {
      id
      name
      address
      licenses {
        id
        name
      }
      createdBy {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

const GET_INSTITUTION_BY_ID = gql`
  query getInstitutionById($where: InstitutionWhereUniqueInput!) {
    institution(where: $where) {
      ...AllInstitutionFields
    }
  }
  ${AllInstitutionFields}
`;

const CREATE_INSTITUTION = gql`
  mutation createInstitution($data: InstitutionCreateInput!) {
    createInstitution(data: $data) {
      id
      name
      logo
      country_code
      state_code
      sales_manager {
        id
        first_name
        last_name
        email
      }
      members {
        user {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
`;

const UPDATE_INSTITUTION = gql`
  mutation updateInsitution(
    $data: InstitutionUpdateInput!
    $where: InstitutionWhereUniqueInput!
  ) {
    updateInstitution(data: $data, where: $where) {
      ...InstitutionFields
    }
  }
  ${InstitutionFields}
`;

const GET_INSTITUTION_LICENSES = gql`
  query institutionLicenses($institutionId: ID!) {
    licenses(where: { institution_id: $institutionId }) {
      ...LicenseFields
    }
  }
  ${LicenseFields}
`;

const SEARCH_INSTITUTIONS = gql`
  query searchInstitutions($pageSize: Int, $after: String, $search: String) {
    paginatedInstitutions(pageSize: $pageSize, after: $after, search: $search) {
      cursor
      hasMore
      totalCount
      institutions {
        ...InstitutionFields
        licenses {
          id
          name
          activation_date
          expiry_date
          days_to_expire
        }
      }
    }
  }
  ${InstitutionFields}
`;

const GET_INSTITUTION_ACTIVITES = gql`
  query getInstitutionActivities(
    $pageSize: Int
    $after: String
    $institution_id: ID!
  ) {
    institutionActivities(
      pageSize: $pageSize
      after: $after
      institution_id: $institution_id
    ) {
      cursor
      hasMore
      totalCount
      activities {
        id
        activity
        created_on
        user {
          id
          avatar
          first_name
          last_name
        }
      }
    }
  }
`;

const GET_INSTITUTION_COMMENTS = gql`
  query getInstitutionComments(
    $pageSize: Int
    $after: String
    $institution_id: ID!
  ) {
    institutionComments(
      pageSize: $pageSize
      after: $after
      institution_id: $institution_id
    ) {
      cursor
      hasMore
      totalCount
      comments {
        id
        comment
        created_on
        user {
          id
          avatar
          first_name
          last_name
        }
      }
    }
  }
`;

const ADD_INSTITUTION_COMMENT = gql`
  mutation addInstitutionComment($data: InstitutionCreateCommentInput!) {
    createInstitutionComment(data: $data) {
      id
      comment
      created_on
      user {
        id
        avatar
        first_name
        last_name
      }
    }
  }
`;

export {
  GET_ALL_INSTITUTIONS,
  GET_INSTITUTION_ACTIVITES,
  GET_INSTITUTION_COMMENTS,
  GET_INSTITUTION_BY_ID,
  CREATE_INSTITUTION,
  UPDATE_INSTITUTION,
  GET_INSTITUTION_LICENSES,
  SEARCH_INSTITUTIONS,
  ADD_INSTITUTION_COMMENT,
};
