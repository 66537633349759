import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    maxHeight: 350,
    overflowY: 'scroll',
    width: '100%',
    maxWidth: '100%',
    //backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    //borderLeft: "5px solid transparent",
    display: 'inline',
    overflow: 'auto',
  },
  selected: {
    //borderLeftColor: theme.palette.primary.main,
  },
  chipInfomation: {
    /* Layout Propieties*/
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    right: '0px',

    /* UI Properties*/
    textAlign: 'center',
    letterSpacing: '0.3px',
    color: '#FFFFFF',
    opacity: 1,
    marginRight: '16px',
  },
  FragmentSplit: {
    height: '2px',
  },
  days90: {
    color: '#4CAF50',
  },
  days60: {
    color: '#FF9800',
  },
  days30: {
    color: '#F44336',
  },
}));
