import React, { useState, Fragment, useMemo } from 'react';
import {
  Typography,
  Divider,
  Box,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {
  LevelTabs,
  CircularProgressWithLabel,
} from '../../../../components';
import { GET_ALL_LESSON_RESULTS } from '../../../../apollo/queries';
import { useQuery } from '@apollo/react-hooks';
import GradeOverviewCard from './GradeOverviewCard';
import Paper from '@material-ui/core/Paper';
import styles from './styles';

const getStudentLessonLevelProgress = (studentResults) => {
  return Math.round(
    (studentResults.filter((sr) => sr.lesson_progress === 100).length /
      studentResults.length) *
    100,
  );
};

const getStudentLessonLevelCompleteCount = (studentResults) => {
  const count = studentResults.filter((sr) => sr.lesson_progress === 100)
    .length;

  return count === 0 ? '-' : count;
};

const LessonGradebook = ({ selectedCourse, selectedStudent, selectedLevel, group, students }) => {
  const [currentLevel, setCurrentLevel] = useState(0);
  const classes = styles();

  const handleTabChange = (event, newValue) => {
    setCurrentLevel(newValue);
  };

  const { data, loading, error } = useQuery(GET_ALL_LESSON_RESULTS, {
    variables: {
      courseId: selectedCourse ? selectedCourse.id : null,
      groupId: group.id,
    },
    skip: !selectedCourse || !group,
    fetchPolicy: 'no-cache',
  });

  const getLessonsData = (data, selectedLevel, currentLevel) => {
    return data
      ? data.instructorLessonGradebookOverview.filter(
        (lessonResult) => lessonResult.level === (selectedLevel && selectedLevel !== 'all' ? selectedLevel : currentLevel + 1),
      )
      : [];
  }

  const lessons = useMemo(() => getLessonsData(data, selectedLevel, currentLevel), [selectedLevel, currentLevel, data]);

  if (error) return <p>Error...</p>;

  const getStudentName = (students, selectedStudent) => {
    const student = students.find((student) => student.user.id === selectedStudent);

    if (student) {
      return `${student.user.first_name} ${student.user.last_name}`;
    } else {
      return 'Lesson - Select a student to see the progress'
    }
  }

  return (
    <GradeOverviewCard className={classes.entityOverviewCard} title={getStudentName(students, selectedStudent)} noCourseSelected={!selectedCourse}>
      {loading && (
        <Box p={6} textAlign="center">
          <CircularProgress size={60} />
        </Box>
      )}
      {selectedCourse && selectedStudent !== 'All' && !loading && (
        <Fragment>
          <LevelTabs
            course={selectedCourse}
            currentLevel={currentLevel}
            handleTabChange={handleTabChange}
            selectedLevel={selectedLevel}
          />
          <Box>
            {lessons.map((lesson) => (
              <LessonGradeOverviewRow
                key={lesson.id}
                lesson={lesson}
                selectedStudent={selectedStudent}
              />
            ))}
          </Box>
        </Fragment>
      )}
    </GradeOverviewCard>
  );
};

export default LessonGradebook;

const LessonGradeOverviewRow = ({ lesson, selectedStudent }) => {
  const classes = styles();

  const student =
    selectedStudent === 'all'
      ? null
      : lesson.student_results.find((sr) => sr.student_id === selectedStudent);

  return (
    <Fragment key={lesson.id}>
      <Box
        p={2}
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-start"
        gridGap='2em'
      >
        <Box flex={0.2}>
          <Typography variant="body1">Lesson {lesson.display_order}</Typography>
          <Typography variant="caption" color="textSecondary">
            {lesson.name}
          </Typography>
        </Box>
        {
          student &&
          <div style={{ flex: 0.8, overflow: 'auto' }}>
            <TableContainer component={Paper} style={{ width: 'fit-content' }} >
              <Table size='small' className={classes.table_lessons} >
                <TableHead style={{ background: '#3fae2a' }}>
                  <TableRow>
                    <TableCell style={{ color: '#ffffff' }} align='center' >Lesson</TableCell>
                    {
                      student.study_project_results.sort(function(a, b){return a.study_project_number-b.study_project_number}).map((studyProject, index) => {
                        return (
                          <TableCell align='center' style={{ color: '#ffffff' }} key={studyProject.study_project_id}>Study Project {index + 1}</TableCell>
                        )
                      })
                    }
                  </TableRow>
                </TableHead>
                <TableBody className={classes.table_lessons} >
                  <TableRow>
                    <TableCell>
                      <Table size='small' >
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ border: 'none' }} align='center' >Progress</TableCell>
                            <TableCell style={{ border: 'none' }} align='center' >Score</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <LessonProgress student={student} />
                            <ScorePercent student={student} />
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                    {
                      student.study_project_results.sort(function(a, b){return a.study_project_number-b.study_project_number}).map((studyProject) => {
                        return (
                          <TableCell key={studyProject.study_project_id}>
                            <Table size='small' >
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ border: 'none' }} align='center' >Progress</TableCell>
                                  <TableCell style={{ border: 'none' }} align='center' >Score</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell align='center' style={{ border: 'none' }}>
                                    {
                                      studyProject.complete && '100%'
                                    }
                                    {
                                      studyProject.started && !studyProject.complete && `${Math.round((studyProject.total_questions_in_progress / studyProject.total_questions) * 100)}%`
                                    }
                                    {
                                      !studyProject.started && !studyProject.complete && '0%'
                                    }
                                  </TableCell>
                                  <TableCell align='center' style={{ border: 'none' }}>
                                    {
                                      studyProject.complete && `${studyProject.correct_answers}/${studyProject.total_questions} - ${Math.round((studyProject.correct_answers / studyProject.total_questions) * 100)}%`
                                    }
                                    {
                                      studyProject.started && !studyProject.complete && `In Progress`
                                    }
                                    {
                                      !studyProject.started && !studyProject.complete && `0/${studyProject.total_questions} - 0%`
                                    }
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        }
        {
          !student ?
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <CircularProgressWithLabel
                size={45}
                progress={
                  student
                    ? student.lesson_progress
                    : getStudentLessonLevelProgress(lesson.student_results)
                }
              >
                {!student && (
                  <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                    {getStudentLessonLevelCompleteCount(lesson.student_results)}/
                    {lesson.student_results.length}
                  </Typography>
                )}
              </CircularProgressWithLabel>
              <Typography variant="caption" color="textSecondary">
                {
                  student ? 'Progress' : 'Learners have completed it'
                }
              </Typography>
            </Box> : null
        }
      </Box>
      <Divider />
    </Fragment>
  );
};

const ScorePercent = ({ student }) => {
  let totalCorrectAnswers;
  let percent;
  let totalQuestions;

  if (student) {
    totalCorrectAnswers = student.study_project_results.reduce((acc, studyProjectResult) => {
      return acc += studyProjectResult.correct_answers;
    }, 0);

    totalQuestions = student.study_project_results.reduce((acc, studyProjectResult) => {
      return acc += studyProjectResult.total_questions;
    }, 0);

    percent = Math.round((totalCorrectAnswers / totalQuestions) * 100);
  }

  return (
    <TableCell align='center' style={{ border: 'none' }}>
      {`${totalCorrectAnswers}/${totalQuestions}`} - {Math.round(percent)}%
    </TableCell>
  );
};

const LessonProgress = ({ student }) => {
  let progress = 0;

  if (student) {
    progress = student.study_project_results.reduce((acc, studyProject) => {
      if (studyProject.complete) {
        acc += 100;
      } else if (studyProject.started && !studyProject.complete) {
        acc += Math.round((studyProject.total_questions_in_progress / studyProject.total_questions) * 100);
      } else {
        acc += 0;
      }
      return acc;
    }, 0)
  }

  return (
    <TableCell align='center' style={{ border: 'none' }}>
      {Math.round((progress / student.study_project_results.length))}%
    </TableCell>
  );
}