import React, { Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import {
  FormControl,
  TextField,
  Button,
  MenuItem,
  Slider,
  Typography,
  Box,
} from '@material-ui/core';
import { GET_FORM_PRODUCTS } from '../../../apollo/queries';
import { useQuery } from '@apollo/react-hooks';
import styles from './styles';
import { CloudinaryImageWidget } from '../../common';

const COURSE_LEVELS = [
  { value: 2, label: '2 levels' },
  { value: 3, label: '3 levels' },
  { value: 4, label: '4 levels' },
];

const CourseForm = ({ course, productId, onCancel, onSubmit }) => {
  const classes = styles();

  const { data, loading, error } = useQuery(GET_FORM_PRODUCTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  return (
    <Form
      initialValues={
        course
          ? course
          : {
              product_id: productId,
              levels: 2,
            }
      }
      onSubmit={onSubmit}
      validate={() => {}}
      render={({ handleSubmit, pristine, valid, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            {productId && (
              <FormControl fullWidth style={{ marginBottom: 16 }}>
                <Typography variant="subtitle2">
                  {data.products.find((p) => p.id === productId).name} Course:
                </Typography>
              </FormControl>
            )}
            <FormControl fullWidth style={{ marginBottom: 16 }}>
              <Field name="name">
                {({ input, meta }) => (
                  <Fragment>
                    <TextField
                      id="name"
                      label="Course Name"
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      variant="filled"
                    />
                  </Fragment>
                )}
              </Field>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Field name="levels">
                  {({ input, meta }) => (
                    <Box display="block" width="100%">
                      <Typography variant="caption">
                        Performance Levels
                      </Typography>
                      <Box pl={3} pr={3}>
                        <Slider
                          marks={COURSE_LEVELS}
                          getAriaValueText={(value) => `${value} levels`}
                          value={input.value}
                          onChange={(event, value) => {
                            input.onChange(value);
                          }}
                          step={1}
                          min={2}
                          max={4}
                        />
                      </Box>
                    </Box>
                  )}
                </Field>
              </div>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 16 }}>
              <Field name="logo">
                {({ input, meta }) => (
                  <CloudinaryImageWidget
                    multiple={false}
                    maxFiles={1}
                    acceptedFormat={'svg'}
                    files={input.value}
                    buttonLabel="Course Logo"
                    onInsert={(files) => {
                      input.onChange(files);
                    }}
                  />
                )}
              </Field>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 16 }}>
              <Field name="description">
                {({ input, meta }) => (
                  <TextField
                    id="description"
                    label="Course description"
                    multiline
                    rows="4"
                    value={input.value}
                    onChange={(event) => {
                      if (event.target.value.length > 150) return;
                      input.onChange(event);
                    }}
                    variant="outlined"
                    helperText="Include a brief description of your course. Max 150 characters"
                  >
                    {data.products.map((product) => (
                      <MenuItem key={product.id} value={product.id}>
                        {product.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Field>
            </FormControl>
            <FormControl style={{ marginBottom: 16 }}>
              <Field name="display_order">
                {({ input, meta }) => (
                  <Fragment>
                    <TextField
                      id="display_order"
                      label="Order"
                      type='number'
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      variant="filled"
                    />
                  </Fragment>
                )}
              </Field>
              <Typography variant='caption' >Order in which the course should appear for Learners</Typography>
            </FormControl>

            <div className={classes.buttonFormControl}>
              {onCancel && (
                <Button
                  type="button"
                  variant="text"
                  onClick={() => onCancel()}
                  color="primary"
                >
                  Cancel
                </Button>
              )}
              <Button
                type="submit"
                variant="text"
                color="primary"
                disabled={pristine || submitting || !valid}
              >
                {course ? 'Save' : 'Create'}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default CourseForm;
