import React, { Fragment, useState } from 'react';
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core';
import styles from './styles';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import { isQuestionAnswered } from '../../../../lib';

const BenchmarkTestOverview = ({
  onQuestionClick,
  pauseTimer,
  showResultsPage,
  enqueueSnackbar,
  questions,
  userAnswer,
  submitEntity
}) => {
  const classes = styles();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      pauseTimer();
      await submitEntity();
      showResultsPage();
    } catch (error) {
      enqueueSnackbar(
        'Oops! Something went wrong. Please try again later! If problem persists try logging out and back in or contact Tech Support.',
        { variant: 'error' },
      );
    }
  };

  return (
    <Fragment>
      <Box p={2} mt={8}>
        <Typography className={classes.reviewTitle}>
          Select the question you want to review.
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Questions</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((question, index) => (
              <TableRow key={question.id}>
                <TableCell>
                  <Typography variant="body2">Question {index + 1}</Typography>
                </TableCell>
                <TableCell>
                  {
                    <Answered
                      isAnswered={isQuestionAnswered(
                        question.type,
                        userAnswer[question.id],
                      )}
                      onQuestionClick={onQuestionClick}
                      index={index}
                    />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Toolbar />
      </Box>

      <AppBar className={classes.appBar}>
        <Toolbar className={classes.answerBar}>
          <Button
            disabled={submitting}
            className={classes.button}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

const Answered = ({ isAnswered, classes, onQuestionClick, index }) => (
  <Box display="flex" alignItems="center" width="50%" flex={1}>
    <Box p={1} flex={0.1}>
      {isAnswered ? (
        <DoneIcon style={{ color: '#10AC84' }} />
      ) : (
        <WarningIcon style={{ color: '#F57C00' }} />
      )}
    </Box>
    <Box
      p={1}
      flex={0.4}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="subtitle2" color="textSecondary">
        {isAnswered ? 'Answered' : 'Unanswered'}
      </Typography>
    </Box>
    <Box p={1} flex={0.4}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => onQuestionClick(index)}
      >
        Review
      </Button>
    </Box>
  </Box>
);

export default BenchmarkTestOverview;
