import React, { Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import { FormControl, TextField, Button, MenuItem } from '@material-ui/core';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import StarsIcon from '@material-ui/icons/Stars';
import { useQuery } from '@apollo/react-hooks';
import {
  GET_COUNTRIES_AND_STATES,
  GET_USERS_BY_ROLE,
} from '../../../apollo/queries';
import { validateInstitution } from './helpers';
import styles from './styles';
import { CloudinaryImageWidget } from '../../common';

const formatInstitutionData = (institution) => {
  return {
    id: institution.id,
    name: institution.name,
    logo: institution.logo,
    country_code: institution.country_code,
    state_code: institution.state_code,
    sales_manager: institution.sales_manager
      ? institution.sales_manager.id
      : null,
    address: institution.address,
    city: institution.city,
  };
};

const InstitutionForm = ({ institution, onSubmit = () => {} }) => {
  const classes = styles();
  const {
    data: dataCountries,
    loading: loadingCountries,
    error: errorCountries,
  } = useQuery(GET_COUNTRIES_AND_STATES);
  const {
    data: dataSalesManager,
    loading: loadingSalesManager,
    error: errorSalesManager,
  } = useQuery(GET_USERS_BY_ROLE, {
    variables: { where: { role: ['SALES_MANAGER', 'SYSTEM_ADMIN'] } },
  });

  if (errorCountries || errorSalesManager) {
    return <p>error...</p>;
  }

  const currentInstitution = institution
    ? formatInstitutionData(institution)
    : {};
  const countries = dataCountries ? dataCountries.countries : [];
  const salesManagers = dataSalesManager ? dataSalesManager.users : [];

  return (
    <Form
      initialValues={currentInstitution}
      validate={(values) => validateInstitution(values, countries)}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, submitting, pristine, valid }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="center" alignItems="center">
              {values.logo && values.logo !== 'Empty' ? (
                <img
                  alt="Institution Logo"
                  src={values.logo}
                  style={{
                    maxWidth: '65%',
                    marginBottom: '15px',
                    height:
                      values.logo &&
                      values.logo.toLowerCase().indexOf('svg') > -1
                        ? '250px'
                        : 'auto',
                  }}
                />
              ) : (
                <StarsIcon
                  className={classes.logoInstitutionFrm}
                  color="primary"
                  style={{
                    fontSize: 120,
                    marginBottom: '15px',
                  }}
                ></StarsIcon>
              )}
            </Box>
            <FormControl
              fullWidth
              style={{
                marginBottom: 16,
              }}
            >
              <Field name="logo">
                {({ input, meta }) => (
                  <CloudinaryImageWidget
                    multiple={false}
                    maxFiles={1}
                    acceptedFormat={'jpg, png, bmp, svg'}
                    files={input.value}
                    buttonLabel="Institution Logo"
                    showImageList={false}
                    cloudinaryFolder="Institution Logos"
                    onInsert={(files) => {
                      if (files) {
                        input.onChange(files);
                      }
                    }}
                  />
                )}
              </Field>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 16 }}>
              <Field name="name">
                {({ input, meta }) => (
                  <TextField
                    onChange={input.onChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={input.value}
                    label="Name"
                    variant="filled"
                  />
                )}
              </Field>
            </FormControl>
            {loadingCountries ? (
              <Fragment>
                <Skeleton
                  variant="rect"
                  height={56}
                  width="100%"
                  style={{ marginBottom: 16, borderRadius: 4 }}
                />
                <Skeleton
                  variant="rect"
                  height={56}
                  width="100%"
                  style={{ marginBottom: 16, borderRadius: 4 }}
                />
              </Fragment>
            ) : (
              <Fragment>
                <FormControl fullWidth style={{ marginBottom: 16 }}>
                  <Field name="country_code">
                    {({ input, meta }) => (
                      <TextField
                        id="country_code"
                        select
                        type="text"
                        label="Country"
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        value={input.value}
                        onChange={(value) => input.onChange(value)}
                        variant="filled"
                      >
                        {countries.map((item) => (
                          <MenuItem
                            key={item.country_code}
                            value={item.country_code}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {item.country_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: 16 }}>
                  <Field name="state_code">
                    {({ input, meta }) => (
                      <TextField
                        disabled={
                          !values.country_code ||
                          countries.find(
                            (c) => c.country_code === values.country_code,
                          ).states.length === 0
                        }
                        id="state_code"
                        select
                        style={{ textTransform: 'capitalize', flex: 1 }}
                        type="text"
                        label="State/Province"
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        value={input.value}
                        onChange={(value) => input.onChange(value)}
                        variant="filled"
                      >
                        {values.country_code ? (
                          countries
                            .find((c) => c.country_code === values.country_code)
                            .states.map((item) => (
                              <MenuItem
                                key={item.state_code}
                                value={item.state_code}
                                style={{ textTransform: 'capitalize' }}
                              >
                                {item.state_name}
                              </MenuItem>
                            ))
                        ) : (
                          <MenuItem value="NO_STATE">No State</MenuItem>
                        )}
                      </TextField>
                    )}
                  </Field>
                </FormControl>
              </Fragment>
            )}

            <FormControl fullWidth style={{ marginBottom: 16 }}>
              <Field name="address">
                {({ input, meta }) => (
                  <TextField
                    onChange={input.onChange}
                    value={input.value}
                    label="Address"
                    variant="filled"
                  />
                )}
              </Field>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 16 }}>
              <Field name="city">
                {({ input, meta }) => (
                  <TextField
                    onChange={input.onChange}
                    value={input.value}
                    label="City"
                    variant="filled"
                  />
                )}
              </Field>
            </FormControl>
            {loadingSalesManager ? (
              <Skeleton
                variant="rect"
                height={56}
                width="100%"
                style={{ marginBottom: 16, borderRadius: 4 }}
              />
            ) : (
              <FormControl fullWidth style={{ marginBottom: 16 }}>
                <Field name="sales_manager">
                  {({ input, meta }) => (
                    <TextField
                      select
                      style={{ textTransform: 'capitalize' }}
                      type="text"
                      label="Assigned Sales Manager"
                      inputProps={{ autoComplete: 'off' }}
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      variant="filled"
                    >
                      {salesManagers.length !== 0 ? (
                        salesManagers.map((manager) => (
                          <MenuItem
                            key={manager.id}
                            value={manager.id}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {`${manager.first_name} ${manager.last_name}`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={null}>No Assigned</MenuItem>
                      )}
                    </TextField>
                  )}
                </Field>
              </FormControl>
            )}

            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                disabled={pristine || submitting || !valid}
                variant="contained"
                color="primary"
              >
                {Boolean(institution) ? 'Save Changes' : 'Save'}
              </Button>
            </Box>
          </form>
        );
      }}
    />
  );
};

export default InstitutionForm;
