import React, { Fragment, useState, useEffect, useCallback } from 'react';
import {
  DrawerShell,
  DarkChip,
  EditorText,
} from '../../../../../../components';
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  Slider,
  Button,
  Chip,
  Avatar,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  CMS_GET_LESSONS,
  GET_PRODUCT_BOOK,
  SET_LESSON_PAGES,
} from '../../../../../../apollo/queries';
import styles from './styles';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import { sum } from 'ramda';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const DevelopCourseBook = ({ course }) => {
  const classes = styles();
  //#region validates the breakpoint and get a bolean result
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm')); 
  //#endregion
  const { data, loading, error } = useQuery(CMS_GET_LESSONS, {
    variables: { courseId: course.id },
  });
  const { data: dataOne, loading: loadingOne, error: errorOne } = useQuery(
    GET_PRODUCT_BOOK,
    {
      variables: { productId: course.product.id },
    },
  );

  const [selectedContent, setSelectedContent] = useState(null);

  const getPageRange = (pages) => {
    return `${pages[0].page_number} - ${pages[pages.length - 1].page_number}`;
  };

  const handleClick = (lesson) => {
    const index = lessons.findIndex((l) => l.id === lesson.id);

    if (index !== -1) {
      setSelectedContent(index);
    }
  };

  if (loading || loadingOne) return <p>Loading...</p>;
  if (error || errorOne) return <p>Error...</p>;

  const lessons = data.lessons;
  const book = dataOne.book;
  if (!book) return <p>No Book!</p>;
  if (matches) {
    return (
    <Box p={2}>
    { selectedContent === null ? ( lessons.map(lesson => (
      <Card key={lesson.id} className={classes.card}>
        <CardActionArea onClick={() => handleClick(lesson)}>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            className={classes.cardContent}
          >
            <Avatar alt={lesson.name} src={lesson.logo} />
            <div style={{ marginLeft: 8, marginRight: 'auto' }}>
              <Typography variant="subtitle2">
                Lesson {lesson.display_order}
              </Typography>
              <Typography variant="h5">{lesson.name}</Typography>
            </div>
            
          </Box>
          <Box
            width="100%"
            className={classes.cardMiddle}
            >
              <Typography variant="caption">{lesson.description}</Typography>
          </Box>
          <Box
          width="100%"
          className={classes.cardContent}
          >           
              <Typography variant='h6' style={{color: '#BF1212'}}>
                READ THE LESSON
              </Typography>
          </Box>
        </CardActionArea>
      </Card>
    ))
    ) : (
      <LessonPages lesson={lessons[selectedContent]} book={book} />
    )}
  </Box>
  );
  } else {
  return (
    <DrawerShell
      drawer={
        <Box p={2}>
          {lessons.map((lesson) => (
            <Card key={lesson.id} className={classes.card}>
              <CardActionArea onClick={() => handleClick(lesson)}>
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  className={classes.cardContent}
                >
                  <Avatar alt={lesson.name} src={lesson.logo} />

                  <div style={{ marginLeft: 8, marginRight: 'auto' }}>
                    <Typography variant="subtitle2">
                      Lesson #{lesson.display_order}
                    </Typography>
                    <Typography variant="caption">{lesson.name}</Typography>
                  </div>

                    {lesson.pages.length <= 0 && (
                      <DarkChip label="No Pages" size="small" />
                    )}
                    {lesson.pages.length >= 1 && (
                      <Chip
                        size="small"
                        label={getPageRange(lesson.pages)}
                        color="primary"
                      />
                    )}
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        }
        content={
          <Fragment>
            {selectedContent !== null ? (
              <LessonPages lesson={lessons[selectedContent]} book={book} />
            ) : (
              <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <BookOutlinedIcon className={classes.icon} />
                <Typography variant="h3" color="textSecondary">
                  Let's Get Started
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Select a Lesson on the left to Add/Edit its contents.
                </Typography>
              </Box>
            )}
          </Fragment>
        }
      />
    );
  }
};


const LessonPages = ({ lesson, book }) => {
  //#region validates the breakpoint and get a bolean result
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm')); 
  //#endregion
  const classes = styles();
  const getLessonPageRange = useCallback(
    (pages) => {
      const bookPages = book.pages;
      if (!pages.length)
        return [1, bookPages[bookPages.length - 1].page_number];
      return [pages[0].page_number, pages[pages.length - 1].page_number];
    },
    [book.pages],
  );

  const [setLessonPages] = useMutation(SET_LESSON_PAGES, {
    refetchQueries: ['getCMSLessons'],
  });

  const [pristine, setPristine] = useState(lesson.pages.length > 0);
  const [page, setPage] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);
  const lessonPageRange = getLessonPageRange(lesson.pages);
  const [selectedPageRange, setSelectedPageRange] = useState(lessonPageRange);

  useEffect(() => {
    setSelectedPageRange(getLessonPageRange(lesson.pages));
  }, [lesson, getLessonPageRange]);

  useEffect(() => {
    let isPristine = sum(lessonPageRange) === sum(selectedPageRange);
    if (lesson.pages.length !== 0 && !lesson.pages.length) {
      isPristine = false;
      setPage(1);
    } 
    setPristine(isPristine);
    if (page < selectedPageRange[0]) {
        setPage(selectedPageRange[0]);
    }
    if (page > selectedPageRange[1]) {
        setPage(selectedPageRange[1]);
    }
  }, [selectedPageRange, page, lesson.pages.length, lessonPageRange]);  

  useEffect(() => {
    let selectedPage = book.pages.filter((p) => p.page_number === page)[0];
    setSelectedPage(selectedPage);
  }, [page, book.pages]);

  const handleChange = (event, newValue) => {
    setSelectedPageRange(newValue);
  };
  const handleSave = async () => {
    const pageIds = book.pages
      .filter(
        (page) =>
          page.page_number >= selectedPageRange[0] &&
          page.page_number <= selectedPageRange[1],
      )
      .map((page) => page.id);

    try {
      await setLessonPages({
        variables: { ebookPageIds: pageIds, where: { id: lesson.id } },
      });
    } catch (error) {}
  };

  if (!selectedPageRange) {
   return <p>No Book...</p>;
  } else if (matches) {
    return (
      <Box >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography  variant="subtitle2">Lesson {lesson.display_order}: {lesson.name} <ArrowForwardIosIcon className={classes.h6} /> Page: {selectedPage.page_number}</Typography>
        </Box>
        
        <div>
          <Box display="flex" alignItems="center" justifyContent="center"style={{ marginTop: '16px' }}>
            <Pagination              
              count={book.pages.length}
              page={page}
              onChange={(event, value) => setPage(value)}
              siblingCount={0}
              variant="outlined" 
              shape="rounded"
            />
          </Box>
          {selectedPage && (
            <Box >
              <EditorText text={selectedPage.content} />
            </Box>
          )}
        </div>
      </Box>
    );
  } else {
    return (
      <Box p={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">{lesson.name}</Typography>
          <Button
            color="primary"
            variant="contained"
            disabled={pristine}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Box>
        <div style={{ marginBottom: 8 }}>
          <Typography variant="caption" color="textSecondary">
            Note: Use the page range slider to select which pages belong to your
            selected lesson. When finished click Save.
          </Typography>
        </div>

        <div>
          <Box display="flex" alignItems="center">
            <Pagination
              style={{ marginRight: 'auto' }}
              count={book.pages.length}
              page={page}
              onChange={(event, value) => setPage(value)}
            />
            <Typography id="range-slider" style={{ marginRight: 8 }}>
              Page Range:
            </Typography>
            <Slider
              style={{ width: 300 }}
              marks={true}
              min={1}
              max={book.pages.length}
              value={selectedPageRange}
              onChange={handleChange}
              valueLabelDisplay="auto"
            />
          </Box>
          {selectedPage && (
            <Box p={4}>
              <Typography variant="subtitle2">
                Page: {selectedPage.page_number}
              </Typography>
              <EditorText text={selectedPage.content} />
            </Box>
          )}
        </div>
      </Box>
    );  
  }
};

export default DevelopCourseBook;
