import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import {
  GET_USER_INSTITUTIONS,
  GET_ALL_INSTITUTIONS,
  CREATE_INSTITUTION,
  UPDATE_INSTITUTION,
} from '../apollo/queries';
import { adopt } from 'react-adopt';

const userInstitutions = ({ render, userId }) => (
  <Query
    query={GET_USER_INSTITUTIONS}
    variables={{ userId }}
    notifyOnNetworkStatusChange
    skip={!userId}
  >
    {({ data: { institutions } = {}, loading, error, networkStatus }) =>
      render({ institutions, loading, error, networkStatus })
    }
  </Query>
);

const allInstitutions = ({ render }) => (
  <Query query={GET_ALL_INSTITUTIONS}>
    {({ data: { institutions } = {}, loading, error }) =>
      render({ institutions, loading, error })
    }
  </Query>
);

const updateInstitution = ({ render }) => (
  <Mutation mutation={UPDATE_INSTITUTION}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const addInstitution = ({ render }) => (
  <Mutation mutation={CREATE_INSTITUTION}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
);

const InstitutionContainer = adopt({
  addInstitution,
  updateInstitution,
  allInstitutions,
  userInstitutions,
});

export default InstitutionContainer;
