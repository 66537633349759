import React from 'react';
import { Form, Field } from 'react-final-form';
import { FormControl, TextField, Box, Button } from '@material-ui/core';
import { TinyEditor } from '../../common';

const EBookPageForm = ({
  nextPage = null,
  bookId = null,
  page = null,
  onSubmit,
}) => {
  return (
    <Form
      initialValues={page ? page : { book_id: bookId, page_number: nextPage }}
      onSubmit={onSubmit}
      validate={(values) => {
        let errors = {};
        if (!values.content) errors.content = 'required';
        if (!values.page_number) errors.page_number = 'required';

        return errors;
      }}
      render={({ handleSubmit, valid, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FormControl style={{ marginBottom: 8 }}>
            <Field name="page_number">
              {({ input, meta }) => (
                <TextField
                  id="page_number"
                  type="number"
                  label="Page Number"
                  value={input.value}
                  onChange={input.onChange}
                  variant="filled"
                  disabled
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 8 }}>
            <Field name="title">
              {({ input, meta }) => (
                <TextField
                  error={!pristine && Boolean(meta.error)}
                  id="title"
                  label="Title"
                  value={input.value}
                  onChange={(value) => input.onChange(value)}
                  variant="filled"
                  helperText={!pristine && meta.error ? meta.error : ''}
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 8 }}>
            <Field name="content">
              {({ input, meta }) => (
                <TinyEditor
                  showMediaLibraryButton={true}
                  showTinyMCEMediaOptions={true}
                  allowRefresh={true}
                  value={input.value}
                  onChange={(content) => {
                    input.onChange(content);
                  }}
                />
              )}
            </Field>
          </FormControl>
          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={submitting || pristine || !valid}
              type="submit"
              variant="contained"
              color="primary"
            >
              {page ? 'Save Changes' : 'Save'}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default EBookPageForm;
