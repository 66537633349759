import { 
  Divider,
  ListItem, 
  ListItemAvatar, 
  ListItemSecondaryAction, 
  ListItemText 
} from '@material-ui/core';

import React, { Fragment } from 'react';
import DownloadButton from './DownloadButton';
import useGetLabelResource from './useGetLabelResource';
import useGetLogoResource from './useGetLogoResource';

const ListItemResource = ({resource, lastOne}) => {
  const { logoResource } = useGetLogoResource(resource);
  const { label } = useGetLabelResource(resource);

  const resourceNameWithExtension = (name) => {
    const splitedName = name.split('.');
    return splitedName[0];
  };

  return (
  <Fragment>
    <ListItem key={resource.id}>
      <ListItemAvatar>
        {logoResource}
      </ListItemAvatar>
      <ListItemText
        primary={resourceNameWithExtension(resource.name)}
        secondary={label}
      />
      <ListItemSecondaryAction>
        <DownloadButton
        url={resource.url}
        name={resource.name}/>
      </ListItemSecondaryAction>
    </ListItem>
    {
      !lastOne ? <Divider component="li" /> : null
    }
  </Fragment> 
  )
}

export default ListItemResource;