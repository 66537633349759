import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export default ({ title, color = 'primary', ...restProps }) => (
  <Button
    {...restProps}
    aria-label={`add-${title}`}
    startIcon={<AddIcon />}
    color={color}
    variant="contained"
  >
    {title}
  </Button>
);
