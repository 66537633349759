import { makeStyles } from '@material-ui/core/styles';
import BackgroundImage from '../../../../assets/background/study-group-dashboard-bg-black.png';

export default makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: "white",
    backgroundImage: `url(${BackgroundImage})`,
  },
  heading: {
    background: theme.palette.common.white,
  },
  main: {
    flexGrow: 1,
  },
  tabs: {
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
  },
  tab: {
    flexGrow: 1
  },
  // old
  groupDashboard: {
    paddingTop: theme.spacing(2),
  },
  peopleContainer: {
    padding: '2em 1em',
  },
  peopleTable: {
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  instructorChip: {
    background: theme.palette.dark.main,
    color: theme.palette.white.main,
    marginLeft: 'auto',
  },
  headerIcon: {
    color: 'rgba(0,0,0,0.54)',
    marginRight: theme.spacing(1),
  },
  settingsContainer: {
    padding: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  settingsPaper: {
    padding: theme.spacing(2),
  },
  groupCourseContainer: {
    padding: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  sortIcon: {
    marginLeft: theme.spacing(2),
    '&:hover': {
      cursor: "pointer"
    }
  },
  groupMembersFinder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxHeight: '80%',
    height: 'auto',
    maxWidth: 960,
    padding: '1em 2em',
  },
  groupMembersFinder_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  navbar_tabs: {
    '& > div > span': {
      backgroundColor: '#bf1212'
    }
  },
  navbar_tabActive: {
    color: '#bf1212'
  },
  groupMembersFinder_main: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
    height: 600,
    overflow: 'hidden'
  },
  groupMembersFinder_groups: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 0.3,
    padding: '0.5em',
    height: '100%'
  },
  groupMembersFinder_groupList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    overflowY: 'auto',
    flex: 1,
    padding: 0,
    width: '100%',
    margin: '0.5em 0',
    borderRadius: '0.5em',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  groupMembersFinder_groupItemActive: {
    backgroundColor: '#bf1212',
    '& > div > span': {
      color: '#ffffff'
    },
    '&:hover': {
      backgroundColor: '#A11419'
    }
  },
  groupMembersFinder_groupItem: {
    color: '#bf1212'
  },
  groupMembersFinder_students: {
    display: 'flex',
    flex: 0.7,
    padding: '0.5em 0.1em 0.5em 1em',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  groupMembersFinder_studentsList: {
    display: 'flex',
    padding: 0,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    overflowY: 'auto',
    flex: 1,
    width: '100%',
    margin: '0.5em 0',
    borderRadius: '0.5em',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  groupMembersFinder_footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    
  },
  groupMembersFinder_footer_addButton: {
    color: '#FFFFFF',
    margin: '1em',
    backgroundColor: '#bf1212',
    '&:hover': {
      backgroundColor: '#A11419'
    }
  },
  groupMembersFinder_footer_addButtonDis: {
    backgroundColor: 'none',
    margin: '1em',
  },
  successLog: {
    '& > span': {
      color: '#3fae2a'
    }
  },
  errorLog: {
    '& > span': {
      color: '#bf1212'
    }
  }
}));
