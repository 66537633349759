import React from 'react';
import { makeStyles, Chip } from '@material-ui/core';

const styles = makeStyles(theme => ({
  chip: {
    background: 'rgba(0,0,0,0.87)',
    color: '#FFF',
  },
}));

const DarkChip = props => {
  const classes = styles();

  return <Chip {...props} className={classes.chip} />;
};

export default DarkChip;
