import React, { useContext } from 'react';
import { Box, Typography, Button, Container } from '@material-ui/core';
import { CloseButton } from '../../../../components';
import styles from './styles';
import clsx from 'clsx';
import { UserConfirmationContext } from '../../../../providers/UserConfirmationProvider';

const Start = ({
  onClose,
  onStart,
  project,
  projectNumber,
  preview,
  studentAnswers,
}) => {
  const classes = styles();
  const { showConfirmation } = useContext(UserConfirmationContext);
  const numberOfCaseStudies = project.caseStudies.filter(
    (cs) => cs.published,
  ).length;

  if (!project) return null;
  return (
    <Container style={{display: 'flex', justifyContent: 'center'}}>
      <Box maxWidth={960} width={'100%'}>
        <Box textAlign="right" p={2}>
          <CloseButton
            iconProps={{ fontSize: 'large' }}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box className={classes.startContainer} p={2}>
          <div className={classes.imageContainer}>
            <img alt={project.lesson.name} src={project.lesson.logo} />
          </div>
          <Typography variant="h3" color="primary">
            Level {project.level}
          </Typography>
          <Typography variant="h4" gutterBottom>
            Lesson {project.lesson.display_order} {project.lesson.name}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Study Project {projectNumber ? `#${projectNumber}` : ''}
          </Typography>

          <div className={classes.buttonContainer}>
            <Button
              disabled={numberOfCaseStudies === 0}
              onClick={
                preview
                  ? () =>
                      showConfirmation({
                        title: 'Heads up!',
                        message:
                          'You may take these Study Projects as many times as you wish while the lesson is free. As this is a free lesson, the results will not be saved.',
                        confirmed: () => onStart(),
                        confirmText: 'I Understand',
                        cancel: () => onClose(),
                      })
                  : onStart
              }
              variant="contained"
              color="primary"
              className={clsx(classes.button, classes.startButton)}
            >
              {
                Object.keys(studentAnswers).length >= 1 ? 'Continue' : "Let's Start"
              }
            </Button>
            <Button
              onClick={onClose}
              variant="outlined"
              color="primary"
              className={clsx(classes.button, classes.cancelButton)}
            >
              Cancel
            </Button>
          </div>

          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.caseStudyText}
          >
            Total Case Studies: {numberOfCaseStudies}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Start;
