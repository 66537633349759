import React, { Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import {
  FormControl,
  Box,
  Button,
  Slider,
  Typography,
  TextField,
} from '@material-ui/core';
import { TinyEditor } from '../../common';

const LEVELS = [
  { value: 1, label: 'Level 1' },
  { value: 2, label: 'Level 2' },
  { value: 3, label: 'Level 3' },
  { value: 4, label: 'Level 4' },
];

const StudyProjectForm = ({
  studyProject = null,
  lessonId,
  onSubmit,
  onCancel = null,
  maxLevel = 2,
}) => {
  const levels = LEVELS.filter((level) => level.value <= maxLevel);
  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        let errors = {};
        if (!values.introduction) errors.introduction = 'required';
        if (!values.level) errors.level = 'required';
        if (!values.lesson_id) errors.lesson_id = 'required';

        return errors;
      }}
      initialValues={
        studyProject
          ? { ...studyProject, lesson_id: studyProject.lesson.id }
          : {
              level: 1,
              lesson_id: lessonId,
            }
      }
      render={({ handleSubmit, pristine, valid, submitting }) => (
        <form onSubmit={handleSubmit} style={{ minWidth: 500 }}>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Field name="level">
              {({ input, meta }) => (
                <Box display="block" width="100%">
                  <Typography variant="caption">
                    Level{' '}
                    <Typography variant="overline" color="primary">
                      *
                    </Typography>
                  </Typography>
                  <Box pl={3} pr={3} maxWidth={300}>
                    <Slider
                      marks={levels}
                      getAriaValueText={(value) => `${value} levels`}
                      value={+input.value}
                      onChange={(event, value) => {
                        input.onChange(value);
                      }}
                      min={1}
                      max={maxLevel}
                    />
                  </Box>
                </Box>
              )}
            </Field>
          </FormControl>
          <FormControl style={{ marginBottom: 16 }}>
            <Field name="study_project_number">
              {({ input, meta }) => (
                <TextField
                  id="study_project_number"
                  label="Study Project Number"
                  value={input.value}
                  onChange={input.onChange}
                  variant="filled"
                  type='number'
                  helperText=""
                />
              )}
            </Field>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Field name="introduction">
              {({ input, meta }) => (
                <Fragment>
                  <Typography gutterBottom variant="subtitle2">
                    Study Project Introduction
                  </Typography>
                  <TinyEditor
                    value={input.value}
                    allowRefresh={true}
                    onChange={(content) => {
                      input.onChange(content);
                    }}
                  />
                </Fragment>
              )}
            </Field>
          </FormControl>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {onCancel && (
              <Button
                type="button"
                onClick={onCancel}
                variant="text"
                color="primary"
                disabled={submitting}
              >
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              // variant="text"
              color="primary"
              variant="contained"
              disabled={submitting || pristine || !valid}
            >
              {studyProject ? 'Save' : 'Create'}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default StudyProjectForm;
