import gql from 'graphql-tag';
import {
  InstitutionFields,
  InstitutionMemberFields,
  UserFields,
} from './fragments';

const GET_INSTITUTION_MEMBER_BY_USER_AND_INSTITUTION_ID = gql`
  query getInstitutionMember($where: InstitutionMemberWhereUniqueInput!) {
    institutionMember(where: $where) {
      id
      user {
        ...UserFields
      }
      institution {
        ...InstitutionFields
      }
      role
    }
  }
  ${UserFields}
  ${InstitutionFields}
`;

const GET_INSTITUTION_MEMBERS = gql`
  query getInstitutionMembers($where: InstitutionMemberWhereInput) {
    institutionMembers(where: $where) {
      id
      user {
        ...UserFields
        groups {
          id
          name
          owner {
            id
            first_name
            last_name
          }
        }
      }
      role
    }
  }
  ${UserFields}
`;

const DELETE_INSTITUTION_MEMBER = gql`
  mutation deleteInstitutionMember($id: ID!) {
    deleteInstitutionMember(id: $id) {
      success
      message
    }
  }
`;

const UPDATE_INSTITUTION_MEMBER = gql`
  mutation updateInstiutionMember(
    $id: ID!
    $data: InstitutionMemberUpdateInput!
  ) {
    updateInstitutionMember(id: $id, data: $data) {
      id
      user {
        ...UserFields
      }
      role
    }
  }
  ${UserFields}
`;

const ADD_INSTITUTION_MEMBER = gql`
  mutation addInstitutionMember($data: InstitutionMemberCreateInput!) {
    addInstitutionMember(data: $data) {
      ...InstitutionMemberFields
    }
  }
  ${InstitutionMemberFields}
`;

export {
  GET_INSTITUTION_MEMBER_BY_USER_AND_INSTITUTION_ID,
  GET_INSTITUTION_MEMBERS,
  DELETE_INSTITUTION_MEMBER,
  ADD_INSTITUTION_MEMBER,
  UPDATE_INSTITUTION_MEMBER,
};
