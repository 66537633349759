import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tabs: {
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid #CFD8DC`,
  },
  root: {
    height: '100%',
    overflow: 'auto',
    marginBottom: theme.spacing(2),
  },
  container: {
    height: '100%',
    padding: theme.spacing(4),
  },
  title: {
    flexGrow: 1,
  },
  productExpansionPanel: {
    width: '100%',
    background: 'transparent',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
  },
  productSummaryPanel: {
    boxShadow: theme.shadows[2],
  },
  productAvatar: {
    background: theme.palette.jasperactiveGreen.main,
  },
  courseCard: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(2, 4),
    minWidth: 500,
  },
  deleteProductModal: {
    background: '#F9DADA',
    padding: theme.spacing(2),
    maxWidth: 600,
  },
}));
