import React, { useContext } from 'react';
import { ViewerContext } from '../../../providers/ViewerProvider';
import { useQuery } from '@apollo/react-hooks';
import { USER_COMPLETED_SUMMARY_IDS } from '../../../apollo/queries';
import { LoadingScreen } from '../../../components';

export const SummaryProgressContext = React.createContext();

const SummaryProgressProvider = ({ groupId, children }) => {
  const { viewer } = useContext(ViewerContext);

  const { data, loading, error } = useQuery(USER_COMPLETED_SUMMARY_IDS, {
    variables: {
      groupId,
      userId: viewer.id,
    },
    skip: !groupId,
  });

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error...</p>;

  const state = {
    complete_summary_ids: !groupId ? [] : data.completedSummaries,
  };

  return (
    <SummaryProgressContext.Provider value={state}>
      {children}
    </SummaryProgressContext.Provider>
  );
};

export default SummaryProgressProvider;