import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { typeDefs, resolvers } from './resolvers';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { IDENTITY_CONFIG } from '../utils/authConst';

const cache = new InMemoryCache();
const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === 'production'
      ? '/graphql'
      : 'http://localhost:8080/graphql',
  credentials:
    process.env.NODE_ENV === 'production' ? 'same-origin' : 'include',
});

const authLink = setContext((_, { headers }) => {
  let token = JSON.parse(
    sessionStorage.getItem(
      `oidc.user:${IDENTITY_CONFIG.authority}:Jasperactive`,
    ),
  );

  if (token) {
    token = `Bearer ${token.access_token}`;
  }

  let initial_checks = sessionStorage.getItem('initial_checks_performed');

  if (token && !initial_checks)
    sessionStorage.setItem('initial_checks_performed', true);

  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
      'X-Perform-Initial-Checks': token && !initial_checks ? true : false,
    },
  };
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(
    ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      httpLink,
    ]),
  ),
  typeDefs,
  resolvers,
});

export default client;
