import { useMutation, useQuery } from '@apollo/react-hooks';

import {
  ADD_INSTITUTION_MEMBER,
  DELETE_INSTITUTE_INVITATION,
  DELETE_INSTITUTION_MEMBER,
  GET_INSTITUTION_LICENSES,
  GET_INSTITUTION_MEMBERS,
  GET_INSTITUTION_MEMBER_BY_USER_AND_INSTITUTION_ID,
  GET_INVITATIONS_BY_INSTITUTION_ID,
  GET_USER_INSTITUTIONS,
  GET_USER_INSTITUTION_GROUPS,
  INVITE_INSTITUTION_MEMBER,
  UPDATE_INSTITUTE_INVITATION,
  UPDATE_INSTITUTION_MEMBER,
} from '../queries';

export function useUserInstitutions(userId) {
  const { data, loading, error, refetch } = useQuery(GET_USER_INSTITUTIONS, {
    variables: { userId },
    skip: !userId,
  });

  return { data, loading, error, refetch };
}

export function useUserInstitutionMember(userId, institutionId) {
  const { data, loading, error } = useQuery(
    GET_INSTITUTION_MEMBER_BY_USER_AND_INSTITUTION_ID,
    {
      variables: {
        where: {
          user_id: userId,
          institution_id: institutionId,
        },
      },
      skip: !userId || !institutionId,
    },
  );

  return { data, loading, error };
}

export function useInstitutionLicenses(institutionId) {
  const { data, loading, error } = useQuery(GET_INSTITUTION_LICENSES, {
    variables: { institutionId },
    skip: !institutionId,
  });

  return { data, loading, error };
}

export function useInstitutionMembers(institutionId) {
  const { data, loading, error } = useQuery(GET_INSTITUTION_MEMBERS, {
    variables: {
      where: {
        institution_id: institutionId,
      },
    },
    skip: !institutionId,
  });

  const members = data ? data.institutionMembers : [];

  return { members, loading, error };
}

export function useDeleteInstitutionMember() {
  const [deleteInstitutionMember, { data }] = useMutation(
    DELETE_INSTITUTION_MEMBER,
    {
      refetchQueries: ['getInstitutionMembers', 'getInstitutionInvitation'],
    },
  );

  return { deleteInstitutionMember, data };
}

export function useUpdateInstitutionMember() {
  const [updateInstitutionMember, { data }] = useMutation(
    UPDATE_INSTITUTION_MEMBER,
    {
      refetchQueries: ['getInstitutionMembers', 'getInstitutionInvitation'],
    },
  );

  return { updateInstitutionMember, data };
}

export function useInviteInstitutionMember() {
  const [inviteInstitutionMember] = useMutation(INVITE_INSTITUTION_MEMBER, {
    refetchQueries: ['getInstitutionMembers', 'getInstitutionInvitation'],
  });

  return { inviteInstitutionMember };
}

export function useUpdateInvitedInstitutionMember() {
  const [updateInvitedInstitutionMember] = useMutation(
    UPDATE_INSTITUTE_INVITATION,
    {
      refetchQueries: ['getInstitutionMembers', 'getInstitutionInvitation'],
    },
  );

  return { updateInvitedInstitutionMember };
}

export function useInstitutionInvitations(institutionId) {
  const { data, loading, error } = useQuery(GET_INVITATIONS_BY_INSTITUTION_ID, {
    variables: {
      where: {
        id: institutionId,
      },
    },
    skip: !institutionId,
  });

  const invitations = data ? data.institutionInvitations : [];

  return { invitations, loading, error };
}

export function useDeleteInvitations() {
  const [deleteInstitutionInvite] = useMutation(DELETE_INSTITUTE_INVITATION, {
    refetchQueries: ['getInstitutionMembers', 'getInstitutionInvitation'],
  });

  return { deleteInstitutionInvite };
}

export function useInstitutionGroups(userId, institutionId) {
  const { data, loading, error, networkStatus } = useQuery(
    GET_USER_INSTITUTION_GROUPS,
    {
      variables: { userId, institutionId },
      skip: !userId || !institutionId,
      fetchPolicy: 'network-only',
    },
  );

  return { data, loading, error, networkStatus };
}

export function useAddInsitutionMember() {
  const [addInstitutionMember] = useMutation(ADD_INSTITUTION_MEMBER, {
    refetchQueries: [
      'getUserInstitutions',
      'userInvitations',
      'getInstitutionMembers',
      'getInstitutionInvitation',
    ],
  });

  return { addInstitutionMember };
}
