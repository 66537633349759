import { Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import KeyElementsCard from './KeyElementsCard';
import styles from './styles';

const KeyElementsList = ({keyElements, setKeyElementID, keyElementID}) => {
  const classes = styles();
  const [value, setValue] = useState(0);

  return (
    <Tabs className={classes.keyElementsList}
    scrollButtons='auto'
    variant='scrollable'
    value={value}>
      {
        keyElements.map((keyElement, index) => {
          return (
            <KeyElementsCard 
            key={keyElement[1].id} 
            id={keyElement[1].id} 
            title={keyElement[1].name} 
            setKeyElementID={setKeyElementID}
            keyElementID={keyElementID}
            index={index}
            setValue={setValue}/>
          )
        })
      }
    </Tabs>
  )
}

export default KeyElementsList;