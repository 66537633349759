export const auditDataFields = [
  'created_on',
  'created_by',
  'modified_by',
  'modified_on',
  'deleted_by',
  'deleted_on',
  'modifiedBy',
  'createdBy',
  'deletedBy',
  'published',
  'publishedBy',
  'published_by',
  'published_on',
];
