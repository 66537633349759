const CORRECT_REQUIRED = ['CASE_STUDY', 'MULTIPLE_CHOICE'];

export const validate = (values, variant) => {
  let errors = {};
  if (!values.question) errors.question = 'A Question is required';
  const correctAnswers = values.answers.filter((a) => a.is_correct);
  if (CORRECT_REQUIRED.includes(values.type) && correctAnswers.length <= 0) {
    errors.answer = 'At least one answer has to be correct.';
  }
  if (variant === 'case study' || variant === 'reinforced') {
    if (!values.incorrect_msg) errors.incorrect_msg = 'required';
    if (variant !== 'benchmark') {
      if (!values.incorrect_msg_two) errors.incorrect_msg_two = 'required';
      if (!values.correct_msg) errors.correct_msg = 'required';
    }
  }
  return errors;
};
