import React from 'react';
import { PrimaryButton } from '../../common';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import { useAuthentication } from '../../../providers/AuthProvider';

const LogoutButton = ({...restProps}) => {
  const auth = useAuthentication();

  return (
    <PrimaryButton
      startIcon={<ExitToAppOutlinedIcon />}
      onClick={() => auth.logout()}
    >
      Logout
    </PrimaryButton>
  );
}

export default LogoutButton;
