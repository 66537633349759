import React from 'react';
import { JasperBackground, LoadingScreen } from '../../components';
import { Dashboard, LearningLibrary, Quickdeck } from './screens';
import { Route } from 'react-router-dom';
import { LessonContainer } from '../../containers';
import { LessonHeading, LessonMobileNavBar, SideLessonNav } from './components';
import { Container, Hidden, Typography } from '@material-ui/core';
import styles from './styles';

const FreeLessonDashboard = ({ match, history }) => {
  const classes = styles();
  const lessonId = match.params.lessonId;

  return (
    <LessonContainer lessonId={lessonId}>
      {({ lessonData: { lesson, loading, error } }) => {
        if (loading)
          return (
            <LoadingScreen>
              <Typography align="center" variant="body2">
                Getting Free Lesson...
              </Typography>
            </LoadingScreen>
          );
        if (error) return <p>Error</p>;

        return (
          <section className={classes.courseDashboardSection}>
            <Hidden mdDown>
              <SideLessonNav match={match} />
            </Hidden>
            <JasperBackground color={lesson.course.product.color} p={3} pb={8}>
              <Container maxWidth="md">
                <LessonHeading
                  className={classes.heading}
                  lesson={lesson}
                  history={history}
                />
                <Route
                  path={`${match.path}/quickdeck`}
                  component={(props) => (
                    <Quickdeck
                      {...props}
                      cards={lesson.cards.filter((card) => card.published)}
                    />
                  )}
                />
                <Route
                  path={`${match.path}/library`}
                  component={(props) => (
                    <LearningLibrary {...props} bookPages={lesson.pages} />
                  )}
                />
                <Route
                  exact
                  path={match.path}
                  component={(props) => (
                    <Dashboard {...props} lesson={lesson} />
                  )}
                />
                <Hidden lgUp>
                  <LessonMobileNavBar match={match} />
                </Hidden>
              </Container>
            </JasperBackground>
          </section>
        );
      }}
    </LessonContainer>
  );
};

export default FreeLessonDashboard;
