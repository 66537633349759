import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  instructorResourcesSection: {
    padding: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  resourcesHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '1em 1em 0 0'
  },
  benchmarkLogo:{
    color: theme.palette.success.main,
    backgroundColor: '#f1f1f1'
  },
  linkToDowloadAll: {
    textDecoration: 'none'
  },
  listItemResourceByLesson_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1em',
    '@media (max-width: 690px)': {
      flexDirection: 'column',
      alignItems: 'stretch'
    }
  },
  listItemResourceByLesson_lessonInf: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '1em',
  },
  listItemResourceByLesson_downloadButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
  },
  listItemResourceByLesson_contentParent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    maxWidth: 300,
    width: '100%',
    '@media (max-width: 540px)': {
      alignItems: 'stretch',
    }
  },
  listItemResourceByLesson_contentChild: {
    display: 'flex',
    gap: '0.5em',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  listItemResourceByLesson_downloadButtons_container: {
    display: 'flex',
    alignItems: 'center',
    gap: '2em'
  }
}));