import React, { Fragment } from 'react';
import { Box, Button } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { CloudinaryContext } from '../../../providers/CloudinaryProvider';

const CloudinaryImageWidget = ({ onInsert, multiple, maxFiles, acceptedFormat, files, showImageList = true, buttonLabel = "Media Library", cloudinaryFolder, enqueueSnackbar }) => {
    const { getMediaWidgetConfig } = React.useContext(CloudinaryContext);
    const [loading, setLoading] = React.useState(false);
    const widgetRef = React.useRef(null);

    let tempFiles = files ? files.split(',').filter(file => file && file !== 'Coming Soon' && file !== 'Empty' && file !== 'Logo belongs here!') : [];

    if (!multiple)
        maxFiles = 1;

    const options = {
        multiple
    };

    if (maxFiles !== 0)
        options["max_files"] = maxFiles;

    if (cloudinaryFolder)
        options["folder"] = { path: cloudinaryFolder };

    const insertImageHandler = (data) => {
        if (onInsert) {
            let assets = [];
            let formats = [];

            if (acceptedFormat) {
                formats = acceptedFormat.split(',').filter(format => format);
                assets = data.assets.filter(asset => formats.find(format => format.trim().toLowerCase() === asset.format.toLowerCase()));
            }
            else {
                assets = data.assets;
            }

            if (assets.length < data.assets.length)
                enqueueSnackbar(`Some elements will be discarded because they are not of the required format: ${formats.join(', ')}.`, { variant: 'info', autoHideDuration: 0 });

            onInsert(assets.map(asset => asset.secure_url).join(', '));
        }
    };

    const openWidget = (e) => {
        e.stopPropagation();
        setLoading(true);

        if (!widgetRef.current) {
            widgetRef.current = window.cloudinary.createMediaLibrary(getMediaWidgetConfig(), { insertHandler: insertImageHandler, showHandler: () => { setLoading(false) } });
        }

        widgetRef.current.show(options);
    };

    return (
        <Fragment>
            {showImageList &&
                <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center" style={{ overflowY: "auto", maxHeight: "200px" }}>
                    {
                        tempFiles.map((file, index) => (
                            <img key={index} src={file} style={{ maxWidth: "80%", marginRight: "20px", marginBottom: "20px" }} alt={file}></img>
                        ))
                    }
                </Box>
            }
            <Button
                color="primary"
                variant="contained"
                style={{ marginTop: "25px" }}
                disabled={loading}
                onClick={openWidget}>{loading ? "loading..." : buttonLabel}
            </Button>
        </Fragment >
    );
};

export default withSnackbar(CloudinaryImageWidget);