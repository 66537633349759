import React from 'react';
import { Card, CardHeader, useTheme } from '@material-ui/core';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';

const NoGroupCard = ({ title = null }) => {
  const theme = useTheme();

  return (
    <Card variant="outlined">
      <CardHeader
        avatar={
          <GroupOutlinedIcon
            fontSize="large"
            style={{ fontSize: 48, color: theme.palette.grey[400] }}
          />
        }
        title={
          title
            ? title
            : 'Already Have a Group key? Click above to enter group key and join your group.'
        }
        titleTypographyProps={{ variant: 'h3', color: 'textSecondary' }}
      />
    </Card>
  );
};

export default NoGroupCard;
