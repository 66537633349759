import React, { useState, Fragment } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core';
import { EditorText } from '../../common';
import styles from './styles';
import QuickCardControls from '../../common/QuickCardControls/QuickCardControls';

const LibraryCardsClip = ({ cards = [] }) => {
  const classes = styles();
  const [cardNumber, setCardNumber] = useState(0);
  let cardsNumberLimit = 1;
  const [maxCardsNumberLimit, setMaxCardsNumberLimit] = useState(4);
  const [minCardsNumberLimit, setMinCardsNumberLimit] = useState(0);
  
  //Hook to active cc from video disabled
  /*const {
    videoPlayer, 
    subTitles,
    tracksInVideo,
    activeCC,
    setSubtitles
  } = useHandleActiveCC(cardNumber);*/

  const handleNextCard = () => {
    setCardNumber(cardNumber + 1);
    //setSubtitles(false);
    if (cardNumber + 2 > maxCardsNumberLimit) {
      setMaxCardsNumberLimit(maxCardsNumberLimit + cardsNumberLimit);
      setMinCardsNumberLimit(minCardsNumberLimit + cardsNumberLimit);
    }
  };
  const handlePrevCard = () => {
    setCardNumber(cardNumber - 1);
    //setSubtitles(false);
    if (cardNumber - 1 < minCardsNumberLimit) {
      setMaxCardsNumberLimit(maxCardsNumberLimit - cardsNumberLimit);
      setMinCardsNumberLimit(minCardsNumberLimit - cardsNumberLimit);
    }
  };
  const handleSelectCard = (cardIndex) => {
    setCardNumber(cardIndex);
    //setSubtitles(false);
  }

  if (!cards?.length) {
    return <Typography>No Cards</Typography>;
  }

  const card = cards[cardNumber];

  return (
    <Fragment>
      <QuickCardControls
        nextCard={handleNextCard}
        prevCard={handlePrevCard}
        currentCardNumber={cardNumber}
        cards={cards}
        disableNext={cardNumber >= cards.length - 1}
        disablePrev={cardNumber === 0}
        selectCard={handleSelectCard}
        maxCardsNumberLimit={maxCardsNumberLimit}
        minCardsNumberLimit={minCardsNumberLimit}
        mt={2}
        mb={2}
      />
      <Box className={classes.videoContainer} m="auto">
        <Card className={classes.card} elevation={4}>
          <CardContent className={classes.cardActionArea}>
            <CardContent className={classes.cardContent}>
              {
                <Box className={classes.box}>
                  <EditorText text={card.video} style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: 'flex-start' }} />
                </Box>
              }
            </CardContent>
            <CardActions className={classes.cardActions}>
              {/*
                tracksInVideo >= 1 &&
                  <Button startIcon={<SubtitleClipIcon viewBox='-3 -3 24 24' />} className={ subTitles ? classes.buttonCloseCC : classes.buttonActiveCC} onClick={activeCC}>
                    {subTitles ? 'close cc' : 'active cc'}
                  </Button>
              */}
            </CardActions>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
};

export default LibraryCardsClip;