import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_LESSON_PAGES } from '../../../../apollo/queries';
import { LibraryBook } from '../../../../components';
import { Redirect } from 'react-router-dom';
import { useCourse } from '../../providers/CourseProvider';
import LessonMenu from '../LessonMenu';
import { Box, Button, Card, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from './styles';

const LearningLibraryBook = ({ match, history }) => {
  const classes = useStyles();
  const course = useCourse();
  const lessonName = match.params.lessonName;
  const lesson = course.lessons.find((lesson) => lesson.name === lessonName);
  const { data, loading, error } = useQuery(GET_LESSON_PAGES, {
    variables: { lessonId: lesson ? lesson.id : null },
    skip: !lesson,
  });

  if (!lesson)
    return (
      <Redirect to={`${match.url.split('/').slice(0, 5).join('/')}/library`} />
    );

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress size={50} />
      </Box>
    );
  }
  if (error) return <p>Error...</p>;

  const pages = data.bookPages;

  return (
    <section className={classes.bookSection}>
      <Box 
      mb={2}>
        <Button
          startIcon={<ArrowBackIcon className={classes.icon} />}
          variant="text"
          onClick={() =>
            history.push(
              `${match.url.split('/').slice(0, 5).join('/')}/library`,
            )
          }
        >
          eBooks
        </Button>
      </Box>
      <Card variant="outlined">
        <Box p={2}>
          <LessonMenu
            lessons={course.lessons}
            history={history}
            currentLesson={lesson}
          />
        </Box>
        <Box 
        display='flex'
        alignItems='flex-start'
        justifyContent='center'
        p={2}>
          <LibraryBook pages={pages} lesson={lesson} />
        </Box>
      </Card>
    </section>
  );
};

export default LearningLibraryBook;
