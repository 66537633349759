import React, { useState, Fragment } from 'react';
import {
  Card,
  CardContent,
  CardActionArea,
  Box,
  Paper,
  Typography,
  Chip,
  Avatar,
  Button,
} from '@material-ui/core';
import Curriculum from '../Curriculum';
import styles from './styles';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import School from '@material-ui/icons/SchoolOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import {
  AddButton,
  LessonForm,
  DrawerShell,
  StudyProjectForm,
  DarkChip,
  EditButton,
  ExpansionPanel,
} from '../../../../../../components';
import withDialog from '../../../../../../hoc/withDialog';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  CREATE_LESSON,
  CREATE_STUDY_PROJECT,
  CREATE_SUMMARY,
  GET_LESSON_STUDY_PROJECTS,
} from '../../../../../../apollo/queries';
import { withSnackbar } from 'notistack';

const LessonFormDialog = withDialog(LessonForm);

const CourseCurriculum = ({ course, enqueueSnackbar }) => {
  const classes = styles();
  const [showCurriculum, setShowCurriculum] = useState(null);
  const [addLesson, setAddLesson] = useState(false);

  const [createLesson] = useMutation(CREATE_LESSON, {
    refetchQueries: ['getCourseById', 'getCMSLessons'],
  });

  const [createSummary] = useMutation(CREATE_SUMMARY, {
    refetchQueries: ['getCourseById'],
  });

  const { benchmarks, lessons, summaries, id } = course;

  const createSummaryAssessments = async () => {
    try {
      await createSummary({
        variables: {
          data: {
            benchmarks: benchmarks.map((benchmark) => {
              return {
                id: benchmark.id,
                level: benchmark.level
              }
            }),
            course_id: id
          }
        }
      });

      enqueueSnackbar(`Successfully actived Summary Assessments`, {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar(
        'Failed to active Summary Assessments. refresh the page and try again!',
        { variant: 'error' },
      );
    }
  }

  return (
    <DrawerShell
      drawer={
        <Box p={2}>
          <Box display="flex" alignItems="center" mb={1}>
            <ExploreOutlinedIcon style={{ marginRight: 8 }} />
            <Typography variant="overline">Benchmark</Typography>
          </Box>
          <Paper style={{ padding: 8 }}>
            {benchmarks.map((benchmark) => (
              <Card
                key={benchmark.id}
                elevation={0}
                className={classes.curriculumCard}
              >
                <CardActionArea
                  onClick={() =>
                    setShowCurriculum({
                      curriculum: 'benchmark',
                      data: { id: benchmark.id },
                    })
                  }
                >
                  <CardContent>
                    <div>
                      <Box display="flex" alignItems="center">
                        <AssessmentOutlinedIcon />
                        <Typography variant="subtitle2">
                          Benchmark - Level {benchmark.level}
                        </Typography>
                      </Box>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Chip
                          size="small"
                          color={benchmark.published ? 'primary' : 'default'}
                          label={benchmark.published ? 'Published' : 'Draft'}
                        />
                      </Box>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Paper>
          <LessonFormDialog
            open={addLesson}
            title="Add Lesson"
            onSubmit={async (values) => {
              try {
                await createLesson({
                  variables: {
                    data: {
                      ...values,
                      display_order: +values.display_order,
                    },
                  },
                });
                enqueueSnackbar(`Successfully created Lesson`, {
                  variant: 'success',
                  autoHideDuration: 1500,
                });
                setAddLesson(false);
              } catch (error) {
                enqueueSnackbar(
                  'Failed to create lesson. refresh the page and try again!',
                  { variant: 'error' },
                );
              }
            }}
            courseId={course.id}
            onCancel={() => setAddLesson(false)}
          />
          <Box display="flex" alignItems="center" mb={1} mt={2}>
            <LayersOutlinedIcon style={{ marginRight: 8 }} />
            <Typography variant="overline">Lessons</Typography>
            <AddButton
              style={{ marginLeft: 'auto' }}
              color="default"
              title="Add Lesson"
              size="small"
              onClick={() => setAddLesson(true)}
            />
          </Box>
          {lessons.length === 0 && (
            <Paper style={{ padding: 8 }}>
              <Card elevation={0} className={classes.curriculumCard}>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Typography variant="body1" color="textSecondary">
                    Course currently has no Lessons
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          )}
          {lessons.map((lesson) => (
            <ExpansionPanel
              showExpandIcon
              key={lesson.id}
              summary={
                <Box display="flex" alignItems="center" width="100%">
                  <Avatar alt={lesson.name} src={lesson.logo} />
                  <div style={{ marginLeft: 8, marginRight: 'auto' }}>
                    <Typography variant="subtitle2">
                      Lesson #{lesson.display_order}
                    </Typography>
                    <Typography variant="caption">{lesson.name}</Typography>
                  </div>
                  <Chip
                    style={{ marginRight: 8 }}
                    label={lesson.published ? 'Published' : 'Draft'}
                    size="small"
                    color={lesson.published ? 'primary' : 'default'}
                  />
                  <EditButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowCurriculum({
                        curriculum: 'lesson',
                        data: { id: lesson.id, setShowCurriculum },
                      });
                    }}
                  />
                </Box>
              }
              details={
                <Box>
                  <LessonContent
                    lesson={lesson}
                    course={course}
                    setShowCurriculum={setShowCurriculum}
                  />
                </Box>
              }
            />
          ))}
          {/*This section is based on Benchmarks */}
          <Box display="flex" alignItems="center" mb={1} mt={2}>
            <School style={{ marginRight: 8 }} />
            <Typography variant="overline">Summary</Typography>
          </Box>
          {
            summaries.length >= 1 ?
              <Paper style={{ padding: 8 }}>
                {
                  summaries.map((summary) => (
                    <Card
                      key={summary.id}
                      elevation={0}
                      className={classes.curriculumCard}
                    >
                      <CardActionArea
                        onClick={() =>
                          setShowCurriculum({
                            curriculum: 'summary',
                            data: { id: summary.id },
                          })
                        }
                      >
                        <CardContent>
                          <div>
                            <Box display="flex" alignItems="center">
                              <AssessmentOutlinedIcon />
                              <Typography variant="subtitle2">
                                Summary - Level {summary.level}
                              </Typography>
                            </Box>
                            <Box
                              width="100%"
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <Chip
                                size="small"
                                color={summary.published ? 'primary' : 'default'}
                                label={summary.published ? 'Published' : 'Draft'}
                              />
                            </Box>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  ))
                }
              </Paper>
            : <Button onClick={createSummaryAssessments} style={{width: '100%'}} color='primary' variant="contained" >Active Summary Assessments</Button>
          }
        </Box>
      }
      content={
        <Curriculum
          course={course}
          curriculum={showCurriculum ? showCurriculum.curriculum : null}
          data={showCurriculum ? showCurriculum.data : null}
        />
      }
    />
  );
};

const StudyProjectFormDialog = withDialog(StudyProjectForm);

const LessonContent = withSnackbar(
  ({ lesson, course, setShowCurriculum, enqueueSnackbar }) => {
    const classes = styles();
    const [addStudyProject, setAddStudyProject] = useState(false);

    const { data, loading, error } = useQuery(GET_LESSON_STUDY_PROJECTS, {
      variables: { lessonId: lesson.id },
    });

    const [createStudyProject] = useMutation(CREATE_STUDY_PROJECT, {
      refetchQueries: ['getLessonStudyProjects'],
    });

    const handleStudyProjectCreate = async (values) => {
      try {
        const result = await createStudyProject({
          variables: {
            data: {
              ...values,
              study_project_number: parseInt(values.study_project_number),
              level: +values.level,
            },
          },
        });
        enqueueSnackbar(`Successfully created Study Project`, {
          variant: 'success',
          autoHideDuration: 1500,
        });
        setAddStudyProject(false);
        setShowCurriculum({
          curriculum: 'study_project',
          data: {
            id: result.data.createStudyProject.id,
            setShowCurriculum,
          },
        });
      } catch (error) {
        enqueueSnackbar(
          'Failed to create Study Project. refresh the page and try again!',
          { variant: 'error' },
        );
      }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error...</p>;

    const studyProjects = data.studyProjects.sort((a, b) => a.study_project_number - b.study_project_number);

    return (
      <Fragment>
        <StudyProjectFormDialog
          title="Add Study Project"
          open={addStudyProject}
          onSubmit={handleStudyProjectCreate}
          lessonId={lesson.id}
          onCancel={() => setAddStudyProject(false)}
          maxLevel={course.levels}
        />
        <Box display="flex" flexDirection="column" pl={1} pr={1} pb={1}>
          {studyProjects.length === 0 && (
            <Card elevation={0} className={classes.curriculumCard}>
              <CardContent style={{ paddingBottom: 16 }}>
                <Typography variant="body1" color="textSecondary">
                  Lesson currently has no Content
                </Typography>
              </CardContent>
            </Card>
          )}
          {studyProjects.map((studyProject, index) => (
            <Card
              key={studyProject.id}
              elevation={0}
              className={classes.curriculumCard}
            >
              <CardActionArea
                onClick={() => {
                  setShowCurriculum({
                    curriculum: 'study_project',
                    data: { id: studyProject.id, setShowCurriculum },
                  });
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <CardContent>
                  <div>
                    <Box display="flex" alignItems="center" mb={1}>
                      <AssignmentOutlinedIcon />
                      <Typography variant="subtitle2">
                        Study Project {studyProject.study_project_number ? '#' + studyProject.study_project_number : ''} - level {studyProject.level}
                      </Typography>
                    </Box>
                    <Box width="100%" display="flex" justifyContent="flex-end">
                      {studyProject.caseStudies.length === 0 && (
                        <DarkChip
                          size="small"
                          label="No Content"
                          style={{ marginRight: 8 }}
                        />
                      )}
                      <Chip
                        size="small"
                        color={studyProject.published ? 'primary' : 'default'}
                        label={studyProject.published ? 'Published' : 'Draft'}
                      />
                    </Box>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
          <AddButton
            title="Add Study Project"
            onClick={() => setAddStudyProject(true)}
          />
        </Box>
      </Fragment>
    );
  },
);

export default withSnackbar(CourseCurriculum);
