import { Tabs, Tab } from '@material-ui/core';
import { useState } from 'react';

function TabPanel(props) {
  const { children, currentTab, index } = props;

  return currentTab === index ? children : null;
}

export function useTabs() {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  return { Tabs, Tab, TabPanel, currentTab, handleTabChange };
}
