import React from 'react';
import { Container, Box } from '@material-ui/core';
import { LibraryCards, LibraryBook } from '../../../components';
import { LibraryCardsClip } from '../../../components';
import styles from '../styles';

const QuestionResources = ({ question, response }) => {
  const classes = styles();

  if (response.case === 'hint' && question.hint !== null) {
    if (question.hint.type === 'quickdeck') {
      return (
        <Container
          maxWidth="md"
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'stretch',
          }}
        >
          <Box 
          width="100%"
          boxShadow='0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'>
            <LibraryCards cards={question.hint.cards} />
          </Box>
        </Container>
      );
    }
    if (question.hint.type === 'quickclip') {
      return (
        <Container
          maxWidth="md"
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'stretch',
            paddingBottom: '1em',
          }}
        >
          <Box className={classes.quickCardContainer}>
            <LibraryCardsClip cards={question.hint.clip_cards} />
          </Box>
        </Container>
      );
    }
    if (question.hint.type === 'page') {
      return (
        <Container maxWidth="md">
          <LibraryBook pages={question.hint.pages} />
        </Container>
      );
    }
    return null;
  }

  if (response.case === 'incorrect' && question.reveal !== null) {
    if (question.reveal.type === 'quickdeck') {
      return (
        <Container
          maxWidth="md"
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'stretch',
          }}
        >
          <Box 
          width="100%"
          boxShadow='0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'>
            <LibraryCards cards={question.reveal.cards} />
          </Box>
        </Container>
      );
    }
    if (question.reveal.type === 'quickclip') {
      return (
        <Container
          maxWidth="md"
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'stretch',
            paddingBottom: '1em'
          }}
        >
          <Box className={classes.quickCardContainer}>
            <LibraryCardsClip cards={question.reveal.clip_cards} />
          </Box>
        </Container>
      );
    }
    if (question.reveal.type === 'page') {
      return (
        <Container maxWidth="md">
          <LibraryBook pages={question.reveal.pages} />
        </Container>
      );
    }
    return null;
  }
  return null;
};

export default QuestionResources;
