import gql from 'graphql-tag';

const CREATE_BENCHMARK_RESULT = gql`
  mutation createBenchmarkResult($data: [BenchmarkResultCreateInput!]!) {
    createBenchmarkResult(data: $data) {
      id
      result
      answer
      question {
        id
        question
      }
      benchmark {
        id
        level
      }
    }
  }
`;

const STUDENT_BENCHMARK_RESULTS = gql`
  query getStudentBenchmarkResults($userId: ID!, $courseId: ID!) {
    benchmarkResults(where: { user_id: $userId, course_id: $courseId }) {
      id
      result
      answer
      question {
        id
        question
      }
      benchmark {
        id
        level
      }
    }
  }
`;

export { CREATE_BENCHMARK_RESULT, STUDENT_BENCHMARK_RESULTS };
