import React from 'react';
import { LoadingScreen, Heading } from '../../../../components';
import useStyles from './styles.js';
import {
  useFreeCourseBenchmarks,
  useFreeLessons,
} from '../../../../apollo/api';
import JoinGroup from '../JoinGroup';
import NoGroupCard from '../NoGroupCard';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

const useNoInstitutionData = () => {
  const { data, loading, error } = useFreeCourseBenchmarks();
  const {
    data: dataOne,
    loading: loadingOne,
    error: errorOne,
  } = useFreeLessons();

  return {
    freeCourseBenchmarks: data ? data.freeCourseBenchmarks : [],
    freeLessons: dataOne ? dataOne.freeLessons : [],
    loading: loading && loadingOne,
    error: error || errorOne,
  };
};

const NoInstitutionPage = ({ history, viewer }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    freeCourseBenchmarks,
    //freeLessons,
    loading,
    error,
  } = useNoInstitutionData();

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <p>Error...</p>;
  }

  return (
    <section className={classes.container}>
      {
        viewer ?
          <Box>
            <Heading variant="h2" component="h2" className={classes.header}>
              Have a Group key?
            </Heading>
            <Box mb={isMobile ? 6 : 8}>
              <JoinGroup className={classes.button} />
              <NoGroupCard className={classes.noGroupCard} />
            </Box>
          </Box> : null
      }
      <Heading variant="h2" component="h2" className={classes.header}>
        Test your knowledge in
      </Heading>
      <Box mb={isMobile ? 6 : 8}>
        <FreeCourseBenchmarks
          courses={freeCourseBenchmarks}
          history={history}
        />
      </Box>

      {/*<Heading variant="h2" component="h2" className={classes.header}>
        Try our free lessons
      </Heading>
      <Box mb={isMobile ? 6 : 8}>
        <FreeLessons lessons={freeLessons} history={history} />
    </Box>*/}
    </section>
  );
};

const JasperCard = ({ item, onClick }) => {
  const classes = useStyles();
  const color = item.product ? item.product.color : item.course?.product.color;
  const productName = item.product ? item.product.name : item.course?.product.name

  return (
    <Card
      onClick={onClick}
      style={{ borderTop: `4px solid ${color}` }}
      className={classes.card}
    >
      <CardHeader
        avatar={<Avatar src={item.logo} />}
        title={`${productName}: ${item.name}`}
        subheader={item.description}
        titleTypographyProps={{
          variant: 'h4',
          style: { fontWeight: 'bold' },
        }}
        subheaderTypographyProps={{
          variant: 'subtitle2',
          color: 'textSecondary',
        }}
      />
    </Card>
  );
};

const FreeCourseBenchmarks = ({ courses, history }) => {
  const courseList = courses.map((course) => (
    <Grid key={course.id} item xs={12} sm={6} md={4} lg={3}>
      <JasperCard
        key={course.id}
        item={course}
        onClick={() => history.push(`/free/course/${course.id}/benchmark/all`)}
      />
    </Grid>
  ));

  return (
    <Grid container spacing={2}>
      {courseList}
    </Grid>
  );
};

/*const FreeLessons = ({ lessons, history }) => {
  const lessonList = lessons.map((lesson) => (
    <Grid key={lesson.id} item xs={12} sm={6} md={4} lg={3}>
      <JasperCard
        key={lesson.id}
        item={lesson}
        onClick={() => history.push(`/free/lesson/${lesson.id}`)}
      />
    </Grid>
  ));
  return (
    <Grid container spacing={2}>
      {lessonList}
    </Grid>
  );
};*/

export default NoInstitutionPage;
