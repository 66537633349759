import React from 'react';
import { LibraryCards, LibraryCardList } from '../../../../components';
import { Container, Typography } from '@material-ui/core';

const QuickDeck = ({ cards }) => {
  return (
    <Container maxWidth="sm">
      <LibraryCards cards={cards} />
      <Typography>Terms in this set({cards.length})</Typography>
      <LibraryCardList cards={cards} />
    </Container>
  );
};

export default QuickDeck;
