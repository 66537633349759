import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ViewerContext } from '../providers/ViewerProvider';
import {
  StudyGroupDashboard,
  ContentManagement,
  SalesManagement,
  GroupDashboard,
  IframeLoad,
  IframeCourse,
  Login,
  StudyProject,
  Benchmark,
  ManageJasperactive,
  MyInstitution,
} from '../pages';
import { Header, AppShell } from '../components/layout';
import { PrivateRoute as PRoute } from '../components';
import { Callback } from '../components/auth/callback';
import { SilentRenew } from '../components/auth/silentRenew';
import FreeLessonDashboard from '../pages/FreeLessonDashboard/FreeLessonDashboard';
import MediaLibrary from '../pages/StudyProject/components/MediaLibrary';

export default () => {
  const { viewer } = useContext(ViewerContext);

  if (viewer) {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectURL = urlParams.get('url');
    if (redirectURL)
      window.location.replace('/' + redirectURL);
  }

  if (!viewer) {
    return (
      <AppShell header={<Header />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route
            path="/iframe/:authCode/:groupId/:courseId"
            component={IframeLoad}
          />
          <Route exact={true} path="/auth-callback" component={Callback} />
          <Route exact={true} path="/silent-renew" component={SilentRenew} />
          <Route
            path="/free/course/:courseId/benchmark/:level"
            component={Benchmark}
          />
          <Route
            path="/free/lesson/:lessonId/study-project/:projectId/media-library/:questionId/:type/:caseStudyNumber/:course_id"
            component={MediaLibrary}
          />
          <Route
            path="/free/lesson/:lessonId/study-project/:projectId"
            component={StudyProject}
          />
          <Route path="/free/lesson/:lessonId" component={FreeLessonDashboard} />
          <Route exact path="/" component={StudyGroupDashboard} />
          <Redirect from="*" to="/" />
        </Switch>
      </AppShell>
    );
  }
  return (
    <AppShell header={<Header />}>
      <Switch>
        <PRoute path="/manage-jasperactive" component={ManageJasperactive} />
        <PRoute path="/my-institution" component={MyInstitution} />
        <PRoute path="/cms" component={ContentManagement} />
        <PRoute path="/sales-management" component={SalesManagement} />
        <PRoute path="/group/:groupId" component={GroupDashboard} />
        <PRoute
          path="/free/course/:courseId/benchmark/:level"
          component={Benchmark}
        />
        <PRoute
          path="/free/lesson/:lessonId/study-project/:projectId/media-library/:questionId/:type/:caseStudyNumber/:course_id"
          component={MediaLibrary}
        />
        <PRoute
          path="/free/lesson/:lessonId/study-project/:projectId"
          component={StudyProject}
        />
        <PRoute path="/free/lesson/:lessonId" component={FreeLessonDashboard} />
        <PRoute
          path="/iframe/:authCode/:groupId/:courseId"
          component={IframeLoad}
        />
        <PRoute path="/iframe/:courseId" component={IframeCourse} />
        <Route exact={true} path="/silent-renew" component={SilentRenew} />
        <PRoute exact path="/" component={StudyGroupDashboard} />
        <Redirect from="*" to="/" />
      </Switch>
    </AppShell>
  );
};
