import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  cardMiddle: { 
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: 60,
  },
  grid: {
    flexGrow: 1,
  },
  noCards: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  h6: {
    fontSize: 14,
    verticalAlign: 'middle'
  },
  imgWidth: {
    'img': {
      width: '100%'
    }
  }
}));
