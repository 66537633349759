import React from 'react';
import {  Card, CardActionArea, CardHeader } from '@material-ui/core';
import { getReadableRole, studentToLearner } from '../../../../lib';
import useStyles from './styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import clsx from 'clsx';

const InstitutionCard = ({institution, isSelected, onClick, roleMember}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.card, {[classes.selected]: isSelected})}>
            <CardActionArea
              onClick={() => onClick(institution)}
            >
              <CardHeader 
                avatar={<AccountBalanceIcon fontSize="large" className={clsx(classes.avatar, {[classes.selected]: isSelected})} />} title={institution.name} 
                subheader={`Role: ${studentToLearner(getReadableRole(roleMember))}`}
                subheaderTypographyProps={{variant: "subtitle2", color: isSelected ? "inherit" : "initial"}}
                titleTypographyProps={{variant: "h4", color: isSelected ? "inherit" : "initial" }}
              />
          </CardActionArea>
        </Card>
  );
}

export default InstitutionCard;
