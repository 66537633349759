import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  completeContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    padding: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      height: 200,
      width: 200,
      marginBottom: theme.spacing(2),
    },
    '& p': {
      marginBottom: theme.spacing(2),
    },
    '& button': {
      width: '100%',
      maxWidth: 300,
    },
  },
}));
