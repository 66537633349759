import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  questionHeader: {
    background: theme.palette.white.main,
    boxShadow: 'none',
  },
  questionToolbar: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  benchmarkHeader_description: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start'
    }
  },
  filler: {
    height: 84,
    [theme.breakpoints.up('md')]: {
      height: 107,
    },
  },
  questionTimer: {
    ...theme.typography.h1,
    color: theme.palette.primary.main,
  },
  questionTitle: {
    ...theme.typography.h3,
    color: theme.palette.grey[500],
  },
  quitBtn: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight:"bold"
  }
}));
