import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  courseSection: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.light,
    overflow: 'auto',
    paddingBottom: theme.spacing(2),
  },
  courseHeader: {
    position: 'relative',
    zIndex: theme.zIndex.drawer,
    boxShadow: theme.shadows[2],
    background: theme.palette.common.white,
  },
  tabs: {
    color: theme.palette.text.primary,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid #CFD8DC`,
    background: theme.palette.common.white,
  },
  toolbarButtonWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    position: 'static',
  },

  [theme.breakpoints.down('sm')]: {
    appBar: {
      top: 'auto',
      bottom: 0,
      position: 'fixed',
    },
    appBarFount: {
      fontSize: 10,
    },
    '.MuiTab-textColorInherit.Mui-selected': {
      color: '#BF1212',
    },
  },
}));
