import { makeStyles } from '@material-ui/core/styles';
import BackgroundImage from '../../../assets/background/study-group-dashboard-bg-black.png';

export default makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: "white",
    backgroundImage: `url(${BackgroundImage})`,
  },
  heading: {
    background: theme.palette.common.white,
  },
  main: {
    flexGrow: 1,
    overflow: 'auto',
  },
}));
