import React from 'react';
import { Button } from '@material-ui/core';
import { KeyElementContainer } from '../../../../../../../containers';
import styles from './styles';

const PublishKeyElement = ({ keyElement, enqueueSnackbar }) => {
  const classes = styles();

  const handleSubmit = async (publishKeyElement, unpublishKeyElement) => {
    const options = {
      variables: { id: keyElement.id },
      refetchQueries: ['getKeyElements'],
    };
    keyElement.published
      ? await unpublishKeyElement.mutation(options)
      : await publishKeyElement.mutation(options);

    enqueueSnackbar(
      `${keyElement.name} ${
        keyElement.published ? 'unpublished' : 'published'
      }`,
      { variant: 'success', autoHideDuration: 1500 },
    );
  };

  return (
    <KeyElementContainer>
      {({ publishKeyElement, unpublishKeyElement }) => (
        <Button
          className={classes.toolbarButton}
          variant="contained"
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            handleSubmit(publishKeyElement, unpublishKeyElement);
          }}
        >
          {keyElement.published ? 'Unpublish' : 'Publish'}
        </Button>
      )}
    </KeyElementContainer>
  );
};

export default PublishKeyElement;
