import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  progressWrapper: {
    height: 20,
    padding: `0px 24px`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  iconTrack: {
    height: 10,
    width: '100%',
    position: 'relative',
  },
  icon: {
    position: 'relative',
    top: -12,
  },
  percentProgressBar: {
    width: '100%',
    display: 'flex',
  },
  percentBlock: {
    width: '33.3333333%',
    height: 3,
  },
  fairBlock: {
    background: '#EF2A15',
  },
  goodBlock: {
    background: '#F28D27',
  },
  excellentBlock: {
    background: '#35F72B',
  },
  // Overview Text
  overviewText: {
    flex: 1,
    fontWeight: 'bold',
  },
}));

export default styles;
