import React from 'react';

const MyInstitution = () => {
  return (
    <div>
      My Institution
    </div>
  );
}

export default MyInstitution;
