import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER_INSTITUTION_GROUPS } from '../../../apollo/queries';
import { LoadingScreen } from '../../../components';

const GroupsContext = React.createContext();

const GroupsProvider = ({ institutionId, userId, children }) => {
  const { data, loading, error } = useQuery(GET_USER_INSTITUTION_GROUPS, {
    variables: { userId, institutionId },
  });

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error...</p>;

  const groups = data.groups;

  return (
    <GroupsContext.Provider value={{ groups }}>{children}</GroupsContext.Provider>
  );
};

export const useGroups = () => {
  const data = useContext(GroupsContext);

  return data ? data.groups : null;
};

export default GroupsProvider;