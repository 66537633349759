import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Timer } from '../../../../components';
import { useContext } from 'react';
import styles from './styles';
import TimeIcon from '../../../../assets/icons/time_icon.svg';
import { UserConfirmationContext } from '../../../../providers/UserConfirmationProvider';

const BenchmarkTestHeader = ({
  showOverview,
  questions,
  questionIndex,
  hideTimer,
  time,
  pauseTimer = false,
  onTimerComplete,
  courseLogo,
  courseName,
  level,
  title,
  timerRef,
}) => {
  const classes = styles();

  const { showConfirmation } = useContext(UserConfirmationContext);

  return (
    <Box display="flex" flexDirection="column" my={2}>
      {showOverview && (
        <Typography variant="overline" color="textSecondary">
          Questions Review
        </Typography>
      )}
      <Box className={classes.benchmarkHeader_description}>
        <Box>
          {!showOverview && (
            <Typography>
              QUESTION {questionIndex + 1} OF {questions.length}
            </Typography>
          )}
          {!showOverview && (
            <Typography style={{ fontWeight: 'bold' }}>
              CHOOSE THE CORRECT ANSWER(S)
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <img src={courseLogo} alt="courseLogo" width={30} height={30} />
              <Typography style={{ fontWeight: 'bold', marginLeft: 8 }}>
                {courseName}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography style={{ marginRight: 8 }}>
                {level === 'all' ? 'All Levels ' : `Level ${level} `}
              </Typography>
              <Typography>{title}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {!hideTimer && (
        <Box my={2} display="flex" flexDirection="column">
          <img src={TimeIcon} alt="timeicon" width={20} height={20} />
          <Typography color="primary">TIME REMAINING: </Typography>
          <Timer
            style={{ fontSize: 20 }}
            time={time}
            paused={pauseTimer}
            ref={timerRef}
            onEnd={() =>
              showConfirmation({
                title: 'Times Up!',
                message:
                  'Time has run out, Click any button to view the results',
                confirmed: (event) => onTimerComplete(),
                cancel: (event) => onTimerComplete(),
                confirmText: 'Go to Results',
              })
            }
            className={classes.questionTimer}
            color="primary"
          />
        </Box>
      )}
    </Box>
  );
};

export default BenchmarkTestHeader;
