import gql from 'graphql-tag';
import { StudyProjectFields, LessonFields } from './fragments';

const CREATE_STUDY_PROJECT = gql`
  mutation createStudyProject($data: StudyProjectCreateInput!) {
    createStudyProject(data: $data) {
      ...StudyProjectFields
    }
  }
  ${StudyProjectFields}
`;

const GET_STUDY_PROJECT = gql`
  query getStudyProject($projectId: ID!) {
    studyProject(where: { id: $projectId }) {
      ...StudyProjectFields
    }
  }
  ${StudyProjectFields}
`;

const GET_LESSON_STUDY_PROJECTS = gql`
  query getLessonStudyProjects($lessonId: ID!) {
    studyProjects(where: { lesson_id: $lessonId }) {
      ...StudyProjectFields
      lesson {
        ...LessonFields
      }
    }
  }
  ${StudyProjectFields}
  ${LessonFields}
`;

const CMS_GET_STUDY_PROJECT = gql`
  query getCMSStudyProject($where: StudyProjectWhereUniqueInput!) {
    studyProject(where: $where) {
      ...StudyProjectFields
    }
  }
  ${StudyProjectFields}
`;

const UPDATE_STUDY_PROJECT = gql`
  mutation updateStudyProject(
    $data: StudyProjectUpdateInput!
    $where: StudyProjectWhereUniqueInput!
  ) {
    updateStudyProject(data: $data, where: $where) {
      ...StudyProjectFields
    }
  }
  ${StudyProjectFields}
`;

const PUBLISH_STUDY_PROJECT = gql`
  mutation publishStudyProject($id: ID!) {
    publishStudyProject(id: $id)
  }
`;

const UNPUBLISH_STUDY_PROJECT = gql`
  mutation unpublishStudyProject($id: ID!) {
    unpublishStudyProject(id: $id)
  }
`;

const DELETE_STUDY_PROJECT = gql`
  mutation deleteStudyProject($id: ID!) {
    deleteStudyProject(id: $id)
  }
`;

export {
  CREATE_STUDY_PROJECT,
  GET_LESSON_STUDY_PROJECTS,
  CMS_GET_STUDY_PROJECT,
  UPDATE_STUDY_PROJECT,
  PUBLISH_STUDY_PROJECT,
  UNPUBLISH_STUDY_PROJECT,
  DELETE_STUDY_PROJECT,
  GET_STUDY_PROJECT,
};
