import React, { useState, useMemo, useCallback, useContext } from 'react';
import {
  Paper,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  Modal,
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import styles from './styles';
import { useGroup } from '../../providers/GroupProvider';
import { useGroups } from '../../providers/GroupsProvider';
import { Heading } from '../../../../components';
import { GROUP_ROLES } from '../../../../lib';
import GroupMembersFinder from './GroupMembersFinder';
import { useGroupMember } from '../../providers/GroupMemberProvider';
import AddInstructor from '../AddInstructor';
import { UserConfirmationContext } from '../../../../providers/UserConfirmationProvider';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_GROUP_MEMBER } from '../../../../apollo/queries';
import { useSnackbar } from 'notistack';
import { studentToLearner } from '../../../../lib/studentToLearner';
import { useInstitutionMembers } from '../../../../apollo/api';

const GroupMembers = () => {
  const group = useGroup();
  const groups = useGroups(); //Gets groups by Gmetrix Instructor
  const { members: institutionMembers } = useInstitutionMembers(group.institution.id);
  const classes = styles();
  const member = useGroupMember();
  const [showStudentsFinder, setShowStudentsFinder] = useState(false);
  const [showInstructorFinder, setShowInstructorFinder] = useState(false);
  const { showConfirmation } = useContext(UserConfirmationContext);
  const { enqueueSnackbar } = useSnackbar();

  const memozeidCallback = useCallback(() => {
    setShowInstructorFinder(false);
  }, [setShowInstructorFinder]);

  const [deleteGroupMember] = useMutation(DELETE_GROUP_MEMBER, {
    refetchQueries: ['getUserInstitutions'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar('Group member has been deleted', {
        variant: 'success'
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  }); 
  
  const members = group.members;

  const mainInstructor = members.filter(
    (member) => member.role === 'MAIN_INSTRUCTOR',
  );
  const instructors = [
    ...mainInstructor,
    ...members.filter((member) => member.role === 'INSTRUCTOR' && member.deletedBy === null),
  ];
  const students = members.filter((member) => member.role === 'STUDENT');

  const handleDeleteMember = (member) => {
    showConfirmation({
      title: `Delete Group Member`,
      message:
        'Group member will be removed from this group',
      confirmed: async () => {
        await deleteGroupMember({
          variables: {
            data: {
              user_id: member.user.id,
              group_id: group.id
            }
          }
        })
      },
    });
  }

  return (
    <div className={classes.peopleContainer}>
      <Modal 
      open={showStudentsFinder}
      onClose={() => setShowStudentsFinder(false)}>
        <GroupMembersFinder institutionMembers={institutionMembers} newGroup={group} setShowStudentsFinder={setShowStudentsFinder} groups={groups}/>
      </Modal>
      <Modal
      open={showInstructorFinder}
      onClose={() => setShowInstructorFinder(false)}
      >
        <AddInstructor institutionMembers={institutionMembers} newGroup={group} setShowInstructorFinder={memozeidCallback}/>
      </Modal>
      <Paper>
        <Box p={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1em'}}>
          <Heading variant="h2" component="h2">
            Group Members
          </Heading>
          {
            member.role === 'STUDENT' ? null
              : <Button onClick={() => setShowStudentsFinder(true)} color='primary' variant='outlined' >Search for learners</Button>
          }
        </Box>
        <Box p={2} pb={0}>
          <Heading variant="h3" component="h3">
            Instructors
          </Heading>
        </Box>
        <Box style={{overflow: 'auto'}} mb={2}>
          <MemberTable handleDeleteMember={handleDeleteMember} members={instructors} classes={classes} />
          {
            member.role === 'STUDENT' ? null
              : <Box style={{margin: '1em'}}><Button color='primary' variant='outlined'  onClick={() => setShowInstructorFinder(true)} >Add Additional Instructor</Button></Box>
          }
        </Box>

        <Box p={2} pb={0}>
          <Heading variant="h3" component="h3">
            Learners
          </Heading>
        </Box>
        <Box style={{overflow: 'auto'}} pb={6}>
          <MemberTable members={students} classes={classes} />
        </Box>
      </Paper>
    </div>
  );
};

export default GroupMembers;

const MemberTable = ({ members, classes, handleDeleteMember }) => {
  const { sortedMembers, requestSort } = useSortedMembers(members);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '33.33333333%' }}>
            <div style={{ display: 'flex' }}>
              <Heading variant="h4" component="h4">
                Name
              </Heading>
              {sortedMembers.length !== 0 ? (
                <SortIcon
                  className={classes.sortIcon}
                  onClick={() => {
                    requestSort('first_name');
                  }}
                />
              ) : (
                ''
              )}
            </div>
          </TableCell>
          <TableCell style={{ width: '33.33333333%' }}>
            <div style={{ display: 'flex' }}>
              <Heading variant="h4" component="h4">
                Email
              </Heading>
              {sortedMembers.length !== 0 ? (
                <SortIcon
                  className={classes.sortIcon}
                  onClick={() => {
                    requestSort('email');
                  }}
                />
              ) : (
                ''
              )}
            </div>
          </TableCell>
          <TableCell style={{ width: '33.33333333%' }}>
            <div style={{ display: 'flex' }}>
              <Heading variant="h4" component="h4">
                Role
              </Heading>
              {sortedMembers.length !== 0 ? (
                <SortIcon
                  className={classes.sortIcon}
                  onClick={() => {
                    requestSort('role');
                  }}
                />
              ) : (
                ''
              )}
            </div>
          </TableCell>
          <TableCell style={{ width: '33.33333333%' }}>
            {/*Column for delete button*/}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedMembers.map((member, index) => {
          return (
            <TableRow key={member ? member.id : index}>
              <TableCell style={{ width: '33.33333333%' }}>
                <Typography variant="body1" color="textSecondary">
                  {member.user.first_name} {member.user.last_name}
                </Typography>
              </TableCell>
              <TableCell style={{ width: '33.33333333%' }}>
                <Typography variant="body1" color="textSecondary">
                  {member.user.email}
                </Typography>
              </TableCell>
              <TableCell style={{ width: '33.33333333%' }}>
                <Typography variant="body1">
                  {studentToLearner(GROUP_ROLES.find((role) => role.value === member.role).name)}
                </Typography>
              </TableCell>
              <TableCell style={{ width: '33.33333333%' }}>
                {
                  member.role === 'MAIN_INSTRUCTOR' || member.role === 'STUDENT' ? null 
                    : <Button onClick={() => handleDeleteMember(member)} color='primary' variant='outlined' >Delete</Button>
                }
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const useSortedMembers = (members, config = null) => {
  const [sortconfig, setSortConfig] = useState(config);
  const sortedMembers = useMemo(() => {
    let sortableMembers = [...members];
    if (sortconfig !== null) {
      sortableMembers.sort((a, b) => {
        if (sortconfig.key !== 'role') {
          if (a.user[sortconfig.key] < b.user[sortconfig.key]) {
            return sortconfig.direction === 'ascending' ? -1 : 1;
          }

          if (a.user[sortconfig.key] > b.user[sortconfig.key]) {
            return sortconfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }

        if(sortconfig.key === 'role'){
          if (a.role < b.role) {
            return sortconfig.direction === 'ascending' ? -1 : 1;
          }

          if (a.role > b.role) {
            return sortconfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
        return 0;
      });
    }

    return sortableMembers;
  }, [members, sortconfig]);
  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortconfig &&
      sortconfig.key === key &&
      sortconfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  return { sortedMembers, requestSort, sortconfig };
};
