import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Typography, Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const Timer = React.forwardRef(({ time, onEnd, paused, ...restProps }, ref) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const normalizeTime = (value) => {
    return ((value - 0) * 100) / (time - 0);
  };
  const [timeProgress, setTimeProgress] = useState(time);
  const [timeSpentByQuestion, setTimeSpentByQuestion] = useState(0);

  const resetTimeSpent = () => {
    setTimeSpentByQuestion(0);
  };

  useImperativeHandle(ref, () => {
    return {
      resetTimeSpent,
      getTimeSpentByQuestion: () => { return timeSpentByQuestion }
    }
  }, [timeSpentByQuestion]);

  useEffect(() => {
    let remainingSeconds = time;
    const hours = Math.floor(time / 3600);
    remainingSeconds = time - hours * 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds - minutes * 60;

    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  }, [time]);

  useEffect(() => {
    let interval = null;
    if (!paused) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            if (hours === 0) {
              clearInterval(interval);
              onEnd(true);
            } else {
              setHours(hours - 1);
              setMinutes(59);
              setSeconds(59);
            }
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
        setTimeSpentByQuestion(prevState => prevState + 1);
        setTimeProgress(t => t - 1);
      }, 1000);
    } else if ((paused && seconds !== 0) || minutes !== 0 || hours !== 0) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [hours, minutes, seconds, paused, onEnd]);

  return (
    <Box display="flex" flexDirection="column" >
      <Typography {...restProps}>
        {hours === 0 ? '' : hours}
        {hours > 0 ? ':' : ''}
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </Typography>
      <LinearProgress style={{ width: "100%" }} value={normalizeTime(timeProgress)} variant="determinate" />
    </Box>
  );
});

export default Timer;
