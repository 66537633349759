import gql from 'graphql-tag';
import { LicenseFields } from './fragments';

const CREATE_LICENSE = gql`
  mutation createLicense($data: LicenseCreateInput!) {
    createLicense(data: $data) {
      ...LicenseFields
    }
  }
  ${LicenseFields}
`;

const GET_ALL_INSTITUTIONS = gql`
  query getAllLicense {
    licenses {
      ...LicenseFields
    }
  }
  ${LicenseFields}
`;

const CREATE_LICENSE_QUOTE = gql`
  mutation createLicenseQuote($data: LicenseCreateInputQuote!) {
    createLicenseQuote(data: $data) {
      ...LicenseFields
    }
  }
  ${LicenseFields}
`;

const UPDATE_LICENSE = gql`
  mutation updateLicense(
    $data: LicenseUpdateInput!
    $where: LicenseWhereUniqueInput!
  ) {
    updateLicense(data: $data, where: $where) {
      ...LicenseFields
    }
  }
  ${LicenseFields}
`;

const RENEW_LICENSE = gql`
  mutation renewLicense($data: LicenseRenewalInput!) {
    renewLicense(data: $data) {
      ...LicenseFields
    }
  }
  ${LicenseFields}
`;

const GET_LICENSES_UPCOMING_RENEWALS_BY_DAYS = gql`
  query getLicensesUncomingByDays($where: LicenseWhereUniqueInputCostume!) {
    licenseComming(where: $where) {
      id
      name
      created_by
      created_on
      days_to_expire
      purchase_order
      expiry_date
      institution {
        id
        name
      }
    }
  }
`;

const GET_LICENSES_BY_ID_INSTITUTION = gql`
  query getLicenseByIdInstitution(
    $where: LicenseWhereUniqueInputByIdInstitution!
  ) {
    licensesByIdInstitution(where: $where) {
      id
      name
      max_users
      is_gmetrix
      products {
        id
        name
      }
      created_on
      expiry_date
      days_to_expire
      purchase_order
      is_quoted
      key
      institution {
        id
        created_by
      }
    }
  }
`;

const SEARCH_LICENSES = gql`
  query searchLicenses(
    $pageSize: Int
    $after: String
    $search: String
    $filter: LicenseFilter!
  ) {
    paginatedLicenses(
      pageSize: $pageSize
      after: $after
      search: $search
      filter: $filter
    ) {
      cursor
      hasMore
      totalCount
      licenses {
        ...LicenseFields
      }
    }
  }
  ${LicenseFields}
`;

const GET_LICENSE_BY_ID = gql`
  query getLicenseById($where: LicenseWhereUniqueInput!) {
    license(where: $where) {
      ...LicenseFields
    }
  }
  ${LicenseFields}
`;

const UPDATE_LICENSES_STATUS_ACTIVE = gql`
  mutation updateLicenseStatusActive(
    $data: LicenseActiveUpdateInput!
    $where: LicenseActiveWhereUniqueInput!
  ) {
    updateLicenseStatusActive(data: $data, where: $where) {
      ...LicenseFields
    }
  }
  ${LicenseFields}
`;

const UPDATE_LICENSES_STATUS_RENEW = gql`
  mutation updateLicenseStatusRenew(
    $data: LicenseRenewUpdateInput!
    $where: LicenseRenewWhereUniqueInput!
  ) {
    updateLicenseStatusRenew(data: $data, where: $where) {
      ...LicenseFields
    }
  }
  ${LicenseFields}
`;

const UPDATE_LICENSES_STATUS_MODIFY = gql`
  mutation updateLicenseStatusModify(
    $data: LicenseModifyUpdateInput!
    $where: LicenseModifyWhereUniqueInput!
  ) {
    updateLicenseStatusModify(data: $data, where: $where) {
      ...LicenseFields
    }
  }
  ${LicenseFields}
`;

export {
  CREATE_LICENSE,
  UPDATE_LICENSE,
  RENEW_LICENSE,
  GET_ALL_INSTITUTIONS,
  SEARCH_LICENSES,
  CREATE_LICENSE_QUOTE,
  UPDATE_LICENSES_STATUS_ACTIVE,
  UPDATE_LICENSES_STATUS_RENEW,
  UPDATE_LICENSES_STATUS_MODIFY,
  GET_LICENSES_UPCOMING_RENEWALS_BY_DAYS,
  GET_LICENSES_BY_ID_INSTITUTION,
  GET_LICENSE_BY_ID,
};
