import React, { Fragment, useState } from 'react';
import {
  Typography,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Card,
  CardHeader,
  CardActions,
} from '@material-ui/core';
import useStyles from './styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useViewer } from '../../../providers/ViewerProvider';
import LogoutButton from '../../auth/LogoutButton/LogoutButton';
import {
  useUserInvitations,
  useAddInsitutionMember,
  useDeleteInvitations,
} from '../../../apollo/api';
import { PrimaryButton } from '../../common';
import { useInstitution } from '../../../providers/InstitutionProvider';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { IDENTITY_CONFIG } from '../../../utils/authConst';

const UserMenu = ({isIframe}) => {
  const viewer = useViewer();
  const classes = useStyles();
  const [updatingId, setUpdatingId] = useState(null);
  const { data, loading, error, refetch } = useUserInvitations(viewer.email);
  const { addInstitutionMember } = useAddInsitutionMember();
  const { deleteInstitutionInvite } = useDeleteInvitations();
  const { institutions } = useInstitution();

  const [open, setOpen] = useState(false);

  const toggleMenu = () => setOpen((open) => !open);

  if (error) {
    return <p>error...</p>;
  }

  const invitations = data ? data.userInvitations : [];

  const isAlreadyMember = (institutionId) => {
    return institutions.map((i) => i.id).includes(institutionId);
  };

  const onAccept = async (invitation) => {
    setUpdatingId(invitation.id);
    await addInstitutionMember({
      variables: {
        data: {
          user_id: viewer.id,
          institution_id: invitation.institution.id,
          role: invitation.role,
        },
      },
    });
    await deleteInstitutionInvite({
      variables: {
        id: invitation.id,
      },
    });
    await refetch();
    setUpdatingId(null);
  };

  const onDecline = async (invitation) => {
    setUpdatingId(invitation.id);
    await deleteInstitutionInvite({
      variables: {
        id: invitation.id,
      },
    });
    await refetch();
    setUpdatingId(null);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classes.container}>
        <AvatarButton
          onClick={toggleMenu}
          viewer={viewer}
          open={open}
          className={classes.mainButton}
        />
        {open ? (
          <Card className={classes.card}>
            <CardHeader
              avatar={<Avatar src={viewer.avatar} />}
              title={`${viewer.first_name} ${viewer.last_name}`}
              subheader={viewer.email}
            />
            <Divider />
            {
              !isIframe ?
                <UserInvitations
                  isAlreadyMember={isAlreadyMember}
                  invitations={invitations}
                  onAccept={onAccept}
                  onDecline={onDecline}
                  updatingId={updatingId}
                /> : null
            }

            <Divider />
            {
              !isIframe ?
                <CardActions>
                  <Box display="flex" flexDirection="column" style={{ gap: "10px" }} mx="auto">
                    <PrimaryButton
                      startIcon={<AccountBoxIcon />}
                      onClick={() => { window.open(`${IDENTITY_CONFIG.authority}/account/userprofile`) }}>
                      My Account
                    </PrimaryButton>
                    <LogoutButton />
                  </Box>
                </CardActions> : null
            }
          </Card>
        ) : loading && !isIframe ? (
          <Typography>Fetching invitations...</Typography>
        ) : null}
        {!isIframe && invitations && invitations.length > 0 && (
          <div className={classes.notifcationIndicator}></div>
        )}
      </div>
    </ClickAwayListener>
  );
};

const AvatarButton = ({ viewer, open, onClick, ...restProps }) => (
  <Box display="flex" alignItems="center" onClick={onClick} {...restProps}>
    <Avatar src={viewer.avatar} />
    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
  </Box>
);

const UserInvitations = ({
  invitations,
  onAccept,
  onDecline,
  isAlreadyMember,
  updatingId,
}) => {
  if (invitations.length === 0) {
    return (
      <Box p={2}>
        <Typography color="textSecondary">No invitations currently</Typography>
      </Box>
    );
  }

  const invitationList = invitations.map((invite) => (
    <Fragment key={invite.id}>
      <Box display="flex" flexWrap="wrap" p={2}>
        <Typography>
          {invite.created_by.first_name} {invite.created_by.last_name} invited
          you to join {invite.institution.name}
        </Typography>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <Button
            style={{ marginRight: 8 }}
            variant="text"
            onClick={() => onDecline(invite)}
            disabled={invite.id === updatingId}
          >
            Decline
          </Button>
          <PrimaryButton
            variant="text"
            onClick={() => onAccept(invite)}
            disabled={
              invite.id === updatingId || isAlreadyMember(invite.institution.id)
            }
          >
            {isAlreadyMember(invite.institution.id)
              ? 'Already a Member'
              : 'Accept'}
          </PrimaryButton>
        </Box>
      </Box>
      <Divider color="primary" />
    </Fragment>
  ));

  return (
    <Box maxHeight={250} overflow="auto" bgcolor="grey.100">
      {invitationList}
    </Box>
  );
};

export default UserMenu;
