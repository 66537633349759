import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  overviewText: {
    flex: 1,
    fontWeight: 'bold',
  },
}));

export default styles;
